import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { fetchDatasets } from '../../../store/actions/main/datasetsActions';

class DatasetSort extends Component {
  render() {
    return (
      <div className="float-right">
        <Form.Control size="sm" as="select" id="sort_value" className="mr-5 filter-organization" onChange={(e) => {
          let filter = {}
          if (typeof(this.props.datasets.datasetFilter.org_id) !== "undefined") {
            filter = {
              org_id: [this.props.datasets.datasetFilter.org_id]
            }
          }

          if (typeof(this.props.datasets.datasetFilter.group_id) !== "undefined") {
            const groupId = this.props.datasets.datasetFilter.group_id;
            if (groupId > 0) {
              filter = {
                group_id: [this.props.datasets.datasetFilter.group_id]
              }
            }
          }

          if (typeof(this.props.datasets.datasetFilter.quality_id) !== "undefined") {
            Object.assign(filter, {
              quality_id: [this.props.datasets.datasetFilter.quality_id]
            })
          }
          
          const sortValue = document.getElementById('sort_value').value;
          switch (sortValue) {
            case "newest":
              this.props.fetchDatasets(filter, null, {created_at: "desc"});
              break;
            case "last_modified":
              this.props.fetchDatasets(filter, null, {updated_at: "desc"});
              break;
            case "asc":
              this.props.fetchDatasets(filter, null, {name: "asc"});
              break;
            case "desc":
              this.props.fetchDatasets(filter, null, {name: "desc"});
              break;
            default:
              this.props.fetchDatasets(filter, null, {created_at: "desc"});
              break;
          }

          document.getElementById('pagination-dropdown').value = 1; 
        }}>
          <option value="newest">Newest</option>
          <option value="last_modified">Last Modified</option>
          <option value="asc">Alphabetical (Ascending)</option>
          <option value="desc">Alphabetical (Descending)</option>
        </Form.Control>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  datasets: state.datasets,
})

const mapDispatchToProps = (dispatch) => {
  return {
      fetchDatasets: (...args) => dispatch(fetchDatasets(...args)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetSort)
