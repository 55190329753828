import axios from 'axios';
import qs from 'qs';
import { store } from '../../../app/store';
import * as apiUtils from '../../../common/DatasetPermissionsAPIUtils';
import { successAPIResDisplay } from '../generalActions';
import { getIdToken } from '../../helpers/tokens';
import { toggleShowAlert, ERROR_ALERT } from '../../actions/generalActions';
import { DATASET_PRIVATE, DATASET_PUBLIC_ALL, DATASET_PUBLIC_ORG } from '../../../store/actions/main/datasetsActions';

export const SHOW_MANAGE_ACCESS_FORM = 'SHOW_MANAGE_ACCESS_FORM';
export const SET_USER_TO_GRANT = 'SET_USER_TO_GRANT';
export const SET_GROUP_TO_GRANT = 'SET_GROUP_TO_GRANT';
export const CLEAR_USER_TO_GRANT = 'CLEAR_USER_TO_GRANT';
export const CLEAR_GROUP_TO_GRANT = 'CLEAR_GROUP_TO_GRANT';
export const SHOW_PUBLIC_TYPES_OPTIONS = 'SHOW_PUBLIC_TYPES_OPTIONS';
export const SET_PERMITTED_USERS = 'SET_PERMITTED_USERS';
export const SET_PERMITTED_GROUPS = 'SET_PERMITTED_GROUPS';
export const SET_PERMITTED_ORGS = 'SET_PERMITTED_ORGS';
export const USER_PERMISSIONS_PAGINATION_DETAILS = 'USER_PERMISSIONS_PAGINATION_DETAILS';
export const GROUP_PERMISSIONS_PAGINATION_DETAILS = 'GROUP_PERMISSIONS_PAGINATION_DETAILS';
export const ORG_PERMISSIONS_PAGINATION_DETAILS = 'ORG_PERMISSIONS_PAGINATION_DETAILS';
export const SET_ALL_PERMISSIONS = 'SET_ALL_PERMISSIONS';
export const SET_ORG_TO_GRANT = 'SET_ORG_TO_GRANT';
export const SET_PERMISSION = 'SET_PERMISSION';
export const CLEAR_PERMISSION = 'CLEAR_PERMISSION';

export const PERMISSION_TYPE_ORG = 'PERMISSION_TYPE_ORG';
export const PERMISSION_TYPE_GROUP = 'PERMISSION_TYPE_GROUP';
export const PERMISSION_TYPE_USER = 'PERMISSION_TYPE_USER';

export const shareToUser = () => {
  return (dispatch, getState) => {
    
    if (typeof(getState().datasetPermissions.userToGrant.user) !== "undefined") {
      const user = getState().datasetPermissions.userToGrant.user;
      const role = getState().datasetPermissions.userToGrant.role;
      if (user && role) {
        const values = {
          dataset_id: getState().datasetPermissions.datasetId,
          type: PERMISSION_TYPE_USER,
          role: role, 
          target_id: user.id
        } 
        axios.post(apiUtils.CREATE_DATASET_PERMISSIONS_ENDPOINT, values, {
          headers: {
            "id-token": getIdToken()
          }
        }).then(json => {
          const result = json.data;
          if (result) {
            const customMessage = "Dataset has been successfully shared to the user.";
            dispatch(successAPIResDisplay(json, customMessage));
            dispatch(fetchPermissions(getState().datasetPermissions.datasetId, PERMISSION_TYPE_USER, null, null, true));
          }
        }).catch(error => {
          console.log(error);
          let errorMessage = '';
          if (typeof(error.reponse) !== "undefined") {
            let response = error.response;
            if (typeof(response.data) !== "undefined") {
              errorMessage = error.response.data.message;
            }
            else {
              errorMessage = "An error occured while sharing dataset to the user.";
            }
            dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
          }
        })
      }
      else {
        const errorMessage = "Please enter user and role.";
        dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
      }
    }
  }
}

export const shareToGroup = () => {
  return (dispatch, getState) => {
    
    if (Object.keys(getState().datasetPermissions.groupToGrant).length > 0) {
      const group = getState().datasetPermissions.groupToGrant;
      const values = {
        dataset_id: getState().datasetPermissions.datasetId,
        type: PERMISSION_TYPE_GROUP,
        target_id: group.id
      } 
      axios.post(apiUtils.CREATE_DATASET_PERMISSIONS_ENDPOINT, values, {
        headers: {
          "id-token": getIdToken()
        }
      }).then(json => {
        const result = json.data;
        if (result) {
          const customMessage = "Dataset has been successfully shared to the group.";
          dispatch(successAPIResDisplay(json, customMessage));
          dispatch(fetchPermissions(getState().datasetPermissions.datasetId, PERMISSION_TYPE_GROUP, null, null, true));
        }
      }).catch(error => {
        console.log(error);
        let errorMessage = '';
        if (typeof(error.reponse) !== "undefined") {
          let response = error.response;
          if (typeof(response.data) !== "undefined") {
            errorMessage = error.response.data.message;
          }
          else {
            errorMessage = "An error occured while sharing the dataset to the group.";
          }
          dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
        }
      })
    }
  }
}

export const shareToOrg = () => {
  return (dispatch, getState) => {
    
    if (Object.keys(getState().datasetPermissions.orgToGrant).length > 0) {
      const org = getState().datasetPermissions.orgToGrant;
      const values = {
        dataset_id: getState().datasetPermissions.datasetId,
        type: PERMISSION_TYPE_ORG,
        target_id: org.id
      } 
      axios.post(apiUtils.CREATE_DATASET_PERMISSIONS_ENDPOINT, values, {
        headers: {
          "id-token": getIdToken()
        }
      }).then(json => {
        const result = json.data;
        if (result) {
          const customMessage = "Dataset has been successfully shared to the organization.";
          dispatch(successAPIResDisplay(json, customMessage));
          dispatch(fetchPermissions(getState().datasetPermissions.datasetId, PERMISSION_TYPE_ORG, null, null, true));
        }
      }).catch(error => {
        console.log(error);
        let errorMessage = '';
        if (typeof(error.reponse) !== "undefined") {
          let response = error.response;
          if (typeof(response.data) !== "undefined") {
            errorMessage = error.response.data.message;
          }
          else {
            errorMessage = "An error occured while sharing the dataset to the organization.";
          }
          dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
        }
      })
    }
  }
}

/**
 * Deletes dataset permission (user/group)
 */
export const unshareDataset = (permissionId, targetType = null) => {
  return (dispatch, getState) => {
    if (permissionId) {
      axios.delete(apiUtils.DELETE_DATASET_PERMISSIONS_ENDPOINT, {
        headers: {
          "id-token": getIdToken()
        },
        params: {
          id: permissionId
        }
      }).then(json => {
        const result = json.data;
        if (result) {
          let txtTargetType;
          switch(targetType) {
            case PERMISSION_TYPE_USER:
              txtTargetType = "user";
              break;
            case PERMISSION_TYPE_GROUP:
              txtTargetType = "group";
              break;
            case PERMISSION_TYPE_ORG:
              txtTargetType = "org";
              break;
            default:
              txtTargetType = "";
              break;
          }
          const customMessage = "Dataset has been successfully unshared from the " + txtTargetType + ".";
          dispatch(successAPIResDisplay(json, customMessage));
          dispatch(fetchPermissions(getState().datasetPermissions.datasetId, targetType, null, null, true));
        }
      }).catch(error => {
        console.log(error);
        let errorMessage = '';
        if (typeof(error.reponse) !== "undefined") {
          let response = error.response;
          if (typeof(response.data) !== "undefined") {
            errorMessage = error.response.data.message;
          }
          else {
            errorMessage = "An error occured while removing permission.";
          }
          dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
        }
      })
    }
  }
}

export const setGroupToGiveAccess = (group) => {
  return (dispatch) => {
    dispatch({type: SET_GROUP_TO_GRANT, group})
  }
}

export const clearGroupToGiveAccess = () => {
  return (dispatch) => {
    dispatch({type: CLEAR_GROUP_TO_GRANT});
  }
}

export const setOrgToGiveAccess = (org) => {
  return (dispatch) => {
    dispatch({type: SET_ORG_TO_GRANT, org})
  }
}

export const setUserToGiveAccess = (e, definedValue = null) => {
  return (dispatch) => {
    let value = '';
    if (!definedValue) {
      value = {
        [e.target.id]: e.target.value
      }
    }
    else {
      value = definedValue
    }
    dispatch({type: SET_USER_TO_GRANT, value})
  }
}

export const clearUserToGiveAccess = () => {
  return (dispatch, getState) => {
    dispatch({type: CLEAR_USER_TO_GRANT});
    console.log(getState().datasetPermissions);
  }
}

export const showManageAccessForm = (show, datasetId) => {
  return (dispatch) => {
    dispatch({type: SHOW_MANAGE_ACCESS_FORM, show, datasetId});
  }
}

export const fetchPermissions = (datasetId, targetType = null, page = null, sort = null, reload = false) => {
  return (dispatch, getState) => {
    
    if (!reload) {
      //fetch only if haven't fetched permissions
      let allPermissions = getState().datasetPermissions.allPermissions;
          
      if (allPermissions === null) {
        return '';
      }

      if (typeof(allPermissions) === "object") {
        if (allPermissions.length > 0) {
          return ''
        }
      }
    }
    
    let filters = {}

    if (datasetId) {
      Object.assign(filters, {dataset_id: datasetId});
    }

    if (targetType) {
      Object.assign(filters, {type: targetType});
    }
    
    if (!page) {
      page = 1;
    }
    axios.get(apiUtils.LIST_DATASET_PERMISSIONS_ENDPOINT, {
      headers: {
        "id-token": getIdToken()
      },
      params: {
        page: page,
        limit: 10,
        filters
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    }).then(json => {
      const result = json.data;
      if (result) {
        let data = result.data;
        if (data.length === 0) {
          data = null;
        }
        
        switch (targetType) {
          case PERMISSION_TYPE_USER:
            dispatch({type: SET_PERMITTED_USERS, users: data});
            break;
          case PERMISSION_TYPE_GROUP:
            dispatch({type: SET_PERMITTED_GROUPS, groups: data});
            break;
          case PERMISSION_TYPE_ORG:
            dispatch({type: SET_PERMITTED_ORGS, orgs: data});
            break;
          default:
            dispatch({type: SET_ALL_PERMISSIONS, permissions: data});
            break;
        }

        let pagination = result.pagination;
        if (result.pagination) {
            dispatch(setPaginationDetails(pagination.total, pagination.per_page, targetType));
        }
      }
    }).catch(error => {
      console.log(error);
      let errorMessage = '';
        if (typeof(error.reponse) !== "undefined") {
          let response = error.response;
          if (typeof(response.data) !== "undefined") {
            errorMessage = error.response.data.message;
          }
          else {
            errorMessage = "An error occured while retrieving permissions.";
          }
          dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
        }
    })
  }
}

export const getPermission = (permissionId) => {
  return (dispatch, getState) => {
    const permission = getState().datasetPermissions.singlePermission;
    if (Object.keys(permission).length === 0) {
      axios.get(apiUtils.GET_DATASET_PERMISSIONS_ENDPOINT, {
        headers: {
          "id-token": getIdToken()
        },
        params: {
          id: permissionId
        }
      }).then(json => {
        const result = json.data;
        if (result) {
          dispatch({type: SET_PERMISSION, permission: result.data});
        }
      }).catch(error => {
        console.log(error);
        let errorMessage = '';
        if (typeof(error.reponse) !== "undefined") {
          let response = error.response;
          if (typeof(response.data) !== "undefined") {
            errorMessage = error.response.data.message;
          }
          else {
            errorMessage = "An error occured while removing permission.";
          }
          dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
        }
      })
    }
  }
}

export const clearPermission = () => {
  return (dispatch) => {
    dispatch({type: CLEAR_PERMISSION});
  }
}

export const setPaginationDetails = (items = 0, limit = 10, permissionType = PERMISSION_TYPE_USER) => {
  if (!items) {
      items = 0;
  }
  return (dispatch) => {
    if (permissionType === PERMISSION_TYPE_USER) {
      dispatch({type: USER_PERMISSIONS_PAGINATION_DETAILS, items, limit})
    }
    else if (permissionType === PERMISSION_TYPE_GROUP) {
      dispatch({type: GROUP_PERMISSIONS_PAGINATION_DETAILS, items, limit})
    }
  }
}

export const resetPermittedUsers = () => {
  return (dispatch) => {
    dispatch({type: SET_PERMITTED_USERS, users:[]})
  }
}

export const resetPermittedGroups = () => {
  return (dispatch) => {
    dispatch({type: SET_PERMITTED_GROUPS, groups:[]})
  }
}

export const resetAllPermissions = () => {
  return (dispatch) => {
    dispatch({type: SET_ALL_PERMISSIONS, permissions:[]})
  }
}

export const checkPermissions = (dataset, role) => {
  let hasPermission = false;
  let permissionId = null;

  const visibility = dataset.visibility;

  const user = store.getState().general.userInfo;
  switch (visibility) {
    case DATASET_PUBLIC_ALL:
      hasPermission = true;
      break;
    case DATASET_PUBLIC_ORG:
      if (typeof(user.organization) !== "undefined") {
        const org = user.organization;
        if (org) {
          if (dataset.org_id === user.organization.id) {
            hasPermission = true;
          }
        }
        else {
          console.log('Organization not found.');
        }
      }
      break;
  }

  //if private, check if has permission
  if (visibility === DATASET_PRIVATE) {
    if (dataset.owner.id === user.id) { //has access user is the owner
      hasPermission = true;
    }
    else {
      const permissions = dataset.permissions;
      if (permissions) {
        permissions.filter(data => {
          if (data.type === PERMISSION_TYPE_USER) {
            return data;
          }
        }).forEach(permission => {
          if (user.id === permission.target_id) {
            role = permission.role;
            permissionId = permission.id;
            hasPermission = true;
          }
        })

        permissions.filter(data => {
          if (data.type === PERMISSION_TYPE_GROUP) {
            return data;
          }
        }).forEach(permission => {
          const userGroups = user.group_memberships;
          if (userGroups) {
            userGroups.forEach(userGroup => {
              if (permission.target_id === userGroup.group_id) {
                role = userGroup.role;
                permissionId = permission.id;
                hasPermission = true;
              }
            })
          }
        })
      }
    }
  }
  return {
    role,
    permissionId,
    visibility, 
    hasPermission
  }
}