import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import { fetchGroups, clearGroups } from '../../../store/actions/settings/groupsActions';
import { fetchOrganizations } from '../../../store/actions/settings/organizationsActions';
import { setupFilter, fetchDatasets } from '../../../store/actions/main/datasetsActions';

class DatasetFilter extends Component {

  constructor(props) {
    super(props);
    this.orgId = 0;
    this.groupId = 0;
  }

  componentDidMount() {
    this.props.fetchOrganizations(null, null, 99, null, true);
    this.props.clearGroups();

    const urlParams = new URLSearchParams(this.props.location.search);
    this.orgId =  urlParams.get('org');
    this.groupId =  urlParams.get('group');

    if (this.orgId && this.groupId) {
      this.props.setupFilter(null, {org_id: [this.orgId], group_id: [this.groupId]});
      this.props.fetchGroups({"org_id": this.orgId}, null, "all");
    }
  }

  orgFilter = () => {
      const allOrganizations = this.props.organizations.allOrganizations;
      let orgList = '';
      if (allOrganizations) {
          orgList = allOrganizations.map((org, key) => {
              return (
                  <option value={org.id} key={key}>{org.name}</option>
              )
          })
      }

      let orgValue = 0;
      if (this.orgId) {
        orgValue = this.orgId;
      }

      return (
          <Form.Control as="select" value={orgValue} id="org_id" className="mr-5 filter-organization" onChange={(e) => {
              const orgId = document.getElementById('org_id').value;
              this.props.fetchGroups({"org_id": orgId}, null, "all");
              this.props.setupFilter(e);
              this.orgId = orgId;
          }}>
          <option value="">Select Organization</option>
              {orgList}
          </Form.Control>
      )
  }

  groupFilter = () => {
      const allGroups = this.props.groups.groups;
      let groupList = '';
      if (allGroups) {
          groupList = allGroups.map((group, key) => {
              return (
                  <option value={group.id} key={key}>{group.name}</option>
              )
          })
      }

      return (
          <Form.Control as="select" value={this.groupId} className="mr-5 filter-group" id="group_id" onChange={(e) => {
            const groupId = document.getElementById('group_id').value;
            this.groupId = groupId;
            this.props.setupFilter(e);
          }}>
            <option value={0}>Select Group</option>
            {groupList}
          </Form.Control>
      )
  }

  qualityFilter = () => {
      const qualities = this.props.datasets.qualities;
      const qualityList =  qualities.map((quality, key) => {
          return (
              <option key={key} title={quality.description} value={quality.id}>{quality.name}</option>
          )
      })

      return (
          <Form.Control as="select" className="mr-5" placeholder="Quality" id="quality_id" onChange={(e) => {
              this.props.setupFilter(e);
          }}>
              <option value="">Select Quality</option>
              {qualityList}
          </Form.Control>
      )
  }

  render() {
    return (
      <Container fluid>
        <Row id="ada-dataset-filter">
          <Col xs={1} sm={1} md={1} lg={1} xl={1}>
            <b className="mr-3">Filter:</b>
          </Col>
          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
            {this.orgFilter()}
          </Col>
          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
            {this.groupFilter()}
          </Col>
          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
            {this.qualityFilter()}
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}>
            <Button variant="primary" className="mr-3" onClick={() => {
                let filter = {}
                console.log(this.props.datasets.datasetFilter.org_id);
                if (typeof(this.props.datasets.datasetFilter.org_id) !== "undefined") {
                  filter = {
                    org_id: [this.props.datasets.datasetFilter.org_id]
                  }
                }

                if (typeof(this.props.datasets.datasetFilter.group_id) !== "undefined") {
                  const groupId = this.props.datasets.datasetFilter.group_id;
                  if (groupId > 0) {
                    filter = {
                      group_id: [this.props.datasets.datasetFilter.group_id]
                    } 
                  }
                }

                if (typeof(this.props.datasets.datasetFilter.quality_id) !== "undefined") {
                  Object.assign(filter, {
                    quality_id: [this.props.datasets.datasetFilter.quality_id]
                  })
                }
                if (document.getElementById('pagination-dropdown')) {
                  document.getElementById('pagination-dropdown').value = 1; 
                }

                if (document.getElementById('sort_value')) {
                  document.getElementById('sort_value').value = "newest";
                }
                this.props.fetchDatasets(filter, null, {created_at: "desc"});

                
            }}>Filter</Button>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}>
            <Button variant="default" className="mr-3" onClick={(e) => {
              document.getElementById('quality_id').selectedIndex = 0;
              this.orgId = 0;
              this.groupId = 0;
              this.props.clearGroups();
              this.props.setupFilter(e, null, true) //clear filter
              this.props.fetchDatasets(null, null, {created_at: "desc"});
              this.props.history.push('/main/datacatalog');
              if (document.getElementById('pagination-dropdown')) {
                document.getElementById('pagination-dropdown').value = 1; 
              }
              if (document.getElementById('sort_value')) {
                document.getElementById('sort_value').value = "newest";
              }
            }}><b>Reset</b></Button>
          </Col>
        </Row>
      </Container>
      
    )
  }
}

const mapStateToProps = (state) => ({
  general: state.general,
  datasets: state.datasets,
  organizations: state.organizations,
  groups: state.groups
})

const mapDispatchToProps = (dispatch) => {
  return {
      fetchGroups: (...args) => dispatch(fetchGroups(...args)),
      setupFilter: (...args) => dispatch(setupFilter(...args)),
      fetchOrganizations: (...args) => dispatch(fetchOrganizations(...args)),
      fetchDatasets: (...args) => dispatch(fetchDatasets(...args)),
      clearGroups: () => dispatch(clearGroups())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatasetFilter))
