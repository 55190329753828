import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchGroups, setGroup, getGroup } from '../../store/actions/settings/groupsActions';
import Avatar from "react-avatar";
import * as config from '../../app/config';

class Groups extends Component {

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const groupId =  urlParams.get('id');
    this.props.getGroup(groupId);
  }
  render() {
    console.log(this.props);
    let groupMembers = this.props.groups.singleGroup.members;
    
    // if (groupMembers.length === 0) {
    //   groupMembers = null;
    // }

    let groupMembersList = '';
    let avatar = '';
    if (groupMembers !== null) {
        groupMembersList = groupMembers.length > 0 ? (
            groupMembers.map((member, key) => {
              console.log(member);
              if (typeof(member.user) !== 'undefined') {
                if (member.user !== null) {
                  if (member.user.photo_url === null) {
                      avatar = <Avatar name={member.user.first_name + " " + member.user.last_name} size={config.AVATAR_SIZE} round={config.AVATAR_RADUIS} />
                  }
                  else {
                      avatar = <Avatar src={member.user.photo_url} size={config.AVATAR_SIZE} round={config.AVATAR_RADUIS} />
                  }
                  return (
                      <tr key={key}>
                          <td className="align-center" width="5%">
                              {avatar}
                          </td>
                          <td width="45%">
                              {member.user.first_name} {member.user.last_name} 
                              <br />
                              <span className="text-muted small">{member.user.email}</span>
                          </td>
                          <td className="align-center" width="48%">{member.role_name} </td>
                      </tr>
                  )
                }
              } 
            })
        ) : (
            <tr>
                <td colSpan={4}>Loading...</td>
            </tr>
        )
    }
    else {
        groupMembersList = <tr><td colSpan={4} className="align-center">No members were found.</td></tr>
    }
    return (
      <div>
          <Container fluid>
            <h3>{this.props.groups.singleGroup.name}</h3>
            
            <Row className="mt-3">
              <Col xls={12} sm={12} md={7} lg={7} xl={7} className="mt-5"> 
                <Table id="groupMembers">
                  <thead>
                      <tr>
                          <th></th>
                          <th className="align-center">Name</th>
                          <th className="align-center">Role</th>
                      </tr>
                  </thead>
                  <tbody>
                      {groupMembersList}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  general: state.general, 
  organizations: state.organizations,
  groups: state.groups
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroups: (...args) => dispatch(fetchGroups(...args)),
    setGroup: (...args) => dispatch(setGroup(...args)),
    getGroup: (...args) => dispatch(getGroup(...args))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Groups))