import axios from 'axios';
import qs from 'qs';
import { getIdToken } from '../../helpers/tokens';
import * as apiDatasetLogsUtils from '../../../common/DatasetLogs';
import { toggleShowAlert, ERROR_ALERT } from '../generalActions';

export const SET_DATASET_LOGS = 'SET_DATASET_LOGS';
export const SET_DATASET_LOGS_PAGINATION = 'SET_DATASET_LOGS_PAGINATION';

export const getDatasetLogs = (datasetId, page = null, limit = null) => {
  return (dispatch) => {
    if (!page) {
      page = 1;
    }

    if (!limit) {
      limit = 10;
    }

    axios.get(apiDatasetLogsUtils.LIST_DATASET_LOGS_ENDPOINT, {
      headers: {
        "id-token": getIdToken()
      }, 
      params: {
        page: page, 
        limit: limit, 
        filters: {
          dataset_id: [datasetId]
        }
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }, 
    }).then(json => {
      const result = json.data;
      if (result) {
        let data = result.data;
        if (data.length === 0) {
          data = null;
        }
        dispatch({type: SET_DATASET_LOGS, logs:data});

        const totalItems = result.pagination.total;
        const limit = result.pagination.per_page; 
        dispatch(setPaginationDetails(totalItems, limit))
      }
    }).catch(error => {
      console.log(error);
      const errorMessage = "An occured while retrieving dataset logs.";
      dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
    })
  }
}

export const resetDatasetLog = () => {
  return (dispatch) => {
    dispatch({type: SET_DATASET_LOGS, logs:[]});
  }
}

export const setPaginationDetails = (totalItems, limit) => {
  return (dispatch) => {
    dispatch({type: SET_DATASET_LOGS_PAGINATION, logsTotalItems: totalItems, logsLimit: limit});
  }
}