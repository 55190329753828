import axios from 'axios';
import qs from 'qs';
import AWS from 'aws-sdk';
import { Storage } from 'aws-amplify';
import { 
    GET_USER_BY_SUB_ENDPOINT, 
    UPDATE_PROFILE_INFO, 
    UPDATE_PROFILE_PHOTO,   
    CONFIRM_USER, } from '../../common/UsersAPIUtils';
import * as apiUtils from '../../common/UsersAPIUtils';
import { ACTION_LOG_LOGIN, transactionLog } from '../../common/LogsUtils';

import { fetchRoles, fetchUser } from './settings/usersActions';
import { fetchOrganizations } from './settings/organizationsActions';
import { getIdToken, getSub, clearTokens, setTokens } from '../helpers/tokens';
import * as config from '../../app/config';
import awsmobile from '../../app/aws-config';
import { Auth, Cache } from 'aws-amplify';

export const TOGGLE_SHOW_PROMPT = 'TOGGLE_SHOW_PROMPT';
export const TOGGLE_FORM_DIALOG_BOX = 'TOGGLE_FORM_DIALOG_BOX';
export const TOGGLE_SHOW_ALERT = 'TOGGLE_SHOW_ALERT';
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';
export const SET_USER = 'SET_USER';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_IS_AUTHENTICATING = 'SET_IS_AUTHENTICATING';
export const SET_FORM_VALIDATED = 'SET_FORM_VALIDATED';
export const PROFILE_SET_FIELD_ON_CHANGE = 'PROFILE_SET_FIELD_ON_CHANGE';
export const SET_PROFILE_PHOTO = 'SET_PROFILE_PHOTO';
export const SET_IS_EDITING = 'SET_IS_EDITING';
export const SET_USER_LOGS = 'SET_USER_LOGS';
export const SET_PAGINATION_DETAILS = 'SET_PAGINATION_DETAILS';
export const SET_ON_FORM_VALIDATOR = 'SET_ON_FORM_VALIDATOR';
export const SET_IDENTITY_PROVIDERS = 'SET_IDENTITY_PROVIDERS';
export const CHANGE_PROFILE_PIC = 'CHANGE_PROFILE_PIC';
export const USER_LOGS_SET_PAGINATION_DETAILS = 'USER_LOGS_SET_PAGINATION_DETAILS';
export const SET_ACCEPTED_TERMS = 'SET_ACCEPTED_TERMS';
export const SET_USER_IDP = 'SET_USER_IDP';

export const SUCCESS_ALERT = 'success';
export const ERROR_ALERT = 'danger';

const SUCCESS = 'SUCCESS';

export const axiosRequestInterceptor = async (config) => {
    var idToken = Cache.getItem('idToken');
    if (idToken) {
        const exp = idToken.payload.exp;
        const currentTime = Math.round(((new Date()).getTime() - 1800) / 1000);

        if (exp < currentTime) {
            await Auth.currentSession().then(item => {
                if(item.isValid()){
                    const tokenHeader = {
                        "id-token": item.idToken.jwtToken
                    }
                    Object.assign(config.headers, tokenHeader);
                    setTokens(item);
                }   
            }).catch(error => {
                console.log(error);
            })
        }
    }
    
    return config;
};

axios.interceptors.request.use(axiosRequestInterceptor, error => {
    // handle the error
    return Promise.reject(error);
});

export const setAuthStatus = (authenticated) => {
    return (dispatch) => {
        dispatch({type: SET_AUTH_STATUS, authenticated})
    }
}

export const setIsAuthenticating = (isAuthenticating) => {
    return (dispatch) => {
        dispatch({type: SET_IS_AUTHENTICATING, isAuthenticating})
    }
}

export const setUser = (user) => {
    return (dispatch, getState) => {
        dispatch({type: SET_USER, user});
        dispatch(setUserInfo());
    }
}

export const checkAccess = () => {
    return (dispatch, getState) => {
        if (getState().general.userInfo) {
            const userInfo = getState().general.userInfo;
            if (userInfo.invitation_id === null && userInfo.invitation_url === "") {
                //regular login access granted
                dispatch(setAuthStatus(true));
                dispatch(setIsAuthenticating(false));
            }
            else {
                dispatch(setAuthStatus(false));
                dispatch(setIsAuthenticating(false));
                var message = "Please confirm your account first."
                dispatch(toggleShowAlert(true, ERROR_ALERT, message));
            }
        }
    }
}

export const setUserInfo = (success_callback=null,error_callback=null) => {
    return (dispatch, getState) => {
        if (getIdToken()) {
            let userInfo = getState().general.userInfo;
            axios.get(GET_USER_BY_SUB_ENDPOINT, {
                params: {
                    sub: getSub()
                }, 
                headers: {
                    "id-token": getIdToken()
                }
            }).then(json => {
                
                userInfo = json.data.data
                dispatch ({type: SET_USER_INFO, userInfo});
                
                Cache.setItem('userId', userInfo.id);
                Cache.setItem('role', userInfo.role);
                Cache.setItem('orgId', userInfo.org_id);

                if(typeof(success_callback)=='function'){
                    success_callback();
                }
                else{
                    const isLoggedIn = Cache.getItem('LOGGED_IN');
                    if (!isLoggedIn) {
                        dispatch(checkAccess());
                        dispatch(fetchRoles());
                    }
                }
                dispatch(fetchOrganizations(null, null, 99));
                Cache.setItem('userExists', true);
                
            }).catch(error => {
                
                if(typeof(error_callback)=='function'){
                    error_callback();
                }
                else{
                    if (typeof(error.response) !== "undefined") {
                        const response = error.response.data;
                        dispatch(setAuthStatus(false));
                        dispatch(setIsAuthenticating(false));
                        var message = response.message;
                        if (response.message === "USER_NOT_EXISTS") {
                            message = "User does not exists";
                            dispatch(toggleShowAlert(true, ERROR_ALERT, message));
                            clearTokens();
                            Auth.signOut().then(()=>{
                                window.location = config.WEB_APP_URL
                            });
                        }
                        dispatch(toggleShowAlert(true, ERROR_ALERT, message));
                    }
                }
            })
        }
    }
}

export const toggleDialogDisplay = (show, actionType) => {
    return (dispatch, getState) => {
        if (typeof actionType === 'undefined') {
            dispatch({type: TOGGLE_FORM_DIALOG_BOX, show})
        }
        else {
            dispatch({type: TOGGLE_FORM_DIALOG_BOX, show, actionType})
        }
    }
}

export const toggleShowPrompt = (show, actionType) => {
    return (dispatch, getState) => {
        if (typeof actionType === 'undefined') {
            dispatch({type: TOGGLE_SHOW_PROMPT, show})
        }
        else {
            dispatch({type: TOGGLE_SHOW_PROMPT, show, actionType})
        }
       
    }
}

export const toggleShowAlert = (show, alertType, message) => {
    return (dispatch) => {
        dispatch({type: TOGGLE_SHOW_ALERT, show, alertType, message})
        //auto-close alert
        setTimeout(() => {
            dispatch({type: TOGGLE_SHOW_ALERT, show: false})
        }, 4000)
    }
}

export const setFormValidated = (validated) => {
    return (dispatch) => {
        dispatch({type: SET_FORM_VALIDATED, validated})
    }
}

export const successAPIResDisplay = (json, customMessage = null) => {
    return (dispatch, getState) => {
        const result = json.data;
        if (result) {
            const code = result.code;
            let message;
            if (customMessage !== null) {
                message = customMessage;
            }
            
            if (code === SUCCESS) {
                dispatch(toggleShowAlert(true, SUCCESS_ALERT, message));
            }
            else {
                message = result.message;
                dispatch(toggleShowAlert(true, ERROR_ALERT, message));
            }
        }
        
    }
}

export const setProfileFieldValueChange = (e) => {
    const value = {
        [e.target.id]: e.target.value
    }
    return (dispatch) => {
        dispatch({type: PROFILE_SET_FIELD_ON_CHANGE, value})
    }
}
export const finishUploadPhoto = () => {
    return (dispatch) => {
        //to show progress bar
        const value = {profilePhoto: null}
        dispatch({type: SET_PROFILE_PHOTO, value}); 
    }
}

export const setProfilePhoto = (e) => {
    return (dispatch) => {
        const fileType = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
        const file = e.target.files[0];
        if (typeof(file) !== "undefined") {
            if (config.imageFileType.includes(file.type)) {          
                dispatch({type: SET_PROFILE_PHOTO, value: true}); 
                const file_path = config.env + '/profile-photos/'+file.name;
                Storage.put(file_path, file, {
                    contentType: file.type
                })
                .then (result => {
                    const value = {
                        profilePhoto: file.name
                    }
                
                    dispatch({type: SET_PROFILE_PHOTO, value});
                    dispatch(updateProfilePhoto());
                    
                })
                .catch(err => console.log(err));
            }
            else {
                return (dispatch) => {
                    dispatch(toggleShowAlert(true, ERROR_ALERT, "Invalid image format."))
                }
            }
        }
    }
}

export const updateProfilePhoto = () => {
    return (dispatch, getState) => {
        const userId     = Cache.getItem('userId');
        const profilePhoto = getState().general.profilePhoto;
        const photo = {
            file_name: profilePhoto
        }
        console.log(profilePhoto, userId);

        axios.put(UPDATE_PROFILE_PHOTO, photo, {
            headers: {
                "id-token": getIdToken()
            },
            params: {
                "id": userId
            }
        }).then(json => {
            const result = json.data;
            if (result) {
                const data = result.data;
                if (data) {
                    dispatch({type: CHANGE_PROFILE_PIC, profilePic: data})
                    dispatch(finishUploadPhoto())
                }
            }
        }).catch(error => {
            console.log(error);
            let errorMessage = '';
            if (typeof(error.reponse) !== "undefined") {
                let response = error.response;
                if (typeof(response.data) !== "undefined") {
                errorMessage = error.response.data.message;
                }
                else {
                errorMessage = "An error occured while uploading profile photo.";
                }
                dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
            }
            dispatch(finishUploadPhoto())
        })
    }
}

export const updateProfile = () => {

    return (dispatch, getState) => {
        const userInfo = getState().general.userInfo;
        const values = {
            first_name: userInfo.first_name,
            middle_name: userInfo.middle_name, 
            last_name: userInfo.last_name,
            email: userInfo.email,
            org_id: userInfo.org_id,
            role: userInfo.role
        }
        axios.put(UPDATE_PROFILE_INFO, values, {
            headers: {
                "id-token": getIdToken()
            },
            params: {
                "sub": userInfo.sub
            }
        }).then(json => {
            const customMessage = "Profile has been successfully updated.";
            dispatch(successAPIResDisplay(json, customMessage));
            dispatch(setUserInfo());
        }).catch(error => {
            console.log(error);
            let errorMessage = '';
            if (typeof(error.reponse) !== "undefined") {
                let response = error.response;
                if (typeof(response.data) !== "undefined") {
                errorMessage = error.response.data.message;
                }
                else {
                    errorMessage = "An error occured while updating profile.";
                }
                dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
            }
        });

    }
}

export const confirmUser = (invitation_id,callback) => {
    return (dispatch, getState) => {
        const values = {
            sub: getSub(),
            invitation_id: invitation_id
        }

        axios.put(CONFIRM_USER, values, {
            headers: {
                "id-token": getIdToken()
            }
        }).then(json => {
            if(typeof(callback) == 'function'){
                callback();
            }

        }).catch(error => {
            const response = error.response.data;
            dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
            dispatch(setIsAuthenticating(false));
            dispatch(setAuthStatus(false));
        })
    }
}

export const setIsEditing = (isEditing) => {
    return (dispatch) => {
        dispatch({type: SET_IS_EDITING, value: isEditing})
    }
}

export const getUserLogs = (userId, page = 1, limit = 10, isPaginating = false) => {
    return (dispatch, getState) => {
        if (!isPaginating) {
            dispatch({type: SET_USER_LOGS, logs: null})
        }
        if (userId) {
            axios.get(apiUtils.USER_LOG_LIST, {
                headers: {
                    "id-token": getIdToken()
                },
                params: {
                    page: page,
                    limit: limit,
                    filters: {
                        user_id: [userId]
                    },
                    sort: {
                        created_at: "desc"
                    }
                },
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
            }).then(json => {
                const result = json.data.data;
                let logs;
                if (result.length > 0) {
                    logs = result;
                }
                else {
                    logs = [];
                    
                }
                console.log(json.data.pagination);
                var pagination = json.data.pagination;
                if (pagination) {
                    dispatch(setUserLogsPagination(pagination.current_page, pagination.total, pagination.per_page));
                }
                dispatch({type: SET_USER_LOGS, logs})
            }).catch(error => {
                console.log(error);
            });
        }
    }
}

export const setUserLogsPagination = (page = 1, items = 0, limit = 10) => {
    if (!items) {
        items = 0;
    }
    return (dispatch) => {
        dispatch({type: USER_LOGS_SET_PAGINATION_DETAILS, page, items, limit})
    }
}

export const setPaginationDetails = (items = 0, limit = 10) => {
    if (!items) {
        items = 0;
    }
    return (dispatch) => {
        dispatch({type: SET_PAGINATION_DETAILS, items, limit})
    }
}

export const setInputValidator = (show, type = null, message = null) => {
    const values = {
        show, 
        type,
        message
    }
    return (dispatch) => {
        dispatch({type: SET_ON_FORM_VALIDATOR, values})
    }
}

export const fetchIdentityProviders = () => {
    return (dispatch) => {
        AWS.config.accessKeyId = config.ACCESS_KEY_ID;
        AWS.config.region = awsmobile.Auth.region
        AWS.config.secretAccessKey = config.SECRET_ACCESS_KEY;

        var cognito = new AWS.CognitoIdentityServiceProvider();

        cognito.listIdentityProviders({
            UserPoolId: awsmobile.Auth.userPoolId
        },(err,data)=>{
            if (data) {
                dispatch({type: SET_IDENTITY_PROVIDERS, identityProviders: data.Providers})
            }
            else if (err) {
                console.log(err);
            }
        });
    }
}

export const setAcceptedTerms = (accepted) => {
    return (dispatch) => {
        dispatch ({type: SET_ACCEPTED_TERMS, accepted});
    }
}

export const setUserIDP = (idp) => {
    return (dispatch) => {
        dispatch ({type: SET_USER_IDP, idp});
    }
}

export const validateEmail = (inputText) =>
{
    if (inputText) {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        console.log(inputText);
        if(inputText.match(mailformat))
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    else {
        return true;
    }
}

