import React from 'react';
import { Cache } from 'aws-amplify';
import { Form, Col, ButtonGroup, Button } from 'react-bootstrap';
import { faCloudDownloadAlt, faTrash, faCloudUploadAlt, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadDataset } from '../../../store/actions/main/datasetsActions';
import * as config from '../../../app/config';
import { DisplayMoment } from '../../../common/Utilities';
import { checkPermissions } from '../../../store/actions/main/datasetPermissionsActions';
export const DatasetFile = ({
  dataset,
  attachment,
  onChangeCallback,
  updateDatasetCallback, 
  removeDatasetCallback
}) =>  {

  let selectCheckBox = <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Form.Check aria-label="option 1" name="checkDownload" value={attachment.id} onChange={(e) => {
                              onChangeCallback(e) 
                            }}/>
                        </Col>
  let downloadButton = <Button variant="primary" id="ada-dataset-option-btn-icon" onClick={(e) => {
                          console.log(attachment.id);
                          const result = downloadDataset(attachment.id);
                          result.then(data => {
                            const fileName = attachment.file_name;

                            var element = document.createElement('a');
                            element.setAttribute('href',  data);
                            element.setAttribute('download', fileName);

                            element.style.display = 'none';
                            document.body.appendChild(element);

                            element.click();
                          })
                        }}>
                      <FontAwesomeIcon icon={faCloudDownloadAlt} />
                    </Button>
  
  let editButton = <React.Fragment>
                      <Button variant="primary" id="ada-dataset-option-btn-icon" onClick={(e) => {
                        var fileUpload = document.getElementById("datasetUpdateFileInput" + attachment.id);
                        fileUpload.click();
                      }}>
                        <FontAwesomeIcon icon={faCloudUploadAlt} /> 
                      </Button>
                      <input type="file" id={"datasetUpdateFileInput" + attachment.id} style={{"display": "none"}} onChange={(e) => {
                        updateDatasetCallback(e, attachment.id, attachment.dataset_id);
                      }} />
                    </React.Fragment>

  let deleteButton = <Button variant="primary" id="ada-dataset-option-btn-icon" onClick={(e) => {
                        removeDatasetCallback(attachment.id)
                      }}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
 
  let role = Cache.getItem('role');
  let access = checkPermissions(dataset, role);

  const hasPermission = access.hasPermission;
  const accessRole = access.role;
  
  if (hasPermission){
    role = accessRole;
    //if has permission, check role first
    if (role !== config.ROLE_CURATOR && role !== config.ROLE_EDITOR && role !== config.ROLE_VIEWER){
      downloadButton = '';
      editButton = '';
      deleteButton = '';
      selectCheckBox = '';
    }
  
    if (role === config.ROLE_VIEWER) {
      editButton = '';
      deleteButton = '';
    }
  }
  else {
    downloadButton = '';
    editButton = '';
    deleteButton = '';
    selectCheckBox = '';
  }
  

  const fileSize = Math.round(attachment.file_size * 100) / 100

  return (
    <tr>
      <td width="5%" className="align-center">
        {selectCheckBox}
      </td>
      <td width="45%">
        <div>{attachment.file_name}</div>
        <div className="small">File size: {fileSize} KB</div>
      </td>
      <td width="20%" className="align-center">
        <DisplayMoment dataTime={attachment.updated_at} />
      </td>
      <td width="20%">
        <ButtonGroup className="float-right">
          {downloadButton}
          {editButton}
          {deleteButton}
        </ButtonGroup>
      </td>
    </tr>
  )
}

export const DatasetSiteLink = ({
  dataset, 
  attachment,
  openEditLinkForm, 
  setLinkFieldChange,
  editLinkToDataset,
  removeLinkCallback
}) =>  {

  const lastModified = attachment.updated_at;

  let editButton = <Button variant="primary" id="ada-dataset-option-btn-icon" onClick={(e) => {
                      setLinkFieldChange(e, attachment)
                      openEditLinkForm();
                    }}>
                      <FontAwesomeIcon icon={faPen} />
                    </Button>

  let deleteButton = <Button variant="primary" id="ada-dataset-option-btn-icon" onClick={() => {
                        removeLinkCallback(attachment.id);
                      }}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
  
  let datasetLink = <p><a target="_blank" href={attachment.file_link}>{attachment.file_name}</a></p>

  let role = Cache.getItem('role');
  let access = checkPermissions(dataset, role);

  const hasPermission = access.hasPermission;
  const accessRole = access.role;
  
  if (hasPermission){
    role = accessRole;
    //if has permission, check role first
    if (role !== config.ROLE_CURATOR && role !== config.ROLE_EDITOR) {
      editButton = '';
      deleteButton = '';
    }
  
    if (role === config.ROLE_PLATFORM_ADMIN || role === config.ROLE_ORG_ADMIN) {
      datasetLink = attachment.file_name
    }
  }
  else {
    datasetLink = attachment.file_name
    editButton = '';
    deleteButton = '';
  }
  
  return (
    <tr>
        <td>
          {datasetLink}
        </td>
        <td>
          <DisplayMoment dataTime={lastModified} />
        </td>
        <td>
          <ButtonGroup className="float-right">
            {editButton}
            {deleteButton}
          </ButtonGroup>
        </td>
    </tr>
  )
}
