const initialState = {
    organizations: [],
    allOrganizations:[], //organizations regardless of user's org
    error: null,
    singleOrganization: {},
    actionType: null,
    search: null, 
    showDialog: false,
    showPrompt: false, 
    showMembers: false,
    error: null,
    isPaginating: false,
    orgsCurrentPage: 1,
    orgsTotalItems: 0, 
    orgsLimit: 10, 
    sortValues: {
        name: "asc"
    }
}

const organizationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_ORGANIZATIONS_BEGIN': 
            return {
                ...state, 
                organizations: null,
                error: null,
            }
        case 'FETCH_ORGANIZATIONS_SUCCESS': 
            return  {
                ...state, 
                organizations: action.organizations,
                error: null
            }
        case 'FETCH_ALL_ORGANIZATIONS_SUCCESS':
            return {
                ...state,
                allOrganizations: action.allOrganizations
            }
        case 'FETCH_ORGANIZATIONS_ERROR': 
            return  {
                ...state, 
                organizations: [],
                error: action.error
            }
        case 'GET_ORGANIZATION':
            let org = '';
            state.organizations.filter((data) => {
                if (data.id === action.id) {
                    return data;
                }
            }).map(item => {
                org = item
            })
            return {
                ...state, 
                singleOrganization: Object.assign(state.singleOrganization, org)
            }
        case 'SET_ORGANIZATION':
            return {
                ...state, 
                singleOrganization: Object.assign(state.singleOrganization, action.organization)
            }
        case 'RESET_ORGANIZATION':
            return {
                ...state,
                singleOrganization: {}
            }
        case 'SET_IS_PAGINATING':
            return {
                ...state, 
                isPaginating: action.isPaginating,
            }
        case 'TOGGLE_FORM_DIALOG_BOX':
            let actionType = '';
            if (typeof action.actionType === 'undefined') { //retain the current actionType
                actionType = state.actionType;
            }
            else {
                actionType = action.actionType;
            }
            return {
                ...state, 
                showDialog: action.show,
                actionType: actionType
            }
        case 'TOGGLE_SHOW_MEMBERS':
            return {
                ...state, 
                showMembers: action.show
            }
        case 'SEARCH_ORGANIZATION':
            return {
                ...state, 
                search: action.keyword
            }
        case 'DELETE_ORGANIZATION':
            const organizations = state.organizations.filter(data => {
                if (data.id  !== action.id) {
                    return data;
                }
            })
            return {
                ...state, 
                organizations
            }
        case 'TOGGLE_SHOW_PROMPT':
            return {
                ...state, 
                showPrompt: action.show,
                actionType: action.actionType
            }
        case 'ORGS_SET_FIELD_ON_CHANGE':
            return {
                ...state, 
                singleOrganization: Object.assign(state.singleOrganization, action.value)
            }
        case 'RESET_ORGANIZATIONS':
            return {
                ...state, 
                organizations: null
            }
        case 'ORGS_SET_SORT_VALUES':
            return {
                ...state, 
                sortValues: action.sortValues
            }
        case 'SEARCH_ORGANIZATION':
            return {
                ...state, 
                searchByText: action.keyword
            }
        case 'ORGS_SET_PAGINATION_DETAILS':
            return {
                ...state, 
                orgsCurrentPage: action.page, 
                orgsTotalItems: action.items,
                orgsLimit: action.limit
            }
        default: 
            return state;
    }
}

export default organizationsReducer