import axios from 'axios';
import qs from 'qs';
import { Cache } from 'aws-amplify';
import { getIdToken } from '../../helpers/tokens';
import { toggleShowAlert, ERROR_ALERT } from '../generalActions';
import * as apiUtils from '../../../common/DatasetViewsAPIUtils';

export const SET_RECENTLY_VIEWED = 'SET_RECENTLY_VIEWED';

export const createDatasetView = (datasetId) => {
  const values = {
    dataset_id: datasetId
  }
  axios.post(apiUtils.CREATE_DATASETS_ENDPOINT, values, {
    headers: {
      "id-token": getIdToken()
    }
  }).then(json => {
    console.log(json);
  }).catch(error => {
    console.log(error);
  })
}

export const fetchRecentlyViewed = () => {
  const userId = Cache.getItem('userId');
  return (dispatch) => {
    axios.get(apiUtils.RECENTLY_VIEWED_ENDPOINT, {
      headers: {
        "id-token": getIdToken()
      },
      params: {
        page: 1,
        limit: 5, 
        filters: {
          user_id: [userId]
        }
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }, 
    }).then(json => {
      const result = json.data;
      if (result) {
        let data = result.data;
        if (data.length === 0) {
          data = null;
        }
        dispatch({type: SET_RECENTLY_VIEWED, recentlyViewed: data})
      }
    }).catch (error => {
      console.log(error);
      let errorMessage = '';
      if (typeof(error.reponse) !== "undefined") {
        let response = error.response;
        if (typeof(response.data) !== "undefined") {
          errorMessage = error.response.data.message;
        }
        else {
          errorMessage = "An error occured while retrieving recently viewed dataset.";
        }
        dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
      }
    })
  }
}