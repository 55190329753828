import React from 'react';
import { Card, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { faUsers, faDatabase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GroupCard = ({
  groupName,
  showDatasetsCallback, 
  showMembersCallback
}) => {
  return (
    <Card id="group-card">
      <Card.Body>
        <Card.Title>{groupName}</Card.Title>
        <Row className="float-right">
          <Col>
            <OverlayTrigger
                key="bottom"
                placement="bottom"
                overlay={
                <Tooltip id="tooltip-top">
                    View Datasets
                </Tooltip>
                }
            >
                <Button id="btn-icon" onClick={(e) => {
                  e.preventDefault();
                  showDatasetsCallback();
                }}>
                  <FontAwesomeIcon icon={faDatabase}  />
                </Button>
            </OverlayTrigger>
            <OverlayTrigger
                key="bottom"
                placement="bottom"
                overlay={
                <Tooltip id="tooltip-top">
                    View Members
                </Tooltip>
                }
            >
              <Button id="btn-icon" onClick={(e) => {
                e.preventDefault();
                showMembersCallback();
              }}>
                <FontAwesomeIcon icon={faUsers}  />
              </Button>
            </OverlayTrigger>
            
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default GroupCard