const initState = {
  datasetId: null,
  userToGrant: {},
  groupToGrant: {},
  orgToGrant: {}, 
  showManageAccessForm: false,
  singlePermission: {}, 
  allPermissions: [], 
  permittedUsers: [],
  permittedGroups: [],
  permittedOrgs: [], 
  permittedOrganizations: [], 
  permittedUsersTotalItems: 0,
  permittedUsersLimit: 10,
  permittedGroupsTotalItems: 0,
  permittedGroupsLimit: 10,
  permittedOrgsTotalItems: 0,
  permittedOrgsLimit: 10
}

const datasetPermissionsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_USER_TO_GRANT':
      return {
        ...state,
        userToGrant: Object.assign(state.userToGrant, action.value)
      }
    case 'CLEAR_USER_TO_GRANT':
      return {
        ...state,
        userToGrant: {}
      }
    case 'SHOW_MANAGE_ACCESS_FORM':
      return {
        ...state,
        showManageAccessForm: action.show,
        datasetId: action.datasetId
      }
    case 'SET_GROUP_TO_GRANT':
      return {
        ...state,
        groupToGrant: action.group
      }
    case 'SET_ORG_TO_GRANT':
      return {
        ...state,
        orgToGrant: action.org
      }
    case 'CLEAR_GROUP_TO_GRANT':
      return {
        ...state,
        groupToGrant: {}
      }
    case 'SET_PERMITTED_USERS':
      return {
        ...state,
        permittedUsers: action.users
      }
    case 'SET_PERMITTED_GROUPS':
      return {
        ...state,
        permittedGroups: action.groups
      }
    case 'SET_PERMITTED_ORGS':
      return {
        ...state,
        permittedOrgs: action.orgs
      }
    case 'USER_PERMISSIONS_PAGINATION_DETAILS':
      return {
          ...state, 
          permittedUsersTotalItems: action.items,
          permittedUsersLimit: action.limit
      }
    case 'GROUP_PERMISSIONS_PAGINATION_DETAILS':
      return {
          ...state, 
          permittedGroupsTotalItems: action.items,
          permittedGroupsLimit: action.limit
      }
    case 'ORGS_PERMISSIONS_PAGINATION_DETAILS':
      return {
          ...state, 
          permittedOrgsTotalItems: action.items,
          permittedOrgsLimit: action.limit
      }
    case 'SET_ALL_PERMISSIONS':
      return {
        ...state,
        allPermissions: action.permissions
      }
    case 'SET_PERMISSION':
      return {
        ...state,
        singlePermission: action.permission 
      }
    case 'CLEAR_PERMISSION':
      return {
        ...state, 
        singlePermission: {}
      }
    default:
      return state;
  }
}

export default datasetPermissionsReducer;