/**
 * This component contains the download links either it's a file or a link to external site.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Cache } from 'aws-amplify';
import { Row, Col, Button, Spinner, Table, Form } from 'react-bootstrap';
import { DatasetFile } from './DatasetLink';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkBoxDownloadChange, 
  downloadBulk, 
  addLinkToDataset, 
  addDatasetFile,
  updateDatasetFile, 
  setAttachment, 
  ACTION_DELETE_DATASET_FILE } from '../../../store/actions/main/datasetsActions';
import { toggleShowPrompt, ERROR_ALERT, toggleShowAlert } from '../../../store/actions/generalActions';
import { checkPermissions } from '../../../store/actions/main/datasetPermissionsActions';
import * as config from '../../../app/config';

class DownloadDataset extends Component {

  componentDidMount() {
    this.props.setAttachment(null);
  }

  render() {
    const dataset = this.props.dataset;
    if (typeof(dataset.attachments) === "undefined") {
      return '';
    }

    const attachments = dataset.attachments;

    let datasetFiles = '';
    if (attachments.length > 0) {
      datasetFiles = attachments.filter(attachment => {
        if (attachment.attachment_type === "DATASET_ATTACHMENT_FILE") {
          return attachment;
        }
      }).map((attachment, key) => {
        const attachmentId = attachment.id;

        return (
          <DatasetFile 
            key={key}
            dataset={this.props.dataset} 
            attachment={attachment} 
          onChangeCallback={(e) =>{
            this.props.checkBoxDownloadChange(e)
          }} 
          updateDatasetCallback={(e, attachmentToUpdate, datasetId) => {
            this.props.updateDatasetFile(e, attachmentToUpdate, datasetId);
          }} 
          removeDatasetCallback={() => {
            this.props.setAttachment(attachment);
            this.props.toggleShowPrompt(true, ACTION_DELETE_DATASET_FILE);
          }}
          datasetsState={this.props.datasets} />
        )
      })
    }

    if (datasetFiles.length === 0) {
      datasetFiles = <tr><td colSpan={4}>No available files.</td></tr>;
    }
    let downloadSelected = '';
    let checkboxDownloadAll = '';
    let role = Cache.getItem('role');
    let access = checkPermissions(dataset, role);
    
    const hasPermission = access.hasPermission;
    const accessRole = access.role;
    if (hasPermission) {
      role = accessRole
      if (role === config.ROLE_CURATOR || role === config.ROLE_EDITOR || role === config.ROLE_VIEWER) {
        const bulkFiles = this.props.datasets.bulkFileDownload;
        let downloadBlkDisabled = true;
        if (bulkFiles.length > 0) {
          downloadBlkDisabled = false;
        }
        if (datasetFiles.length > 0) {
          checkboxDownloadAll = <Form.Check onClick={(e) => {
                                  let value;
                                  let checkboxes = document.getElementsByName('checkDownload');
                                  for (var i = 0; i < checkboxes.length; i++) {
                                    value = checkboxes[i].value
                                    checkboxes[i].checked = e.target.checked;
                                    if (e.target.checked) {
                                      this.props.checkBoxDownloadChange(null, value, "add")
                                    }
                                    else {
                                      this.props.checkBoxDownloadChange(null, value, "remove")
                                    }
                                  }
                                }}/>
        }
        downloadSelected = <Button hidden={downloadBlkDisabled} variant="primary" id="btn-icon" onClick={(e) => {
                                if (bulkFiles.length === 0) {
                                  const errorMsg = "No selected files."
                                  this.props.toggleShowAlert(true, ERROR_ALERT, errorMsg)
                                }
                                else {
                                  e.preventDefault();
                                  const result = downloadBulk();
                                  console.log(result);
                                  if (result) {
                                    result.then(data => {
                                      if (typeof(data) !== "undefined") {
                                        var element = document.createElement('a');
                                        element.setAttribute('href',  data);
      
                                        element.style.display = 'none';
                                        document.body.appendChild(element);
      
                                        element.click();
                                      }
                                      else {
                                        const errorMsg = "An error occured while downloading dataset/s."
                                        this.props.toggleShowAlert(true, ERROR_ALERT, errorMsg)
                                      }
                                    })
                                  }
                                }
                                
                              }}>
                                <span className="ada-page-link">
                                  Download Selected Files
                                </span>
                              </Button>
      }
    }

    let addDatasetBtnVal = <React.Fragment>
                            <span className="ada-page-link">
                              <FontAwesomeIcon icon={faPlus} /> Upload
                            </span>
                          </React.Fragment>
    if (this.props.datasets.isLoading) {
      addDatasetBtnVal = <Spinner animation="border" size="sm" />
    }

    let addDatasetFile = '';
  
    if (hasPermission) {
      if (role === config.ROLE_CURATOR || role === config.ROLE_EDITOR) {
        addDatasetFile = <React.Fragment>
                            <Button variant="primary" id="btn-icon" onClick={() => {
                              var fileUpload = document.getElementById("datasetFileInput");
                              fileUpload.click();
                            }}>
                              {addDatasetBtnVal}
                            </Button>
                            <input multiple type="file" id="datasetFileInput" style={{"display": "none"}} onChange={(e) => {
                              this.props.addDatasetFile(e, this.props.dataset.id);
                            }} />
                          </React.Fragment>
      }
    }
    
    return (
      <React.Fragment>
        <Row className="mt-3 mb-3">
          <Col xs={6} sm={6} md={6} lg={6} xl={6}><h5>Files</h5></Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="float-right">
              {downloadSelected}
              {addDatasetFile}
            </div>
          </Col>
        </Row>
        <Table>
          <thead>
            <tr>
              <th width="5%" className="align-center">
                {checkboxDownloadAll}
              </th>
              <th width="45%" className="align-center">Name</th>
              <th width="20%" className="align-center">Last Modified</th>
              <th width="20%" className="align-center"></th>
            </tr>
          </thead>
          <tbody>
            {datasetFiles}
          </tbody>
        </Table>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  general: state.general, 
  datasets: state.datasets,
  datasetPermissions: state.datasetPermissions 
})

const mapDispatchToProps = (dispatch) => {
  return {
    checkBoxDownloadChange: (...args) => dispatch(checkBoxDownloadChange(...args)),
    addLinkToDataset: (...args) => dispatch(addLinkToDataset(...args)),
    addDatasetFile: (...args) => dispatch(addDatasetFile(...args)),
    updateDatasetFile: (...args) => dispatch(updateDatasetFile(...args)),
    toggleShowPrompt: (...args) => dispatch(toggleShowPrompt(...args)),
    setAttachment: (...args) => dispatch(setAttachment(...args)),
    toggleShowAlert: (...args) => dispatch(toggleShowAlert(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDataset)
