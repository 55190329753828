import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom"; 
import { Link } from 'react-router-dom';
import { faSearch, faEllipsisV, faPlusCircle, faPlus, faTrash, faEdit, faUsers, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-dropdown-select";
import { Table, 
    Button, 
    Container, 
    Row, 
    Col, 
    InputGroup, 
    FormControl, 
    Form, 
    Modal, 
    Spinner, 
    Dropdown } from 'react-bootstrap';
import { fetchGroups, 
    createGroup, 
    setGroup, 
    searchGroup, 
    searchGroupByOrg,
    toggleShowMembers,
    setFieldValueChange, 
    setMemberFieldValueChange, 
    deleteMemberFromGroup, 
    setGroupMember, 
    updateGroup, 
    searchGroupMember, 
    updateMemberRole, 
    trashGroup, 
    addMemberToGroup, 
    ACTION_ADD_GROUP,
    ACTION_UPDATE_GROUP,
    ACTION_DELETE_GROUP,
    ACTION_REMOVE_GROUP_MEMBER,
    ACTION_CHANGE_ROLE } from '../../store/actions/settings/groupsActions';
import { fetchOrganizations } from '../../store/actions/settings/organizationsActions';
import { toggleDialogDisplay, toggleShowPrompt, setFormValidated, setIsEditing } from '../../store/actions/generalActions';
import { fetchRoles, searchUser } from '../../store/actions/settings/usersActions';
import { ConfirmDialog, getRoleName, DisplayAvatar, Pagination, SortButton, DisplayMoment } from '../../common/Utilities';
import SearchUser from '../../common/SearchUser';
import * as config from '../../app/config';

class GroupsManagement extends Component {

    constructor(props){
        super(props);
        this.delay =  null;
    }

    componentDidMount() {
        this.props.fetchRoles("group");
        this.props.fetchGroups();
        this.props.fetchOrganizations(null, null, 99, null);
    }

    searchGroups = (e) => {
        this.setState({
            search: e.target.value
        })
    }

    handleSubmitMember = (e) => {
        e.preventDefault();
        this.props.setFormValidated(true);

        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        else {
            this.props.addMemberToGroup();
            this.props.setFormValidated(false);
            this.props.toggleDialogDisplay(false);
            document.getElementById('user-keyword').value = "";
            document.getElementById('search-user-result').style.display = "none";
            document.getElementById('search-user-div').style.display = "block";
            document.getElementById("role").value = "";
        }
    }

    
    assignToGroupForm = () => {
        const roles = this.props.users.roles;
        const rolesList = roles.map((role, key) => {
            return (
                <option key={key} value={role.role}>{role.name}</option>
            )
        })

        return (
            <tr style={{background: "#eee"}}>
                <td width="50%" colSpan={2} >
                    <SearchUser 
                        searchAll={false} 
                        resultAction={(e, user) => {
                        const value = {
                            user
                        }
                        this.props.setMemberFieldValueChange(e, value);
                    }} />
                </td>
                <td width="48%">
                        <Form.Control id="role" required as="select" 
                            onChange={this.props.setMemberFieldValueChange}>
                            <option value="">--SELECT--</option>
                            {rolesList}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please select a role.
                        </Form.Control.Feedback>
                </td>
                <td width="2%">
                    <Button variant="primary" type="submit" id="add-member-btn">
                        <FontAwesomeIcon icon={faPlusCircle} size="lg"/>
                    </Button>
                </td>
            </tr>

        )
    }

    editableRoleDisplay = (currentRole, memberId) => {
        const roles = this.props.users.roles;
        const currentMember = this.props.groups.singleGroup.singleMember;
        const rolesList = roles.map((role, key) => {
            return (
                <option key={key} value={role.role}>{role.name}</option>
            )
        })
        if (this.props.groups.actionType === ACTION_CHANGE_ROLE && currentMember.id === memberId) {
            return  <div>
                        <div>
                            <Form.Control defaultValue={currentRole} 
                                id="role" required as="select" onChange={this.props.updateMemberRole}>
                                {rolesList}
                            </Form.Control>
                        </div>
                        <div>
                            <Link className="small" to="#" onClick={() => {
                                this.props.setGroupMember(memberId, null);
                            }}>Cancel</Link>
                        </div>
                    </div>
        }
        else {
            return <React.Fragment>{getRoleName(currentRole)}</React.Fragment>
        }
    }

    membersModal = () => {
        if (this.props.groups.showMembers) { // get members only when modal is shown
            const group = this.props.groups.singleGroup;
            const searchText = this.props.groups.searchMemberByText;
            let groupMembers = this.props.groups.singleGroup.members;
            let groupMembersList = '';
            let avatar = '';

            if (groupMembers !== null) {
                groupMembersList = groupMembers.length > 0 ? (
                    groupMembers.filter(data => {
                        if (typeof(data.user) !== "undefined") {
                            if (data.user !== null) {
                                if (data.user.last_name.toLowerCase().includes(searchText.toLowerCase()) 
                                || data.user.first_name.toLowerCase().includes(searchText.toLowerCase())) {
                                    return data;
                                }
                            }
                            else {
                                return data;
                            }
                        }
                    }).map((member, key) => {
                        if (member.user !== null) {
                            const displayName = member.user.first_name + " " + member.user.last_name;
                            let photo = '';
                            if (typeof(member.user.photo_url_signed) !== 'undefined') {
                                photo = member.user.photo_url_signed;
                            }
                            return (
                                <tr key={key}>
                                    <td className="align-center" width="5%">
                                        <DisplayAvatar photo={photo} displayName={displayName} /> 
                                    </td>
                                    <td width="45%">
                                        {member.user.first_name} {member.user.last_name} 
                                        <br />
                                        <span className="text-muted small">{member.user.email}</span>
                                    </td>
                                    <td className="align-center" width="48%">{this.editableRoleDisplay(member.role, member.id)} </td>
                                    <td className="align-center" width="2%">
                                        <Dropdown drop={"down"} id="ada-button-options">
                                            <Dropdown.Toggle variant="outline">
                                                <FontAwesomeIcon icon={faEllipsisV} />
                                            </Dropdown.Toggle>                    
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={
                                                    () => {
                                                        this.props.setGroupMember(member.id, ACTION_CHANGE_ROLE);
                                                    }
                                                }>
                                                    <FontAwesomeIcon icon={faPen} /> Edit Member
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={
                                                    () => {
                                                        this.props.setGroupMember(member.id);
                                                        this.props.toggleShowMembers(false);
                                                        this.props.toggleShowPrompt(true, ACTION_REMOVE_GROUP_MEMBER);
                                                    }
                                                }>
                                                    <FontAwesomeIcon icon={faTrash} /> Remove Member
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            )
                        }
                        else {
                            {/** Might be a deleted user */}
                            return (
                                <tr>
                                    <td colSpan={3}>    
                                        <span className="text-muted"><i>Invalid user</i></span>
                                    </td>
                                    <td className="align-center" width="2%">
                                        <Dropdown drop={"down"} id="ada-button-options">
                                            <Dropdown.Toggle variant="outline">
                                                <FontAwesomeIcon icon={faEllipsisV} />
                                            </Dropdown.Toggle>                    
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={
                                                    () => {
                                                        this.props.setGroupMember(member.id);
                                                        this.props.toggleShowMembers(false);
                                                        this.props.toggleShowPrompt(true, ACTION_REMOVE_GROUP_MEMBER);
                                                    }
                                                }>
                                                    Remove Member
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            )
                        }
                    })
                ) : (
                    <tr>
                        <td colSpan={4}>Loading...</td>
                    </tr>
                )
            }
            else {
                groupMembersList = <tr><td colSpan={4} className="align-center">No members were found.</td></tr>
            }

            return (
                <Modal show={this.props.groups.showMembers} onHide={() => {
                    this.props.fetchGroups();
                    this.props.toggleShowMembers(false);
                    if (document.getElementById('user-keyword')) {
                        document.getElementById('user-keyword').value = "";
                    }
                    if (document.getElementById("pagination-dropdown")) {
                        document.getElementById("pagination-dropdown").value = 1;
                    }
                    this.props.searchUser(null, true);
                }} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Members - {group.name} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={this.props.general.validated} onSubmit={this.handleSubmitMember}>
                            <Table id="groupMembers">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className="align-center">Name</th>
                                        <th className="align-center">Role</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.assignToGroupForm() }
                                    {groupMembersList}
                                </tbody>
                            </Table>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={(e) => {
                            if (document.getElementById("pagination-dropdown")) {
                                document.getElementById("pagination-dropdown").value = 1;
                            }
                            this.props.fetchGroups();
                            this.props.toggleShowMembers(false);
                            if (document.getElementById('user-keyword')) {
                                document.getElementById('user-keyword').value = "";
                            }
                            this.props.searchUser(e, true) //clear search
                        }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }
        
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.setFormValidated(true);
        
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        else {
            const action = this.props.general.actionType;

            if (action=== ACTION_ADD_GROUP) {
                this.props.createGroup();
            }
            else if (action === ACTION_UPDATE_GROUP) {
                this.props.updateGroup();
            }
  
            this.props.setFormValidated(false);
            this.props.toggleDialogDisplay(false);
        }
    }

    groupModal = () => {
        const group = this.props.groups.singleGroup;
        const organizations = this.props.organizations.organizations;
        const action = this.props.groups.actionType;

        let modalTitle = '';
        let groupName = '';
        let organization = '';
        const orgsLists = organizations != null && organizations.length > 0 && organizations.map((org, key) => {
            return <option key={key} value={org.id}>{org.name}</option>
        });

        if (action === ACTION_ADD_GROUP) {
            modalTitle =  'Add New Group';
        }
        else if (action === ACTION_UPDATE_GROUP) {
            modalTitle =  'Update Group';
            groupName = group.name;
            organization = group.org_id;
        }

        return (
            <Modal show={this.props.groups.showDialog} onHide={() => {
                this.props.setIsEditing(false);
                this.props.toggleDialogDisplay(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={this.props.general.validated} onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <Form.Group controlId="name">
                            <Form.Label>Group Name</Form.Label>
                            <Form.Control required type="text" defaultValue={groupName} onChange={this.props.setFieldValueChange} />
                            <Form.Control.Feedback type="invalid">
                                Please enter group name.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="org_id">
                            <Form.Label>Organization</Form.Label>
                            <Form.Control required as="select" defaultValue={organization} onChange={this.props.setFieldValueChange} >
                                <option value="">--SELECT ORGANIZATION--</option>
                                {orgsLists}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select an organization.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.props.toggleDialogDisplay(false);
                            this.props.setFormValidated(false);
                            if (action === ACTION_UPDATE_GROUP && this.props.general.isEditing) {
                                this.props.toggleShowPrompt(true, ACTION_UPDATE_GROUP);
                            }
                        }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }

    promptModal = () => {
        const action = this.props.general.actionType;
        let modalTitle = '';
        let modalBody = '';
        let actionButton = '';

        switch (action) {
            case ACTION_UPDATE_GROUP:
                modalTitle = 'Update Group';
                modalBody = 'Your changes have not been saved. Are you sure you want to discard your changes?';
                break;
            case ACTION_DELETE_GROUP:
                modalTitle = 'Delete Group';
                modalBody = 'Are you sure you want to delete this group?';
                actionButton = () => this.props.trashGroup(this.props.groups.singleGroup.id);
                break;
            case ACTION_REMOVE_GROUP_MEMBER:
                const group = this.props.groups.singleGroup;
                if (Object.keys(group).length > 0) {
                    const member = group.singleMember;

                    if (member.user !== null) {
                        modalTitle = 'Remove User from Group';
                        modalBody = 'Are you sure you want to remove ' + member.user.first_name + " " + member.user.last_name + " from the group?";
                        actionButton = () => this.props.deleteMemberFromGroup(member.id, group.id);
                    }
                    else {
                        modalTitle = 'Remove User from Group';
                        modalBody = 'Are you sure you want to remove this Invalid User?';
                        actionButton = () => this.props.deleteMemberFromGroup(member.id, group.id);
                    }
                    break;
                }
                
        }

        const onHideFunction = () => {
            this.props.toggleShowPrompt(false);
            this.props.setIsEditing(false);
        }

        const onClickCancel = () => {
            this.props.toggleShowPrompt(false);
            if (action === ACTION_UPDATE_GROUP) {
                this.props.toggleDialogDisplay(true, ACTION_UPDATE_GROUP);
            }
            else if (action === ACTION_REMOVE_GROUP_MEMBER) {
                this.props.toggleShowMembers(true);
            }
        }

        const onClickConfirm = () => {
            switch (action) {
                case ACTION_REMOVE_GROUP_MEMBER:
                    actionButton();
                    this.props.toggleShowMembers(true);
                    break;
                case ACTION_DELETE_GROUP:
                    actionButton();
                    break;
                case ACTION_UPDATE_GROUP:
                    this.props.toggleDialogDisplay(false);
                    this.props.setIsEditing(false);
                    break;
            }
            this.props.toggleShowPrompt(false);
        }

        return (
            <ConfirmDialog 
                show={this.props.general.showPrompt} 
                modalTitle={modalTitle} 
                modalBody={modalBody} 
                onHideFunction={onHideFunction}
                onClickCancel={onClickCancel}
                onClickConfirm={onClickConfirm}
            />
        ) 
    }

    renderGroups = (groups) => {
        if (groups === null && !this.props.groups.isPaginating) {
            return <p>Loading...</p>
        }
        const organizations = this.props.organizations.organizations;
        const error = this.props.groups.error;
        const searchText = this.props.groups.searchByText;
        const searchOrg = this.props.groups.searchByOrg;

        let noResultText = 'No groups found';
        if (searchText) {
            noResultText = 'No results found';
        }

        let groupLists;

        if (error === null) {
            if (groups !== null) {
                groupLists = groups.length ? (
                    groups.map((group, key) => {
                        //check who owns the group
                        let showKebab = '';
                        let groupOrg = '';
                        if (typeof(group.organization) !== "") {
                            const org = group.organization
                            if (org) {
                                groupOrg = org.name;
                            }
                           
                        }
                        if (this.props.general.userInfo.role === config.ROLE_PLATFORM_ADMIN 
                            || this.props.general.userInfo.role === config.ROLE_ORG_ADMIN  
                            || (this.props.general.userInfo.role === config.ROLE_CURATOR 
                                    && group.owner_id === this.props.general.userInfo.id)) {
                            showKebab = <Dropdown drop={"left"}>
                                            <Dropdown.Toggle variant="outline" id="ada-button-options">
                                                <FontAwesomeIcon icon={faEllipsisV} />
                                            </Dropdown.Toggle>                    
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => {
                                                    this.props.setGroup(group.id);
                                                    this.props.toggleDialogDisplay(true, ACTION_UPDATE_GROUP)
                                                }}>
                                                    <FontAwesomeIcon icon={faEdit} /> Edit Group
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={
                                                    ()=> {
                                                        this.props.setGroup(group.id);
                                                        //give time to update state
                                                        setTimeout(() => {
                                                            this.props.toggleShowMembers(true);
                                                        }, 1000)
                                                    }
                                                }>
                                                    <FontAwesomeIcon icon={faUsers} /> Manage Members
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={
                                                    () => {
                                                        this.props.setGroup(group.id);
                                                        this.props.toggleShowPrompt(true, ACTION_DELETE_GROUP);
                                                    }
                                                }>
                                                    <FontAwesomeIcon icon={faTrash} /> Remove Group
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                        }
                        return  (
                            <tr key={key}>
                                <td>{group.name}</td>
                                <td className="align-center">{groupOrg}</td>
                                <td className="align-center">{group.total_members}</td>
                                <td className="align-center">
                                    <DisplayMoment dataTime={group.created_at} />
                                </td>
                                <td className="align-center">
                                    <DisplayMoment dataTime={group.updated_at} />
                                </td>
                                <td width="3%" >
                                    {showKebab}
                                </td>
                            </tr>
                        )
                    })
                ) : (
                    <tr>
                        <td colSpan={6}><i>{noResultText}</i></td>
                    </tr>
                )
            }
            else {
                groupLists =<tr><td colSpan={6} className="align-center"><Spinner animation="grow" variant="primary" /></td></tr>
            }
        }
        else {
            groupLists =<tr><td colSpan={6}><p className="text-danger">{error}</p></td></tr>
        }

        return (
            <div>
                {this.groupModal()}
                {this.membersModal()}
                {this.promptModal()}
                <Container fluid>
                    <h2>Groups</h2>
                    <Row className="mt-5">
                        <Col sm={12} md={6} lg={6}>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                defaultValue={this.props.groups.searchByText}
                                placeholder="Search Group"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                id="search" 
                                autoComplete="off"
                                onChange={(e) => {
                                    var searchText = e.target.value;
                                    if (this.delay) {
                                    clearTimeout(this.delay);
                                    }
                                    this.delay = setTimeout(() => {
                                    if (searchText) {
                                        this.props.searchGroup(searchText);
                                    }
                                    else {
                                        this.props.searchGroup(searchText, true); //clear search
                                    }}, 500)
                                    if (document.getElementById("pagination-dropdown")) {
                                        document.getElementById("pagination-dropdown").value = 1;
                                    }
                                }} 
                                />
                            </InputGroup>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <Select 
                                id="ada-management-filter-orgs"
                                labelField="name"
                                valueField="id"
                                placeholder="Organizations"
                                className="ada-management-filter-select-dropdown"     
                                multi={false} 
                                color="#f0781e"                 
                                keepSelectedInList={false}
                                clearable={true}      
                                options={organizations}
                                onChange={(values) => {
                                    this.props.searchGroupByOrg(values);
                                    if (document.getElementById("pagination-dropdown")) {
                                        document.getElementById("pagination-dropdown").value = 1;
                                    }
                                }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="float-right mb-3" onClick={() => {
                                this.props.toggleDialogDisplay(true, ACTION_ADD_GROUP)
                            }}> 
                                <FontAwesomeIcon icon={faPlus} /> Add Group
                            </Button>
                        </Col>
                    </Row>
                    <div>
                        <Row className="mt-3 mb-1 float-right">
                            <Col md={6}>
                                <Pagination totalitems={this.props.groups.groupsTotalItems}  
                                    limit={this.props.groups.groupsLimit}
                                    callback={(page) => {
                                        let filters = {}
                                        
                                        if (this.props.groups.searchByText) {
                                            Object.assign(filters, {
                                                name: this.props.groups.searchByText
                                            })
                                        }
                                        
                                        if (this.props.groups.searchByOrg.length > 0) {
                                            Object.assign(filters, {
                                                org_id: [this.props.groups.searchByOrg]
                                            })
                                        }

                                        this.props.fetchGroups(filters, page)
                                    }} />
                            </Col>
                        </Row>
                        <Table hover>
                            <thead className="align-center">
                                <tr>
                                    <th>Name 
                                        <SortButton callback={(sortValue) => {
                                            this.props.fetchGroups(null, null, null, {"name":sortValue});
                                        }} />
                                    </th>
                                    <th>Organization 
                                        <SortButton callback={(sortValue) => {
                                            this.props.fetchGroups(null, null, null, {"org_id":sortValue});
                                        }} />
                                    </th>
                                    <th>Members</th>
                                    <th>Date Created</th>
                                    <th>Date Modified</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {groupLists}
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </div>
        )
    }
    render() {
        console.log(this.props);
        const groups = this.props.groups.groups;
        return (
            <div>
                {this.renderGroups(groups)}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    users: state.users, 
    general: state.general, 
    groups: state.groups,
    organizations: state.organizations
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrganizations: (...args) => dispatch(fetchOrganizations(...args)),
        addMemberToGroup: () => dispatch(addMemberToGroup()), 
        createGroup: () => dispatch(createGroup()), 
        updateGroup: () => dispatch(updateGroup()), 
        fetchGroups: (...args) => dispatch(fetchGroups(...args)),
        setGroup: (...args) => dispatch(setGroup(...args)),
        trashGroup: (...args) => dispatch(trashGroup(...args)),
        searchGroup: (...args) => dispatch(searchGroup(...args)),
        toggleDialogDisplay: (...args) => dispatch(toggleDialogDisplay(...args)),
        searchGroupByOrg: (...args) => dispatch(searchGroupByOrg(...args)),
        toggleShowMembers: (...args) => dispatch(toggleShowMembers(...args)), 
        toggleShowPrompt: (...args) => dispatch(toggleShowPrompt(...args)),
        setFieldValueChange: (...args) => dispatch(setFieldValueChange(...args)),
        setFormValidated: (...args) => dispatch(setFormValidated(...args)),
        setMemberFieldValueChange: (...args) => dispatch(setMemberFieldValueChange(...args)),
        deleteMemberFromGroup: (...args) => dispatch(deleteMemberFromGroup(...args)),
        setGroupMember: (...args) => dispatch(setGroupMember(...args)),
        searchGroupMember: (...args) => dispatch(searchGroupMember(...args)),
        fetchRoles: (...args) => dispatch(fetchRoles(...args)),
        updateMemberRole: (...args) => dispatch(updateMemberRole(...args)),
        setIsEditing: (...args) => dispatch(setIsEditing(...args)),
        searchUser: (...args) => dispatch(searchUser(...args))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupsManagement))
