const initState = {
    groups: [],
    userGroups: [], 
    singleGroup: {
        members: [],
        singleMember: {}
    },
    searchByText: '', 
    searchByOrg: [],
    searchMemberByText: '',
    showDialog: false,
    showMembers: false, 
    actionType: null,
    error: null,
    isPaginating: false,
    groupsCurrentPage: 1, 
    groupsTotalItems: 0, 
    groupsLimit: 10, 
    sortValues: {
        org_id: "",
        name: "asc"
    }
}

const groupsReducer = (state = initState, action) => {
    switch(action.type) {
        case 'FETCH_GROUPS_BEGIN':
            return {
                ...state, 
                groups: null,
                error: null,
            }
        case 'FETCH_GROUPS_SUCCESS':
            return {
                ...state, 
                groups: action.groups
            }
        case 'FETCH_GROUPS_ERROR':
            return {
                ...state, 
                groups: [],
                error: action.error
            }
        case 'RESET_GROUP':
            return {
                ...state,
                singleGroup: {
                    members: [],
                    singleMember: {}
                }
            }
        case 'GET_GROUP':
            return  {
                ...state,
                singleGroup: Object.assign(state.singleGroup, action.group)
            }
        case 'RESET_GROUPS':
            return {
                ...state, 
                groups: []
            }
        case 'SET_GROUP':
            let group = '';
            state.groups.filter((data) => {
                if (data.id === action.id) {
                    return data;
                }
            }).map(item => {
                group = item
            })
            return {
                ...state, 
                singleGroup: Object.assign(state.singleGroup, group)
            }
        case 'SET_GROUP_MEMBERS':
            return {
                ...state, 
                singleGroup: Object.assign(state.singleGroup, {members: action.members})
            }
        case 'RESET_GROUP_MEMBERS':
            return {
                ...state,
                singleGroup: Object.assign(state.singleGroup, {members: []})
            }
        case 'SET_IS_PAGINATING':
            return {
                ...state, 
                isPaginating: action.isPaginating,
            }
        case 'SEARCH_GROUP':
            return {
                ...state, 
                searchByText: action.keyword
            }
        case 'TOGGLE_FORM_DIALOG_BOX':
            return {
                ...state, 
                showDialog: action.show,
                actionType: action.actionType 
            }
        case 'TOGGLE_SHOW_MEMBERS':
            return {
                ...state, 
                showMembers: action.show
            }
        case 'DELETE_GROUP':
            return {
                ...state, 
                groups: action.groups
            }
        case 'SEARCH_GROUP_BY_ORG':
            return {
                ...state,
                searchByOrg: action.organization
            }
        case 'GROUPS_SET_FIELD_ON_CHANGE':
            return {
                ...state, 
                singleGroup:  Object.assign(state.singleGroup, action.value)
            }
        case 'MEMBER_SET_FIELD_ON_CHANGE':
            const singleMember = Object.assign(state.singleGroup.singleMember, action.value);
            return {
                ...state, 
                singleGroup: Object.assign(state.singleGroup, singleMember)
            }
        case 'RESET_SELECTED_MEMBER':
            return {
                ...state, 
                singleGroup: Object.assign(state.singleGroup, {'singleMember':{}})   
            }
        case 'SET_GROUP_MEMBER':
            return {
                ...state, 
                singleGroup: Object.assign(state.singleGroup, {'singleMember':action.member}),
                actionType: action.actionType 
            }
        case 'SEARCH_GROUP_MEMBER':
            return {
                ...state,
                searchMemberByText: action.keyword
            }
        case 'RESET_ACTION_TYPE':
            return {
                ...state,
                actionType: null
            }
        case 'FETCH_USER_GROUPS_BEGIN':
            return {
                ...state, 
                userGroups: null
            }
        case 'FETCH_USER_GROUPS_SUCCESS':
            return {
                ...state, 
                userGroups: action.userGroups
            }
        case 'RESET_USER_GROUPS':
            return {
                ...state, 
                userGroups: []
            }
        case 'GROUPS_SET_SORT_VALUES':
            return {
                ...state, 
                sortValues: action.sortValues
            }
        case 'GROUPS_SET_PAGINATION_DETAILS':
            return {
                ...state, 
                groupsCurrentPage: action.page, 
                groupsTotalItems: action.items,
                groupsLimit: action.limit
            }
        default:
            return state;
    }
}

export default groupsReducer