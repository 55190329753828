import * as config from '../app/config';

const MODULE = config.API_GATEWAY_URL + 'datasets/';

export const LIST_DATASETS_ENDPOINT = MODULE + "list";
export const GET_DATASETS_ENDPOINT = MODULE + "get";
export const CREATE_DATASETS_ENDPOINT = MODULE + "create";
export const UPDATE_DATASETS_ENDPOINT = MODULE + "update";
export const TRASH_DATASETS_ENDPOINT = MODULE + "trash";
export const RESTORE_DATASETS_ENDPOINT = MODULE + "restore";
export const DELETE_DATASETS_ENDPOINT = MODULE + "delete";

export const SEARCH_DATASET_LIST = MODULE + "search/list";