import React from 'react';
import Moment from 'react-moment';
import { store } from '../app/store';
import { Alert, Button, Modal, Spinner, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Avatar from "react-avatar";
import { faCheck, faExclamationCircle, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as config from '../app/config';

export const LOG_USER_LOGIN = 'LOG_USER_LOGIN';
export const LOG_USER_LOGOUT = 'LOG_USER_LOGOUT';
export const LOGS_USER_CREATE = 'LOGS_USER_CREATE';
export const LOGS_USER_UPDATE = 'LOGS_USER_UPDATE';
export const LOGS_USER_DELETE = 'LOGS_USER_DELETE';
export const LOGS_USER_TRASH = 'LOGS_USER_TRASH';
export const LOGS_USER_UPDATE_BY_SUB = 'LOGS_USER_UPDATE_BY_SUB';
export const LOGS_USER_UPDATE_BYSUB = 'LOGS_USER_UPDATE_BYSUB';
export const LOGS_USER_CONFIRM = 'LOGS_USER_CONFIRM';
export const LOGS_USER_UPDATE_PHOTO = 'LOGS_USER_UPDATE_PHOTO';

export const LOGS_GROUP_CREATE = 'LOGS_GROUP_CREATE';
export const LOGS_GROUP_UPDATE = 'LOGS_GROUP_UPDATE';
export const LOGS_GROUP_DELETE = 'LOGS_GROUP_DELETE';
export const LOGS_GROUP_TRASH = 'LOGS_GROUP_TRASH';
export const LOGS_GROUP_MEMBER_CREATE = 'LOGS_GROUP_MEMBER_CREATE';
export const LOGS_GROUP_MEMBER_ASSIGN = 'LOGS_GROUP_MEMBER_ASSIGN';
export const LOGS_GROUP_MEMBER_REMOVE = 'LOGS_GROUP_MEMBER_REMOVE';
export const LOGS_GROUP_MEMBER_UPDATE = 'LOGS_GROUP_MEMBER_UPDATE';
export const LOGS_GROUP_MEMBER_LEAVE = 'LOGS_GROUP_MEMBER_LEAVE';

export const LOGS_ORG_CREATE = 'LOGS_ORG_CREATE';
export const LOGS_ORG_UPDATE = 'LOGS_ORG_UPDATE';
export const LOGS_ORG_DELETE = 'LOGS_ORG_DELETE';
export const LOGS_ORG_TRASH = 'LOGS_ORG_TRASH';
export const LOGS_ORG_RESTORE = 'LOGS_ORG_RESTORE';

export const LOGS_DATASET_CREATE = 'LOGS_DATASET_CREATE';
export const LOGS_DATASET_UPDATE = 'LOGS_DATASET_UPDATE';
export const LOGS_DATASET_DELETE = 'LOGS_DATASET_DELETE';
export const LOGS_DATASET_TRASH = 'LOGS_DATASET_TRASH';
export const LOGS_DATASET_RESTORE = 'LOGS_DATASET_RESTORE';
export const LOGS_DATASET_PERMISSION_ASSIGN = 'LOGS_DATASET_PERMISSION_ASSIGN';
export const LOGS_DATASET_PERMISSION_REVOKE = 'LOGS_DATASET_PERMISSION_REVOKE';
export const LOGS_DATASET_PERMISSION_UPDATE = 'LOGS_DATASET_PERMISSION_UPDATE';
export const LOGS_DATASET_DICTIONARY_CREATION = 'LOGS_DATASET_DICTIONARY_CREATION';
export const LOGS_DATASET_ATTACHMENT_CREATE = 'LOGS_DATASET_ATTACHMENT_CREATE';
export const LOGS_DATASET_DICTIONARY_UPDATE = 'LOGS_DATASET_DICTIONARY_UPDATE';
export const LOGS_DATASET_DICTIONARY_DELETE = 'LOGS_DATASET_DICTIONARY_DELETE';
export const LOGS_DATASET_ATTACHMENT_UPLOAD = 'LOGS_DATASET_ATTACHMENT_UPLOAD';
export const LOGS_DATASET_ATTACHMENT_DOWNLOAD = 'LOGS_DATASET_ATTACHMENT_DOWNLOAD';
export const LOGS_DATASET_ATTACHMENT_REMOVE = 'LOGS_DATASET_ATTACHMENT_REMOVE';
export const LOGS_DATASET_QUALITY_CREATE = 'LOGS_DATASET_QUALITY_CREATE';
export const LOGS_DATASET_QUALITY_UPDATE = 'LOGS_DATASET_QUALITY_UPDATE';
export const LOGS_DATASET_QUALITY_DELETE = 'LOGS_DATASET_QUALITY_DELETE';

export const AlertMessage = ({
    show,
    type,
    onCloseFunc,
    message
}) => {
    let alertIcon = '';
    if (type === "success") {
        alertIcon = <FontAwesomeIcon icon={faCheck} />
    }
    else {
        alertIcon = <FontAwesomeIcon icon={faExclamationCircle} />
    }

    return (
        <div className="alert-fixed">
            <Alert variant={type} show={show} onClose={ () => onCloseFunc() } dismissible>
                {alertIcon} {message}
            </Alert>
        </div>
    )
}

export const ConfirmDialog = ({
    show, 
    onHideFunction, 
    modalTitle, 
    modalBody, 
    onClickCancel, 
    onClickConfirm
}) => {
    return (
        <Modal show={show} onHide={() => {
                onHideFunction();
        }}>
            <Modal.Header closeButton>
                <Modal.Title> {modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modalBody}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={() => {
                onClickCancel();
            }}>
                Cancel
            </Button>
            <Button variant="primary" onClick={() => {   
                onClickConfirm();
            }}>
                Yes
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export const getRoleName = (role) => {
    const roles = store.getState().users.roles;
    return roles.length > 0 && roles.filter(data => {
        if (data.role === role) {
            return data
        }
    }).map(role => {
        return role.name
    })
}

export const getLogActionName = (action) => {
    const logActions = [
        {key: LOG_USER_LOGIN, label: 'Login'},
        {key: LOG_USER_LOGOUT, label: 'Logout'},
        {key: LOGS_USER_CREATE, label: 'Create User'},
        {key: LOGS_USER_UPDATE, label: 'Update User'},
        {key: LOGS_USER_TRASH, label: 'Delete User'},
        {key: LOGS_USER_UPDATE_PHOTO, label: 'Update Profile Photo'},
        {key: LOGS_USER_UPDATE_BY_SUB, label: 'Update Profile'},
        {key: LOGS_USER_UPDATE_BYSUB, label: 'Update Profile'},
        {key: LOGS_USER_CONFIRM, label: 'Confirm User'},

        {key: LOGS_GROUP_CREATE, label: 'Create Group'},
        {key: LOGS_GROUP_UPDATE, label: 'Update Group'},
        {key: LOGS_GROUP_DELETE, label: 'Delete Group'},
        {key: LOGS_GROUP_TRASH, label: 'Trash Group'},
        {key: LOGS_GROUP_MEMBER_CREATE, label: 'Assign Member to Group'},
        {key: LOGS_GROUP_MEMBER_ASSIGN, label: 'Assign Member to Group'},
        {key: LOGS_GROUP_MEMBER_REMOVE, label: 'Remove Member from Group'},
        {key: LOGS_GROUP_MEMBER_UPDATE, label: 'Update Group Member'},

        {key: LOGS_ORG_CREATE, label: 'Create Organization'},
        {key: LOGS_ORG_UPDATE, label: 'Update Organization'},
        {key: LOGS_ORG_DELETE, label: 'Delete Organization'},
        {key: LOGS_ORG_TRASH, label: 'Trash Organization'},
        {key: LOGS_ORG_RESTORE, label: 'Restore Organization'},

        {key: LOGS_DATASET_CREATE, label: 'Create Dataset'},
        {key: LOGS_DATASET_UPDATE, label: 'Update Dataset'},
        {key: LOGS_DATASET_TRASH, label: 'Trash Dataset'},
        {key: LOGS_DATASET_DELETE, label: 'Delete Dataset'},
        {key: LOGS_DATASET_RESTORE, label: 'Restore Dataset'},
        {key: LOGS_DATASET_PERMISSION_ASSIGN, label: 'Assign Permission'},
        {key: LOGS_DATASET_PERMISSION_REVOKE, label: 'Revoke Permission'},
        {key: LOGS_DATASET_PERMISSION_UPDATE, label: 'Update Permission'},
        {key: LOGS_DATASET_DICTIONARY_CREATION, label: 'Create Data Dictionary'},
        {key: LOGS_DATASET_DICTIONARY_UPDATE, label: 'Update Data Dictionary'},
        {key: LOGS_DATASET_DICTIONARY_DELETE, label: 'Delete Data Dictionary'},
        {key: LOGS_DATASET_ATTACHMENT_CREATE, label: 'Create Attachment'},
        {key: LOGS_DATASET_ATTACHMENT_DOWNLOAD, label: 'Download Attachment'},
        {key: LOGS_DATASET_ATTACHMENT_REMOVE, label: 'Remove Attachment'},
        {key: LOGS_DATASET_QUALITY_CREATE, label: 'Create Quality'},
        {key: LOGS_DATASET_QUALITY_UPDATE, label: 'Update Quality'},
        {key: LOGS_DATASET_QUALITY_DELETE, label: 'Delete Quality'},
    ]

    return logActions.length > 0 && logActions.filter(data => {
        if (data.key === action) {
            return data
        }
    }).map(action => {
        return action.label
    })
}

export const DisplayAvatar = ({
    photo, 
    displayName,
    size
}) => {
    let avatar;
    if (typeof(size) !== "undefined") {
        switch(size) {
            case "sm":
                size = config.AVATAR_SIZE_SM;
                break;
            case "md":
                size = config.AVATAR_SIZE_MD;
                break;
            case "lg":
                size = config.AVATAR_SIZE_LG;
                break;
            default:
                size = config.AVATAR_SIZE;
                break;
        }
    }
    else {
        size = config.AVATAR_SIZE;
    }
    if (photo === "") {
        avatar = <Avatar name={displayName} size={size} round={config.AVATAR_RADUIS} />
    }
    else {
        avatar = <Avatar src={photo} size={size} round={config.AVATAR_RADUIS} />
    }

    return avatar;
}

export const ProfilePhoto = ({
    photo, 
    displayName,
    loading
}) => {
    let avatar;
    let isLoading = '';
    if (!loading) {
        if (photo === '') {
            avatar = <Avatar name={displayName} size={config.AVATAR_PROFILE_PIC_SIZE} />
        }
        else {
            avatar = <Avatar src={photo} size={config.AVATAR_PROFILE_PIC_SIZE} />
        }
    }
    else {
        avatar = <div id="image-loader-div">
                    <div id="image-loader"><Spinner animation="border" /></div>
                </div>
    }

    return avatar;
}

export const Pagination = ({
    totalitems,
    limit, 
    callback
}) => {
    const pages = Math.ceil(totalitems / parseInt(limit));
    if (pages > 0) {
        let options = [];
        for (var page = 1; page <= pages; page++) {
            const option = <option key={page} value={page}>Page {page}</option>;
            options.push(option);
        }
        const optionLists  = options.map(opt => {
            return opt;
        })
        return (
            <div>
                <Form.Control size="sm" as="select" id="pagination-dropdown" onChange={(e) => {
                    callback(e.target.value);
                }}>
                {optionLists}
                </Form.Control>
            </div>
        )
    }
    else {
        return '';
    }
}

export const SortButton = ({
    callback
}) => {
    return (
        <React.Fragment>
            <Button id="btn-icon" onClick={() => {
                
                var sort = document.getElementById("sort-value");
                var sortValue = sort.value;
                //default value
                if (!sortValue) {
                    sortValue = "asc";
                }

                if (sortValue === "asc") {
                    sortValue = "desc";
                }
                else if (sortValue === "desc") {
                    sortValue = "asc";
                }
                document.getElementById("sort-value").value = sortValue
                document.getElementById("pagination-dropdown").value = 1;
                callback(sortValue);
            }}>
                <FontAwesomeIcon icon={faSort} />
                
            </Button>
            <input type="hidden" id="sort-value" />
        </React.Fragment>
    )
}

export const DisplayMoment = ({
    dataTime
}) => {
    return (
        <OverlayTrigger
            key="top"
            placement="top"
            overlay={
            <Tooltip id="moment">
            {dataTime}
            </Tooltip>
            }
        >
            <Moment fromNow>{dataTime}</Moment>
        </OverlayTrigger>
    )
}

export const getPrivacyName = (visibility) => {
    let name;
    switch (visibility) {
        case 'DATASET_PRIVATE':
            name = 'Private';
            break;
        case 'DATASET_PUBLIC':
            name = 'Private';
            break;
        default:
            name = '';
            break;
    }

    return name;
}

export const Loader = () => {
    return (
        <React.Fragment>
        <div id="ada-loader">
            <div id="loader-image">
            <Spinner animation="border" />
            </div>
        </div>
        </React.Fragment>
    )
}