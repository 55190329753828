import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { searchOrganization, setOrganization } from '../store/actions/settings/organizationsActions';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SearchOrganization extends Component {
  constructor(props){
    super(props);
    this.delay =  null;
  }
  
  searchOrganization = () => {
    const keyword = this.props.organizations.search;
    if (!keyword) {
      return ''
    }

    const orgs = this.props.organizations.allOrganizations;
    let orgsList = '';
    
    if (orgs) {
      orgsList = orgs.length > 0 ? (
        orgs.map((org, key) => {
          let name = org.name;
          return (
            <li key={key} onClick={() => {
              this.props.resultAction(org);
              this.props.setOrganization(org.id);
              document.getElementById('search-org-div').style.display = "none";
              document.getElementById('ada-org-autosuggest').style.display = "none";
              document.getElementById('search-org-result').style.display = "block";
            }}>
              <Row>
                <Col className="ml-3" xs={7} sm={7} md={7} lg={7} xl={7}>
                  <div>{name}</div>
                </Col>
              </Row>
            </li>
          )
        })
      ) : (
        <li><i>No results found.</i></li>
      )
    }
    else {
      orgsList = <li><i>Searching...</i></li>;
    }

    return (
      <ul id="ada-org-autosuggest">
          {orgsList}
      </ul>
    )
  }

  searchResult = () => {
    const org = this.props.organizations.singleOrganization;

    if (Object.keys(org).length === 0) {
      return '';
    }

    let orgName = org.name;
    return (
      <Row>
        <Col className="ml-3" xs={9} sm={9} md={9} lg={9} xl={9}>
          <div>{orgName}</div>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          <Button variant="primary" id="btn-icon" onClick={(e) => {
            //clear search
            this.props.searchGroup(e, true);
            document.getElementById('group-keyword').value = "";
            document.getElementById('search-group-div').style.display = "block";
            document.getElementById('ada-group-autosuggest').style.display = "block";
            document.getElementById('search-group-result').style.display = "none";
          }}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Col>
      </Row>
    )
  }
  
  render() {
    return (
      <React.Fragment>
          <div id="search-org-div" className="align-center">
            <Form.Control defaultValue={this.props.organizations.searchByText} 
              id="org-keyword" 
              type="text" 
              placeholder="Search Organization" 
              onChange={(e) => {
                var searchText = e.target.value;
                if (this.delay) {
                  clearTimeout(this.delay);
                }
                this.delay = setTimeout(() => {
                  if (searchText) {
                    this.props.searchOrganization(searchText, false, true); //search all available organization
                  }
                  else {
                    this.props.searchOrganization(searchText, true); //clear search
                  }
                }, 500)
              }} 
              autoComplete="off" />
          </div>
          <div id="search-org-result" style={{display: "none"}}>
            {this.searchResult()}
          </div>
          {this.searchOrganization()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  organizations: state.organizations, 
  general: state.general 
});

const mapDispatchToProps = (dispatch) => {
  return {
    searchOrganization: (...args) => dispatch(searchOrganization(...args)),
    setOrganization: (...args) => dispatch(setOrganization(...args))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchOrganization)
