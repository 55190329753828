import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Cache } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { DisplayAvatar, DisplayMoment } from '../../../common/Utilities';
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { faSitemap, faTag, faArrowLeft, faGlobeAsia, faUsers, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setTags,
  fetchDatasets, 
  getDataset,  
  resetDataset, 
  fetchDatasetDD, 
  setupFilter, 
  DATASET_PRIVATE, 
  DATASET_PUBLIC_ALL, 
  DATASET_PUBLIC_ORG, 
  ACTION_TRASH_DATASET, 
  ACTION_EDIT_DATASET } from '../../../store/actions/main/datasetsActions';
import { toggleDialogDisplay, 
  setFormValidated, 
  toggleShowPrompt, 
  setInputValidator } from '../../../store/actions/generalActions';
import { checkPermissions, 
  resetAllPermissions, 
  showManageAccessForm,
  clearPermission, 
  getPermission } from '../../../store/actions/main/datasetPermissionsActions';
import { fetchRoles } from '../../../store/actions/settings/usersActions';
import * as config from '../../../app/config';
import editIcon from '../../../assets/edit-icon.png';
import trashIcon from '../../../assets/trash-icon.png';
import manageAccessIcon from '../../../assets/manage-access-icon.png';

import ManageAccessForm from '../Datasets/ManageAccessForm';
import DisplayUserAccess from '../Datasets/DisplayUserAccess';

class DatasetDetails extends Component {
  
  componentDidMount() {
    this.props.fetchRoles("group");
  }
  
  componentWillUnmount(){
    this.props.clearPermission();
  }

  showTags = (tags) => {
    if (tags === "") {
      return '';
    }

    let comma = ", ";
    let arrTags = tags.split(",");
    let tagBadges = arrTags.map((tag, key) => {
      if ((arrTags.length - 1) === key) {
          comma = "";
      }
      return (
        <React.Fragment key={key}>
          <Link to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.setupFilter(e, {tag});
                    this.props.history.push('/main/datacatalog');
                }}>{tag}</Link>{comma}
        </React.Fragment>
      )    
    })
    
    return (
      <Col xs={12} sm={12} md={3} lg={3} xl={3}>
        <Row>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} className="mt-2">
            <FontAwesomeIcon icon={faTag} size="2x" />
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
        
          <h6 className="text-primary">Tags</h6>
          {tagBadges}
        </Col>
        </Row>
      </Col>
    )
  }
  render() {
    const dataset = this.props.dataset;
    let ownerId = '';
    let authorName = '';
    let authorPhoto = '';
    let orgId = dataset.org_id;  
    let org = '';

    if (dataset.owner) {
      const owner = dataset.owner;
      ownerId = owner.id;
      authorName = owner.first_name + " " + owner.last_name;
      authorPhoto = owner.photo_url_signed;

      if (typeof(owner.organization) !== "undefined") {
        const organization = owner.organization;
        if (organization) {
            org = organization.name;
        }
      }
    }
    
    if (typeof(dataset.organization) !== "undefined") {
      const organization = dataset.organization;
      if (organization) {
          org = organization.name;
      }
    }

    const urlParams = new URLSearchParams(this.props.location.search);
    const keywordParam =  urlParams.get('q');
    let backToSearchBtn = '';
    if (keywordParam) {
      backToSearchBtn = <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                <Tooltip id="tooltip-top">
                                    Back to Search Results
                                </Tooltip>
                                }
                            >
                                <Button variant="primary" id="btn-icon" onClick={() => {
                                  this.props.history.push('/dataset/search?q='+keywordParam)
                                }}>
                                  <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                                </Button>
                            </OverlayTrigger>
    }
    let manageAccessBtn = <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                              <Tooltip id="tooltip-top">
                                  Set Permission
                              </Tooltip>
                              }
                          >
                            <Button variant="primary" id="btn-icon" onClick={() => {
                                this.props.fetchRoles("group");
                                this.props.showManageAccessForm(true, dataset.id);
                                this.props.resetAllPermissions();
                              }}>
                                <img alt="manage-access-icon" src={manageAccessIcon} />
                              </Button>
                          </OverlayTrigger>
    let editDatasetBtn = <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                              <Tooltip id="tooltip-top">
                                  Edit Dataset
                              </Tooltip>
                              }
                          > 
                        <Button variant="primary" id="btn-icon" onClick={() => {
                            this.props.setInputValidator(false);
                            this.props.toggleDialogDisplay(true, ACTION_EDIT_DATASET)
                          }}>
                            <img alt="edit-icon" src={editIcon} />
                          </Button>
                        </OverlayTrigger>
    
    let trashDatasetBtn = <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                              <Tooltip id="tooltip-top">
                                  Delete Dataset
                              </Tooltip>
                              }
                          > 
                            <Button variant="primary" id="btn-icon" onClick={() => {
                              this.props.toggleShowPrompt(true, ACTION_TRASH_DATASET);
                            }}>
                              <img alt="trash-icon" src={trashIcon} />
                            </Button>
                          </OverlayTrigger>


    let viewTrashBtn = '';
    let viewEditBtn = '';
    let viewManageAccessBtn = '';
    let accessText = '';
    let role = Cache.getItem('role');
    let defaultRole = role;
    let access = checkPermissions(dataset, role);
    let hasPermission = access.hasPermission;
    let permissionId = access.permissionId;
    let permission = this.props.datasetPermissions.singlePermission;
    let visibility = access.visibility;
    let visibilityLabel = '';

    if (permissionId) {
      this.props.getPermission(permissionId);
      console.log(this.props.datasetPermissions);
      if (Object.keys(permission).length > 0) {
        accessText = <DisplayUserAccess role={access.role} permission={this.props.datasetPermissions.singlePermission} />
      }
    }
    else {
      switch (visibility) {
        case DATASET_PUBLIC_ALL:
          visibilityLabel = <React.Fragment>
                              <FontAwesomeIcon icon={faGlobeAsia} /> Public Across All Organizations 
                            </React.Fragment>
          break;
        case DATASET_PUBLIC_ORG:
          visibilityLabel = <React.Fragment>
                              <FontAwesomeIcon icon={faUsers} /> Public within Organizations 
                            </React.Fragment>
          break; 
        case DATASET_PRIVATE:
          visibilityLabel = <React.Fragment>
                              <FontAwesomeIcon icon={faLock} /> Private
                            </React.Fragment>
          break;
        default:
          visibilityLabel = '';
          break; 
      }
      accessText = visibilityLabel;
    }
    //if the different meaning no grant permission added
    if (hasPermission) { 
        role = access.role;
    }
    else {
      //only allow PA and OA to do their default jobs
      if (role === config.ROLE_ORG_ADMIN 
        || role === config.ROLE_PLATFORM_ADMIN) {
        hasPermission = true;
      } 
    }

    switch (role) {
      case config.ROLE_VIEWER:
        break;
      case config.ROLE_EDITOR:
        //public (all or withing org) or shared to user 
        //(as user or in group) as editor 
        if (hasPermission) { 
          viewEditBtn = editDatasetBtn;
        }
        else {
          //check if owned
          if (ownerId === this.props.general.userInfo.id) {
            viewEditBtn = editDatasetBtn;
          }
        }
        break;
      case config.ROLE_ORG_ADMIN:
        if (hasPermission) {
          if (typeof(this.props.general.userInfo.organization) != "undefined") {
            const org = this.props.general.userInfo.organization;
            if (org) {
              if (orgId === org.id) {
                viewTrashBtn = trashDatasetBtn;
                viewManageAccessBtn = manageAccessBtn
              }
            }
          }
        }
        break;
      case config.ROLE_PLATFORM_ADMIN:
        if (hasPermission) {
          viewTrashBtn = trashDatasetBtn;
          viewManageAccessBtn = manageAccessBtn
        }
        break;
      case config.ROLE_CURATOR:
        if (hasPermission) {
          //just in case the owner was added in grant permission
          if (ownerId === this.props.general.userInfo.id) { 
            viewTrashBtn = trashDatasetBtn;
            viewEditBtn = editDatasetBtn;
            viewManageAccessBtn = manageAccessBtn
          }
          else {
            viewEditBtn = editDatasetBtn;
            viewManageAccessBtn = manageAccessBtn
          }
        }
        else {
          if (ownerId === this.props.general.userInfo.id) {
            viewTrashBtn = trashDatasetBtn;
            viewEditBtn = editDatasetBtn;
            viewManageAccessBtn = manageAccessBtn
          }
        }
        
        break;
    }
    //if default role is PA or OA, show Trash button even they have CU/ED/VI access grant
    if (defaultRole === config.ROLE_PLATFORM_ADMIN || defaultRole === config.ROLE_ORG_ADMIN) {
      if (defaultRole === config.ROLE_ORG_ADMIN) {
        if (typeof(this.props.general.userInfo.organization) != "undefined") {
          const org = this.props.general.userInfo.organization;
          if (org) {
            if (orgId === org.id) {
              viewTrashBtn = trashDatasetBtn;
              viewManageAccessBtn = manageAccessBtn
            }
          }
        }
      }
      else {
        viewTrashBtn = trashDatasetBtn;
        viewManageAccessBtn = manageAccessBtn
      }
    }

    return (
      <React.Fragment>
        <ManageAccessForm />
        <Row>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <h3>
              <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                  <Tooltip id="tooltip-top">
                      <b>{dataset.quality.name}</b> - {dataset.quality.description}
                  </Tooltip>
                  }
              >
                  <img alt="quality-icon" src={dataset.quality.icon_url} width="40" height="40" />
              </OverlayTrigger>
              <span className="ada-page-title">
                {dataset.name}
              </span>
            </h3>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <div className="float-right">
              {backToSearchBtn}
              {viewManageAccessBtn}
              {viewEditBtn}
              {viewTrashBtn}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="float-right text-muted">
              {accessText}
            </div>
          </Col>
        </Row>
        <Row className="mt-3" id="ada-dataset-details">
            <Col xs={12} sm={12} md={3} lg={3} xl={3}>
              <Row>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    <DisplayAvatar photo={authorPhoto} displayName={authorName} /> 
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
               
                  <h6 className="text-primary">Author</h6>
                  {authorName}
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} xl={3}>
              <Row>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2} className="mt-2">
                    <FontAwesomeIcon icon={faSitemap} size="2x" />
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
               
                  <h6 className="text-primary">Organization</h6>
                  {org}
                </Col>
              </Row>
            </Col>
            {this.showTags(dataset.tags)}
            <Col xs={12} sm={12} md={3} lg={3} xl={3}>
              <p><b className="text-primary">Date Created: </b><DisplayMoment dataTime={dataset.created_at} /></p>
              <p><b className="text-primary">Date Modified: </b><DisplayMoment dataTime={dataset.updated_at} /></p>
            </Col>
        </Row>
        
        <Row className="mt-3 mb-5" >
          <h6 className="text-primary">Description</h6>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} id="ada-dataset-description">
            <Row><Col xs={12} sm={12} md={12} lg={12} xl={12}></Col></Row>
            <span id="ada-dataset-description-text">{dataset.description}</span>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  general: state.general,
  datasets: state.datasets,
  datasetPermissions: state.datasetPermissions
})

const mapDispatchToProps = (dispatch) => {
  return {
      toggleDialogDisplay: (...args) => dispatch(toggleDialogDisplay(...args)),
      setTags: (...args) => dispatch(setTags(...args)),
      setFormValidated: (...args) => dispatch(setFormValidated(...args)),
      getDataset: (...args) => dispatch(getDataset(...args)),
      resetDataset: () => dispatch(resetDataset()),
      toggleShowPrompt: (...args) => dispatch(toggleShowPrompt(...args)),
      fetchDatasetDD: (...args) => dispatch(fetchDatasetDD(...args)),
      setInputValidator: (...args) => dispatch(setInputValidator(...args)),
      fetchDatasets: (...args) => dispatch(fetchDatasets(...args)),
      setupFilter: (...args) => dispatch(setupFilter(...args)),
      resetAllPermissions: () => dispatch(resetAllPermissions()),
      getPermission: (...args) => dispatch(getPermission(...args)), 
      clearPermission: (...args) => dispatch(clearPermission(...args)), 
      showManageAccessForm: (...args) => dispatch(showManageAccessForm(...args)),
      fetchRoles: (...args) => dispatch(fetchRoles(...args))
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatasetDetails))
