
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav } from 'react-bootstrap'; 
import { faUsers, faUserFriends, faSitemap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from 'react-router-dom';
import * as config from '../../app/config';

class SideNavbar extends Component {

    render() {
        const userInfo = this.props.general.userInfo;
        let navsItems;
        switch (userInfo.role) {
            case config.ROLE_PLATFORM_ADMIN:
            case config.ROLE_ORG_ADMIN:
                navsItems = <Nav id="ada-main-side-nav" className="flex-column">
                                <Nav.Item id="ada-main-side-nav-org-setting" onClick={() => {
                                    this.props.history.push('/settings/organizations');
                                }}>
                                    <FontAwesomeIcon icon={faSitemap} />  Organizations
                                </Nav.Item>
                                <Nav.Item id="ada-main-side-nav-org-setting" onClick={() => {
                                    this.props.history.push('/settings/users');
                                }}>
                                    <FontAwesomeIcon icon={faUserFriends} /> Users
                                </Nav.Item>
                                <Nav.Item id="ada-main-side-nav-org-setting" onClick={() => {
                                    this.props.history.push('/settings/groups');
                                }}>
                                    <FontAwesomeIcon icon={faUsers} /> Groups
                                </Nav.Item>
                            </Nav>
                break;
            case config.ROLE_CURATOR:
                navsItems = <Nav id="ada-main-side-nav" className="flex-column">
                                <Nav.Item id="ada-main-side-nav-org-setting" onClick={() => {
                                    this.props.history.push('/settings/groups');
                                }}>
                                    <FontAwesomeIcon icon={faUsers} /> Groups
                                </Nav.Item>
                            </Nav>
                break;
            default:   
                this.props.history.push('/main/datacatalog');
                break;
        }
        return (
            <div id="ada-settings-side-container">
                <div id="ada-header">
                    <h4>User Access Management</h4>
                </div>
                {navsItems}
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    general: state.general, 
})

export default connect(mapStateToProps)(withRouter(SideNavbar))
