import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { searchDataset, search } from '../../../store/actions/main/datasetsActions';
import DatasetCard from './DatasetCard';
import { Pagination } from '../../../common/Utilities';

class SearchResults extends Component {
  componentDidMount() {
    const keywordState =  this.props.datasets.searchDatasets
    
    const urlParams = new URLSearchParams(this.props.location.search);
    const keywordParam =  urlParams.get('q');

    if (!keywordState) {
      this.props.searchDataset(keywordParam);
      this.props.search();
    }
  }
  
  listDatasetResults = (searchResults) => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const keywordParam =  urlParams.get('q');
    let searchResultsList = ''
    if (searchResults) {
      searchResultsList = searchResults.length > 0 ? (
        searchResults.map((result, key) => {
          let orgName = '';
          let imgQuality = '';
          let qualityDesc = '';
          let ownerName = '';
          let ownerPhoto = '';
          if (typeof(result.organization) !== "undefined") {
            const org = result.organization;
            if (org) {
              orgName = org.name;
            }
          }
  
          if (typeof(result.owner) !== "undefined") {
            const owner = result.owner;
            if (owner) {
              ownerName = owner.first_name + " " + owner.last_name;
              ownerPhoto = owner.photo_url_signed;
            }
          }
  
          if (typeof(result.quality) !== "undefined") {
            const quality = result.quality;
            if (quality) {
              imgQuality = quality.icon_url;
              qualityDesc = quality.description;
            }
          }
          let createdAt = new Date(result.created_at * 1000);
          var dd = String(createdAt. getDate()). padStart(2, '0');
          var mm = String(createdAt. getMonth() + 1). padStart(2, '0'); //January is 0!
          var yyyy = createdAt. getFullYear();
          var hour = createdAt.getHours() + "";
          hour = hour % 12;
          hour = hour ? hour : 12; // the hour '0' should be '12'
          hour = String(hour). padStart(2, '0');
          var minutes = String(createdAt.getMinutes()).padStart(2, '0');
          var ampm = hour >= 12 ? 'pm' : 'am';
          createdAt = mm + "/" + dd + "/" + yyyy + " " + hour + ":" + minutes + " " + ampm;

          return (<DatasetCard 
            q={keywordParam}
            key={key} 
            datasetId={result.id} 
            datasetName={result.name} 
            ownerName={ownerName}
            ownerPhoto={ownerPhoto} 
            createdAt={createdAt} 
            orgName={orgName} 
            visibility={result.visibility_label}
            quality={imgQuality}
            qualityDesc={qualityDesc}
            tags={result.tags} 
          />)
        })
      ) : (
        <Row className="align-center">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Spinner animation="grow" variant="primary" />
          </Col>
        </Row>
      )
    }
    else {
      searchResultsList = <p>No results found.</p>
    }

    return searchResultsList;
  }

  render() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const keyword =  urlParams.get('q');

    const searchResults = this.props.datasets.searchResults;
    let searchingText = '';
    let totalItems = '';

    if (keyword) {
      searchingText = "Searching for " + keyword;
    }

    if (searchResults) {
      if (searchResults.length > 0) {      
        const searchTotalItems = this.props.datasets.searchTotalItems;
        let txtResult = "Result";
        if (searchTotalItems > 1) {
          txtResult = "Results";
        }
        totalItems = searchTotalItems + " " + txtResult
      }
    }
    
    return (
      <Container fluid id="ada-dataset-search-results">
        <h3>Search Results</h3> 
        <div>
          {searchingText}
        </div>
        <Row className="mt-5 mb-3">
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            {totalItems}
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="float-right">
              <Pagination totalitems={this.props.datasets.searchTotalItems}  
                limit={this.props.datasets.searchLimit}
                callback={(page) => {
                    this.props.search(null, page);
                }} 
                currentPage={this.props.datasets.currentPage} />
            </div>
          </Col>
        </Row>
        <div>
          {this.listDatasetResults(searchResults)}
        </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  datasets: state.datasets
})

const mapDispatchToProps = (dispatch) => {
  return {
    searchDataset: (...args) => dispatch(searchDataset(...args)),
    search: (...args) => dispatch(search(...args))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResults))
