import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Button, Form, Nav, Row, Col, Container, Card, ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { faUser, faUsers, faGlobeAsia, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Cache } from 'aws-amplify';
import SearchUser from '../../../common/SearchUser';
import SearchGroup from '../../../common/SearchGroup';
import SearchOrganization from '../../../common/SearchOrganization';
import PermittedUsers from './PermittedUsers';
import PermittedGroups from './PermittedGroups';
import PermittedOrganizations from './PermittedOrganizations';
import { 
  shareToGroup,
  shareToUser, 
  shareToOrg, 
  showManageAccessForm, 
  setUserToGiveAccess,  
  setGroupToGiveAccess,
  setOrgToGiveAccess, 
  clearGroupToGiveAccess,
  clearUserToGiveAccess, 
  checkPermissions, 
  resetPermittedUsers} from '../../../store/actions/main/datasetPermissionsActions';
import { 
  changeVisibility, 
  fetchDatasets, 
  resetDataset,
  getDataset, 
  DATASET_PRIVATE, 
  DATASET_PUBLIC_ALL, 
  DATASET_PUBLIC_ORG } from '../../../store/actions/main/datasetsActions';
import { searchUser } from '../../../store/actions/settings/usersActions';
import { searchGroup } from '../../../store/actions/settings/groupsActions';
import { setFormValidated, toggleShowAlert, ERROR_ALERT } from '../../../store/actions/generalActions';
import * as config from '../../../app/config';

class ManageAccessForm extends Component {

  handleSubmitUser = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const userSearchField = document.getElementById('user-keyword');
    const roleField = document.getElementById("role");
    const userToAdd = this.props.datasetPermissions.userToGrant;

    if (form.checkValidity() === false || (!userToAdd.role || !userToAdd.user)) {
        e.preventDefault();
        e.stopPropagation();
        this.props.toggleShowAlert(true, ERROR_ALERT,  "Please enter user and role.");
    }
    else {
      this.props.shareToUser();
      if (userSearchField) {
        userSearchField.value = "";
      }
      if (roleField) {
        roleField.value = "";
      }
      this.props.clearUserToGiveAccess();
      document.getElementById('search-user-result').style.display = "none";
      document.getElementById('search-user-div').style.display = "block"; 
    }
  }

  handleSubmitGroup = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    const groupToAdd = this.props.datasetPermissions.groupToGrant;

    if (form.checkValidity() === false || !groupToAdd.id) {
        e.preventDefault();
        e.stopPropagation();
        this.props.toggleShowAlert(true, ERROR_ALERT,  "Please enter group.");
    }
    else {
        this.props.shareToGroup();
        document.getElementById('group-keyword').value = "";
        document.getElementById('search-group-result').style.display = "none";
        document.getElementById('search-group-div').style.display = "block";
    }
  }

  handleSubmitOrg = (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
    }
    else {
        this.props.shareToOrg();
        document.getElementById('org-keyword').value = "";
        document.getElementById('search-org-result').style.display = "none";
        document.getElementById('search-org-div').style.display = "block";
    }
  }

  visibilityOptions = (dataset) => {
    const org = Cache.getItem('orgId');
    const userId = Cache.getItem('userId');
    let role = Cache.getItem('role');

    let access = checkPermissions(dataset, role);
    let hasPermission = access.hasPermission;
    
    if (role !== config.ROLE_PLATFORM_ADMIN && role !== config.ROLE_ORG_ADMIN) {
      if (hasPermission) {
        role = access.role;
        if (role === config.ROLE_EDITOR || role === config.ROLE_VIEWER) {
          return '';
        }
      }
    }
    const visibility = dataset.visibility;
    let optPrivate = false;
    let optPublic = false;
    let optPublicAll = false;
    let optPublicOrg = false;
    let publicTypesHidden = true;
    switch (visibility) {
      case DATASET_PRIVATE:
        optPrivate = true;
        break;
      case DATASET_PUBLIC_ALL:
        optPublicAll = true;
        optPublic = true;
        break;
      case DATASET_PUBLIC_ORG:
        optPublicOrg = true;
        optPublic = true;
        break;
    }

    if (optPublic) {
      publicTypesHidden = false;
    }

    let publicAll = '';
    let publicWithinOrg = '';

    publicAll = <Form.Check
                        inline
                        label="Public to All"
                        type="radio"
                        name="visibility_public"
                        id="dataset-public-all"
                        defaultChecked={optPublicAll}
                        value={DATASET_PUBLIC_ALL}
                        onClick={(e) => {
                          this.props.changeVisibility(DATASET_PUBLIC_ALL)
                        }}
                      />
    publicWithinOrg = <Form.Check
                            inline
                            label="Public within Organization"
                            type="radio"
                            name="visibility_public"
                            value={DATASET_PUBLIC_ORG}
                            defaultChecked={optPublicOrg}
                            id="dataset-public-org" 
                            onClick={(e) => {
                              this.props.changeVisibility(DATASET_PUBLIC_ORG)
                            }}
                          />

    const options = <Container className="mt-3 mb-3">
                      <Row>
                        <Col>
                        <Card>
                          <ListGroup variant="flush">
                            <ListGroup.Item>
                              <Row>
                                <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                  <FontAwesomeIcon icon={faGlobeAsia} /> Public
                                  <div className="small text-muted">Allow this dataset to be viewed by all users.</div>
                                  <div id="dataset-public-types" hidden={publicTypesHidden}>
                                    {publicAll}
                                    {publicWithinOrg}
                                  </div>
                                </Col>
                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                  <Form.Check 
                                    type="radio"
                                    id="dataset-public"
                                    className="float-right"
                                    value="dataset_public"
                                    name="visibility" 
                                    defaultChecked={optPublic}
                                    onClick={() => {
                                      var publicTypes = document.getElementById('dataset-public-types');
                                      if (publicTypes.hasAttribute('hidden')) {
                                        publicTypes.removeAttribute('hidden');
                                      }
                                      else {
                                        publicTypes.style.display = "block";
                                      }
                                    }} />
                                    
                                </Col>
                              </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <Row>
                                <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                  <FontAwesomeIcon icon={faUserShield} /> Private
                                  <div className="small text-muted">Hide this dataset to other users except those with permission.</div>
                                </Col>
                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                  <Form.Check 
                                    type="radio"
                                    id="dataset-private"
                                    name="visibility"
                                    defaultChecked={optPrivate}
                                    value={DATASET_PRIVATE} 
                                    className="float-right"
                                    onClick={() => {
                                      document.getElementById('dataset-public-types').style.display = "none";
                                      this.props.changeVisibility(DATASET_PRIVATE)
                                    }}  />
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          </ListGroup>
                        </Card>
                        </Col>
                      </Row>
                    </Container>
    switch (role) {
      case config.ROLE_PLATFORM_ADMIN:
        return options;
        break;
      case config.ROLE_CURATOR:
      case config.ROLE_ORG_ADMIN:
        if (org === dataset.org_id) {
          return options;
        }
        return '';
      default:
        return '';
    }
  }

  render() {
    const dataset = this.props.datasets.singleDataset;
    const roles = this.props.users.roles;
    const rolesList = roles.map((role, key) => {
        return (
            <option key={key} value={role.role}>{role.name}</option>
        )
    })

    return (
      <Modal show={this.props.datasetPermissions.showManageAccessForm} onHide={() => {
        this.props.showManageAccessForm(false);
        document.getElementById('group-keyword').value = "";
        document.getElementById('user-keyword').value = "";
        this.props.searchUser(null, true) //clear search
        this.props.searchGroup(null, true) //clear search
      }} backdrop="static">
          <Modal.Header closeButton>
              <Modal.Title>
                Manage Access
                <div id="ada-dataset-permission-modal-title" className="small text-muted">{dataset.name}</div>
              </Modal.Title>
              
          </Modal.Header>
          <div id="ada-dataset-manage-access-modal">
            <Modal.Body>
              {this.visibilityOptions(dataset)}
              <Container className="mt-3">
                <h5>Grant Permission</h5>
              </Container>
              <Nav fill variant="tabs" defaultActiveKey="#users">
                <Nav.Item>
                  <Nav.Link href="#users" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('ada-org-grant-access').style.display = "none";
                    document.getElementById('ada-group-grant-access').style.display = "none";
                    document.getElementById('ada-user-grant-access').style.display = "block";
                  }}>
                    <FontAwesomeIcon icon={faUser} /> Users
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="org-link" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('ada-group-grant-access').style.display = "none";
                    document.getElementById('ada-user-grant-access').style.display = "none";
                    document.getElementById('ada-org-grant-access').style.display = "block";
                  }}>
                    <FontAwesomeIcon icon={faUsers} /> Organizations
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link eventKey="group-link" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('ada-group-grant-access').style.display = "block";
                    document.getElementById('ada-user-grant-access').style.display = "none";
                    document.getElementById('ada-org-grant-access').style.display = "none";
                  }}>
                    <FontAwesomeIcon icon={faUsers} /> Groups
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Container>
                <Form id="ada-user-grant-access" onSubmit={this.handleSubmitUser}>
                  <Row className="mt-5">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <SearchUser 
                          searchAll={true}
                          resultAction={(e, user) => {
                            const value = {
                                user
                            }
                            this.props.setUserToGiveAccess(e, value)
                        }} />
                      </Col>
                      <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Form.Group>
                          <Form.Control as="select" id="role" onChange={this.props.setUserToGiveAccess}>
                            <option value="">--SELECT--</option>
                            {rolesList}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Button type="submit" variant="primary">Grant</Button>
                      </Col>
                  </Row>
                  <PermittedUsers datasetId={dataset.id} />
                </Form>
              </Container>
              <Container>
                <Form id="ada-org-grant-access" onSubmit={this.handleSubmitOrg}>
                  <Row className="mt-5 mb-3">
                      <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                        <SearchOrganization resultAction={(org) => {
                          this.props.setOrgToGiveAccess(org)
                        }} />
                      </Col>
                      <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Button type="submit" variant="primary">Grant</Button>
                      </Col>
                  </Row>
                  <PermittedOrganizations datasetId={dataset.id} />
                </Form>
              </Container>
              <Container>
                <Form id="ada-group-grant-access" onSubmit={this.handleSubmitGroup}>
                  <Row className="mt-5 mb-3">
                      <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                        <SearchGroup resultAction={(group) => {
                          this.props.setGroupToGiveAccess(group)
                        }} />
                      </Col>
                      <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Button type="submit" variant="primary">Grant</Button>
                      </Col>
                  </Row>
                  <PermittedGroups datasetId={dataset.id} />
                </Form>
              </Container>
            </Modal.Body>
          </div>
          <Modal.Footer>
              <Button variant="secondary" onClick={(e) => {
                this.props.resetPermittedUsers();
                this.props.showManageAccessForm(false);
                let filter = {}
                let orgId = '';
                let groupId = '';
                if (typeof(this.props.datasets.datasetFilter.org_id) !== "undefined") {
                  orgId = this.props.datasets.datasetFilter.org_id;
                  filter = {
                      org_id: [orgId]
                  }
                }

                if (typeof(this.props.datasets.datasetFilter.group_id) !== "undefined") {
                  groupId = this.props.datasets.datasetFilter.group_id;
                  if (groupId > 0) {
                    filter = {
                      group_id: [groupId]
                    }
                  }
                }

                if (typeof(this.props.datasets.datasetFilter.quality_id) !== "undefined") {
                    Object.assign(filter, {
                        quality_id: [this.props.datasets.datasetFilter.quality_id]
                    })
                }
                const urlParams = new URLSearchParams(this.props.location.search);
                const datasetId =  urlParams.get('id');
                if (datasetId) {
                  this.props.resetDataset();
                  this.props.getDataset(datasetId);
                }
                else {
                  let page = this.props.datasets.currentPage;
                  this.props.fetchDatasets(filter, page, {created_at: "desc"});
                }
                document.getElementById('group-keyword').value = "";
                document.getElementById('user-keyword').value = "";
                this.props.searchGroup(e, true) //clear search
                this.props.searchUser(e, true) //clear search
              }}>
                  Done
              </Button>
          </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  datasetPermissions: state.datasetPermissions, 
  datasets: state.datasets,
  users: state.users,
  groups: state.groups
})

const mapDispatchToProps = (dispatch) => {
  return {
      showManageAccessForm: (...args) => dispatch(showManageAccessForm(...args)),
      setUserToGiveAccess: (...args) => dispatch(setUserToGiveAccess(...args)),
      shareToUser: () => dispatch(shareToUser()),
      shareToGroup: () => dispatch(shareToGroup()), 
      shareToOrg: () => dispatch(shareToOrg()), 
      setGroupToGiveAccess: (...args) => dispatch(setGroupToGiveAccess(...args)),
      setOrgToGiveAccess: (...args) => dispatch(setOrgToGiveAccess(...args)), 
      changeVisibility: (...args) => dispatch(changeVisibility(...args)),
      resetPermittedUsers: () => dispatch(resetPermittedUsers()),
      searchUser: (...args) => dispatch(searchUser(...args)),
      searchGroup: (...args) => dispatch(searchGroup(...args)), 
      fetchDatasets: (...args) => dispatch(fetchDatasets(...args)),
      setFormValidated: (...args) => dispatch(setFormValidated(...args)),
      toggleShowAlert: (...args) => dispatch(toggleShowAlert(...args)),
      clearGroupToGiveAccess: () => dispatch(clearGroupToGiveAccess()),
      clearUserToGiveAccess: () => dispatch(clearUserToGiveAccess()),
      checkPermissions: (...args) => dispatch(checkPermissions(...args)),
      resetDataset: () => dispatch(resetDataset()),
      getDataset: (...args) => dispatch(getDataset(...args))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageAccessForm))