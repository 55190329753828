import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Row, Col, Button, Spinner } from 'react-bootstrap';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchPermissions, 
  unshareDataset, 
  PERMISSION_TYPE_USER } from '../../../store/actions/main/datasetPermissionsActions';
import Avatar from "react-avatar";
import { getRoleName, Pagination } from '../../../common/Utilities';
import * as config from '../../../app/config';

class PermittedUsers extends Component {
  
  componentDidMount() {
    this.props.fetchPermissions(this.props.datasetId, PERMISSION_TYPE_USER);
  }
  renderPermittedUsers = () => {
    const permittedUsers = this.props.datasetPermissions.permittedUsers;
    let usersList;
    if (permittedUsers) {
      usersList = permittedUsers.length > 0 ? (
        permittedUsers.filter(data => {
          if (typeof(data.user) !== "undefined") {
            const user = data.user;
            if (user) {
              return data;
            }
          }
        }).map((permittedUser, key) => {
          const user = permittedUser.user;
          let avatar;
          if (user.photo_url === null) {
              avatar = <Avatar name={user.first_name + " " + user.last_name} size={config.AVATAR_SIZE} round={config.AVATAR_RADUIS} />
          }
          else {
              avatar = <Avatar src={user.photo_url_signed} size={config.AVATAR_SIZE} round={config.AVATAR_RADUIS} />
          }
          return (
            <tr key={key}>
              <td width="10%" className="align-right">
                {avatar}
              </td>
              <td width="55%">
                <div>
                  {user.first_name + " " + user.last_name}
                </div>
                <div>
                  <span className="text-muted small">{user.email} </span>
                </div>
              </td>
              <td width="15%">{getRoleName(permittedUser.role)}</td>
              <td width="20%">
                <Button variant="icon" id="btn-icon" onClick={() => {
                  this.props.unshareDataset(permittedUser.id, PERMISSION_TYPE_USER);
                }}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          )
        })
      ) : (
        <tr><td colSpan={4} className="align-center"><Spinner animation="grow" variant="primary" /></td></tr>
      )
    }
    else {
      usersList = <tr><td colSpan={4}>No users added yet</td></tr>
    }

    return usersList;
  }
  
  render() {
    return (
      <Row> 
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row className="mt-3 mb-1 float-right">
            <Col md={6}>
                <Pagination totalitems={this.props.datasetPermissions.permittedUsersTotalItems}  
                    limit={this.props.datasetPermissions.permittedUsersLimit}
                    callback={(page) => {
                        this.props.fetchPermissions(this.props.datasetId, PERMISSION_TYPE_USER, page, null)
                    }} />
            </Col>
          </Row>
          <Table>
            <thead>
              <tr>
                <th width="10%"></th>
                <th width="55%">Name</th>
                <th width="15%" className="align-center">Role</th>
                <th width="20%" className="align-center"></th>
              </tr>
            </thead>
            <tbody>
              {this.renderPermittedUsers()}
            </tbody>
          </Table>
        </Col>
        
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({
  datasetPermissions: state.datasetPermissions
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPermissions: (...args) => dispatch(fetchPermissions(...args)),
    unshareDataset: (...args) => dispatch(unshareDataset(...args)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermittedUsers)
