import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Cache } from 'aws-amplify';
import { Nav } from 'react-bootstrap'; 
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from 'react-router-dom';
import { fetchOrganizations, 
    getOrganization, 
    resetOrgs, 
    resetOrganization,
    fetchUserOtherOrgs } from '../../store/actions/settings/organizationsActions';
import { fetchGroups, resetUserGroups, clearGroups } from '../../store/actions/settings/groupsActions';
import * as config from '../../app/config';
import RecentlyViewed from './Datasets/RecentlyViews';

class SideNavbar extends Component {

    componentDidMount() {
        this.props.fetchOrganizations(null, null, 99);
    }

    getOrganizations = () => {
        if (Object.keys(this.props.general.userInfo)) {
            const organizations = this.props.organizations.organizations;
            const error = this.props.organizations.error;
            if (error === null) {
                if (organizations !== null) {
                    return organizations.length > 0 ? (
                        organizations.map((org, key) => {
                            return (
                                <Nav.Item key={key} onClick={() => {
                                    this.props.clearGroups();
                                    this.props.resetOrganization();
                                    this.props.fetchGroups({"org_id": org.id}, 1, 99);
                                    this.props.resetUserGroups();
                                    this.props.history.push('/main/organizations?id='+org.id);
                                    
                                }}>
                                {org.name}
                                </Nav.Item>
                            )
                        })
                    ) : (
                        <div>No organizations found</div>
                    )
                }
                else {
                    return <div>Loading...</div>
                }
            }
            else {
                return <div className="text-danger">Error retrieving organizations.</div>
                
            }
        }
        else {
            return <div>Loading...</div>
        }
    }
    //if not PA, get user outside orgs (added from a group from other org/s)
    getOutsideOrgs = () => {
        const role = Cache.getItem('role');
        const orgId = Cache.getItem('orgId');
        if (role !== config.ROLE_PLATFORM_ADMIN) {
            let groupMemberships = [];
            let otherOrgs = [];
            
            if (typeof(this.props.general.userInfo) !== 'undefined') {
                const userInfo = this.props.general.userInfo;
                if (Object.keys(userInfo).length > 0) {
                    groupMemberships = userInfo.group_memberships;
                    if (typeof(groupMemberships) !== 'undefined') {
                        if (groupMemberships.length > 0) {
                            otherOrgs = groupMemberships.filter(data => {
                                if (data.org_id !== orgId) {
                                    return data;
                                }
                            }).map(group => {
                                return group.org_id;
                            })
                            if (otherOrgs.length > 0) {
                                this.props.fetchUserOtherOrgs(otherOrgs);
                            }

                            if (otherOrgs.length > 0) {
                                
                            }
                        }
                    }
                }
            }
        }
    }

    displayOtherOrgs = () => {
        const otherOrgs = this.props.general.userOtherOrgs;
        if (otherOrgs) {
            return otherOrgs.length > 0 ? (
                otherOrgs.map((org, key) => {
                    return (
                        <Nav.Item key={key} onClick={() => {
                            this.props.clearGroups();
                            this.props.resetOrganization();
                            this.props.fetchGroups({"org_id": org.id}, 1, 99);
                            this.props.resetUserGroups();
                            this.props.history.push('/main/organizations?id='+org.id);
                            
                        }}>
                        {org.name}
                        </Nav.Item>
                    )
                })
            ) : (
                <div></div>
            )
        }
        else {
            return '';
        }
    }

    render() {
        this.getOutsideOrgs();
        return (
            <div id="ada-main-side-container">
                <div id="ada-header">
                    <FontAwesomeIcon icon={faSitemap} /> Organizations
                </div>
                <Nav id="ada-main-side-nav" className="flex-column">
                    {this.getOrganizations()}
                    {this.displayOtherOrgs()}
                    <RecentlyViewed />
                </Nav>
                
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    general: state.general, 
    organizations: state.organizations,
})

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrganizations: (...args) => dispatch(fetchOrganizations(...args)),
        getOrganization: (...args) => dispatch(getOrganization(...args)),
        fetchGroups: (...args) => dispatch(fetchGroups(...args)),
        resetOrgs: () => dispatch(resetOrgs()),
        resetUserGroups: ()  => dispatch(resetUserGroups()),
        resetOrganization: () => dispatch(resetOrganization()),
        clearGroups: () => dispatch(clearGroups()),
        fetchUserOtherOrgs: (...args) => dispatch(fetchUserOtherOrgs(...args))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideNavbar))
