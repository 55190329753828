const awsmobile = {
  "aws_project_region": "ap-southeast-1",
  Auth: {
    
    identityPoolRegion:'ap-southeast-1',
    region: "ap-southeast-1",
    
    identityPoolId: 'ap-southeast-1:47e7672c-c586-43e5-a518-713de7eb2fe1', //prod
    userPoolId: "ap-southeast-1_blvE5DZSj", //prod
    userPoolWebClientId: "gavfcfb3pni2k8kdcdakkq8oj",  //prod


    oauth: {
      domain: 'ada.auth.ap-southeast-1.amazoncognito.com', //prod
      scope: ['phone', 'email', 'profile', 'openid'],
	  
      redirectSignIn: 'https://www.adaplatform.ac',
      redirectSignOut: 'https://www.adaplatform.ac',

      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },
  Storage: {
    AWSS3: {
        bucket: 'ada.analytics.ayala.com', //REQUIRED -  Amazon S3 bucket
        region: 'ap-southeast-1', //OPTIONAL -  Amazon service region
    }
}
};


export default awsmobile;