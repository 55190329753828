import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
import TopNabvar from '../common/TopNavbar';
import SideNavbar from './main/SideNavbar';
import DataCatalog from './main/DataCatalog';
import Dataset from './main/Datasets/Dataset';
import Organizations from './main/Organizations';
import Groups from './main/Groups';
import Profile from './main/Profile';
import TrashDataset from './main/Datasets/TrashDatasets';
import MyOrganization from '../common/MyOrganization';
import SearchResults from './main/Datasets/SearchResults';

import '../css/main.css';

class Main extends Component {
    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
    }

    switchContent = () => {
        const pathname = this.props.location.pathname;

        switch(pathname){
            case "/main/datacatalog":
                return <DataCatalog />;
            case "/dataset":
                return <Dataset />;
            case "/dataset/search":
                return <SearchResults />;
            case "/main/datacatalog/trash":
                return <TrashDataset />;
            case "/main/organizations":
                return <Organizations />;
            case "/main/groups":
                return <Groups />;
            case "/profile":
                return <Profile />;
            default:
                return ('Nothing');

        }
    }

    render() {
        return (
            !this.props.general.isAuthenticating &&
            <div>
                <TopNabvar/>
                <MyOrganization />
                <Container fluid id="ada-main-container">
                    <Row>
                        <Col sm={12} md={3} lg={2} id="ada-main-side-nav-left">
                            <SideNavbar />
                        </Col>
                        <Col sm={12} md={9} lg={10} id="ada-main-content">
                            { this.switchContent() }
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        general: state.general
    }
}

export default connect(mapStateToProps)(Main)
