
import { Auth, Cache } from 'aws-amplify';

export const getIdToken = () => {
    
    var idToken = Cache.getItem('idToken');

    if(idToken == null){
        return '';
    }

    // const exp = idToken.payload.exp;
    // const currentTime = Math.round((new Date()).getTime() / 1000);
    // console.log(currentTime, 'currentTime');
    // console.log(exp, 'exp');
    // if (exp < currentTime) {
    //     await Auth.currentSession().then(item => {
    //         if(item.isValid()){
    //             setTimeout(() => {
    //                 console.log('refresh');
    //                 setTokens(item);
    //                 return true;
    //             }, 2000)
    //         }   
    //     }).catch(error => {
    //         console.log(error);
    //     })
    // }
    // else {
    //     return idToken.jwtToken;
    // }

    return idToken.jwtToken;
}

export const getSub = () => {
    
    var idToken = Cache.getItem('idToken');

    if(idToken == null){
        return '';
    }

    return idToken.payload.sub;
}

export const setTokens = (item) => {

    Cache.setItem('idToken',item.getIdToken());
    Cache.setItem('refreshToken',item.getRefreshToken());
    Cache.setItem('accessToken',item.getAccessToken());
    return true;
}

export const clearTokens = () => {

    Cache.removeItem('idToken');
    Cache.removeItem('refreshToken');
    Cache.removeItem('accessToken');
    return true;
}
