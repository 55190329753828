import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setOrganization } from '../../store/actions/settings/organizationsActions';
import { fetchGroups, 
  setGroup, 
  getUserGroups, 
  getGroupMembers, 
  resetGroupMembers, 
  resetUserGroups } from '../../store/actions/settings/groupsActions';
import GroupCard from './GroupCard';
import { Cache } from 'aws-amplify';

class Organizations extends Component {

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    let orgId =  urlParams.get('id');
    if (!orgId) {
      const singleOrg = this.props.organizations.singleOrganization;
      if (Object.keys(singleOrg).length === 0) {
        orgId = Cache.getItem('orgId');
      }
    }
    
    this.props.setOrganization(orgId);
    this.props.resetUserGroups();

    const ownedGroups = this.props.groups.groups;
    const userGroups = this.props.groups.userGroups;

    if (ownedGroups !== null) {
      if (ownedGroups.length === 0) {
        this.props.fetchGroups({"org_id": orgId}, 1, 99);
      }
    }
    
    if (userGroups !== null) {
      if (userGroups.length === 0) {
        this.props.getUserGroups();
      }
    }
  }

  renderOrgGroups = (ownedGroups, userGroups) => {
    if (ownedGroups == null && userGroups == null) {
      return <p>Loading...</p>
    }

    const org = this.props.organizations.singleOrganization;
    let orgId = '';
    if (Object.keys(org).length > 0) {
      orgId = org.id;

      let groupsList;
      let allGroups = [];

      //owned groups
      if (ownedGroups !== null) {
        if (ownedGroups.length > 0) {
          ownedGroups.forEach((group) => {
              allGroups.push(group);
          })
        }
      }
      //group's where the user is assigned
      if (userGroups !== null) {
        if (userGroups.length > 0) {
          userGroups.forEach((group) => {
            if (orgId === group.org_id) {
              allGroups.push(group);
            }
          })
        }
      }

      let uniqueGroup = [];

      groupsList = allGroups.length > 0 ? (
        allGroups.map((group, key) => {   
          let groupId = '';
          let groupName = '';
          if (typeof(group.group) !== "undefined" && group.group !== null) {
            groupId = group.group.id;
            groupName = group.group.name;
          }
          else {
            groupId = group.id;
            groupName = group.name;
          }
          if (uniqueGroup.includes(groupId)) {
            return '';
          }
          else {
            uniqueGroup.push(groupId);

            return (
              <Col xls={12} sm={12} md={3} lg={3} xl={3} className="mt-5" key={key}>  
                <GroupCard groupName={groupName} 
                showDatasetsCallback={() => {
                  this.props.history.push('/main/datacatalog?org=' + orgId + '&group=' + groupId);
                }}
                showMembersCallback={() => {
                  this.props.resetGroupMembers();
                  this.props.history.push('/main/groups?id='+groupId)
                }} />
              </Col>
            )
          }
        })
      ) : (
        <Col xls={12} sm={12} md={12} lg={12} xl={12} className="mt-5">  
          <p className="text-muted"><i>Loading...</i></p>
        </Col>
      )
      
      if (ownedGroups != null) {
        if (allGroups.length === 0 && ownedGroups.length === 0) {
          groupsList = <Col xls={12} sm={12} md={12} lg={12} xl={12} className="mt-5">  
                          <p className="text-muted"><i>No groups found.</i></p>
                        </Col>
        }
      }
      return (
        <div>
          <Container fluid>
            <h3>{this.props.organizations.singleOrganization.name}</h3>
            
            <Row className="mt-3">
              {groupsList}
            </Row>
          </Container>
        </div>
      )
    }
  }
  render() {
    console.log(this.props);
    window.scrollTo(0, 0);
    const urlParams = new URLSearchParams(this.props.location.search);
    const orgId =  urlParams.get('id');
    
    this.props.setOrganization(orgId);

    const groups = this.props.groups.groups;
    const userGroups = this.props.groups.userGroups;
    console.log(this.props);
    return (
      <div>
        {this.renderOrgGroups(groups, userGroups)}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  organizations: state.organizations,
  groups: state.groups
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroups: (...args) => dispatch(fetchGroups(...args)),
    setOrganization: (...args) => dispatch(setOrganization(...args)), 
    setGroup: (...args) => dispatch(setGroup(...args)),
    getUserGroups: () => dispatch(getUserGroups()),
    getGroupMembers: (...args) => dispatch(getGroupMembers(...args)),
    resetUserGroups: () => dispatch(resetUserGroups()),
    resetGroupMembers: () => dispatch(resetGroupMembers())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Organizations))