import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Cache } from 'aws-amplify';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clearDatasetToUpload, ACTION_ADD_DATASET } from '../store/actions/main/datasetsActions';
import { toggleDialogDisplay, setInputValidator } from '../store/actions/generalActions';
import * as config from '../app/config';
import DatasetForm from '../components/main/Datasets/DatasetForm';

class MyOrganization extends Component {

  displayAddDatasetBtn = () => {
    const role = Cache.getItem('role');
    if (role === config.ROLE_CURATOR) {
        return (
          <Button variant="primary" className="float-right" onClick={() => {
            this.props.setInputValidator(false);
            this.props.clearDatasetToUpload();
            this.props.toggleDialogDisplay(true, ACTION_ADD_DATASET);
          }}>
            <FontAwesomeIcon icon={faDatabase} />  Create Dataset
          </Button>
        )
    }
    else {
        return '';
    }
  }
  render() {
    let org = '';
    if (Object.keys(this.props.general.userInfo).length > 0) {
      org = this.props.general.userInfo.organization.name;
    }
    return (
      <Container id="ada-my-organization" fluid>
        <DatasetForm />
        
        <Row id="ada-my-org-details">
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <h6 className="text-primary">My Organization</h6>
            <p>{org}</p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            {this.displayAddDatasetBtn()}
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  general: state.general, 
})

const mapDispatchToProps = (dispatch) => {
  return {
      toggleDialogDisplay: (...args) => dispatch(toggleDialogDisplay(...args)),
      setInputValidator: (...args) => dispatch(setInputValidator(...args)),
      clearDatasetToUpload: () => dispatch(clearDatasetToUpload())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyOrganization))