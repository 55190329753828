import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchRecentlyViewed, createDatasetView } from '../../../store/actions/main/datasetViewsActions';
import { fetchPermissions } from '../../../store/actions/main/datasetPermissionsActions';
import { fetchDatasetDD } from '../../../store/actions/main/datasetsActions';
import { resetDataset } from '../../../store/actions/main/datasetsActions';
import { DisplayMoment } from '../../../common/Utilities';

class RecentlyViews extends Component {
  componentDidMount() {
    this.props.fetchRecentlyViewed();
  }

  getRecentlyViewed = () => {
    let recentlyViewedList = '';
    const recentlyViewed = this.props.datasets.recentlyViewed;
    if (recentlyViewed) {
      recentlyViewedList = recentlyViewed.length > 0 ? (
        recentlyViewed.map((dataset, key) => {
          return (
            <Nav.Item key={key} onClick={(e) => {
              e.preventDefault();
              this.props.resetDataset();
              this.props.createDatasetView(dataset.dataset_id);
              this.props.fetchPermissions(dataset.dataset_id);
              const filter = {
                dataset_id: [dataset.dataset_id]
              }
              this.props.fetchDatasetDD(filter);
              this.props.fetchRecentlyViewed();
              this.props.history.push('/dataset?id=' + dataset.dataset_id);
            }}>
              <div>{dataset.name}</div>
              <div className="small">Viewed this dataset <DisplayMoment dataTime={dataset.created_at} /></div>
            </Nav.Item>
          )
        })
      ) : (
        <div>Loading...</div>
      )
    }
    else {
      recentlyViewedList = <div>No recent views yet.</div>
    }

    return recentlyViewedList
  }
  render() {
    return (
      <div id="ada-dataset-recently-viewed" className="flex-column">
        <div id="ada-dataset-recently-viewed-title">
          <span id="ada-dataset-recently-viewed-title-text"><FontAwesomeIcon icon={faHistory} /> Recently Viewed </span>
        </div>
        {this.getRecentlyViewed()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  datasets: state.datasets,
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRecentlyViewed: () => dispatch(fetchRecentlyViewed()),
    resetDataset: () => dispatch(resetDataset()),
    fetchDatasetDD: (...args) => dispatch(fetchDatasetDD(...args)),
    fetchPermissions: (...args) => dispatch(fetchPermissions(...args)),
    createDatasetView: (...args) => createDatasetView(...args),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecentlyViews))
