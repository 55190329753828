import axios from 'axios';
import qs from 'qs';
import { isWebUri } from 'valid-url';
import { Storage } from 'aws-amplify';
import { getIdToken } from '../../helpers/tokens';
import { toggleShowAlert, 
  setIsEditing, 
  successAPIResDisplay,
  toggleDialogDisplay, 
  ERROR_ALERT } from '../generalActions';
import { store } from '../../../app/store';
import * as config from '../../../app/config';
import * as apiDatasetUtils from '../../../common/DatasetsAPIUtils';
import * as apiAttachmentUtils from '../../../common/DatasetAttachmentAPIUtils';
import * as apiDataDictionaryUtils from '../../../common/DataDictionaryAPIUtils';

export const FETCH_DATASETS_BEGIN = 'FETCH_DATASETS_BEGIN';
export const FETCH_DATASETS_SUCCESS = 'FETCH_DATASETS_SUCCESS';
export const FETCH_DATASETS_ERROR = 'FETCH_DATASETS_ERROR';
export const DATASET_SET_FIELD_ON_CHANGE = 'DATASET_SET_FIELD_ON_CHANGE';
export const DATASET_EDIT_SET_FIELD_ON_CHANGE = 'DATASET_EDIT_SET_FIELD_ON_CHANGE';
export const SET_DATASET = 'SET_DATASET';
export const RESET_DATASET = 'RESET_DATASET';
export const DATASETS_SET_SORT_VALUES = 'DATASETS_SET_SORT_VALUES';
export const SEARCH_DATASETS = 'SEARCH_DATASETS';
export const SET_DATASET_DATA_DICTIONARY = 'SET_DATASET_DATA_DICTIONARY';
export const DATADICTIONARY_SET_FIELD_ON_CHANGE = 'DATADICTIONARY_SET_FIELD_ON_CHANGE';
export const SET_DATA_DICTIONARY_FIELD = 'SET_DATA_DICTIONARY_FIELD';
export const RESET_DATA_DICTIONARY_FIELD = 'RESET_DATA_DICTIONARY_FIELD';
export const DATASET_LINK_FIELD_ON_CHANGE = 'DATASET_LINK_FIELD_ON_CHANGE';
export const ADD_LINK_TO_DATASET = 'ADD_LINK_TO_DATASET';
export const REMOVE_DATASET_LINK_ITEM = 'REMOVE_DATASET_LINK_ITEM';
export const SET_BULK_DOWNLOAD_FILES = 'SET_BULK_DOWNLOAD_FILES';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_TOTAL_TRASHED_DATASETS = 'SET_TOTAL_TRASHED_DATASETS';
export const SET_TOTAL_ACTIVE_DATASETS = 'SET_TOTAL_ACTIVE_DATASETS';
export const DATASET_PAGINATION_DETAILS = 'DATASET_PAGINATION_DETAILS';
export const SET_SELECTED_ATTACHMENT = 'SET_SELECTED_ATTACHMENT';
export const DATA_DICTIONARY_PAGINATION_DETAILS = 'DATA_DICTIONARY_PAGINATION_DETAILS';
export const SEARCH_DATASET_PAGINATION_DETAILS = 'SEARCH_DATASET_PAGINATION_DETAILS';
export const TOGGLE_DATA_DICTIONARY_FORM = 'TOGGLE_DATA_DICTIONARY_FORM';
export const TOGGLE_ADD_EXTERNAL_LINK = 'TOGGLE_ADD_EXTERNAL_LINK';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_DATASET_FILTER = 'SET_DATASET_FILTER';
export const RESET_CREATE_DATASET = 'RESET_CREATE_DATASET';
export const CLEAR_DATASET_FILTER = 'CLEAR_DATASET_FILTER';
export const INCREMENT_ATTACHMENT_FORM = 'INCREMENT_ATTACHMENT_FORM';
export const ADD_LINK_TO_DATASET_FOR_CREATE = 'ADD_LINK_TO_DATASET_FOR_CREATE';
export const DECREMENT_ATTACHMENT_FORM = 'DECREMENT_ATTACHMENT_FORM';
export const CLEAR_ATTACHMENT_FORM = 'CLEAR_ATTACHMENT_FORM';
export const RESET_DATASET_TO_UPLOAD = 'RESET_DATASET_TO_UPLOAD';
export const ADD_DATASET_TO_UPLOAD = 'ADD_DATASET_TO_UPLOAD';
export const NEW_SET_OF_DATASET_TO_UPLOAD = 'NEW_SET_OF_DATASET_TO_UPLOAD';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';

export const ACTION_ADD_DATASET = 'ACTION_ADD_DATASET';
export const ACTION_EDIT_DATASET = 'ACTION_EDIT_DATASET';
export const ACTION_TRASH_DATASET = 'ACTION_TRASH_DATASET';
export const ACTION_DELETE_DATASET = 'ACTION_DELETE_DATASET';
export const ACTION_ADD_DD_FIELD = 'ACTION_ADD_DD_FIELD';
export const ACTION_EDIT_DD_FIELD = 'ACTION_EDIT_DD_FIELD';
export const ACTION_DELETE_DD_FIELD = 'ACTION_DELETE_DD_FIELD';
export const ACTION_DELETE_DATASET_FILE = 'ACTION_DELETE_DATASET_FILE';
export const ACTION_ADD_DATASET_LINK = 'ACTION_ADD_DATASET_LINK';
export const ACTION_EDIT_DATASET_LINK = 'ACTION_EDIT_DATASET_LINK';

export const DATASET_PRIVATE = 'DATASET_PRIVATE';
export const DATASET_PUBLIC_ALL = 'DATASET_PUBLIC_ALL';
export const DATASET_PUBLIC_ORG = 'DATASET_PUBLIC_ORG';

export const setTags = (newTags, action = null) => {
  const value = {
    tags: newTags.join()
  }
  return (dispatch) => {
    if (action === ACTION_ADD_DATASET) {
      dispatch({type: DATASET_SET_FIELD_ON_CHANGE, value});
    }
    else if (action === ACTION_EDIT_DATASET) {
      dispatch(setIsEditing(true, ACTION_EDIT_DATASET))
      dispatch({type: DATASET_EDIT_SET_FIELD_ON_CHANGE, value});
    }
  }
}

export const addLinks = (newLinks, action = null) => {
  const value = {
    links: newLinks
  }
  return (dispatch) => {
    if (action === ACTION_ADD_DATASET) {
      dispatch({type: DATASET_SET_FIELD_ON_CHANGE, value});
    }
    else if (action === ACTION_EDIT_DATASET) {
      dispatch({type: DATASET_EDIT_SET_FIELD_ON_CHANGE, value});
    }
  }
}

export const setDataset = (dataset) => {
  return (dispatch) => {
    dataset.tags = dataset.tags.join();
    dispatch({type: SET_DATASET, dataset});
  }
}

export const createDataset = (callback = null) => {
  return (dispatch, getState) => {
    const values = getState().datasets.createDataset;
    const userInfo = getState().general.userInfo;
    if (Object.keys(userInfo).length > 0) {
      Object.assign(values, {owner_id: userInfo.id})
      Object.assign(values, {org_id: userInfo.org_id})
      Object.assign(values, {visibility: "DATASET_PRIVATE"})
    }
    axios.post(apiDatasetUtils.CREATE_DATASETS_ENDPOINT, values, {
      headers: {
          "id-token": getIdToken()
      }
    }).then(json => {
      let result = '';
      if (json.data) {
        result = json.data;
        if (result) {
          const dataset = result.data;
          /**
           * Uploading of Datasets 
           */
          if (typeof(getState().datasets.uploadDatasets) !== "undefined") {
            const datasetFiles = getState().datasets.uploadDatasets;
            if (datasetFiles.length > 0) {
              datasetFiles.map((file) => {
                const datasetFile = file.fileId;
                for (let i = 0; i < datasetFile.length; i++) {
                  const file = datasetFile.item(i);
                  const file_name = file.name;
                  const values = {
                    dataset_id: dataset.id,
                    file_name, 
                    attachment_type: 'DATASET_ATTACHMENT_FILE'
                  }
                  axios.post(apiAttachmentUtils.CREATE_ATTACHMENTS_ENDPOINT, values, {
                    headers: {
                      "id-token": getIdToken()
                    },
                  }).then(json => {
                    console.log(json);
                  }).catch(error => {
                    console.log(error);
                    const errorMessage = "Dataset upload has failed.";
                    dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
                  })
                }
              })
            }
          }
          /**
           * Adding Links
           */
          if (typeof(getState().datasets.addAttachmentForms) !== "undefined") {
            let attachments = getState().datasets.addAttachmentForms;
            console.log(attachments);
            if (attachments.length > 0) {
              attachments.filter(data => {
                if (data.attachmentType === "link") {
                  return data;
                }
              }).forEach(link => {
                const values = {
                  dataset_id: dataset.id,
                  file_name: link.fileName,
                  file_link: link.fileLink,  
                  attachment_type: 'DATASET_ATTACHMENT_LINK'
                }
                axios.post(apiAttachmentUtils.CREATE_ATTACHMENTS_ENDPOINT, values, {
                    headers: {
                      "id-token": getIdToken()
                    },
                }).then(json => {
                  console.log(json);
                }).catch(error => {
                  console.log(error);
                  let errorMessage = '';
                  if (typeof(error.reponse) !== "undefined") {
                    let response = error.response;
                    if (typeof(response.data) !== "undefined") {
                      errorMessage = error.response.data.message;
                    }
                    else {
                      errorMessage = "An error occured while adding dataset links.";
                    }
                    dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
                  }
                })
              })
            }
          }

          /**
           * Uploading of Data Dictionary
           */
          if (typeof(values.data_dictionary) !== "undefined") {
            const file = values.data_dictionary[0];
            let formData = new FormData();
            formData.append('file', file);
            formData.append('dataset_id', dataset.id);

            axios.post(apiDataDictionaryUtils.CREATE_DATA_DICTIONARY_BULK_ENDPOINT, formData, {
              headers: {
                "id-token": getIdToken(),
                'Content-Type': 'multipart/form-data'
              }
            }).then(json => {
              console.log(json);
            }).catch(error => {
              console.log(error);
              let errorMessage = '';
              if (typeof(error.reponse) !== "undefined") {
                let response = error.response;
                if (typeof(response.data) !== "undefined") {
                  errorMessage = error.response.data.message;
                }
                else {
                  errorMessage = "An error occured while uploading data dictionary.";
                }
                dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
              }
            })
          }
                    
          const customMessage = dataset.name + " has been successfully created.";
          dispatch(successAPIResDisplay(json, customMessage));
          dispatch(clearCreateDataset());
          dispatch(fetchDatasets());
          dispatch(toggleDialogDisplay(false));
          dispatch(clearNumAttachmentForm());
        }
      }
    }).catch(error => {
      if (error) {
        if (typeof(error.response) !== "undefined") {
          const response = error.response.data;
          dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        }
        else {
          console.log(error);
          let errorMessage = '';
          if (typeof(error.reponse) !== "undefined") {
            let response = error.response;
            if (typeof(response.data) !== "undefined") {
              errorMessage = error.response.data.message;
            }
            else {
              errorMessage = "An error occured while creating dataset.";
            }
            dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
          }
        }
      }
    })
  }
} 

export const fetchDatasetsBegin = (isPaginating) => {
  return {
    type: FETCH_DATASETS_BEGIN,
    isPaginating
  }
}

export const fetchDatasetsSuccess = (datasets) => {
  return {
    type: FETCH_DATASETS_SUCCESS,
    datasets
  }
}

export const fetchDatasets = (definedFilters = null, page = null, sort = null, onlyTrash = false) => {
  return (dispatch, getState) => {
    let isPaginating = false;
    let filters = {}
    let sortValues = getState().datasets.sortValues;

    if (page !== null || sort !== null) {
      isPaginating = true;
    }

    if (page === null) {
        page = 1;
    }

    if (typeof(definedFilters) === 'object') {
      Object.assign(filters, definedFilters);
    }

    if (sort) {
      if (typeof(sort) === 'object') {
        sortValues = sort;
        dispatch({type: DATASETS_SET_SORT_VALUES, sortValues});
      } 
    }

    let boolOnlyTrash = 0;
    if (onlyTrash) {
      boolOnlyTrash = 1;
    }

    let params = {
        page,
        limit: 10, 
        filters, 
        only_trashed: boolOnlyTrash, 
        sort: sortValues,
    }

    dispatch(fetchDatasetsBegin(isPaginating));
    axios.get(apiDatasetUtils.LIST_DATASETS_ENDPOINT, {
      headers: {
        "id-token": getIdToken()
      },
      params,
      paramsSerializer: params => {
        return qs.stringify(params)
      }, 
    }).then(json => {
      let result = json.data;
      if (result) {
        let pagination = result.pagination;
        if (result.pagination) {
            dispatch(setPaginationDetails(pagination.current_page, pagination.total, pagination.per_page));
        }
        dispatch(fetchDatasetsSuccess(result.data))
      }
    }).catch(error => {
      if (error) {
        if (typeof(error.response) !== "undefined") {
          const response = error.response.data;
          dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        }
        else {
          console.log(error);
          let errorMessage = '';
          if (typeof(error.reponse) !== "undefined") {
            let response = error.response;
            if (typeof(response.data) !== "undefined") {
              errorMessage = error.response.data.message;
            }
            else {
              errorMessage = "An error occured while fetching datasets.";
            }
            dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
          }
        }
      }
    })
  }
}

export const setPaginationDetails = (page = 1, items = 0, limit = 10, gridType="dataset") => {
  if (!items) {
      items = 0;
  }
  return (dispatch) => {
    switch(gridType) {
      case "dataset":
        dispatch({type: DATASET_PAGINATION_DETAILS, page, items, limit})
        break;
      case "data_dictionary":
        dispatch({type: DATA_DICTIONARY_PAGINATION_DETAILS, page, items, limit})
        break;
      case "search_dataset":
        dispatch({type: SEARCH_DATASET_PAGINATION_DETAILS, page, items, limit})
        break;
      default:
        break;
    }
  }
}

export const updateDataset = () => {
  return (dispatch, getState) => {
    const values = getState().datasets.editDataset;

    axios.put(apiDatasetUtils.UPDATE_DATASETS_ENDPOINT, values, {
      headers: {
        "id-token": getIdToken()
      },
    }).then(json => {
      let result = '';
      if (json.data) {
        result = json.data;
        if (result) {
          const dataset = result.data;
          
          /**
           * Uploading of Datasets 
           */
          if (typeof(values.dataset) !== "undefined") {
            const files = values.dataset;
            const numFiles = Object.keys(files).length;
            for (let i = 0; i < numFiles; i++) {
              const file = files[i];
              const file_name = file.name;
              const file_path = config.env + '/datasets/'+file_name;
              Storage.put(file_path, file, {
                  contentType: file.type
              })
              .then (result => {
                  const values = {
                    dataset_id: dataset.id,
                    file_name, 
                    attachment_type: 'DATASET_ATTACHMENT_FILE'
                  }
                  axios.post(apiAttachmentUtils.UPDATE_ATTACHMENTS_ENDPOINT, values, {
                    headers: {
                      "id-token": getIdToken()
                    },
                  }).then(json => {
                    console.log(json);
                  }).catch(error => {
                    console.log(error);
                    const errorMessage = "Dataset upload has failed.";
                    dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
                  })
              }).catch (error => {
                console.log(error);
              })
            }
          }

          Object.assign(dataset, {tags: dataset.tags.join()});
          dispatch(resetDataset());
          dispatch({type: SET_DATASET, dataset});
        
          const customMessage = "Dataset has been successfully updated.";
          dispatch(successAPIResDisplay(json, customMessage));
        }
      }
    }).catch(error => {
      if (error) {
        if (typeof(error.response.data) !== "undefined") {
          const response = error.response.data;
          dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        }
        else {
          console.log(error);
          let errorMessage = '';
          if (typeof(error.reponse) !== "undefined") {
            let response = error.response;
            if (typeof(response.data) !== "undefined") {
              errorMessage = error.response.data.message;
            }
            else {
              errorMessage = "An error occured while updating dataset.";
            }
            dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
          }
        }
        dispatch(resetDataset());
      }
    })
  }
}

export const getDataset = (id) => {
  return (dispatch, getState) => {
    const dataset = getState().datasets.singleDataset;
    if (typeof(dataset.id) === "undefined") {    //no dataset set yet
      axios.get(apiDatasetUtils.GET_DATASETS_ENDPOINT, {
        headers: {
          "id-token": getIdToken()
        },
        params: {
          id
        }
      }).then(json => {
        let result = json.data;
        if (result) {
          let dataset = result.data;
          if (Object.keys(dataset).length > 0) {
            if (typeof(dataset.tags) !== "undefined") {
              if (dataset.tags.length > 0) {
                Object.assign(dataset, {tags: dataset.tags.join()});
              }
            }
            dispatch({type: SET_DATASET, dataset});
          }
          else {
            window.location = config.WEB_APP_URL;
          }
         
        }
      }).catch(error => {
        let errorMessage = '';
        if (typeof(error.response) !== "undefined") {
          let response = error.response;
          if (typeof(response.data) !== "undefined") {
            errorMessage = error.response.data.message;
          }
          else {
            errorMessage = "An error occured while retrieving dataset.";
          }
        }
        else {
          errorMessage = "An error occured while retrieving dataset.";
        }
        dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
      })
    }
  }
}

//unsetting the singleDataset in state
export const resetDataset = () => {
  return (dispatch) => {
    dispatch({type: RESET_DATASET, dataset: {tags: '', uploadLinks:[]}});
  }
}

//unsetting the singleDataset in state
export const clearCreateDataset = () => {
  return (dispatch) => {
    dispatch({type: RESET_CREATE_DATASET, dataset: {tags: '', uploadLinks:[], quality_id: 1}});
  }
}

export const setFieldValueChange = (e, action, attachmentId = null) => {
  return (dispatch, getState) => {
    let value = '';
    let fileId = '';
    if (e.target.files) {
      const files = e.target.files;
      //if no files uploaded / cancelled
      if (files.length > 0) 
      {
        fileId = e.target.id
        value = {
          [fileId]: files
        }
        //if file upload is a dataset, pre-upload to S3
        if (fileId.includes("dataset")) {
          fileId = "dataset";
          value = {
            fileId: files,
            attachmentId
          } 
          const numFiles = Object.keys(files).length;
          let uploaded = numFiles;
          dispatch(setDatasetLoader(true));
          for (let i = 0; i < numFiles; i++) {
            const file = files[i];
            const file_name = file.name;
            const file_path = config.env + '/datasets/'+file_name;
            Storage.put(file_path, file, {
                contentType: file.type
            })
            .then (result => {
              uploaded  = parseInt(uploaded) - 1;
              
              if (uploaded === 0) {
                dispatch(setDatasetLoader(false));
              }
            }).catch (error => {
              console.log(error);
              const errorMessage = "Dataset upload has failed.";
              dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
            })
          }
        }
      }
    }
    else {
      if (e.target.id === "is_empty_yes" || e.target.id === "is_empty_no") {
        value = {
          is_empty: e.target.value
        }
      }
      else {
        value = {
          [e.target.id]: e.target.value
        }
      }
    }

    dispatch(setIsEditing(true));
    switch (action) {
      case ACTION_ADD_DATASET:
        if (fileId === "dataset") {
          //check if there's already a file in the attachment form
          const datasetsToUpload = getState().datasets.uploadDatasets;
          if (datasetsToUpload.length > 0) {
            datasetsToUpload.filter(dataset => {
              if (dataset.attachmentId === attachmentId) {
                dispatch(removeFileToUpload(attachmentId));
                dispatch({type: ADD_DATASET_TO_UPLOAD, value});
              }
              else {
                dispatch({type: ADD_DATASET_TO_UPLOAD, value});
              }
            })
          }
          else {
            dispatch({type: ADD_DATASET_TO_UPLOAD, value});
          }
        }
        else {
          dispatch({type: DATASET_SET_FIELD_ON_CHANGE, value});
        }
        break;
      case ACTION_EDIT_DATASET:
        dispatch({type: DATASET_EDIT_SET_FIELD_ON_CHANGE, value});
        break;
      case ACTION_ADD_DD_FIELD:
      case ACTION_EDIT_DD_FIELD:
        dispatch({type: DATADICTIONARY_SET_FIELD_ON_CHANGE, value});
        break;
    }
  }
}

export const setLinkFieldChange = (e, attachment = null) => {
  return (dispatch) => {
    let value = '';
    if (attachment) {
      value = {
        attachmentId: attachment.id,
        fileName: attachment.file_name,
        fileLink: attachment.file_link
      }
    }
    else {
      value = {
        [e.target.id]: e.target.value
      }
    }
    
    dispatch({type: DATASET_LINK_FIELD_ON_CHANGE, value});
  }
}

export const addLinkToDataset = (datasetId = null) => {
  return (dispatch, getState) => {
    const link = getState().datasets.datasetLink;
    if (isWebUri(link.fileLink)) {
      if (datasetId) {
        const values = {
          dataset_id: datasetId,
          file_name: link.fileName,
          file_link: link.fileLink,  
          attachment_type: 'DATASET_ATTACHMENT_LINK'
        }
        axios.post(apiAttachmentUtils.CREATE_ATTACHMENTS_ENDPOINT, values, {
            headers: {
              "id-token": getIdToken()
            },
        }).then(json => {
          const result = json.data;
          if (result) {
            const customMessage = "Dataset link has been successfully updated.";
            dispatch(successAPIResDisplay(json, customMessage));
            dispatch(resetDataset());
          }
        }).catch(error => {
          console.log(error);
          let errorMessage = '';
          if (typeof(error.reponse) !== "undefined") {
            let response = error.response;
            if (typeof(response.data) !== "undefined") {
              errorMessage = error.response.data.message;
            }
            else {
              errorMessage = "An error occured while deleting dataset.";
            }
            dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
          }
        })
      }
      else {
        dispatch({type: ADD_LINK_TO_DATASET, link: {datasetName: link.fileName, datasetUrl: link.fileLink}});
      }
    }
    else {
      const message = "Invalid URL";
      dispatch(toggleShowAlert(true, ERROR_ALERT, message));
    }
  }
}

export const editLinkToDataset = (datasetId = null) => {
  return (dispatch, getState) => {
    const link = getState().datasets.datasetLink;
    if (isWebUri(link.fileLink)) {
      if (datasetId) {
        const values = {
          dataset_id: datasetId,
          file_name: link.fileName,
          file_link: link.fileLink,  
          attachment_type: 'DATASET_ATTACHMENT_LINK'
        }
        axios.put(apiAttachmentUtils.UPDATE_ATTACHMENTS_ENDPOINT, values, {
            headers: {
              "id-token": getIdToken()
            },
            params: {
              id: link.attachmentId
            }
        }).then(json => {
          const result = json.data;
          if (result) {
            dispatch(resetDataset());
          }
        }).catch(error => {
          console.log(error);
          let errorMessage = '';
          if (typeof(error.reponse) !== "undefined") {
            let response = error.response;
            if (typeof(response.data) !== "undefined") {
              errorMessage = error.response.data.message;
            }
            else {
              errorMessage = "An error occured while deleting dataset.";
            }
            dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
          }
        })
      }
    }
    else {
      const message = "Invalid URL";
      dispatch(toggleShowAlert(true, ERROR_ALERT, message));
    }
  }
}


export const trashDataset = () => {
  return (dispatch, getState) => {
    const datasetId = getState().datasets.singleDataset.id;
    if (datasetId) {
      axios.delete(apiDatasetUtils.TRASH_DATASETS_ENDPOINT, {
        headers: {
          "id-token": getIdToken()
        },
        params: {
          id: datasetId
        }
      }).then(json => {
        const result = json.data;
        if (result) {
          const customMessage = "Dataset has been successfully trashed.";
          dispatch(successAPIResDisplay(json, customMessage));
          dispatch(fetchDatasets());
          dispatch(getTotalTrashedDatasets());
        }
      }).catch(error => {
        console.log(error);
        let errorMessage = '';
        if (typeof(error.reponse) !== "undefined") {
          let response = error.response;
          if (typeof(response.data) !== "undefined") {
            errorMessage = error.response.data.message;
          }
          else {
            errorMessage = "An error occured while deleting dataset.";
          }
          dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
        }
      })
    }
  }
}

/**
 * Fetch Dataset's Data Dictionary
 */
export const fetchDatasetDD = (definedFilters = null, page = null, sort = null, reload = false) => {
  return (dispatch, getState) => {
    const dataset = getState().datasets.singleDataset;
    if (typeof(dataset.dataDictionary) === "undefined" || reload) {  
      let filters = {}

      if (page === null) {
          page = 1;
      }
      
      if (typeof(definedFilters) === 'object') {
        Object.assign(filters, definedFilters);
      }

      axios.get(apiDataDictionaryUtils.LIST_DATA_DICTIONARY_ENDPOINT, {
        headers: {
          "id-token": getIdToken()
        },
        params: {
          page, 
          limit:10, 
          filters
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        }, 
      }).then(json => {
        const result = json.data;
        if (result) {
          let data = result.data;
          let pagination = result.pagination;
          if (result.pagination) {
              dispatch(setPaginationDetails(pagination.current_page, pagination.total, pagination.per_page, "data_dictionary"));
          }
          if (data.length === 0) {
            data = null;
          }

          dispatch({type: SET_DATASET_DATA_DICTIONARY, dataDictionary: data})
        }
      }).catch(error => {
        console.log(error);
        let errorMessage = '';
        if (typeof(error.reponse) !== "undefined") {
          let response = error.response;
          if (typeof(response.data) !== "undefined") {
            errorMessage = error.response.data.message;
          }
          else {
            errorMessage = "An error occured while fetching dataset.";
          }
          dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
        }
      })
    }
  }
}

export const addDataDictionaryField = (datasetId) => {
  if (datasetId) {
    return (dispatch, getState) => {
      const values = getState().datasets.dataDictionaryField;
      Object.assign(values, {dataset_id: datasetId});
      if (typeof(values) !== "undefined") {
        axios.post(apiDataDictionaryUtils.CREATE_DATA_DICTIONARY_ENDPOINT, values, {
          headers: {
            "id-token": getIdToken()
          }
        }).then(json => {
          console.log(json);
          const result = json.data;
          
          if (result) {
            const customMessage = "Data Dictionary field has been successfully created.";
            dispatch(successAPIResDisplay(json, customMessage));
            const filter = {
              dataset_id: [datasetId]
            }
            dispatch(fetchDatasetDD(filter, null, null, true)) //reload data dictionary table
          }
        }).catch(error => {
          console.log(error);
          let errorMessage = '';
          if (typeof(error.reponse) !== "undefined") {
            let response = error.response;
            if (typeof(response.data) !== "undefined") {
              errorMessage = error.response.data.message;
            }
            else {
              errorMessage = "An error occured while adding data dictionary.";
            }
            dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
          }
        })
      }
    }
  }
}

export const resetDataDictionaryField = () => {
  return (dispatch) => {
    dispatch({type: RESET_DATA_DICTIONARY_FIELD});
  }
}


export const setDataDictionaryField = (id) => {
  return (dispatch, getState) => {
    let field = '';
    getState().datasets.singleDataset.dataDictionary.filter((data) => {
        if (data.id === id) {
            return data;
        }
    }).map(item => {
      field = item
    })
    dispatch ({type: SET_DATA_DICTIONARY_FIELD, dataDictionaryField: field })
  }
}

export const updateDataDictionaryField = () => {
  return (dispatch, getState) => {
    const dataDictionary = getState().datasets.dataDictionaryField;
    const datasetId = dataDictionary.dataset_id;
    if (Object.keys(dataDictionary).length > 0) {
      axios.put(apiDataDictionaryUtils.UPDATE_DATA_DICTIONARY_ENDPOINT, dataDictionary, {
        headers: {
          "id-token": getIdToken()
        },
        params: {
          id:  dataDictionary.id
        }
      }).then(json => {
        const result = json.data;
        if (result) {
          const customMessage = "Data Dictionary field has been successfully updated.";
          dispatch(successAPIResDisplay(json, customMessage));
          const filter = {
            dataset_id: [datasetId]
          }
          dispatch(fetchDatasetDD(filter, null, null, true)) //reload data dictionary table
        }
      }).catch(error => {
        console.log(error);
        let errorMessage = '';
        if (typeof(error.reponse) !== "undefined") {
          let response = error.response;
          if (typeof(response.data) !== "undefined") {
            errorMessage = error.response.data.message;
          }
          else {
            errorMessage = "An error occured while updating data dictionary.";
          }
          dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
        }
      })
    }
  }
}

export const deleteDataDictionaryField = () => {
  return (dispatch, getState) => {
    const dataDictionary = getState().datasets.dataDictionaryField;
    const datasetId = dataDictionary.dataset_id;
    if (Object.keys(dataDictionary).length > 0) {
      axios.delete(apiDataDictionaryUtils.DELETE_DATA_DICTIONARY_ENDPOINT, {
        headers: {
          "id-token": getIdToken()
        },
        params: {
          id:  dataDictionary.id
        }
      }).then(json => {
        const result = json.data;
        if (result) {
          const customMessage = "Data Dictionary field has been successfully deleted.";
          dispatch(successAPIResDisplay(json, customMessage));
          const filter = {
            dataset_id: [datasetId]
          }
          dispatch(fetchDatasetDD(filter, null, null, true)) 
        }
      }).catch (error => {
        console.log(error);
        let errorMessage = '';
        if (typeof(error.reponse) !== "undefined") {
          let response = error.response;
          if (typeof(response.data) !== "undefined") {
            errorMessage = error.response.data.message;
          }
          else {
            errorMessage = "An error occured while deleting data dictionary field.";
          }
          dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
        }
      })
    }
  }
}

export const downloadDataset = (attachmentId) => {
  return axios.get(apiAttachmentUtils.DOWNLOAD_ATTACHMENTS_ENDPOINT, {
    headers: {
      "id-token": getIdToken()
    },
    params: {
      id: attachmentId
    }
  }).then(json => {
    const result = json.data;
    if (result) {
      return result.data;
    }
  }).catch(error => {
    console.log(error);
    let errorMessage = '';
    if (typeof(error.reponse) !== "undefined") {
      let response = error.response;
      if (typeof(response.data) !== "undefined") {
        errorMessage = error.response.data.message;
      }
      else {
        errorMessage = "An error occured while downloading dataset.";
      }
      return (dispatch) => {
        dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
      }
    }
  })
}

export const removeDatasetLinkItem = (item) => {
  return (dispatch) => {
    dispatch({type: REMOVE_DATASET_LINK_ITEM, item});
  }
}

export const checkBoxDownloadChange = (e, attachmentId = null, action = null) => {
  let actionItem = '';
  if (!attachmentId) {
    attachmentId = e.target.value;
    if (e.target.checked) {
      actionItem = "add";
    }
    else {
      actionItem = "remove";
    }
  }
  else {
    actionItem = action;
  }
  return (dispatch) => {
    dispatch({type: SET_BULK_DOWNLOAD_FILES, actionItem, attachmentId});
  }
}

export const downloadBulk = () => {
    const fileDownloads = store.getState().datasets.bulkFileDownload;
    if (fileDownloads.length > 0) {
      return axios.get(apiAttachmentUtils.DOWNLOAD_BULK_ATTACHMENTS_ENDPOINT, {
        headers: {
          "id-token": getIdToken()
        },
        params: {
          ids: fileDownloads
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        },
      }).then(json => {
        const result = json.data;
        if (result) {
          return result.data;
        }
      }).catch(error => {
        console.log(error);
        let errorMessage = '';
        if (typeof(error.reponse) !== "undefined") {
          let response = error.response;
          if (typeof(response.data) !== "undefined") {
            errorMessage = error.response.data.message;
          }
          else {
            errorMessage = "An error occured while deleting data dictionary field.";
          }
          return (dispatch) => {
            dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
          }
        }
      })
    }
    else {
      return '';
    }
}

export const setAttachment = (attachment) => {
  return (dispatch) => {
    dispatch({type: SET_SELECTED_ATTACHMENT, attachment})
  }
}

export const trashDatasetAttachment = (attachmentId, isLink = null) => {
  return (dispatch) => {
    axios.delete(apiAttachmentUtils.TRASH_ATTACHMENTS_ENDPOINT, {
      headers: {
        "id-token": getIdToken()
      },
      params: {
        id: attachmentId
      },
    }).then(json => {
      let customMessage;
      if (isLink) {
        customMessage = "Dataset external link has been successfully deleted.";
      }
      else {
        customMessage = "Dataset file has been successfully deleted.";
      }
      
      dispatch(successAPIResDisplay(json, customMessage));
      dispatch(resetDataset());
    }).catch(error => {
      console.log(error);
      let errorMessage = '';
      if (typeof(error.reponse) !== "undefined") {
        let response = error.response;
        if (typeof(response.data) !== "undefined") {
          errorMessage = error.response.data.message;
        }
        else {
          errorMessage = "An error occured while deleting data dictionary field.";
        }
        return (dispatch) => {
          dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
        }
      }
    })
  }
}

export const addDatasetFile = (e, datasetId) => {
  return (dispatch) => {
    dispatch(setDatasetLoader(true));
    if (e.target.files) {
      const files = e.target.files;
      const numFiles = Object.keys(files).length;
      for (let i = 0; i < numFiles; i++) {
        const file = files[i];
        const file_name = file.name;
        const file_path = config.env + '/datasets/'+file_name;
        Storage.put(file_path, file, {
            contentType: file.type
        })
        .then (result => {
            const values = {
              dataset_id: datasetId,
              file_name, 
              attachment_type: 'DATASET_ATTACHMENT_FILE'
            }
            axios.post(apiAttachmentUtils.CREATE_ATTACHMENTS_ENDPOINT, values, {
              headers: {
                "id-token": getIdToken()
              },
            }).then(json => {
              const customMessage = "Dataset has been successfully added.";
              dispatch(successAPIResDisplay(json, customMessage));
              dispatch(resetDataset());
              dispatch(setDatasetLoader(false));
            }).catch(error => {
              console.log(error);
            })
        }).catch (error => {
          console.log(error);
          let errorMessage = '';
          if (typeof(error.reponse) !== "undefined") {
            let response = error.response;
            if (typeof(response.data) !== "undefined") {
              errorMessage = error.response.data.message;
            }
            else {
              errorMessage = "An error occured while downloading dataset.";
            }
            return (dispatch) => {
              dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
            }
          }
        })
      }
    }
  }
}

export const updateDatasetFile = (e, attachmentId, datasetId) => {
  return (dispatch) => {
    dispatch(setDatasetLoader(true));
    if (e.target.files) {
      const files = e.target.files;
      const numFiles = Object.keys(files).length;
      for (let i = 0; i < numFiles; i++) {
        const file = files[i];
        const file_name = file.name;
        const file_path = config.env + '/datasets/'+file_name;
        Storage.put(file_path, file, {
            contentType: file.type
        })
        .then (result => {
            const values = {
              dataset_id: datasetId,
              file_name, 
              attachment_type: 'DATASET_ATTACHMENT_FILE'
            }
            axios.put(apiAttachmentUtils.UPDATE_ATTACHMENTS_ENDPOINT, values, {
              headers: {
                "id-token": getIdToken()
              },
              params: {
                id: attachmentId
              }
            }).then(json => {
              const customMessage = "Dataset has been successfully updated.";
              dispatch(successAPIResDisplay(json, customMessage));
              dispatch(resetDataset());
              dispatch(setDatasetLoader(false));
            }).catch(error => {
              console.log(error);
            })
        }).catch (error => {
          console.log(error);
          let errorMessage = '';
          if (typeof(error.reponse) !== "undefined") {
            let response = error.response;
            if (typeof(response.data) !== "undefined") {
              errorMessage = error.response.data.message;
            }
            else {
              errorMessage = "An error occured while updating dataset.";
            }
            return (dispatch) => {
              dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
            }
          }
        })
      }
    }
  }
}

export const setDatasetLoader = (loading) => {
  return (dispatch) => {
    dispatch({type: SET_IS_LOADING, loading});
  }
}

export const restoreDataset = (datasetId) => {
  return (dispatch) => {
    if (datasetId) {
      axios.get(apiDatasetUtils.RESTORE_DATASETS_ENDPOINT, {
        headers: {
          "id-token": getIdToken()
        },
        params: {
          id: datasetId
        }
      }).then(json => {
        const customMessage = "Dataset has been successfully restored.";
        dispatch(successAPIResDisplay(json, customMessage));
        dispatch(fetchDatasets(null, null, null, true));
        dispatch(getTotalActiveDatasets());
      }).catch(error => {
        console.log(error);
        let errorMessage = '';
        if (typeof(error.reponse) !== "undefined") {
          let response = error.response;
          if (typeof(response.data) !== "undefined") {
            errorMessage = error.response.data.message;
          }
          else {
            errorMessage = "An error occured while restoring dataset.";
          }
          return (dispatch) => {
            dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
          }
        }
      })
    }
  }
}

export const deleteDataset = (datasetId) => {
  return (dispatch) => {
    axios.delete(apiDatasetUtils.DELETE_DATASETS_ENDPOINT, {
      headers: {
        "id-token": getIdToken()
      },
      params: {
        id: datasetId
      }
    }).then(json => {
      const customMessage = "Dataset has been permanently deleted.";
      dispatch(successAPIResDisplay(json, customMessage));
      dispatch(fetchDatasets(null, null, null, true));
      dispatch(getTotalTrashedDatasets());
    }).catch(error => {
      console.log(error);
      let errorMessage = '';
      if (typeof(error.reponse) !== "undefined") {
        let response = error.response;
        if (typeof(response.data) !== "undefined") {
          errorMessage = error.response.data.message;
        }
        else {
          errorMessage = "An error occured while deleting dataset.";
        }
        dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
      }
    })
  }
}

export const getTotalTrashedDatasets = () => {
  return (dispatch) => {
    axios.get(apiDatasetUtils.LIST_DATASETS_ENDPOINT, {
      headers: {
        "id-token": getIdToken()
      },
      params: {
        page: 1,
        limit: 10, 
        only_trashed: 1
      }
    }).then(json => {
      const result = json.data;
      if (result) {
        dispatch({type: SET_TOTAL_TRASHED_DATASETS, totalTrash: result.pagination.total});
      }
    }).catch(error => {
      console.log(error);
    })
  }
}

export const getTotalActiveDatasets = () => {
  return (dispatch) => {
    axios.get(apiDatasetUtils.LIST_DATASETS_ENDPOINT, {
      headers: {
        "id-token": getIdToken()
      },
      params: {
        page: 1,
        limit: 10, 
      }
    }).then(json => {
      const result = json.data;
      if (result) {
        dispatch({type: SET_TOTAL_ACTIVE_DATASETS, totalActive: result.pagination.total});
      }
    }).catch(error => {
      console.log(error);
    })
  }
}

export const changeVisibility = (visibility) => {
  return (dispatch, getState) => {
    const dataset = getState().datasets.singleDataset;
    Object.assign(dataset, {visibility});
    axios.put(apiDatasetUtils.UPDATE_DATASETS_ENDPOINT, dataset, {
      headers: {
        "id-token": getIdToken()
      }
    }).then (json => {
      const result = json.data;
      if (result) {
        const data = result.data;
        const customMessage = "Dataset has been successfully set as " + data.visibility_label + ".";
        dispatch(successAPIResDisplay(json, customMessage));
      }
    }).catch(error => {
      console.log(error);
      let errorMessage = '';
      if (typeof(error.reponse) !== "undefined") {
        let response = error.response;
        if (typeof(response.data) !== "undefined") {
          errorMessage = error.response.data.message;
        }
        else {
          errorMessage = "An error occured while updating dataset.";
        }
        dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
      }
    })
  }
}

export const toggleShowDataDictionaryForm = (show, actionType) => {
  return (dispatch) => {
    if (typeof (actionType) === 'undefined') {
        dispatch({type: TOGGLE_DATA_DICTIONARY_FORM, show})
    }
    else {
        dispatch({type: TOGGLE_DATA_DICTIONARY_FORM, show, actionType})
    }
  }
}

export const toggleAddExternalLinkForm = (show, actionType = null) => {
  return (dispatch) => {
    dispatch({type: TOGGLE_ADD_EXTERNAL_LINK, show, actionType})
  }
}

export const search = (callback = null, page = 1, onlyTag = false) => {
  return (dispatch, getState) => {
    dispatch({type: CLEAR_SEARCH_RESULTS});
    let search = null;
    let filters = null;
    if (!onlyTag) {
      search = getState().datasets.searchDatasets;
    }
    else {
      filters = {
        tags: [getState().datasets.searchDatasets]
      }
    }
    
    axios.get(apiDatasetUtils.SEARCH_DATASET_LIST, {
      headers: {
        "id-token": getIdToken()
      },
      params: {
        page: page,
        limit: 10, 
        filters, 
        search
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    }).then(json => {
      const result = json.data;
      if (result) {
        
        let data = result.data;
        let pagination = result.pagination;
        if (result.pagination) {
            dispatch(setPaginationDetails(pagination.current_page, pagination.total, pagination.per_page, "search_dataset"));
        }
        if (data.length === 0) {
          data = null;
        }
        dispatch({type: SET_SEARCH_RESULTS, results: data})
        if (typeof(callback) === "function") {
          callback();
        }
      }
    }).catch(error => {
      console.log(error);
    })
  }
}

export const searchDataset = (keyword, clear = null) => {
  if (clear) {
      keyword = "";
  }
  return (dispatch) => {
    dispatch({type: SEARCH_DATASETS, keyword})
  }
}

export const setupFilter = (e, additionalFilter = null, clear = null) => {
  let filter = {}
  let type = '';
  if (!clear) {
    if (!additionalFilter) {
      filter = {
        [e.target.id]: e.target.value
      }
    }
    else {
      Object.assign(filter, additionalFilter);
    }
    type = SET_DATASET_FILTER;
  }
  else {
    type = CLEAR_DATASET_FILTER;
  }
  return (dispatch) => {
    dispatch({type, filter})
  }
}

export const incrementAttachmentForm = () => {
  return (dispatch) => {
    let randID = Math.floor(Math.random()*100);

    const newForm = {
      id: randID, 
      attachmentType: "file"
    }

    dispatch({type: INCREMENT_ATTACHMENT_FORM, newForm})
  }
}

export const changeAttachmentType = (attachmentId, attachmentType) => {
  return (dispatch, getState) => {
    const numAttachmentForms = getState().datasets.addAttachmentForms;
    const newNumAttachForms = numAttachmentForms.map(attachmentForm => {
      if (attachmentId === attachmentForm.id) {
        attachmentForm.attachmentType = attachmentType;

        return attachmentForm;
      }
      return attachmentForm;
    })
    dispatch({type: DECREMENT_ATTACHMENT_FORM, newNumAttachForms})
  }
}

export const handleAttachmentLinkChange = (e, attachmentId) => {
  return (dispatch, getState) => {
    const value = {
      [e.target.id]: e.target.value
    }
    const numAttachmentForms = getState().datasets.addAttachmentForms;
    const newNumAttachForms = numAttachmentForms.map(attachmentForm => {
      if (attachmentId === attachmentForm.id) {
        Object.assign(attachmentForm, value);
      }
      return attachmentForm;
    });
    dispatch({type: DECREMENT_ATTACHMENT_FORM, newNumAttachForms})
  }
}

export const decrementAttachmentForm = (attachmentId) => {
  return (dispatch, getState) => {
    const numAttachmentForms = getState().datasets.addAttachmentForms;
    const newNumAttachForms = numAttachmentForms.filter(data => {
      if (data.id !== attachmentId) {
        return data;
      }
    })
    dispatch({type: DECREMENT_ATTACHMENT_FORM, newNumAttachForms})
  }
}

export const clearNumAttachmentForm = () => {
  return (dispatch) => {
    dispatch({type: CLEAR_ATTACHMENT_FORM})
  }
}

export const clearDatasetToUpload = () => {
  return (dispatch) => {
    dispatch({type: RESET_DATASET_TO_UPLOAD})
  }
}

export const removeFileToUpload = (attachmentId) => {
  return (dispatch, getState) => {
    const uploadDatasets = getState().datasets.uploadDatasets;
    let datasets = uploadDatasets.filter(data => {
      if (data.attachmentId !== attachmentId) {
        return data;
      }
    })

    dispatch({type: NEW_SET_OF_DATASET_TO_UPLOAD, datasets});
  }
}