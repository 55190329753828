const initState = {
    currentUser: {}, 
    userInfo: {},
    userOtherOrgs: [],  
    isAuthenticated: false,
    isAuthenticating: false, 
    showPrompt: false, 
    showFormDialog: false,
    actionType: null,
    validated: false,
    showAlert: false,
    alertMessage: null,
    alertType: null,
    profilePhoto: null,
    isEditing: false,
    onInputValidator: {
        show: false,
        message: null,
        type:null
    }, 
    userLogs: null,
    userLogsCurrentPage: 1, 
    userLogsTotalItems: 0,
    userLogsLimit: 10,
    totalItems: 0,
    limitPerTable: 0,
    identityProviders: [],
    acceptedTerms: false, 
    userIDP: null,
}

const generalReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_AUTH_STATUS':
            return {
                ...state, 
                isAuthenticated: action.authenticated
            }
        case 'SET_USER':
            return {
                ...state,
                currentUser: Object.assign(state.currentUser, action.user)
            }
        case 'SET_IS_AUTHENTICATING': 
            return {
                ...state,
                isAuthenticating: action.isAuthenticating
            }
        case 'SET_USER_INFO':
            return {
                ...state,
                userInfo: Object.assign(state.userInfo, action.userInfo)
            }
        case 'TOGGLE_SHOW_PROMPT':
            return {
                ...state, 
                showPrompt: action.show,
                actionType: action.actionType
            }
        case 'TOGGLE_FORM_DIALOG_BOX':
            let actionType = '';
            if (typeof action.actionType === 'undefined') {
                actionType = state.actionType;
            }
            else {
                actionType = action.actionType;
            }

            return {
                ...state, 
                showFormDialog: action.show, 
                actionType: actionType
            }
        case 'TOGGLE_SHOW_ALERT':
            let alertType = action.alertType;
            let alertMessage = action.message;
            if (action.show === false) {
                alertType = null;
                alertMessage = null;
            }
            return {
                ...state, 
                showAlert:  action.show,
                alertType, 
                alertMessage
            }
        case 'SET_FORM_VALIDATED': 
            return {
                ...state, 
                validated: action.validated
            }
        case 'PROFILE_SET_FIELD_ON_CHANGE':
            return {
                ...state, 
                userInfo: Object.assign(state.userInfo, action.value)
            }
        case 'SET_PROFILE_PHOTO':
            return {
                ...state, 
                profilePhoto: action.value.profilePhoto
            }
        case 'SET_IS_EDITING':
            return {
                ...state, 
                isEditing: action.value
            }
        case 'SET_USER_LOGS':
            return {
                ...state, 
                userLogs: action.logs
            }
        case 'SET_PAGINATION_DETAILS':
            return {
                ...state, 
                totalItems: action.items,
                limitPerTable: action.limit
            }
        case 'SET_ON_FORM_VALIDATOR':
            return {
                ...state,
                onInputValidator: Object.assign(state.onInputValidator, action.values) 
            }
        case 'SET_IDENTITY_PROVIDERS':
            return {
                ...state,
                identityProviders: action.identityProviders
            }
        case 'CHANGE_PROFILE_PIC':
            return {
                ...state, 
                userInfo: Object.assign(state.userInfo, {photo_url_signed: action.profilePic})
            }
        case 'USER_LOGS_SET_PAGINATION_DETAILS':
            return {
                ...state, 
                userLogsCurrentPage: action.page, 
                userLogsTotalItems: action.items,
                userLogsLimit: action.limit
            }
        case 'SET_USER_OTHER_ORGANIZATIONS':
            return {
                ...state,
                userOtherOrgs: action.otherOrgs 
            }
        case 'SET_ACCEPTED_TERMS':
            return {
                ...state,
                acceptedTerms: action.accepted
            }
        case 'SET_USER_IDP':
            return {
                ...state,
                userIDP: action.idp 
            }
        default:
            return state;
    }
}

export default generalReducer