import axios from 'axios';
import qs from 'qs';
import * as apiQualityUtils from '../../../common/DatasetQualityAPIUtils';
import { getIdToken } from '../../helpers/tokens';

export const SET_DATASET_QUALITIES = 'SET_DATASET_QUALITIES';

export const fetchQualities = () => {
  return (dispatch) => {
    axios.get(apiQualityUtils.LIST_QUALITIES_ENDPOINT, {
      headers: {
        "id-token": getIdToken()
      },
      params: {
        page: 1,
        limit: 10,
        sort: {
          created_at: "asc"
        }
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }, 
    }).then(json => {
      const result = json.data;
      if (result) {
        const data = result.data;
        dispatch({type: SET_DATASET_QUALITIES, qualities: data});
      }
    }).catch(error => {
      console.log(error);
    })
  }
}
