import * as config from '../app/config';

const MODULE = config.API_GATEWAY_URL + 'users/';

export const LIST_USERS_ENDPOINT = MODULE + "list";
export const GET_USER_ENDPOINT = MODULE + "get";
export const CREATE_USER_ENDPOINT = MODULE + "create";
export const UPDATE_USER_ENDPOINT = MODULE + "update";
export const GET_USER_BY_SUB_ENDPOINT = MODULE + "get/bysub";
export const TRASH_USER_ENDPOINT = MODULE + "trash";
export const DELETE_USER_ENDPOINT = MODULE + "delete";
export const UPDATE_PROFILE_INFO = MODULE + "update/bysub";
export const UPDATE_PROFILE_PHOTO = MODULE + "photo/update";
export const HELPER_LIST_ROLES = MODULE + "helper/roles";
export const CONFIRM_USER = MODULE + "confirm";

export const USER_LOG_LOGIN = MODULE + "logs/login";
export const USER_LOG_LOGOUT = MODULE + "logs/logout";
export const USER_LOG_LIST = MODULE + "logs/list";
