import React, { Component } from 'react';
import { connect } from 'react-redux';
import Main from './components/Main';
import Settings from './components/Settings';
import ConfirmUser from './components/auth/ConfirmUser';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { setAuthStatus, setUserInfo, setIsAuthenticating, confirmUser, toggleShowAlert } from './store/actions/generalActions';
import { AlertMessage } from './common/Utilities';
import { getSub } from './store/helpers/tokens';

import Authenticator from './components/auth/Authenticator';
import TokenWatcher from './components/auth/TokenWatcher';
import SignIn from './components/auth/SignIn';
import TermsAndConditions from './components/auth/TermsAndConditions';

class App extends Component {

  componentDidMount() {
    if (getSub()) {
      this.props.setUserInfo();
    }
  }
  render() {
    return ( 
      <div className="App">
        <AlertMessage 
          show={this.props.general.showAlert} 
          onCloseFunc = { () => { 
              if (this.props.general.showAlert === true) {
                this.props.toggleShowAlert(false) 
              }
          }}
          type={this.props.general.alertType}
          message={this.props.general.alertMessage} />
        <Router history={this.props.history}>
          <Switch>
            <Route exact path='/' component={Authenticator} />
            <Route exact path='/terms' component={TermsAndConditions} />
            <Route exact path='/login' component={SignIn} />
            <Route exact path='/logout' component={Authenticator}  />
            <Route exact path='/users/confirm' component={ConfirmUser}  />

            <Route exact path='/dataset' component={Main} />
            <Route exact path='/dataset/search' component={Main} />
            <Route exact path='/main/datacatalog/trash' component={Main} />
            <Route exact path='/main/datacatalog' component={Main} />
            <Route exact path='/main/organizations' component={Main} />
            <Route exact path='/main/groups' component={Main} />

            <Route exact path='/settings/organizations' component={Settings} />
            <Route exact path='/settings/users' component={Settings}  />
            <Route exact path='/settings/groups' component={Settings} />
            <Route exact path='/settings/logs' component={Settings} />

            <Route exact path='/profile' component={Main} />
          </Switch>
        </Router>
        <TokenWatcher />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    general: state.general
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthStatus: (...args) => dispatch(setAuthStatus(...args)), 
    setUserInfo: (...args) => dispatch(setUserInfo(...args)), 
    setIsAuthenticating: (...args) => dispatch(setIsAuthenticating(...args)), 
    toggleShowAlert: (...args) => dispatch(toggleShowAlert(...args)),
    confirmUser: (...args) => dispatch(confirmUser(...args)), 
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
