import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Auth, Cache } from 'aws-amplify';
import Select from 'react-select';
import { connect } from 'react-redux';
import { fetchOrganizationsIdp } from '../../store/actions/settings/organizationsActions';



class ConfirmUser extends Component {
  componentDidMount() {

    this.props.fetchOrganizationsIdp();
  }

  getInvitationId(){
    const urlParams = new URLSearchParams(this.props.location.search);
    return urlParams.get('invitation_id');
  }

  dropdownProviders(){
      var orgs = this.props.listIdp.organizations;

      if(orgs){
          orgs = Object.values(orgs);
          var idps = orgs.map((item)=>{
              return {
                  value: item.cognito_idp, label: item.name 
              };
          });
          return (<Select autoFocus={true} options={idps} onChange={ (item) => {
              Cache.setItem('FOR_CONFIRMATION','yes');
              Cache.setItem('INVITATION_ID',this.getInvitationId());
              Auth.federatedSignIn({provider: item.value} );
          }} /> );
      }
  }

  render() {
    
    return (
      <Modal show={true}>
        <Modal.Header>
          <Modal.Title>Welcome to ADA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please login to confirm your account. </p>
          { this.dropdownProviders() }
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    )
  }
}


const mapStateToProps = (state) => {
  return {
      listIdp: state.organizations,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      fetchOrganizationsIdp: (...args) => dispatch(fetchOrganizationsIdp(...args)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmUser)