import axios from 'axios';
import qs from 'qs';
import { toggleShowAlert, 
    successAPIResDisplay, 
    setIsEditing, 
    SUCCESS_ALERT, 
    ERROR_ALERT } from '../generalActions';
import { selectUser } from '../settings/usersActions';
import { getIdToken } from '../../helpers/tokens';
import * as apiGroupUtils from '../../../common/GroupsAPIUtils';
import * as apiGroupMemberUtils from '../../../common/GroupMembersAPIUtils';
import * as apiUsersUtils from '../../../common/UsersAPIUtils';
import * as config from '../../../app/config';
import { Cache } from 'aws-amplify';

export const FETCH_GROUPS_BEGIN = 'FETCH_GROUPS_BEGIN';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_ERROR = 'FETCH_GROUPS_ERROR';
export const SET_GROUP = 'SET_GROUP';
export const SET_GROUP_MEMBER = 'SET_GROUP_MEMBER';
export const SEARCH_GROUP = 'SEARCH_GROUP';
export const TOGGLE_FORM_DIALOG_BOX = 'TOGGLE_FORM_DIALOG_BOX';
export const DELETE_GROUP = 'DELETE_GROUP';
export const SEARCH_GROUP_BY_ORG = 'SEARCH_GROUP_BY_ORG';
export const TOGGLE_SHOW_MEMBERS = 'TOGGLE_SHOW_MEMBERS';
export const GROUPS_SET_FIELD_ON_CHANGE = 'GROUPS_SET_FIELD_ON_CHANGE';
export const SET_GROUP_MEMBERS = 'SET_GROUP_MEMBERS';
export const MEMBER_SET_FIELD_ON_CHANGE = 'MEMBER_SET_FIELD_ON_CHANGE';
export const SEARCH_GROUP_MEMBER = 'SEARCH_GROUP_MEMBER';
export const FETCH_USER_GROUPS_BEGIN = 'FETCH_USER_GROUPS_BEGIN';
export const FETCH_USER_GROUPS_SUCCESS = 'FETCH_USER_GROUPS_SUCCESS';
export const RESET_USER_GROUPS = 'RESET_USER_GROUPS';
export const RESET_SELECTED_MEMBER = 'RESET_SELECTED_MEMBER';
export const GROUPS_SET_SORT_VALUES = 'GROUPS_SET_SORT_VALUES';
export const GROUPS_SET_PAGINATION_DETAILS = 'GROUPS_SET_PAGINATION_DETAILS';
export const SET_IS_PAGINATING = 'SET_IS_PAGINATING';
export const RESET_GROUPS = 'RESET_GROUPS';
export const GET_GROUP = 'GET_GROUP';
export const RESET_GROUP_MEMBERS = 'RESET_GROUP_MEMBERS';
export const RESET_GROUP = 'RESET_GROUP';

export const ACTION_ADD_GROUP = 'ACTION_ADD_GROUP';
export const ACTION_UPDATE_GROUP = 'ACTION_UPDATE_GROUP';
export const ACTION_DELETE_GROUP = 'ACTION_DELETE_GROUP';
export const ACTION_ADD_GROUP_MEMBER = 'ACTION_ADD_GROUP_MEMBER';
export const ACTION_REMOVE_GROUP_MEMBER = 'ACTION_REMOVE_GROUP_MEMBER';
export const ACTION_CHANGE_ROLE = 'ACTION_CHANGE_ROLE';


const SUCCESS = 'SUCCESS';

export const createGroup = () => {
    
    return (dispatch, getState) => {
        const group = getState().groups.singleGroup;
        const values = {
            org_id: group.org_id,
            owner_id: getState().general.userInfo.id,
            name: group.name
        }
        axios.post(apiGroupUtils.CREATE_GROUP_ENDPOINT, values, {
            headers: {
                "id-token": getIdToken()
            }
        }).then(json => {
            const customMessage = "New group was successfully created.";
            
            dispatch(fetchGroups());
            dispatch(successAPIResDisplay(json, customMessage));
            
        }).catch(error => {
            const response = error.response.data;
            dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        })
    }
}

export const updateGroup = () => {
    return (dispatch, getState) => {
        const group = getState().groups.singleGroup;
        axios.put(apiGroupUtils.UPDATE_GROUP_ENDPOINT, group, {
            headers: {
                "id-token": getIdToken()
            }
        }).then(json => {
            const customMessage = "Group has been successfully updated.";
            dispatch(successAPIResDisplay(json, customMessage));
            dispatch(setIsEditing(false));
            dispatch(fetchGroups());
        }).catch(error => {
            const response = error.response.data;
            dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        })
    }
}

export const fetchGroups = (definedFilters = null, page = null, limit = 10, sort = null, getAll = false, ids=[]) => {
    return (dispatch, getState) => {
        const role = Cache.getItem('role');
        const orgId = Cache.getItem('orgId');
        let filters = {}
        let isPaginating = false;
        let sortValues = getState().groups.sortValues;
        if (!getAll) {
            switch (role) {
                case config.ROLE_ORG_ADMIN:
                    filters = {
                        org_id: [orgId]
                    }
                    break;
                case config.ROLE_EDITOR:    
                case config.ROLE_VIEWER:
                case config.ROLE_CURATOR: 
                    filters = {
                        org_id: [orgId],
                    }
                    break;
                case config.ROLE_PLATFORM_ADMIN:
                    filters = {}
                    break;
            }
        }

        if (typeof(definedFilters) === 'object') {
            Object.assign(filters, definedFilters);
        }

        if (typeof(sort) === 'object') {
            Object.assign(sortValues, sort);
            dispatch({type: GROUPS_SET_SORT_VALUES, sortValues});
        } 

        if (page !== null || sort !== null) {
            isPaginating = true;
        }

        if (page === null) {
            page = 1;
        }

        if (limit === null) {
            limit = 10;
        }

        if (limit === "all") {
            limit = '';
        }

        let params = {
            page,
            ids, 
            limit: limit,
            sort: sortValues,
            filters
        }

        dispatch(setIsPaginating(isPaginating));
        
        if (!getAll) {
            dispatch(fetchGroupsBegin())
        }
        
        axios.get(apiGroupUtils.LIST_GROUPS_ENDPOINT, {
            params, 
            headers: {
                "id-token": getIdToken()
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(json => {
            let result = json.data;
            if (result) {
                let data = [];
                if (result.data.length > 0) {
                    data = result.data;
                }

                let pagination = result.pagination;
                if (result.pagination) {
                    dispatch(setPaginationDetails(pagination.current_page, pagination.total, pagination.per_page));
                }
                dispatch({type: FETCH_GROUPS_ERROR, error: null});
                dispatch(fetchGroupsSuccess(data));
            }
        }).catch(error => {
            console.log(error);
            dispatch(fetchGroupsError())
        })
    }
}

export const fetchGroupsBegin = () => {
    return {
        type: FETCH_GROUPS_BEGIN
    }
}

export const fetchGroupsSuccess = (groups) => {
    return {
        type: FETCH_GROUPS_SUCCESS, 
        groups
    }
}

export const setIsPaginating = (isPaginating) => {
    return (dispatch) => {
        dispatch({type: SET_IS_PAGINATING, isPaginating});
    }
}

export const setPaginationDetails = (page = 1, items = 0, limit = 10) => {
    if (!items) {
        items = 0;
    }
    return (dispatch) => {
        dispatch({type: GROUPS_SET_PAGINATION_DETAILS, page, items, limit})
    }
}


export const fetchUserGroupsBegin = () => {
    return {
        type: FETCH_USER_GROUPS_BEGIN
    }
}

export const fetchUserGroupsSuccess = (userGroups) => {
    return {
        type: FETCH_USER_GROUPS_SUCCESS, 
        userGroups
    }
}

export const fetchGroupsError = () => {
    return {
        type: FETCH_GROUPS_ERROR, 
        error: 'Error: Failed to load groups list'
    }
}

export const setGroup = (id) => {
    return (dispatch, getState) => {
        dispatch ({type: SET_GROUP, id })
        dispatch (getGroupMembers())
    }
}

export const setGroupMembers = (members) => {
    return (dispatch) => {
        dispatch ({type: SET_GROUP_MEMBERS, members})
    }
}

export const search = () => {
    return (dispatch, getState) => {

        const filters = {
            name: getState().groups.searchByText
        }
        
        if (getState().groups.searchByOrg.length > 0) {
            Object.assign(filters, {
                org_id: [getState().groups.searchByOrg]
            })
        }
        else if (typeof(getState().datasets.datasetFilter.org_id) !== "undefined") {
            Object.assign(filters, {
                org_id: [getState().datasets.datasetFilter.org_id]
            })
        }

        const page = 1;
        dispatch(fetchGroups(filters, page, null, null, true))
    }
}

export const searchGroup = (keyword, clear=null) => {
    if (clear) {
        keyword = "";
    }
    return (dispatch) => {
        dispatch({type: SEARCH_GROUP, keyword});
        dispatch(search());
    }
}

export const searchGroupByOrg = (values) => {
    let organization = [];
    values.forEach((value) => {
        organization.push(value.id)
    })
    return (dispatch, getState) => {
        dispatch({type: SEARCH_GROUP_BY_ORG, organization})
        dispatch(search());
    }
}

export const toggleDialogDisplay = (show, actionType) => {
    return (dispatch, getState) => {
        dispatch({type: TOGGLE_FORM_DIALOG_BOX, show, actionType})
    }
}

export const deleteGroup = (id) => {
    return (dispatch, getState) => {
        axios.delete(apiGroupUtils.DELETE_GROUP_ENDPOINT, {
            headers: {
                "id-token": getIdToken()
            },
            params: {
                id
            }
        }).then(json => {
            const result = json.data.code;
            let message = json.data.message;
            if (result === SUCCESS) {
                //TODO - Delete from group if user is a member
                message = "Group has been successfully deleted."
                dispatch(toggleShowAlert(true, SUCCESS_ALERT, message));
                dispatch(fetchGroups());
            }

        }).catch(error => {
            const response = error.response.data;
            dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        })

        const groups = getState().groups.groups.filter(data => {
            if (data.id  !== id) {
                return data;
            }
        })
    }
}

export const trashGroup = (id) => {
    return (dispatch, getState) => {
        axios.delete(apiGroupUtils.TRASH_GROUP_ENDPOINT, {
            headers: {
                "id-token": getIdToken()
            },
            params: {
                id
            }
        }).then(json => {
            const result = json.data.code;
            let message = json.data.message;
            if (result === SUCCESS) {
                //TODO - Delete from group if user is a member
                message = "Group has been successfully deleted."
                dispatch(toggleShowAlert(true, SUCCESS_ALERT, message));
                dispatch(fetchGroups());
            }

        }).catch(error => {
            const response = error.response.data;
            dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        })

        const groups = getState().groups.groups.filter(data => {
            if (data.id  !== id) {
                return data;
            }
        })
    }
}

export const toggleShowMembers = (show) => {
    return (dispatch, getState) => {
        dispatch({type: TOGGLE_SHOW_MEMBERS, show})
    }
}

export const setFieldValueChange = (e) => {
    const value = {
        [e.target.id]: e.target.value
    }
    return (dispatch, getState) => {
        if (!getState().general.isEditing) {
            dispatch(setIsEditing(true));
        }
        dispatch({type: GROUPS_SET_FIELD_ON_CHANGE, value})
    }
}

export const setMemberFieldValueChange = (e, definedValue=null) => {
    let value = '';
    if (!definedValue) {
        value = {
            [e.target.id]: e.target.value
        }
    }
    else {
        value = definedValue
    }
    
    return (dispatch) => {
        dispatch({type: MEMBER_SET_FIELD_ON_CHANGE, value})
    }
}

export const getGroupMembers = (definedFilters, page = 1, limit = 10, groupId = null) => {
    return (dispatch, getState) => {
        if (!groupId) {
            groupId = getState().groups.singleGroup.id;
        }

        let filters = {
            group_id: [groupId]
        }

        if (typeof(definedFilters) === 'object') {
            Object.assign(filters, definedFilters);
        }

        axios.get(apiGroupMemberUtils.LIST_GROUP_MEMBERS_ENDPOINT, {
            headers: {
                "id-token": getIdToken()
            },
            params: {
                page: page,
                limit: limit,
                filters
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(json => {
            let members = json.data.data;
            if (members.length === 0) {
                members = null;
            }
            dispatch(setGroupMembers(members));
        }).catch(error => {
            const response = error.response.data;
            dispatch(toggleShowAlert(true, ERROR_ALERT, error));
        })
    }
}

export const resetGroupMembers = () => {
    return (dispatch) => {
        dispatch({type: RESET_GROUP_MEMBERS});
    }
}

export const getGroup = (id) => {
    return (dispatch) => {
        axios.get(apiGroupUtils.GET_GROUP_ENDPOINT, {
            headers:  {
                "id-token": getIdToken()
            },
            params: {
                id
            }
        }).then (json => {
            const result = json.data;
            if (result) {
                dispatch({type: GET_GROUP, group: result.data});
                dispatch(getGroupMembers({group_id: id}, 1, 99));
            }
        }).catch (error => {
            console.log(error);
            if (typeof(error.response) !== "undefined") {
                const response = error.response.data;
                dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
            }
        })
    }
}

export const addMemberToGroup = () => {
    return (dispatch, getState) => {
        const member = getState().users.singleUser
        
        if (Object.keys(member).length > 0) {
            axios.get(apiUsersUtils.LIST_USERS_ENDPOINT, {
                headers: {
                    "id-token":  getIdToken()
                }, 
                params: {
                    page: 1,
                    filters: {
                        email: member.email
                    }
                },
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
            }).then(json => {
                const result = json.data.data;
                //if user exists
                if (result.length > 0) {
                    let userId;
                    result.forEach(res => {
                        userId = res.id;
                        return res.id;
                    })
                    /*****
                     * ADD MEMBER TO GROUP
                     */
                    const group = getState().groups.singleGroup;
                    if (group) {
                        const member = group.singleMember;
                        const values = {
                            org_id:  group.org_id,
                            group_id: group.id,
                            member_id: userId, 
                            role: member.role
                        }
                        axios.post(apiGroupMemberUtils.CREATE_GROUP_MEMBER_ENDPOINT, values, {
                            headers: {
                                "id-token":  getIdToken()
                            }
                        }).then(json => {
                            let result = json.data.data;
                            if (result) {
                                const customMessage = "User has been successfully added to the group";
                                dispatch(successAPIResDisplay(json, customMessage));
                                dispatch ({type: RESET_SELECTED_MEMBER});
                                dispatch(getGroupMembers());
                                const clearUser = {}
                                dispatch(selectUser(clearUser));
                            }
                        }).catch (error => {
                            console.log(error);
                            if (typeof(error.response) !== "undefined") {
                                const response = error.response.data;
                                dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
                            }
                        })
                    }
                } 
                else {
                    dispatch(toggleShowAlert(true, ERROR_ALERT, "User does not exists."));
                }
            }).catch(error => {
                const response = error.response.data;
                dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
            })
        }
        else {
            const message = "User is required."
            dispatch(toggleShowAlert(true, ERROR_ALERT, message));
        }
    }
}

export const setGroupMember = (groupMemberId, actionType) => {
    return (dispatch, getState) => {
        const groupMembers = getState().groups.singleGroup.members;
        groupMembers.length > 0 && groupMembers.filter(data => {
            if (groupMemberId === data.id) {
                return data
            }
        }).forEach(member => {  
            dispatch({type: SET_GROUP_MEMBER, member, actionType})
        })
    }
}

export const deleteMemberFromGroup = (memberId, groupId) => {
    return (dispatch, getState) => {
        const group = getState().groups.singleGroup;
        axios.delete(apiGroupMemberUtils.REMOVE_GROUP_MEMBER_ENDPOINT, {
            headers: {
                "id-token": getIdToken()
            },
            params: {
                id: memberId,
                group_id: groupId
            }
        }).then(json => {
            const customMessage = "Member has been successfully removed from the group.";
            dispatch ({type: RESET_SELECTED_MEMBER});
            dispatch(successAPIResDisplay(json, customMessage));
            dispatch(getGroupMembers())
        }).catch (error => {
            console.log(error);
            if (typeof(error.response) !== "undefined") {
                const response = error.response.data;
                dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
            }
        })
    }
}

export const searchGroupMember = (e) => {
    const keyword = e.target.value;
    return (dispatch) => {
        dispatch({type: SEARCH_GROUP_MEMBER, keyword})
    }
}

export const getUserGroups = () => {
    return (dispatch, getState) => {
        //dispatch(fetchUserGroupsBegin());
        if (getState().groups.userGroups !== null) {
            if (getState().groups.userGroups.length === 0) {
            
                axios.get(apiGroupMemberUtils.LIST_GROUP_MEMBERS_ENDPOINT, {
                    headers: {
                        "id-token": getIdToken()
                    },
                    params: {
                        page: 1,
                        limit: 10,
                        filters: {
                            user_id: Cache.getItem('userId'),
                        }
                    },
                    paramsSerializer: params => {
                        return qs.stringify(params)
                    }
                }).then(json => {
                    var data = [];
                    if(json){
                        var result = json.data;
                        if(result){
                            data = result.data;
                            if (data.length === 0) {
                                data = null;
                            }
                        }
                    }
                    dispatch(fetchUserGroupsSuccess(data));
                }).catch(error => {
                    console.log(error);
                    if (typeof(error.response) !== "undefined") {
                        const response = error.response.data;
                        dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
                    }
                })
            }
        }
        
    }
}

export const updateMemberRole = (e) => {
    return (dispatch, getState) => {
        const group = getState().groups.singleGroup;
        const values = {
            org_id: group.singleMember.org_id,
            group_id: group.id, 
            member_id: group.singleMember.user_id,
            role: e.target.value
        }
        axios.put(apiGroupMemberUtils.UDPATE_GROUP_MEMBER_ENDPOINT, values, {
            headers: {
                "id-token": getIdToken()
            },
            params: {
                id: group.singleMember.id
            }
        }).then(json => {
            const customMessage = "Group member has been succesfully updated.";
            dispatch(successAPIResDisplay(json, customMessage));
            dispatch(setGroupMember(group.singleMember.id, null));
            dispatch(setGroup(group.id));
        }).catch(error => {
            const response = error.response.data;
            dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        })
    }
}

export const resetUserGroups = () => {
    return (dispatch) => {
        dispatch({type: RESET_USER_GROUPS});
    }
}

export const clearGroups = () => {
    return (dispatch) => {
        dispatch({type: RESET_GROUPS});
    }
}

export const clearGroup = () => {
    return (dispatch) => {
        dispatch({type: RESET_GROUP})
    }
}