import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import "@pathofdev/react-tag-input/build/index.css";
import { getDataset,  
  resetDataset, 
  fetchDatasetDD } from '../../../store/actions/main/datasetsActions';
import { fetchPermissions } from '../../../store/actions/main/datasetPermissionsActions';
import { createDatasetView, fetchRecentlyViewed } from '../../../store/actions/main/datasetViewsActions';
import { toggleDialogDisplay, setFormValidated, toggleShowPrompt, setInputValidator } from '../../../store/actions/generalActions';

import DatasetDetails from './DatasetDetails';
import DataDictionary from './DataDictionary';
import DownloadDataset from './DownloadDataset';
import DatasetHistory from './DatasetHistory';
import ExternalLinks from './ExternalLinks';

class Dataset extends Component {
  
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.resetDataset();
    const urlParams = new URLSearchParams(this.props.location.search);
    const datasetId = urlParams.get('id');
    this.props.createDatasetView(datasetId);
    this.props.fetchPermissions(datasetId);
    const filter = {
      dataset_id: [datasetId]
    }
    this.props.fetchDatasetDD(filter);
    this.props.fetchRecentlyViewed();
  }
  
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.setFormValidated(true);
    
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
    }
    else {
        this.props.setFormValidated(false);
        this.props.toggleDialogDisplay(false);
    }
  }

  renderDataset = (dataset) => {

    let dataDictionary;

    if (typeof(dataset.id) === "undefined") {
      return "Loading...";
    }

    if (typeof(dataset.dataDictionary) !== "undefined") {
      dataDictionary = dataset.dataDictionary;
    }

    let panelContent = '';
    let navDataDictionary = '';
    let navFiles = '';
    let navLinks = '';
    let navHistory = '';

    const urlParams = new URLSearchParams(this.props.location.search);
    const view =  urlParams.get('view');

    switch (view) {
      case 'datadictionary':
        navDataDictionary = "active-nav";
        panelContent = <DataDictionary dataset={dataset} fields={dataDictionary} />
        break;
      case 'attachments':
        navFiles = "active-nav";
        panelContent = <DownloadDataset dataset={dataset} />
        break;
      case 'external_links':
        navLinks = "active-nav";
        panelContent = <ExternalLinks dataset={dataset} />
        break;
      case 'history':
        navHistory = "active-nav";
        panelContent = <DatasetHistory datasetId={dataset.id} />
        break;
      default: 
        navDataDictionary = "active-nav";
        panelContent = <DataDictionary dataset={dataset} fields={dataDictionary} />
        break;
    }

    return (
      <Container fluid>
        <DatasetDetails dataset={dataset} />
        <Row className="mt-3" id="ada-dataset-panels">
          <Col id="ada-dataset-panels-nav" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Nav>
                <Nav.Item className={navDataDictionary} onClick={() => {
                    this.props.history.push('/dataset?id=' + dataset.id + '&view=datadictionary');
                }}>
                  Data Dictionary
                </Nav.Item>
                <Nav.Item className={navFiles} onClick={() => {
                    this.props.history.push('/dataset?id=' + dataset.id + '&view=attachments');
                }}>
                  Files
                </Nav.Item>
                <Nav.Item className={navLinks} onClick={() => {
                    this.props.history.push('/dataset?id=' + dataset.id + '&view=external_links');
                }}>
                  External Links
                </Nav.Item>
                <Nav.Item className={navHistory} onClick={() => {
                    this.props.history.push('/dataset?id=' + dataset.id + '&view=history');
                }}>
                  History
                </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {panelContent}
          </Col>
        </Row>
        <Row className="mt-5 mb-5">
        </Row>
      </Container>
    )
  }
  render() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const datasetId =  urlParams.get('id');

    if (!datasetId) {
      this.props.history.push('/main/datacatalog');
    }

    this.props.getDataset(datasetId);
    const dataset = this.props.datasets.singleDataset;
    return (
      <div>
        {this.renderDataset(dataset)}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  general: state.general,
  datasets: state.datasets,
  datasetPermissions: state.datasetPermissions
})

const mapDispatchToProps = (dispatch) => {
  return {
      toggleDialogDisplay: (...args) => dispatch(toggleDialogDisplay(...args)),
      setFormValidated: (...args) => dispatch(setFormValidated(...args)),
      getDataset: (...args) => dispatch(getDataset(...args)),
      resetDataset: () => dispatch(resetDataset()),
      toggleShowPrompt: (...args) => dispatch(toggleShowPrompt(...args)),
      fetchDatasetDD: (...args) => dispatch(fetchDatasetDD(...args)),
      setInputValidator: (...args) => dispatch(setInputValidator(...args)),
      fetchPermissions: (...args) => dispatch(fetchPermissions(...args)),
      createDatasetView: (...args) => createDatasetView(...args),
      fetchRecentlyViewed: () => dispatch(fetchRecentlyViewed())
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dataset))
