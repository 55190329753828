import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Auth, Hub } from 'aws-amplify';

import { fetchOrganizationsIdp } from '../../store/actions/settings/organizationsActions';
import { setAuthStatus, setUser, setIsAuthenticating, setAcceptedTerms, setUserIDP } from '../../store/actions/generalActions';
import '../../css/authentication.css';
import Select from 'react-select';
import { WEB_APP_URL } from '../../app/config';
import adaLogo from '../../assets/ac-icon-only.png';
import loginPhoto from '../../assets/ada.jpg';
class SignIn extends Component {

    componentDidMount() {
        
        this.props.fetchOrganizationsIdp();
        Hub.listen("auth", async ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                    await Auth.currentAuthenticatedUser().then(user => {
                        this.props.setIsAuthenticating(true);
                        this.props.setUser(user);
                    });
                    break;
                case "signIn_failure":
                    window.location.reload();
                    break;
                case "customOAuthState":
                    this.setState({ customState: data });
                    break;
                default:
                    break;
            }
        });
    }

    dropdownProviders(){
        var orgs = this.props.listIdp.organizations;
    
        if(orgs){
            orgs = Object.values(orgs);
            var idps = orgs.map((item)=>{
                return {
                    value: item.cognito_idp, label: item.name 
                };
            });
            return (<Select autoFocus={true} options={idps} onChange={ (item) => {
                this.props.setUserIDP(item.value);
            }} /> );
        }
    }

    termsAndConditionLink = () => {
        return (
            <React.Fragment>
                By using Ayala Data Analytics you accept the <Link to="/terms" 
                    target="_blank" id="terms-and-condition">Terms and Conditions</Link>
            </React.Fragment>
        )
    }

    acceptTermsAndCondition = () => {
        return (
            <div id="accept-terms">
                <Form.Group controlId="checkbox-accept-terms" onClick={(e) => {
                    const accepted = e.target.checked;
                    this.props.setAcceptedTerms(accepted);
                }}>
                    <Form.Check type="checkbox" label={ 
                        this.termsAndConditionLink()
                    } />
                </Form.Group>
            </div>
        )
    }

    processLogin = () => {
        const acceptedTerms = this.props.general.acceptedTerms;
        const userIDP = this.props.general.userIDP;

        if (acceptedTerms && userIDP) {
            Auth.federatedSignIn({provider: userIDP});
        }
    }

    isAuthenticated(){
        Auth.currentSession().then((item)=>{
            if(item.isValid && this.props.general.isAuthenticated){
                this.props.history.push('/main/datacatalog');
            }
            else {
                //this.props.history.replace('/login?error=Invalid User');
                window.location = WEB_APP_URL+'#/login';
            }
        });
        
        return (
            <div id="ada-login">
                <Row id="ada-login-main">
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} id="ada-login-photo-div" fluid>
                         <img alt="ada-login-gui-alt" src={loginPhoto} id="ada-login-photo" />
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} id="ada-login-container">
                        <Form id="ada-login-form-container">
                            <div align="center" id="ada-login-header">
                                <img alt="ada-logo" src={adaLogo} id="ada-logo" />
                                <h5 align="center">Welcome to <span id="ada-login-title">Ayala Data Analytics</span></h5>
                                <span id="ada-login-guide">Please select your organization to login.</span>
                            </div>
                            <div align="center">
                                <br/>
                            </div>
                            <div align="center">
                                { this.dropdownProviders() }
                            </div>
                            <div align="center">
                                {this.acceptTermsAndCondition()}
                            </div>
                            
                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.processLogin()}
                {this.isAuthenticated()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        general: state.general,
        listIdp: state.organizations,
        users: state.users,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrganizationsIdp: (...args) => dispatch(fetchOrganizationsIdp(...args)),
        setAuthStatus: (...args) => dispatch(setAuthStatus(...args)),
        setUser: (...args) => dispatch(setUser(...args)),
        setIsAuthenticating: (...args) => dispatch(setIsAuthenticating(...args)),
        setAcceptedTerms: (...args) => dispatch(setAcceptedTerms(...args)),
        setUserIDP: (...args) => dispatch(setUserIDP(...args))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)