import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Cache } from 'aws-amplify';
import { Link, withRouter } from 'react-router-dom';
import { Container, Table, Spinner, Row, Col, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import { fetchDatasets, 
    restoreDataset, 
    resetDataset, 
    getDataset, 
    getTotalTrashedDatasets,  
    getTotalActiveDatasets, 
    ACTION_DELETE_DATASET } from '../../../store/actions/main/datasetsActions';
import { faEllipsisH, faRedo, faTimes, faSitemap, faLock, faClock, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toggleShowPrompt } from '../../../store/actions/generalActions';
import { Pagination, DisplayMoment, DisplayAvatar } from '../../../common/Utilities';
import * as config from '../../../app/config';

class TrashedDatasets extends Component {
  
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchDatasets(null, null, null, true);
    this.props.getTotalActiveDatasets();
    this.props.getTotalTrashedDatasets();
  }

  showTags = (tags) => {
    if (tags.length === 1 && tags[0] === "") {
        return '';
    }

    let tagsBadges;
    if (tags) {
        let comma = ", ";

        tagsBadges = tags.length > 0 && tags.map((tag, key) => {
            if (key === 3) {
                return "and more..."
            }

            if ((tags.length - 1) === key) {
                comma = "";
            }

            return <React.Fragment key={key}>
                        <Link to="#"className="small" onClick={(e) => {
                            e.preventDefault();
                            const filter = {
                                tags: tag
                            }
                            window.scrollTo(0, 0);
                            this.props.fetchDatasets(filter, null, {created_at: "desc"}, true)
                        }}>{tag}</Link>{comma}
                    </React.Fragment>
        })
    }

    return (
        <div className="mt-1">
            <span className="small text-primary">
                <b><FontAwesomeIcon icon={faTag} />Tags:</b>
            </span>&nbsp;
            {tagsBadges}
        </div>
    )
    
  }

  renderTrashedDatasets = () => {
    const datasets = this.props.datasets.datasets;
    if (datasets === null && !this.props.datasets.isPaginating) {
        return <p>Loading...</p>
    }

    const role = Cache.getItem('role');

    let datasetsList;
    if (datasets !== null) {
        datasetsList = datasets.length > 0 ? (
            datasets.map((dataset, key) => {
                let id = '';
                let name = '';
                let description = '';
                
                let orgId = '';
                let org = '';
                let ownerId = '';
                let ownerName = '';
                let ownerPhoto = '';
                let visibility = '';
                let quality = '';
                let tags = '';

                let trashDatasetOption = '';

                if (dataset) {
                    id = dataset.id;
                    name = dataset.name;
                    description = dataset.description;
                    tags = dataset.tags;
                    visibility = dataset.visibility_label;
                    quality = dataset.quality;
                    orgId = dataset.org_id;    
                    ownerId =  dataset.owner_id;

                    if (typeof(dataset.organization) !== "undefined") {
                        if (dataset.organization) {
                            org = dataset.organization.name;
                        }
                    }

                    if(typeof(dataset.owner) !== "undefined") {
                        ownerName = dataset.owner.first_name + " " + dataset.owner.last_name;
                        ownerPhoto = dataset.owner.photo_url_signed;
                    }

                    

                    trashDatasetOption = <Dropdown drop={"left"}>
                                                <Dropdown.Toggle variant="outline" id="ada-button-options">
                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                </Dropdown.Toggle>                    
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => {
                                                        this.props.restoreDataset(id);
                                                    }}>
                                                        <FontAwesomeIcon icon={faRedo} /> Restore 
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => { 
                                                        this.props.history.push('/main/datacatalog/trash?id=' + id);
                                                        this.props.toggleShowPrompt(true, ACTION_DELETE_DATASET);
                                                    }}>
                                                        <span className="text-danger"><FontAwesomeIcon icon={faTimes} /> Permanently Delete</span>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                    
                    switch (role) {
                        case config.ROLE_CURATOR:
                            if (ownerId !== this.props.general.userInfo.id) {
                                trashDatasetOption = '';
                            }
                            break;
                        case config.ROLE_ORG_ADMIN:
                            if (typeof(this.props.general.userInfo.organization) != "undefined") {
                                const org = this.props.general.userInfo.organization;
                                if (org) {
                                    if (orgId !== org.id) {
                                        trashDatasetOption = '';
                                    }
                                }
                            }
                            break;
                        case config.ROLE_EDITOR:
                        case config.ROLE_VIEWER:
                            trashDatasetOption = '';
                            break;
                        default:
                            break;
                    }
                }

                return (
                    <tr key={key} className="text-muted">
                        <td width="10%" className="align-center">
                            <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                <Tooltip id="tooltip-top">
                                    {quality.description}
                                </Tooltip>
                                }
                            >
                                <img alt="quality-icon" src={quality.icon_url} id="ada-dataset-quality-icon" />
                            </OverlayTrigger>
                        </td>

                        <td width="80%" className="align-left">
                            <span id="ada-dataset-name-trash">{name}</span>
                            <div className="small mt-1">
                                <span className="mr-3">
                                    <DisplayAvatar photo={ownerPhoto} displayName={ownerName} size="sm" /> {ownerName}
                                </span> |
                                <span className="ml-3 mr-3">
                                    <FontAwesomeIcon icon={faSitemap} /> {org}
                                </span> |
                                <span className="ml-3 mr-3">
                                    <FontAwesomeIcon icon={faLock} /> {visibility}
                                </span> |
                                <span className="ml-3 mr-3">
                                <FontAwesomeIcon icon={faClock} /> <DisplayMoment dataTime={dataset.created_at} />
                                </span>
                            </div>
                            {this.showTags(tags)}
                        </td>
                        <td width="10%">
                            <span className="float-right">
                                {trashDatasetOption}
                            </span>
                            
                        </td>
                    </tr>
                )
            })
        ) : (
            <tr><td colSpan={7}>No datasets so far.</td></tr>
        )
    }
    else {
        datasetsList = <tr><td colSpan={7} className="align-center"><Spinner animation="grow" variant="primary" /></td></tr>
    }

    return (
      <Container fluid>
        <h3>Trash Datasets</h3>
        <Row className="mt-3 mb-1">
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="float-left">
                    <Link to="#" onClick={() => {
                        this.props.history.push('/main/datacatalog');
                    }}>All ({this.props.datasets.totalActiveDatasets})</Link> | &nbsp;

                    <Link to="#" onClick={() => {
                        this.props.history.push('/main/datacatalog/trash');
                    }}>Trash ({this.props.datasets.totalTrashDatasets})</Link>
                </div> 
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="float-right">
                    <Pagination totalitems={this.props.datasets.totalItems}  
                        limit={this.props.datasets.limit}
                        callback={(page) => {
                            this.props.fetchDatasets(null, page, null, true)
                        }} />
                </div>
            </Col>
        </Row>
        <Table>
            <tbody>
                {datasetsList}
            </tbody>
        </Table>
      </Container>
    )
  }
  render() {
    return (
      <div>
        {this.renderTrashedDatasets()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  general: state.general, 
  datasets: state.datasets
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDatasets: (...args) => dispatch(fetchDatasets(...args)),
    restoreDataset: (...args) => dispatch(restoreDataset(...args)),
    resetDataset: () => dispatch(resetDataset()),
    toggleShowPrompt: (...args) => dispatch(toggleShowPrompt(...args)),
    getDataset: (...args) => dispatch(getDataset(...args)),
    getTotalTrashedDatasets: () => dispatch(getTotalTrashedDatasets()),
    getTotalActiveDatasets: () => dispatch(getTotalActiveDatasets())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrashedDatasets))
