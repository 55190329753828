import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormControl, InputGroup, Row, Col, Button } from 'react-bootstrap';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { searchDataset, 
  search, 
  getDataset, 
  resetDataset,
  fetchDatasetDD
 } from '../../../store/actions/main/datasetsActions';
 import { fetchPermissions } from '../../../store/actions/main/datasetPermissionsActions';
import { createDatasetView, fetchRecentlyViewed } from '../../../store/actions/main/datasetViewsActions';

class SearchDataset extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <Row id="search-dataset-div">
        <Col>
          <div className="align-center">
            <InputGroup id="ada-dataset-search">
                <FormControl
                placeholder="Search Datasets"
                aria-label="Username"
                aria-describedby="dataset-search"
                id="dataset-search" 
                autoComplete="off" 
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    if (this.props.datasets.searchDatasets) {
                      if (document.getElementById('dataset-search')) {
                        document.getElementById('dataset-search').value = "";
                      }
                      this.props.search(() => {
                        this.props.history.push('/dataset/search?q=' + this.props.datasets.searchDatasets)
                      })
                    }
                  }
                }}
                onChange={(e) => {
                  var searchText = e.target.value;
                  this.props.searchDataset(searchText);
                }}
                />
                <InputGroup.Append>
                  <Button id="ada-search-dataset-btn" onClick={(e) => {
                    e.preventDefault();
                    if (document.getElementById('dataset-search')) {
                      document.getElementById('dataset-search').value = "";
                    }
                    if (this.props.datasets.searchDatasets) {
                      this.props.search(() => {
                        this.props.history.push('/dataset/search?q=' + this.props.datasets.searchDatasets)
                      })
                    }
                  }}>
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </InputGroup.Append>
            </InputGroup>
          </div>
        </Col>
      </Row>
    )
  }
}
const mapStateToProps = (state) => ({
  datasets: state.datasets
})

const mapDispatchToProps = (dispatch) => {
  return {
    searchDataset: (...args) => dispatch(searchDataset(...args)),
    search: (...args) => dispatch(search(...args)), 
    getDataset: (...args) => dispatch(getDataset(...args)),
    resetDataset: () =>dispatch(resetDataset()),
    fetchPermissions: (...args) => dispatch(fetchPermissions(...args)),
    createDatasetView: (...args) => createDatasetView(...args),
    fetchRecentlyViewed: () => dispatch(fetchRecentlyViewed()),
    fetchDatasetDD: (...args) => dispatch(fetchDatasetDD(...args))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchDataset))
