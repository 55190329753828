import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
import TopNabvar from '../common/TopNavbar';
import SideNavbar from '../components/settings/SideNavbar';

import UsersManagement from './settings/UsersManagement';
import OrganizationsManagement from './settings/OrganizationsManagement';
import GroupsManagement from './settings/GroupsManagement';
import ViewLogs from './settings/ViewLogs';
import { toggleShowAlert, ERROR_ALERT } from '../store/actions/generalActions';
import * as config from '../app/config';
import { resetOrgs } from '../store/actions/settings/organizationsActions';


import '../css/settings.css';

class Settings extends Component {
    
    componentDidMount() {
        window.scrollTo(0, 0);
        const pathname = this.props.location.pathname;
        const userInfo = this.props.general.userInfo;
        let showError = false;
        if (userInfo.role === config.ROLE_EDITOR || userInfo.role === config.ROLE_VIEWER) {
            showError = true;
            this.props.history.push('/main/datacatalog');
        }

        if (userInfo.role === config.ROLE_CURATOR) {
            if (pathname !== "/settings/groups") {
                showError = true;
                this.props.history.push('/main/datacatalog');
            }
        }
        if (showError) {
            this.props.toggleShowAlert(true, ERROR_ALERT, "You are not authorized to perform this action.");
        }
        this.props.resetOrgs(); //For loading purposes.
    }

    switchContent = () => {
        const pathname = this.props.location.pathname;
        switch(pathname){
            case "/settings/users":
                return <UsersManagement />;
            case "/settings/organizations":
                return <OrganizationsManagement />;
            case "/settings/groups":
                return <GroupsManagement />;
            case "/settings/logs":
                return <ViewLogs />;
            default:
                return ('Nothing');
        }
    }

    render() {
        return (
            !this.props.general.isAuthenticating &&
            <div>
                <TopNabvar />
                <Container fluid id="ada-main-container">
                    <Row>
                        <Col xs={12} sm={12} md={3} lg={2} id="ada-settings-side-nav-left">
                            <SideNavbar />
                        </Col>
                        <Col xs={12} sm={12} md={9} lg={10} id="ada-settings-content">
                            { this.switchContent() }
                        </Col>
                    </Row>
                    
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        general: state.general
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleShowAlert: (...args) => dispatch(toggleShowAlert(...args)),
        resetOrgs: () => dispatch(resetOrgs())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
