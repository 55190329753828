import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Row, Col, Button, Spinner } from 'react-bootstrap';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchPermissions, 
  unshareDataset, 
  PERMISSION_TYPE_ORG} from '../../../store/actions/main/datasetPermissionsActions';

import { Pagination } from '../../../common/Utilities';
class PermittedOrganizations extends Component {
  componentDidMount() {
    this.props.fetchPermissions(this.props.datasetId, PERMISSION_TYPE_ORG);
  }

  renderPermittedOrgs = () => {
    const permittedOrgs = this.props.datasetPermissions.permittedOrgs;
    let orgsList;
    if (permittedOrgs) {
      orgsList = permittedOrgs.length > 0 ? (
        permittedOrgs.filter(data => {
          if (typeof(data.organization) !== "undefined") {
            const org = data.organization;
            if (org) {
              return data;
            }
          }
        }).map((permittedOrg, key) => {
          const org = permittedOrg.organization;
          return (
            <tr key={key}>
              <td width="75%">
                <div>{org.name}</div>
              </td>
              <td width="25%">
                <Button variant="icon" id="btn-icon" onClick={() => {
                  this.props.unshareDataset(permittedOrg.id, PERMISSION_TYPE_ORG);
                }}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          )
        })
      ) : (
        <tr><td colSpan={2} className="align-center"><Spinner animation="grow" variant="primary" /></td></tr>
      )
    }
    else {
      orgsList = <tr><td colSpan={2}>No organizations added yet</td></tr>
    }

    return orgsList;
  }

  render() {
    return (
      <Row> 
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row className="mt-3 mb-1 float-right">
            <Col md={6}>
                <Pagination totalitems={this.props.datasetPermissions.permittedOrgsTotalItems}  
                    limit={this.props.datasetPermissions.permittedOrgsLimit}
                    callback={(page) => {
                        this.props.fetchPermissions(this.props.datasetId, PERMISSION_TYPE_ORG, page, null)
                    }} />
            </Col>
          </Row>
          <Table>
            <thead>
              <tr>
                <th width="75%" className="align-center">Group</th>
                <th width="25%" className="align-center"></th>
              </tr>
            </thead>
            <tbody>
              {this.renderPermittedOrgs()}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({
  datasetPermissions: state.datasetPermissions
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPermissions: (...args) => dispatch(fetchPermissions(...args)),
    unshareDataset: (...args) => dispatch(unshareDataset(...args)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermittedOrganizations)
