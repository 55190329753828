import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { searchGroup, setGroup, fetchGroups } from '../store/actions/settings/groupsActions'; 
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SearchGroup extends Component {
  constructor(props){
    super(props);
    this.delay =  null;
  }
  
  searchGroup = () => {
    const keyword = this.props.groups.searchByText;
    if (!keyword) {
      return ''
    }

    const groups = this.props.groups.groups;
    let groupsList = '';
    
    if (groups) {
      groupsList = groups.length > 0 ? (
        groups.map((group, key) => {
          let name = group.name;
          let groupOrg = '';
          if (typeof(group.organization) !== "undefined") {
            if (group.organization) {
              groupOrg = group.organization.name;
            }
          }
          return (
            <li key={key} onClick={() => {
              this.props.resultAction(group);
              this.props.setGroup(group.id);
              document.getElementById('search-group-div').style.display = "none";
              document.getElementById('ada-group-autosuggest').style.display = "none";
              document.getElementById('search-group-result').style.display = "block";
            }}>
              <Row>
                <Col className="ml-3" xs={7} sm={7} md={7} lg={7} xl={7}>
                  <div>{name}</div>
                  <span className="small text-muted">{groupOrg}</span>
                </Col>
              </Row>
            </li>
          )
        })
      ) : (
        <li><i>No results found.</i></li>
      )
    }
    else {
      groupsList = <li><i>Searching...</i></li>;
    }

    return (
      <ul id="ada-group-autosuggest" onScroll={() => {
        const list = document.getElementById('ada-group-autosuggest');
        
        if (list.scrollTop === (list.scrollHeight - list.offsetHeight)) {
          const filters = {
            name: this.props.groups.searchByText
          }
          let currentLimit = parseInt(this.props.groups.groupsLimit);
          const totalItems = parseInt(this.props.groups.groupsTotalItems);

          if (currentLimit < totalItems) {
            currentLimit = currentLimit + 10;
            this.props.fetchGroups(filters, null, currentLimit, null, true);
          }
         
        }
      }}>
          {groupsList}
      </ul>
    )
  }

  searchResult = () => {
    const group = this.props.groups.singleGroup;

    if (Object.keys(group).length === 0) {
      return '';
    }

    let groupOrg = '';
    if (typeof(group.organization) !== "undefined") {
      if (group.organization) {
        groupOrg = group.organization.name;
      }
    }
    return (
      <Row>
        <Col className="ml-3" xs={9} sm={9} md={9} lg={9} xl={9}>
          <div>{group.name}</div>
          <div className="small text-muted">{groupOrg}</div>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          <Button variant="primary" id="btn-icon" onClick={(e) => {
            //clear search
            this.props.searchGroup(e, true);
            document.getElementById('group-keyword').value = "";
            document.getElementById('search-group-div').style.display = "block";
            document.getElementById('ada-group-autosuggest').style.display = "block";
            document.getElementById('search-group-result').style.display = "none";
          }}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Col>
      </Row>
    )
  }

  render() {
    console.log(this.props);
    return (
      <React.Fragment>
          <div id="search-group-div" className="align-center">
            <Form.Control defaultValue={this.props.groups.searchByText} 
              id="group-keyword" 
              type="text" 
              placeholder="Search Group" 
              onChange={(e) => {
                var searchText = e.target.value;
                if (this.delay) {
                  clearTimeout(this.delay);
                }
                this.delay = setTimeout(() => {
                  if (searchText) {
                    this.props.searchGroup(searchText);
                  }
                  else {
                    this.props.searchGroup(searchText, true); //clear search
                  }
                }, 500)
              }} 
              autoComplete="off" />
          </div>
          <div id="search-group-result" style={{display: "none"}}>
            {this.searchResult()}
          </div>
          {this.searchGroup()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  groups: state.groups, 
  general: state.general, 
});

const mapDispatchToProps = (dispatch) => {
  return {
    searchGroup: (...args) => dispatch(searchGroup(...args)),
    fetchGroups: (...args) => dispatch(fetchGroups(...args)), 
    setGroup: (...args) => dispatch(setGroup(...args))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchGroup)
