import axios from 'axios';
import qs from 'qs';
import { toggleShowAlert, 
    successAPIResDisplay, 
    setIsEditing, 
    setUserInfo,
    SUCCESS_ALERT, ERROR_ALERT } from '../generalActions';
import * as apiUtils from '../../../common/UsersAPIUtils';
import * as apiGroupMemberUtils from '../../../common/GroupMembersAPIUtils';
import { getIdToken } from '../../helpers/tokens';
import * as config from '../../../app/config';
import { Cache } from 'aws-amplify';

export const FETCH_USERS_BEGIN   = 'FETCH_USERS_BEGIN';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const GET_USER = 'GET_USER';
export const SEARCH_USER = 'SEARCH_USER';
export const TRASH_USER = 'TRASH_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SEARCH_USER_BY_ORG = 'SEARCH_USER_BY_ORG';
export const SEARCH_USER_BY_ROLE = 'SEARCH_USER_BY_ROLE';
export const USERS_SET_FIELD_ON_CHANGE = 'USERS_SET_FIELD_ON_CHANGE';
export const SELECT_USER = 'SELECT_USER';
export const SET_IS_USER_EXISTS = 'SET_IS_USER_EXISTS';
export const SET_AVAILABLE_ROLES = 'SET_AVAILABLE_ROLES';
export const SET_IS_EDITING = 'SET_IS_EDITING';
export const USERS_SET_SORT_VALUES = 'USERS_SET_SORT_VALUES';
export const USERS_SET_PAGINATION_DETAILS = 'USERS_SET_PAGINATION_DETAILS';
export const SET_IS_PAGINATING = 'SET_IS_PAGINATING';
export const CLEAR_USER_SEARCH = 'CLEAR_USER_SEARCH';

export const ACTION_TRASH_USER = 'ACTION_TRASH_USER';
export const ACTION_DELETE_USER = 'ACTION_DELETE_USER';
export const ACTION_UPDATE_USER = 'ACTION_UPDATE_USER';
export const ACTION_ADD_USER = 'ACTION_ADD_USER';

const SUCCESS = 'SUCCESS';

export const createUser = (user) => {
    return (dispatch, getState) => {
        const user = getState().users.singleUser;
        axios.post(apiUtils.CREATE_USER_ENDPOINT, user, {
            headers: {
                "id-token": getIdToken()
            }
        }).then(json => {
            const customMessage = "New user was successfully created."
            dispatch(successAPIResDisplay(json, customMessage));
            dispatch(setIsEditing(false));
            dispatch(fetchUsers());
        }).catch(error => {
            const response = error.response.data;
            dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        })
        dispatch(fetchUsers());
    }
}

export const updateUser = (user) => {
    return (dispatch, getState) => {
        const user = getState().users.singleUser;
        axios.put(apiUtils.UPDATE_USER_ENDPOINT, user, {
            headers: {
                "id-token": getIdToken(),
                id: user.id
            }, 
        }).then(json => {
            //check if the updated user is the current user
            var result = json.data.data;
            if (result.id === getState().general.userInfo.id) {
                dispatch(setUserInfo());
            }

            const customMessage = "User has been successfully updated.";
            dispatch(successAPIResDisplay(json, customMessage));
            dispatch(setIsEditing(false));
            dispatch(fetchUsers());
        }).catch(error => {
            console.log(error);
            let errorMessage = '';
            if (typeof(error.reponse) !== "undefined") {
                let response = error.response;
                if (typeof(response.data) !== "undefined") {
                errorMessage = error.response.data.message;
                }
                else {
                errorMessage = "An error occured while updating the user.";
                }
                dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
            }
        })
    }
}


export const fetchUsers = (definedFilters = null, page = null, limit = null, sort = null, getAll = false) => {
    return (dispatch, getState) => {
        let filters = {}
        let isPaginating = false;
        let sortValues = getState().users.sortValues;
        const role = Cache.getItem('role');
        const orgId = Cache.getItem('orgId');
        if (!getAll) {
            switch (role) {
                case config.ROLE_ORG_ADMIN:
                case config.ROLE_CURATOR:  
                case config.ROLE_EDITOR:    
                case config.ROLE_VIEWER:
                    filters = {
                        org_id: [orgId]
                    }
                    break;
                case config.ROLE_PLATFORM_ADMIN:
                    filters = {}
                    break;
                default:
                    filters = {}
                    break;
            }
        }
        
        if (typeof(definedFilters) === 'object') {
            Object.assign(filters, definedFilters);
        }
        
        if (typeof(sort) === 'object') {
            Object.assign(sortValues, sort);
            dispatch({type: USERS_SET_SORT_VALUES, sortValues});
        }

        if (page !== null || sort !== null) {
            isPaginating = true;
        }

        if (page === null) {
            page = 1;
        }

        if (limit === null){
            limit = 10;
        }
        
        let params = {
            page,
            limit: limit, 
            show_deleted:0,
            sort: sortValues,
            filters
        }
        if (role !== config.ROLE_PLATFORM_ADMIN && role !== config.ROLE_ORG_ADMIN) {
            Object.assign(params, {confirmed: 1})
        }
        else {
            //unconfirmed won't show in search
            if (getAll) {
                Object.assign(params, {confirmed: 1})
            }
        }
        dispatch(setIsPaginating(isPaginating));

        if (!getAll) {
            dispatch(fetchUsersBegin());
        } 
        axios.get(apiUtils.LIST_USERS_ENDPOINT, {
            params, 
            paramsSerializer: params => {
                return qs.stringify(params)
            }, 
            headers: {
                "id-token": getIdToken()
            }
        }).then(json => {
            var result = json.data;
            if (result) {
                var pagination = result.pagination;
                if (result.pagination) {
                    dispatch(setPaginationDetails(pagination.current_page, pagination.total, pagination.per_page));
                }
                dispatch(fetchUsersSuccess(result.data));
            }
        }).catch (error => {
            console.log(error);
            dispatch(fetchUsersError());
        })
    };
}

export const fetchUsersBegin = () => {
    return {
        type: FETCH_USERS_BEGIN
    }
}

export const setIsPaginating = (isPaginating) => {
    return (dispatch) => {
        dispatch({type: SET_IS_PAGINATING, isPaginating});
    }
}

export const fetchUsersSuccess = (users) => {
    return {
        type: FETCH_USERS_SUCCESS, 
        users
    }
}

export const fetchUsersError = () => {
    return {
        type: FETCH_USERS_ERROR, 
        users: null,
        error: 'Error: Failed to load users list.'
    }
}

export const setPaginationDetails = (page = 1, items = 0, limit = 10) => {
    if (!items) {
        items = 0;
    }
    return (dispatch) => {
        dispatch({type: USERS_SET_PAGINATION_DETAILS, page, items, limit})
    }
}

/**
 * Get the user from users from store
 * @param {} id 
 */
export const getUser = (id) => {
    return (dispatch, getState) => {
        let user = '';
        getState().users.users.filter((data) => {
            if (data.id === id) {
                return data;
            }
        }).map(item => {
            user = item
        })

        dispatch ({type: GET_USER, user })
    }
}

/**
 * Fetch user from Users API
 * 
 */
export const fetchUser = (filters) => {
    return (dispatch, getState) => {
        axios.get(apiUtils.LIST_USERS_ENDPOINT, {
            headers: {
                "id-token":  getIdToken()
            }, 
            params: {
                page: 1,
                filters
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(json => {
            const result = json.data.data;
            if (result.length > 0) {
                result.forEach(user => {
                    //set the user to the singleUser state
                    dispatch(selectUser(user));
                });
                dispatch(setIsUserExists(true));
            } 
            else {
                dispatch(setIsUserExists(false));
            }
        }).catch(error => {
            const response = error.response.data;
            dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        })
    }
}

export const selectUser = (user) => {
    return (dispatch, getState) => {
        dispatch ({type: SELECT_USER, user })
    }
}

export const search = (searchAll = false) => {
    return (dispatch, getState) => {

        const filters = {
            name: getState().users.searchByText
        }
        
        if (getState().users.searchByOrg.length > 0) {
            Object.assign(filters, {
                org_id: [getState().users.searchByOrg]
            })
        }
        
        if (getState().users.searchByRole.length > 0) {
            Object.assign(filters, {
                role: [getState().users.searchByRole]
            })
        }
        const page = 1;
        dispatch(fetchUsers(filters, page, null, null, searchAll))
    }
}

export const searchUser = (keyword, clear = null, searchAll = false) => {
    if (clear) {
        keyword = "";
    }
    return (dispatch) => {
        dispatch({type: SEARCH_USER, keyword})
        dispatch(search(searchAll));
    }
}

export const searchUserByOrg = (values) => {
    let organization = [];
    values.map((value) => {
        organization.push(value.id)
    })
    return (dispatch) => {
        dispatch({type: SEARCH_USER_BY_ORG, organization});
        dispatch(search());
    }
}

export const searchUserByRole = (values) => {
    let role = [];
    values.map((value) => {
        role.push(value.role)
    })
    return (dispatch, getState) => {
        dispatch({type: SEARCH_USER_BY_ROLE, role});
        dispatch(search());
    }
}

export const clearSearch = () => {
    return (dispatch) => {
        dispatch({type: CLEAR_USER_SEARCH});
    }
}

export const trashUser = (id) => {
    return (dispatch, getState) => {
        const user = getState().users.singleUser;
        axios.delete(apiUtils.TRASH_USER_ENDPOINT, {
            headers: {
                "id-token": getIdToken()
            },
            params: {
                id: user.id
            }
        }).then(json => {
            const result = json.data.code;
            let message = json.data.message;
            if (result === SUCCESS) {
                //TODO - Delete from group if user is a member
                if (user.group_memberships.length > 0) {
                    const groupMemberships = user.group_memberships;
                    groupMemberships.forEach(membership => {
                        axios.delete(apiGroupMemberUtils.REMOVE_GROUP_MEMBER_ENDPOINT, {
                            headers: {
                                "id-token": getIdToken()
                            },
                            params: {
                                id: membership.id,
                                group_id: membership.group_id
                            }
                        }).then(json => {
                            console.log(json);
                        }).catch(error => {
                            console.log(error);
                            const errorMessage = "Error deleting user from group.";
                            dispatch(toggleShowAlert(true, ERROR_ALERT, errorMessage));
                        })
                    })
                }
                message = "User has been successfully deleted."
                dispatch(toggleShowAlert(true, SUCCESS_ALERT, message));
            }

        }).catch(error => {
            const response = error.response.data;
            dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        })

        const users = getState().users.users.filter(data => {
            if (data.id  !== id) {
                return data;
            }
        })
        dispatch({type: TRASH_USER, users})
    }
}

export const deleteUser = (id) => {
    return (dispatch, getState) => {
        const user = getState().users.singleUser;
        axios.delete(apiUtils.DELETE_USER_ENDPOINT, {
            headers: {
                "id-token": getIdToken()
            },
            params: {
                id: user.id
            }
        }).then(json => {
            const result = json.data.code;
            let message = json.data.message;
            if (result === SUCCESS) {
                message = "User has been successfully deleted."
                dispatch(toggleShowAlert(true, SUCCESS_ALERT, message));
            }

        }).catch(error => {
            const response = error.response.data;
            dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        })

        const users = getState().users.users.filter(data => {
            if (data.id  !== id) {
                return data;
            }
        })
        dispatch({type: DELETE_USER, users})
    }
}

export const setFieldValueChange = (e) => {
    const value = {
        [e.target.id]: e.target.value
    }
    return (dispatch, getState) => {
        if (!getState().general.isEditing) {
            dispatch(setIsEditing(true));
        }
        dispatch({type: USERS_SET_FIELD_ON_CHANGE, value});
    }
}

export const setIsUserExists = (exists) => {
    return (dispatch) => {
        dispatch({type: SET_IS_USER_EXISTS, exists})
    }
}

export const setAvailableRoles = (roles) => {
    const availableRoles = []
    roles.data.forEach(role => {
        availableRoles.push({
            role: role.key,
            name: role.label
        })
    })
    return (dispatch) => {
        dispatch({type: SET_AVAILABLE_ROLES, availableRoles})
    }
}

export const fetchRoles = (type = null) => {
    if (type === null) {
        type = "all";
    }
    return (dispatch) => {
        axios.get(apiUtils.HELPER_LIST_ROLES, {
            headers: {
                "id-token": getIdToken()
            },
            params: {
                type: type
            }
        }).then(json => {
            const result = json.data.code;
            const roles = json.data.data;
            if (result === SUCCESS) {
                dispatch(setAvailableRoles(roles));
            }

        }).catch(error => {
            console.log(error);
            //dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        })
    }
}

