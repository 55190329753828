import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Table, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getDatasetLogs, resetDatasetLog } from '../../../store/actions/main/datasetLogsActions';
import { fetchRoles } from '../../../store/actions/settings/usersActions';
import { PERMISSION_TYPE_USER, PERMISSION_TYPE_GROUP, PERMISSION_TYPE_ORG } from '../../../store/actions/main/datasetPermissionsActions';
import { DisplayMoment, getRoleName } from '../../../common/Utilities';

class DatasetHistory extends Component {

  componentDidMount() {
    this.props.resetDatasetLog();
    this.props.fetchRoles();

    const datasetId = this.props.datasetId;
    this.props.getDatasetLogs(datasetId);
  }

  seeMoreLink = () => {
    let currLimit = this.props.datasets.logsLimit;
    let totalItems = this.props.datasets.logsTotalItems;
    if (currLimit > totalItems) {
      return '';
    }

    currLimit = parseInt(currLimit) + 10;

    return (
      <div className="mb-5">
        <Link to="#" onClick = {(e) => {
          e.preventDefault();
          this.props.getDatasetLogs(this.props.datasetId, null, currLimit);
        }}>See more</Link>
      </div>
    )
  }
  
  renderDatasetHistory = () => {
    const datasetLogs = this.props.datasets.datasetLogs;
    let logLists = '';
    if (datasetLogs) {
      logLists = datasetLogs.length > 0 ? (
        datasetLogs.map((datasetLog, key) => {
          let action = '';
          let details = '';
          switch (datasetLog.action) {
            case "LOGS_DATASET_CREATE":
              action = 'created this dataset';
              if (typeof(datasetLog.data) !== "undefined") {
                const datasetData = datasetLog.data;
                if (datasetData) {
                  let qualityName = '';
                  if (typeof(datasetData.quality) !== "undefined"){
                    if (datasetData.quality) {
                      qualityName = datasetData.quality.name;
                    }
                  }
                  details = <div>
                              <b>Name:</b> {datasetData.name} <br />
                              <b>Quality:</b> {qualityName} <br />
                              <b>Description:</b> {datasetData.description} <br />
                              <b>Tags:</b> {datasetData.tags.join(",")} <br />
                              <b>Privacy:</b> {datasetData.visibility_label} <br />
                            </div>
                }
              }            
              break;
            case "LOGS_DATASET_UPDATE":
              action = 'updated this dataset';
              if (typeof(datasetLog.data) !== "undefined") {
                const datasetData = datasetLog.data;
                if (datasetData) {
                  let qualityName = '';
                  if (typeof(datasetData.quality) !== "undefined"){
                    if (datasetData.quality) {
                      qualityName = datasetData.quality.name;
                    }
                  }
                  details = <div>
                              <b>Name:</b> {datasetData.name} <br />
                              <b>Quality:</b> {qualityName} <br />
                              <b>Description:</b> {datasetData.description} <br />
                              <b>Tags:</b> {datasetData.tags.join(",")} <br />
                              <b>Privacy:</b> {datasetData.visibility_label} <br />
                            </div>
                }
              }   
              break;
            case "LOGS_DATASET_TRASH":
              if (typeof(datasetLog.data) !== "undefined") {
                const datasetData = datasetLog.data.data;
                if (datasetData) {
                  let qualityName = '';
                  if (typeof(datasetData.quality) !== "undefined"){
                    if (datasetData.quality) {
                      qualityName = datasetData.quality.name;
                    }
                  }
                  details = <div>
                              <b>Name:</b> {datasetData.name} <br />
                              <b>Quality:</b> {qualityName} <br />
                              <b>Description:</b> {datasetData.description} <br />
                              <b>Tags:</b> {datasetData.tags.join(",")} <br />
                              <b>Privacy:</b> {datasetData.visibility_label} <br />
                            </div>
                }
              } 
              action = 'deleted this dataset';
              break;
            case "LOGS_DATASET_DELETE":
              if (typeof(datasetLog.data) !== "undefined") {
                const datasetData = datasetLog.data;
                if (datasetData) {
                  let qualityName = '';
                  if (typeof(datasetData.quality) !== "undefined"){
                    if (datasetData.quality) {
                      qualityName = datasetData.quality.name;
                    }
                  }
                  details = <div>
                              <b>Name:</b> {datasetData.name} <br />
                              <b>Quality:</b> {qualityName} <br />
                              <b>Description:</b> {datasetData.description} <br />
                              <b>Tags:</b> {datasetData.tags.join(",")} <br />
                              <b>Privacy:</b> {datasetData.visibility_label} <br />
                            </div>
                }
              } 
              action = 'permanently deleted this dataset';
              break;
            case "LOGS_DATASET_RESTORE":
              if (typeof(datasetLog.data) !== "undefined") {
                const datasetData = datasetLog.data.data;
                if (datasetData) {
                  let qualityName = '';
                  if (typeof(datasetData.quality) !== "undefined"){
                    if (datasetData.quality) {
                      qualityName = datasetData.quality.name;
                    }
                  }
                  details = <div>
                              <b>Name:</b> {datasetData.name} <br />
                              <b>Quality:</b> {qualityName} <br />
                              <b>Description:</b> {datasetData.description} <br />
                              <b>Tags:</b> {datasetData.tags.join(",")} <br />
                              <b>Privacy:</b> {datasetData.visibility_label} <br />
                            </div>
                }
              } 
              action = 'restored this dataset';
              break;
            case "LOGS_DATASET_PERMISSION_CREATE":     
              if (typeof(datasetLog.data) !== "undefined") {
                let targetDetails = ''; 
                const datasetData = datasetLog.data;
                if (datasetData) {
                  if (typeof(datasetData.type) !== "undefined"){
                    if (datasetData.type) {
                      const type = datasetData.type;
                      switch (type) {
                        case PERMISSION_TYPE_USER:
                          let userOrg = '';
                          let role = getRoleName(datasetData.role);
                          if (typeof(datasetData.user) !== "undefined") {
                            const user = datasetData.user;
                            if (user) {
                              targetDetails = user.first_name + " " + user.last_name + " as " + role;

                              if (typeof(user.organization) !== "undefined") {
                                const org = user.organization;
                                if (org) {
                                  userOrg = org.name;
                                }
                              }
                            }
                            details = <div>
                              <b>User's Organization:</b> {userOrg} <br />
                            </div>
                          }
                          break;
                        case PERMISSION_TYPE_GROUP:
                          let groupOrg = '';
                          if (typeof(datasetData.group) !== "undefined") {
                            const group = datasetData.group;
                            if (group) {
                              targetDetails = group.name + " group";
                              if (typeof(group.organization) !== "undefined") {
                                const org = group.organization;
                                if (org) {
                                  groupOrg = org.name;
                                }
                              }
                            }
                            details = <div>
                              <b>Group's Organization:</b> {groupOrg} <br />
                            </div>
                          }
                          break;
                        case PERMISSION_TYPE_ORG:
                          targetDetails = 'an oranizations';
                          break;
                      }
                    }
                  }
                }
                action = 'shared this dataset to ' + targetDetails;
              } 
              break;
            case "LOGS_DATASET_PERMISSION_UPDATE":
              break;
            case "LOGS_DATASET_PERMISSION_DELETE":
              if (typeof(datasetLog.data) !== "undefined") {
                let targetDetails = ''; 
                const datasetData = datasetLog.data;
                
                if (datasetData) {
                  if (typeof(datasetData.data.type) !== "undefined"){
                    if (datasetData.data.type) {
                      const type = datasetData.data.type;
                      switch (type) {
                        case PERMISSION_TYPE_USER:
                          let userOrg = '';
                          if (typeof(datasetData.data.user) !== "undefined") {
                            const user = datasetData.data.user;
                            if (user) {
                              targetDetails = user.first_name + " " + user.last_name;

                              if (typeof(user.organization) !== "undefined") {
                                const org = user.organization;
                                if (org) {
                                  userOrg = org.name;
                                }
                              }
                              details = <div>
                                <b>User's Organization:</b> {userOrg} <br />
                              </div>
                            }
                          }
                          break;
                        case PERMISSION_TYPE_GROUP:
                          let groupOrg = '';
                          if (typeof(datasetData.data.group) !== "undefined") {
                            const group = datasetData.data.group;
                            if (group) {
                              targetDetails = group.name + " group";
                              if (typeof(group.organization) !== "undefined") {
                                const org = group.organization;
                                if (org) {
                                  groupOrg = org.name;
                                }
                              }
                            }
                            details = <div>
                              <b>Group's Organization:</b> {groupOrg} <br />
                            </div>
                          }
                          break;
                        case PERMISSION_TYPE_ORG:
                          targetDetails = 'an oranizations';
                          break;
                        default:
                          break;
                      }
                    }
                  }
                }
                action = 'unshared this dataset to ' + targetDetails;
              } 
              break; 
            case "LOGS_DATASET_ATTACHMENT_CREATE":
              if (typeof(datasetLog.data) !== "undefined") {
                const datasetData = datasetLog.data;
                if (datasetData) {
                  let attachmentType = '';
                  let fileSize = '';
                  if (datasetData.attachment_type === "DATASET_ATTACHMENT_FILE") {
                    attachmentType = 'File';
                    fileSize = Math.round(datasetData.file_size*100)/100 + " KB";
                  }
                  else if (datasetData.attachment_type === "DATASET_ATTACHMENT_LINK") {
                    attachmentType = 'Link';
                    fileSize = 'N/A';
                  }
                  details = <div>
                              <b>Attachment Type:</b> {attachmentType} <br />
                              <b>File Name:</b> {datasetData.file_name} <br />
                              <b>File Link:</b> {datasetData.file_link} <br />
                              <b>File Size:</b> {fileSize} <br />
                            </div>
                }
              } 
              action = 'created an attachment';
              break;
            case "LOGS_DATASET_ATTACHMENT_DOWNLOAD":
              if (typeof(datasetLog.data.data) !== "undefined") {
                const datasetData = datasetLog.data.data;
                if (datasetData) {
                  let attachmentType = '';
                  let fileSize = '';
                  if (datasetData.attachment_type === "DATASET_ATTACHMENT_FILE") {
                    attachmentType = 'File';
                    fileSize = Math.round(datasetData.file_size*100)/100 + " KB";
                  }
                  else if (datasetData.attachment_type === "DATASET_ATTACHMENT_LINK") {
                    attachmentType = 'Link';
                    fileSize = 'N/A';
                  }
                  details = <div>
                              <b>Attachment Type:</b> {attachmentType} <br />
                              <b>File Name:</b> {datasetData.file_name} <br />
                              <b>File Link:</b> {datasetData.file_link} <br />
                              <b>File Size:</b> {fileSize} <br />
                            </div>
                }
              } 
              action = 'downloaded an attachment';
              break;
            case "LOGS_DATASET_ATTACHMENT_TRASH":
              if (typeof(datasetLog.data.data) !== "undefined") {
                const datasetData = datasetLog.data.data;
                if (datasetData) {
                  let attachmentType = '';
                  let fileSize = '';
                  if (datasetData.attachment_type === "DATASET_ATTACHMENT_FILE") {
                    attachmentType = 'File';
                    fileSize = Math.round(datasetData.file_size*100)/100 + " KB";
                  }
                  else if (datasetData.attachment_type === "DATASET_ATTACHMENT_LINK") {
                    attachmentType = 'Link';
                    fileSize = 'N/A';
                  }
                  details = <div>
                              <b>Attachment Type:</b> {attachmentType} <br />
                              <b>File Name:</b> {datasetData.file_name} <br />
                              <b>File Link:</b> {datasetData.file_link} <br />
                              <b>File Size:</b> {fileSize} <br />
                            </div>
                }
              } 
              action = 'removed an attachment';
              break;
            case "LOGS_DATASET_ATTACHMENT_UPDATE":
                if (typeof(datasetLog.data) !== "undefined") {
                  const datasetData = datasetLog.data;
                  if (datasetData) {
                    let attachmentType = '';
                    let fileSize = '';
                    if (datasetData.attachment_type === "DATASET_ATTACHMENT_FILE") {
                      attachmentType = 'File';
                      fileSize = Math.round(datasetData.file_size*100)/100 + " KB";
                    }
                    else if (datasetData.attachment_type === "DATASET_ATTACHMENT_LINK") {
                      attachmentType = 'Link';
                      fileSize = 'N/A';
                    }
                    details = <div>
                                <b>Attachment Type:</b> {attachmentType} <br />
                                <b>File Name:</b> {datasetData.file_name} <br />
                                <b>File Link:</b> {datasetData.file_link} <br />
                                <b>File Size:</b> {fileSize} <br />
                              </div>
                  }
                } 
                action = 'updated an attachment';
                break;
            case "LOGS_DATA_DICTIONARY_CREATE":
              if (typeof(datasetLog.data) !== "undefined") {
                const datasetData = datasetLog.data;
                if (datasetData) {
                  details = <div>
                    <b>Field:</b> {datasetData.field} <br />
                    <b>Description:</b> {datasetData.description} <br />
                    <b>Type:</b> {datasetData.type} <br />
                    <b>Unit:</b> {datasetData.unit} <br />
                    <b>Size:</b> {datasetData.size} <br />
                    <b>Min:</b> {datasetData.min} <br />
                    <b>Max:</b> {datasetData.max} <br />
                    <b>Empty Value?:</b> {datasetData.is_empty} <br />
                    <b>Data Prep Notes:</b> {datasetData.data_prep_notes} <br />
                    <b>Additional Comments:</b> {datasetData.additional_comments} <br />
                  </div>
                }
              } 
              action = 'created a data dictionary';
              break;
            case "LOGS_DATA_DICTIONARY_CREATE_BULK":
              if (typeof(datasetLog.data) !== "undefined") {
                const datasetData = datasetLog.data;
                if (datasetData) {
                  details = <div>
                    N/A 
                  </div>
                }
              }
              action = 'uploaded a data dictionary';
              break;
            case "LOGS_DATA_DICTIONARY_UPDATE":
              if (typeof(datasetLog.data) !== "undefined") {
                const datasetData = datasetLog.data;
                if (datasetData) {
                  details = <div>
                    <b>Field:</b> {datasetData.field} <br />
                    <b>Description:</b> {datasetData.description} <br />
                    <b>Type:</b> {datasetData.type} <br />
                    <b>Unit:</b> {datasetData.unit} <br />
                    <b>Size:</b> {datasetData.size} <br />
                    <b>Min:</b> {datasetData.min} <br />
                    <b>Max:</b> {datasetData.max} <br />
                    <b>Empty Value?:</b> {datasetData.is_empty} <br />
                    <b>Data Prep Notes:</b> {datasetData.data_prep_notes} <br />
                    <b>Additional Comments:</b> {datasetData.additional_comments} <br />
                  </div>
                }
              } 
              action = 'updated a data dictionary';
              break;
            case "LOGS_DATA_DICTIONARY_DELETE":
              if (typeof(datasetLog.data) !== "undefined") {
                const datasetData = datasetLog.data.data;
                if (datasetData) {
                  details = <div>
                      <b>Field:</b> {datasetData.field} <br />
                      <b>Description:</b> {datasetData.description} <br />
                      <b>Type:</b> {datasetData.type} <br />
                      <b>Unit:</b> {datasetData.unit} <br />
                      <b>Size:</b> {datasetData.size} <br />
                      <b>Min:</b> {datasetData.min} <br />
                      <b>Max:</b> {datasetData.max} <br />
                      <b>Empty Value?:</b> {datasetData.is_empty} <br />
                      <b>Data Prep Notes:</b> {datasetData.data_prep_notes} <br />
                      <b>Additional Comments:</b> {datasetData.additional_comments} <br />
                    </div>
                }
                
              } 
              action = 'deleted a data dictionary';
              break;
            default:
              break;
          }
          const createdBy = datasetLog.created_by;
  
          return (
            <tr key={key}>
              <td width="50%">
                <OverlayTrigger
                    key="top"
                    placement="right"
                    overlay={
                    <Tooltip id="action">

                      <div className="align-left">
                        <h6>Details:</h6>
                        {details}
                      </div>
                    </Tooltip>
                    }
                >
                    <b>{createdBy} {action} </b>
                </OverlayTrigger>
              </td>
              <td width="50%"> <DisplayMoment dataTime={datasetLog.created_at} /></td>
            </tr>
          )
        })
      ) : (
        <tr><td colSpan={3} className="align-center"><Spinner animation="grow" variant="primary" /></td></tr>
      )
    }
    else {
      logLists = <tr><td>No Dataset Logs.</td></tr>
    }
    return (
      <React.Fragment>
        <Row className="mt-3 mb-3">
            <Col>
              <h5>Dataset History</h5>
            </Col>
        </Row>
        <hr />
        <Table responsive bordered>
          <tbody>
            {logLists}
          </tbody>
        </Table>
        {this.seeMoreLink()}
      </React.Fragment>
    )
  }

  render() {
    return (
      <div>
        {this.renderDatasetHistory()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  datasets: state.datasets
})

const mapDispatchToProps = (dispatch) => {
  return {
    getDatasetLogs: (...args) => dispatch(getDatasetLogs(...args)),
    resetDatasetLog: () => dispatch(resetDatasetLog()),
    fetchRoles: (...args) => dispatch(fetchRoles())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetHistory)
