import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Navbar, Button, Form, Nav, Dropdown, Row, Col } from 'react-bootstrap';
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clearTokens } from '../store/helpers/tokens';
import adaLogo from '../assets/ac-icon-only.png';
import homeBtn from '../assets/home-orange-icon.png';
import settingsBtn from '../assets/manage-access-icon-orange.png';
import '../css/top-navigation.css';

import { Auth, Cache } from 'aws-amplify'; 
import aws_config from '../app/aws-config';
import { setAuthStatus, setUser, setUserInfo } from '../store/actions/generalActions';
import { DisplayAvatar } from '../common/Utilities';
import { transactionLog, ACTION_LOG_LOGOUT } from '../common/LogsUtils';

import SearchDataset from '../components/main/Datasets/SearchDataset';

class TopNavbar extends Component {

    constructor(props) {
        super(props);

        const pathname = this.props.location.pathname;
        this.location = pathname;
    }
    displayOrgSettings = () => {
        const role = Cache.getItem('role');
        switch (role) {
            case "ROLE_ORG_ADMIN":
            case "ROLE_PLATFORM_ADMIN":
                return <Button id="user-settings" onClick={ ()=> { 
                            this.props.history.push('/settings/organizations');
                        } 
                        } >
                            <img alt="settings-icon" src={settingsBtn} />
                        </Button>
            case "ROLE_CURATOR": 
                return <Button id="user-settings" onClick={ ()=> { 
                    this.props.history.push('/settings/groups');
                } 
                } >
                    <img alt="settings-icon" src={settingsBtn} />
                </Button>
            default:
                return '';
        }   
    }

    showDatasetSearchBar = () => {
        if (this.location === "/terms") {
            return '';
        }

        return (
            <Row>
                <Col>
                    <SearchDataset />
                </Col>
            </Row>
        )
    }

    showSubmenus = () => {
        if (this.location === "/terms") {
            return '';
        }

        const userInfo = this.props.general.userInfo;
        let userAvatar = '';
        let displayName = '';
        let photo = '';
        if(Object.keys(userInfo).length > 0){
            displayName = userInfo.first_name + " " + userInfo.last_name;
            photo = userInfo.photo_url_signed;
            userAvatar = <React.Fragment>
                            <DisplayAvatar photo={photo} displayName={displayName} size="md" /> 
                        </React.Fragment>
        }

        return (
            <Form inline>
                {/* Search Dataset */}
                {this.displayOrgSettings()}
                <Button className="mr-sm-2" id="user-settings" onClick={ ()=> { 
                        this.props.history.push('/main/datacatalog');
                    } 
                } >
                    <img alt="home-icon" src={homeBtn} />
                </Button>
                
                <Dropdown alignRight id="user-burger-menu"> 
                    <Dropdown.Toggle id="user-burger-menu_button">
                        {userAvatar}
                    </Dropdown.Toggle>
                    <Dropdown.Menu id="user-burger-menu-dropdown" className="super-colors">
                        <Dropdown.Header>
                            <div>Welcome!</div>
                            <div>{displayName}</div>
                        </Dropdown.Header>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => {
                            this.props.history.push('/profile');
                        }}>
                            <FontAwesomeIcon icon={faUser} /> My Profile
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            Auth.signOut().then(()=>{
                                transactionLog(ACTION_LOG_LOGOUT);
                                Cache.removeItem('userId');
                                Cache.removeItem('role');
                                Cache.removeItem('orgId');
                                Cache.removeItem('LOGGED_IN');
                                Cache.setItem('IS_LOGOUT','yes');
                                this.props.clearTokens();
                                var client_id = aws_config.Auth.userPoolWebClientId;
                                var logout_uri = aws_config.Auth.oauth.redirectSignOut;
                                window.location = ['https://',aws_config.Auth.oauth.domain,"/logout?response_type=code&client_id=",client_id,"&logout_uri=",logout_uri].join('');
                            });

                        }}>
                            <FontAwesomeIcon icon={faSignOutAlt} flip="horizontal" /> Logout
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Form> 
        )

        return '';
    }

    render() {
        
        return (
            <Navbar bg="dark" variant="dark" id="ada-top-nav">
                <Navbar.Brand>
                    <NavLink id="ada-top-nav-brand" to="/main/datacatalog">
                        <img alt="ada-logo" id="ada-logo" src={adaLogo} />
                        Ayala Data Analytics 
                    </NavLink>
                </Navbar.Brand>
                <Nav className="mr-auto">
                </Nav>
                {this.showDatasetSearchBar()}
                {this.showSubmenus()} 
            </Navbar>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        general: state.general
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthStatus: (...args) => dispatch(setAuthStatus(...args)), 
        setUser: (...args) => dispatch(setUser(...args)), 
        setUserInfo: (...args) => dispatch(setUserInfo(...args)),
        clearTokens: () => clearTokens(),
      
    }
  }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNavbar))