import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from "react-avatar";
import { Form, Row, Col, Button } from 'react-bootstrap';
import { searchUser, fetchUsers, selectUser } from '../store/actions/settings/usersActions';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as config from '../app/config';

class SearchUser extends Component {
  constructor(props){
    super(props);
    this.delay =  null;
  }

  searchUser = () => {
    const keyword = this.props.users.searchByText;
    if (!keyword) {
      return ''
    }

    const users = this.props.users.users;
    let usersList = '';
    
    if (users) {
      usersList = users.length > 0 ? (
        users.map((user, key) => {
          let avatar = '';
          if (user.photo_url === null) {
              avatar = <Avatar name={user.first_name + " " + user.last_name} size={config.AVATAR_SIZE} round={config.AVATAR_RADUIS} />
          }
          else {
              avatar = <Avatar src={user.photo_url_signed} size={config.AVATAR_SIZE} round={config.AVATAR_RADUIS} />
          }
          
          let name = user.first_name + " " + user.last_name;
          return (
            <li key={key} onClick={(e) => {
              this.props.resultAction(e, user);
              this.props.selectUser(user);
              document.getElementById('search-user-div').style.display = "none";
              document.getElementById('ada-user-autosuggest').style.display = "none";
              document.getElementById('search-user-result').style.display = "block";
            }}>
              <Row>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  {avatar}
                </Col>
                <Col className="ml-3" xs={7} sm={7} md={7} lg={7} xl={7}>
                  {name} <br />
                  <span className="text-muted small">{user.email}</span>
                </Col>
              </Row>
            </li>
          )
        })
      ) : (
        <li><i>No results found.</i></li>
      )
    }
    else {
      usersList = <li><i>Searching...</i></li>;
    }

    return (
      <ul id="ada-user-autosuggest" onScroll={() => {
        const list = document.getElementById('ada-user-autosuggest');
        if (list.scrollTop === (list.scrollHeight - list.offsetHeight)) {
          const filters = {
            name: this.props.users.searchByText
          }
          let currentLimit = parseInt(this.props.users.usersLimit);
          const totalItems = parseInt(this.props.users.usersTotalItems);
  
          if (currentLimit < totalItems) {
            currentLimit = currentLimit + 10;
            this.props.fetchUsers(filters, null, currentLimit, null, true);
          }
        }
      }}>
          {usersList}
      </ul>
    )
  }

  searchResult = () => {
    const user = this.props.users.singleUser;

    if (Object.keys(user).length === 0) {
      return '';
    }
    
    return (
      <Row>
        <Col className="ml-3" xs={9} sm={9} md={9} lg={9} xl={9}>
          {user.first_name} {user.last_name}<br />
          <span className="text-muted small">{user.email}</span>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          <Button variant="primary" id="btn-icon" onClick={(e) => {
            //clear search
            this.props.searchUser(e, true);
            document.getElementById('user-keyword').value = "";
            document.getElementById('search-user-div').style.display = "block";
            document.getElementById('ada-user-autosuggest').style.display = "block";
            document.getElementById('search-user-result').style.display = "none";
          }}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Col>
      </Row>
    )
  }

  render() {
    const searchAll = this.props.searchAll;
    return (
      <React.Fragment>
          <div id="search-user-div" className="align-center">
            <Form.Control defaultValue={this.props.users.searchByText} 
              id="user-keyword" 
              type="text" 
              placeholder="Search User" 
              onChange={(e) => {
                var searchText = e.target.value;
                if (this.delay) {
                  clearTimeout(this.delay);
                }
                this.delay = setTimeout(() => {
                  if (searchText) {
                    this.props.searchUser(searchText, false, searchAll); //search all
                    let autoSuggest = document.getElementById('ada-user-autosuggest');
                    if (autoSuggest) {
                      document.getElementById('ada-user-autosuggest').style.display = "block";
                    }
                  }
                  else {
                    this.props.searchUser(null, true); //clear search
                  }
                }, 500)
              }} 
              autoComplete="off" />
          </div>
          <div id="search-user-result" style={{display: "none"}}>
            {this.searchResult()}
          </div>
          {this.searchUser()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  users: state.users, 
  general: state.general, 
});

const mapDispatchToProps = (dispatch) => {
  return {
    searchUser: (...args) => dispatch(searchUser(...args)),
    fetchUsers: (...args) => dispatch(fetchUsers(...args)),
    selectUser: (...args) => dispatch(selectUser(...args))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchUser)
