import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/main.css';

import TopNabvar from '../../common/TopNavbar';

class TermsAndConditions extends Component {
  render() {
    return (
      <React.Fragment>
        <TopNabvar/>
        <Container id="ada-terms-condition">
          <Row className="mb-5">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h2>Ada Platform Terms & Conditions</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 className="ada-terms-and-condition-section-title">General Information</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                These Terms and Conditions (“Terms”) are a legal contract between you or your organization, as a user of Ada Platform (“Ada”) and Ayala Corporation (“AC”). These Terms explain how you are permitted to access or use the website located at the url: http://adaplatform.ac as well as all associated sites, tools and channels used by Ada.
              </p>
              <p>
                By accessing Ada, you are agreeing to all these terms.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 className="ada-terms-and-condition-section-title">Changes</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                AC may make changes to the content and services offered on Ada at any time. AC can change, update, or add or remove provisions of these Terms, at any time. Any update to these Terms will take effect on the noted effective date. If you do not agree with any of the updated Terms, you must stop using Ada.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 className="ada-terms-and-condition-section-title">Using Ada</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Ada is exclusively for Ayala Group of companies or affiliated organizations (“AG”). Only AG organizations that have been officially provided accounts and rights to use by AC will have access to Ada. There is no public sign up process. Users are registered via an invitation process initiated by Ada administrators.
              </p>
              <p>
                Using a registered account on Ada, users can login to the password protected website and use the various services available (such as collaborating in an organization, creating groups, searching and uploading datasets).
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 className="ada-terms-and-condition-section-title">Passwords</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                You are responsible for maintaining the confidentiality of your Password, and you are responsible for all activities that occur using your Passwords. You agree not to share your Passwords, let others access or use your Passwords or do anything else that might jeopardize the security of your Passwords. You agree to notify AC if any of your Passwords on Ada is lost, stolen, if you are aware of any unauthorized use of your Passwords on Ada or if you know of any other breach of security in relation to Ada.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 className="ada-terms-and-condition-section-title">Ada User Roles</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Registered users of Ada will have assigned roles and access rights to Datasets. You acknowledge and agree to comply with any and all of the responsibilities associated with your assigned roles.
              </p>
              <p>
                “Admin” roles are responsible for registering users and assigning roles within their organization. They are also responsible for managing Dataset access across their organization. Admins shall maintain oversight on user data access, holding users accountable and ensuring compliance with legal, ethical, and other imperatives that govern data usage within their organization.
              </p>
              <p>
                “Curator” and “Editor” roles are responsible for uploading and maintaining Datasets owned by their organization. Datasets should be sufficiently documented. Datasets will have accompanying metadata that contains the data handling requirements. (ex. terms of use, licenses etc.). It is the responsibility of the Curators and Editors to maintain the quality of Datasets uploaded to Ada.
              </p>
              <p>
                You understand and agree that AC shall conduct active monitoring of data access through automated audit logs to ensure compliance with auditing best practices.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 className="ada-terms-and-condition-section-title">Materials and Datasets</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                AC provides content on Ada that is confidential and copyrighted work of AG organizations. Except as set forth below, Materials may include logos, graphics, video, images, software and other content.
              </p>
              <p>
                AC also provides its users with access to its own Datasets and Datasets that are the property of third-parties or other AG organizations. Neither Datasets, nor application programming interfaces (“APIs”) that AC may make available to you, are deemed to be “Materials” for purposes of these Terms.
              </p>
              <p>
                Subject to your compliance with these Terms, AC hereby grants you a limited, non-exclusive and non-transferable license to use the Materials in Ada.
              </p>
              <p>
                Except for the foregoing license, you have no other rights on Ada or any Materials and you may not modify, edit, copy, reproduce, create derivative works of, reverse engineer, alter, enhance or in any way exploit Ada or Materials in any manner.
              </p>
              <p>
                You may use, copy or distribute the Datasets you have access to (including allowing AC to use, copy, and distribute the Datasets to provide the “Services”) solely as permitted by the usage terms applicable to such Datasets.
              </p>
              <p>
                You understand that AC may remove Datasets, including without limitation derivatives of and/or results from using the Datasets from the Service at any time if required by law or otherwise in its sole discretion. Your searches and saved Datasets may be negatively impacted by such removal.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 className="ada-terms-and-condition-section-title">Uploading Datasets</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                A Dataset is any information, description, text, data, metadata, Datasets, groups, tags, searches, images, videos, graphics, sounds and other content or material that is submitted, uploaded, or otherwise made available on Ada.
              </p>
              <p>
                You are responsible for all your Dataset uploads. You may not upload on Ada any material protected by copyright, trademark, or any other proprietary right without the express permission of the owner of such copyright, trademark or other proprietary right owned by a third-party or your AG organization, and the burden of determining whether you have sufficient permission to share such information is on you.
              </p>
              <p>
                You shall be solely liable for any damage resulting from any infringement of copyrights, trademarks, proprietary rights, violation of contract, privacy or publicity rights or any other harm resulting from any Dataset uploads that you make. You have full responsibility for each Dataset, including its legality, reliability and appropriateness.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 className="ada-terms-and-condition-section-title">Third-Party Datasets</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Datasets in Ada may be subject to one or more usage terms and licenses. Your AG organization may own a Dataset or, subject to appropriate licenses from such owners, a Dataset may be owned by another party or a Dataset may result from combining one or more Dataset owned by your organization and others.
              </p>
              <p>
                Except for those Dataset that your organization owns completely, third-party licensors may have rights in the other Dataset. You are responsible for identifying and complying with the license terms applicable to Datasets not owned by your organization. Such Datasets are licensed directly to you from the applicable third-party licensor under the terms uploaded with the Datasets.
              </p>
              <p>
                You acknowledge and agree that each Dataset is licensed under separate terms and that the license terms applicable to any Dataset may not be the same as or compatible with the terms applicable to any other Dataset. You hereby agree to comply with any and all license terms applicable to any Dataset used by you.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 className="ada-terms-and-condition-section-title">Sharing Datasets</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Unless otherwise explicitly stated herein, you agree that any Datasets provided by you in connection with Ada is provided for use by AC and AG organizations only.
              </p>
              <p>
                You agree that any Metadata (such as data dictionary, data description, tags, etc.) will be visible in search results to all registered users of Ada to facilitate data discovery across the AG.
              </p>
              <p>
                Unless your Dataset is “private”, you hereby grant AC rights to use, reproduce, process, adapt, modify, prepare derivative works, publish, transmit and distribute your Dataset, or any portion thereof, in any form, medium or distribution method.
              </p>
              <p>
                Also, by explicitly “sharing” Dataset to other AG organizations, groups and users, you are also authorizing other users, groups and organizations to use those Datasets.
              </p>
              <p>
                AC may modify or adapt your Dataset in order to transmit, display or distribute them over computer networks and in various media and/or make changes to the Submissions as necessary to conform and adapt them to any requirements or limitations of any networks, devices, services or media.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 className="ada-terms-and-condition-section-title">Regulated Data</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                “Regulated Data” means information or data the collection, use, and disclosure/sharing of which is subject to one or more laws. Regulated Data includes but is not limited to full payment card data (credit, debit, or other payment card data); national identification numbers (for example, Social Security Numbers); Personal Information; protected health information; financial information pertaining to a natural living person; or any other data where publishing such data is prohibited under applicable law.
              </p>
              <p>
                Prior to including any Regulated Data in any of your Submissions, you must enter into a separate written agreement with AC governing such Regulated Data, including without limitation the receipt, storage, use, transmission of such Regulated Data.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 className="ada-terms-and-condition-section-title">AC Rights</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Unless otherwise specified, all information and screens appearing on Ada, including documents, services, site design, text, graphics, logos, images and icons, as well as the arrangement thereof, and the APIs and all related documentation are the sole property of the AC. All rights not expressly granted herein are reserved. Except as otherwise required or limited by applicable law, any reproduction, distribution, modification, retransmission, or publication of any copyrighted material is strictly prohibited without the express written consent of the copyright owner or license.
              </p>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
      
    )
  }
}

export default TermsAndConditions
