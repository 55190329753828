import axios from 'axios';
import qs from 'qs';
import * as apiUtils from '../../../common/OrgsAPIUtils';
import { toggleShowAlert, 
    successAPIResDisplay, 
    setIsEditing, 
    SUCCESS_ALERT, 
    ERROR_ALERT } from '../generalActions';
import { getIdToken } from '../../helpers/tokens';
import * as config from '../../../app/config';
import { Cache } from 'aws-amplify';

export const FETCH_ORGANIZATIONS_BEGIN = 'FETCH_ORGANIZATIONS_BEGIN';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATIONS_ERROR = 'FETCH_ORGANIZATIONS_ERROR';
export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const SEARCH_ORGANIZATION = 'SEARCH_ORGANIZATION';
export const TOGGLE_FORM_DIALOG_BOX = 'TOGGLE_FORM_DIALOG_BOX';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const TOGGLE_SHOW_MEMBERS = 'TOGGLE_SHOW_MEMBERS';
export const TOGGLE_SHOW_PROMPT = 'TOGGLE_SHOW_PROMPT';
export const ORGS_SET_FIELD_ON_CHANGE = 'ORGS_SET_FIELD_ON_CHANGE';
export const RESET_ORGANIZATIONS = 'RESET_ORGANIZATIONS';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const ORGS_SET_SORT_VALUES = 'ORGS_SET_SORT_VALUES';
export const FETCH_ALL_ORGANIZATIONS_SUCCESS = 'FETCH_ALL_ORGANIZATIONS_SUCCESS';
export const SET_IS_PAGINATING = 'SET_IS_PAGINATING'; 
export const ORGS_SET_PAGINATION_DETAILS = 'ORGS_SET_PAGINATION_DETAILS';
export const RESET_ORGANIZATION = 'RESET_ORGANIZATION';
export const SET_USER_OTHER_ORGANIZATIONS = 'SET_USER_OTHER_ORGANIZATIONS';

export const ACTION_ADD_ORGANIZATION = 'ACTION_ADD_ORGANIZATION';
export const ACTION_UPDATE_ORGANIZATION = 'ACTION_UPDATE_ORGANIZATION';
export const ACTION_DELETE_ORGANIZATION = 'ACTION_DELETE_ORGANIZATION';

const SUCCESS = 'SUCCESS';

export const fetchOrganizationsIdp = (orgs, filters, limit = null) => {
    return (dispatch) => {
        let params = {
            page: 1,
            limit: 99,
            ids: orgs
        }
        if (typeof(filters) !== "undefined") {
            Object.assign(params, {"filters": filters});
        }
        dispatch(fetchOrgsBegin());
        axios.get(apiUtils.LIST_ORGS_IDP_ENDPOINT, {
            params, 
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }).then(json => {
            const result = json.data.code;
            const message = json.data.message;
            if (result === SUCCESS) {
                const orgs = json.data.data;
                dispatch(fetchOrgsSuccess(orgs));
            }
            else {
                dispatch(fetchOrgsError(message));
            }
        }).catch(error => {
            console.log(error);
            dispatch(fetchOrgsError());
        })
    }
}

export const fetchOrganizations = (definedFilters = null, page = null, limit = null, sort = null, getAll = false) => {
    return (dispatch, getState) => {
        let filters = {}
        let orgs = [];
        let isPaginating = false;
        let sortValues = getState().organizations.sortValues;
        const role = Cache.getItem('role');
        const org_id = Cache.getItem('orgId');

        if (!getAll) {
            switch (role) {
                case config.ROLE_ORG_ADMIN:
                case config.ROLE_CURATOR:
                case config.ROLE_EDITOR:    
                case config.ROLE_VIEWER:
                    orgs = [org_id];
                    break;
                default:
                    break;
            }
        }

        if (page !== null || sort !== null) {
            isPaginating = true;
        }

        if (!page) {
            page = 1;
        }

        if (sort === null) {
            sort = {
                name: "asc"
            }
        }

        if (typeof(definedFilters) === 'object') {
            Object.assign(filters, definedFilters);
        }

        if (typeof(sort) === 'object') {
            Object.assign(sortValues, sort);
            dispatch({type: ORGS_SET_SORT_VALUES, sortValues});
        } 

        let params = {
            page,
            limit: limit,
            ids: orgs,
            filters,
            sort: sortValues
        }

        dispatch(setIsPaginating(isPaginating));
        
        if (!getAll) {
            dispatch(fetchOrgsBegin());
        }
        
        axios.get(apiUtils.LIST_ORGS_ENDPOINT, {
            params, 
            paramsSerializer: params => {
                return qs.stringify(params)
            }, 
            headers: {
                accept: "*/*",
                "id-token": getIdToken()
            }
        }).then(json => {
            let result = json.data;
            if(result) {
                let data = [];
                if (result.data.length > 0) {
                    data = result.data;
                }
                
                let pagination = result.pagination;
                if (result.pagination) {
                    dispatch(setPaginationDetails(pagination.current_page, pagination.total, pagination.per_page));
                }
                dispatch(fetchOrgsSuccess(data, getAll));
            }
        }).catch(error => {
            console.log(error);
            dispatch(fetchOrgsError());
        })
    }
}

export const fetchUserOtherOrgs = (orgs) => {
    return (dispatch, getState) => {
        const otherOrgs = getState().general.userOtherOrgs;
        if (otherOrgs.length === 0) {
            axios.get(apiUtils.LIST_ORGS_ENDPOINT, {
                params: {
                    page: 1, 
                    limit: 99, 
                    ids: [orgs]
                }, 
                paramsSerializer: params => {
                    return qs.stringify(params)
                }, 
                headers: {
                    accept: "*/*",
                    "id-token": getIdToken()
                }
            }).then(json => {
                let result = json.data;
                console.log(result);
                if(result) {
                    let data = [];
                    if (result.data.length > 0) {
                        data = result.data;
                        dispatch({type: SET_USER_OTHER_ORGANIZATIONS, otherOrgs: data});
                    }
                }
            }).catch(error => {
                console.log(error);
                dispatch(fetchOrgsError());
            })
        }
    }
}

export const fetchOrgsBegin = () => {
    return {
        type: FETCH_ORGANIZATIONS_BEGIN
    }
} 

export const fetchOrgsSuccess = (orgs, getAll) => {
    if (!getAll) {
        return {
            type: FETCH_ORGANIZATIONS_SUCCESS, 
            organizations: orgs
        }
    }
    else {
        return {
            type: FETCH_ALL_ORGANIZATIONS_SUCCESS, 
            allOrganizations: orgs
        }
    }
}


export const setIsPaginating = (isPaginating) => {
    return (dispatch) => {
        dispatch({type: SET_IS_PAGINATING, isPaginating});
    }
}

export const resetOrgs = () => {
    return {
        type: RESET_ORGANIZATIONS,
    }
}

export const fetchOrgsError = (errMessage) => {
    let error;
    if (typeof errMessage !== "undefined") {
        error = errMessage;
    }
    else {
        error = 'Error: Failed to load organizations list'
    }

    return {
        type: FETCH_ORGANIZATIONS_ERROR, 
        organizations: null,
        error
    }
}

export const getOrganization = (id) => {
    return (dispatch, getState) => {
        dispatch ({type: GET_ORGANIZATION, id })
    }
}

export const setOrganization = (id) => {
    return (dispatch, getState) => {
        if (Object.keys(getState().organizations.singleOrganization).length === 0) {
            axios.get(apiUtils.GET_ORG_ENDPOINT, {
                params: {
                    id
                }, 
                headers: {
                    "id-token": getIdToken()
                }
            }).then(json => {
                const result = json.data;
                if (result) {
                    dispatch ({type: SET_ORGANIZATION, organization: result.data })
                }
            }).catch (error => {
                console.log(error);
            })
        }
    }
}

export const resetOrganization = () => {
    return (dispatch) => {
        dispatch({type: RESET_ORGANIZATION});
    }
}

export const createOrganization = () => {
    return (dispatch, getState) => {
        const organization = getState().organizations.singleOrganization;
        axios.post(apiUtils.CREATE_ORG_ENDPOINT, organization, {
            headers: {
                "id-token": getIdToken()
            }
        }).then(json => {
            const result = json.data.code;
            let message = json.data.message;
            if (result === SUCCESS) {
                const message = "New organization was successfully created."
                dispatch(toggleShowAlert(true, SUCCESS_ALERT, message));
            }
            else {
                dispatch(toggleShowAlert(true, ERROR_ALERT, message));
            }
            dispatch(fetchOrganizations(null, 1, 10));
        })
    }
}

export const updateOrganization = () => {
    return (dispatch, getState) => {
        const organization = getState().organizations.singleOrganization
        axios.put(apiUtils.UPDATE_ORG_ENDPOINT, organization, {
            headers: {
                "id-token": getIdToken()
            }
        }).then(json => {
            const customMessage = "Organization has been successfully updated.";
            dispatch(setIsEditing(false));
            dispatch(successAPIResDisplay(json, customMessage));
            dispatch(fetchOrganizations(null, 1, 10));
        }).catch(error => {
            const response = error.response.data;
            dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        })
    }
}

export const searchOrganization = (keyword, clear = null, getAll = false) => {
    if (clear) {
        keyword = "";
    }
    return (dispatch) => {
        dispatch({type: SEARCH_ORGANIZATION, keyword});
        const filters = {
            name: keyword
        }
        dispatch(fetchOrganizations(filters, 1, 10, null, getAll))
    }
}

export const toggleDialogDisplay = (show, actionType) => {
    return (dispatch) => {
        if (typeof actionType === 'undefined') {
            dispatch({type: TOGGLE_FORM_DIALOG_BOX, show})
        }
        else {
            dispatch({type: TOGGLE_FORM_DIALOG_BOX, show, actionType})
        }
    }
}

export const deleteOrganization = (id) => {
    return (dispatch, getState) => {
        const org = getState().organizations.singleOrganization;
        axios.delete(apiUtils.TRASH_ORG_ENDPOINT, {
            headers: {
                "id-token": getIdToken()
            },
            params: {
                id: org.id
            }
        }).then(json => {
            const result = json.data.code;
            let message = json.data.message;
            if (result === SUCCESS) {
                message = "Organization has been successfully deleted."
                dispatch(toggleShowAlert(true, SUCCESS_ALERT, message));
            }

        }).catch(error => {
            const response = error.response.data;
            dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        })
        dispatch({type: DELETE_ORGANIZATION, id})
    }
}

export const toggleShowMembers = (show) => {
    return (dispatch) => {
        dispatch({type: TOGGLE_SHOW_MEMBERS, show})
    }
}

export const toggleShowPrompt = (show, actionType) => {
    return (dispatch) => {
        dispatch({type: TOGGLE_SHOW_PROMPT, show, actionType})
    }
}

export const setFieldValueChange = (e) => {
    const value = {
        [e.target.id]: e.target.value
    }
    return (dispatch, getState) => {
        if (!getState().general.isEditing) {
            dispatch(setIsEditing(true));
        }
        dispatch({type: ORGS_SET_FIELD_ON_CHANGE, value})
    }
}

export const setPaginationDetails = (page = 1, items = 0, limit = 10) => {
    if (!items) {
        items = 0;
    }
    return (dispatch) => {
        dispatch({type: ORGS_SET_PAGINATION_DETAILS, page, items, limit})
    }
}
