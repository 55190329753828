export const API_GATEWAY_URL = "https://apigateway.adaplatform.ac/api/";
export const WEB_APP_URL = "https://www.adaplatform.ac";

export const env = "prod";

export const ACCESS_KEY_ID = "AKIASBRWFM6WO7MH2COB";
export const SECRET_ACCESS_KEY = "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";

export const AVATAR_SIZE = 40; //default
export const AVATAR_SIZE_MD = 30;
export const AVATAR_SIZE_SM = 20;
export const AVATAR_SIZE_LG = 60;
export const AVATAR_RADUIS = "100px";

export const AVATAR_PROFILE_PIC_SIZE = 200;

export const ROLE_PLATFORM_ADMIN = 'ROLE_PLATFORM_ADMIN';
export const ROLE_ORG_ADMIN = 'ROLE_ORG_ADMIN';
export const ROLE_CURATOR = 'ROLE_CURATOR';
export const ROLE_EDITOR = 'ROLE_EDITOR';
export const ROLE_VIEWER = 'ROLE_VIEWER';

export const imageFileType = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];