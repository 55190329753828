import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Cache } from 'aws-amplify';
import { Table, Row, Col, Button, Modal, Form, Dropdown, Container, InputGroup, FormControl } from 'react-bootstrap';
import { faPlus, faEllipsisV, faPen, faTrash, faSearch, faFile, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setFormValidated, 
  toggleShowPrompt, 
  setIsEditing } from '../../../store/actions/generalActions';
import { setFieldValueChange, 
  addDataDictionaryField, 
  fetchDatasetDD, 
  setDataDictionaryField, 
  updateDataDictionaryField, 
  deleteDataDictionaryField, 
  resetDataDictionaryField, 
  toggleShowDataDictionaryForm, 
  ACTION_ADD_DD_FIELD,
  ACTION_EDIT_DD_FIELD,
  ACTION_DELETE_DD_FIELD } from '../../../store/actions/main/datasetsActions';
import { checkPermissions } from '../../../store/actions/main/datasetPermissionsActions';
import { searchDataDictionary, uploadDataDictionary } from '../../../store/actions/main/dataDictionaryActions';
import { Pagination, DisplayMoment } from '../../../common/Utilities';
import * as config from '../../../app/config';

class DataDictionary extends Component {

  constructor(props){
    super(props);
    this.delay =  null;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.setFormValidated(true);
    
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
    }
    else {
        const action = this.props.datasets.ddActionType;

        if (action=== ACTION_ADD_DD_FIELD) {
            this.props.addDataDictionaryField(this.props.dataset.id);
        }
        else if (action=== ACTION_EDIT_DD_FIELD){
            this.props.updateDataDictionaryField();
        }

        this.props.setFormValidated(false);
        this.props.toggleShowDataDictionaryForm(false);
    }
    this.props.setIsEditing(false);
  }

  dataDictionaryModal = () => {
    const action = this.props.datasets.ddActionType;
    const handleChange = (e) => this.props.setFieldValueChange(e, action);
    let dataset = this.props.datasets.singleDataset;
    let modalTitle = '';
    let fieldName = '';
    let description = '';
    let type = '';
    let units = '';
    let exampleValues = '';
    let size = '';
    let min = '';
    let max = '';
    let is_empty = '';
    let dataPrepNotes = '';
    let additionalComments = '';
    let is_empty_yes = false;
    let is_empty_no = false;
    if (action === ACTION_ADD_DD_FIELD) {
      modalTitle = 'Add Data Dictionary Field';
    }
    else if (action === ACTION_EDIT_DD_FIELD) {
      const dataDictionaryField = this.props.datasets.dataDictionaryField;
      if (Object.keys(dataDictionaryField).length > 0) {
        fieldName = dataDictionaryField.field;
        description = dataDictionaryField.description;
        type = dataDictionaryField.type;
        units = dataDictionaryField.unit;
        exampleValues = dataDictionaryField.example_values;
        size = dataDictionaryField.size;
        min = dataDictionaryField.min;
        max = dataDictionaryField.max;
        is_empty = dataDictionaryField.is_empty;
        dataPrepNotes = dataDictionaryField.data_prep_notes;
        additionalComments = dataDictionaryField.additional_comments;
      }
      modalTitle = 'Edit Data Dictionary Field';
      if (is_empty) {
        is_empty_yes = true;
        is_empty_no = false;
      }
      else {
        is_empty_yes = false;
        is_empty_no = true;
      }
    }

    const cancelFunc = () => {
      this.props.toggleShowDataDictionaryForm(false);
      this.props.setFormValidated(false);
      const filter = {
        dataset_id: [dataset.id]
      }

      this.props.fetchDatasetDD(filter, null, null, true) //reload data dictionary table
    }
    return (
      <Modal show={this.props.datasets.showDataDictionaryForm} onHide={() => {
          cancelFunc();
        }} backdrop="static">
          <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Form noValidate validated={this.props.general.validated} onSubmit={this.handleSubmit}>
              <div id="ada-dataset-data-dictionary-modal">
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Group controlId="field">
                            <Form.Label>Field Name</Form.Label>
                            <Form.Control required defaultValue={fieldName} type="text"
                                onChange={handleChange} className="mb-3" />
                            <Form.Control.Feedback type="invalid">
                                Please enter field name.
                            </Form.Control.Feedback>
                        </Form.Group>   
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control required as="textarea" defaultValue={description} 
                                onChange={handleChange} className="mb-3" />
                            <Form.Control.Feedback type="invalid">
                                Please enter description.
                            </Form.Control.Feedback>
                        </Form.Group>   
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Form.Group controlId="type">
                            <Form.Label>Data Type</Form.Label>
                            <Form.Control required type="text" defaultValue={type} 
                                onChange={handleChange} className="mb-3" />
                            <Form.Control.Feedback type="invalid">
                                Please enter dataset name.
                            </Form.Control.Feedback>
                        </Form.Group>   
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Form.Group controlId="unit">
                            <Form.Label>Units</Form.Label>
                            <Form.Control type="text" defaultValue={units} 
                                onChange={handleChange} className="mb-3" />
                        </Form.Group>   
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Group controlId="example_values">
                            <Form.Label>Example Values</Form.Label>
                            <Form.Control type="text" defaultValue={exampleValues} 
                                onChange={handleChange} className="mb-3" />
                        </Form.Group>   
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Group controlId="min">
                            <Form.Label>Min Value</Form.Label>
                            <Form.Control type="text" defaultValue={min} 
                                onChange={handleChange} className="mb-3" />
                        </Form.Group>   
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Group controlId="max">
                            <Form.Label>Max Value</Form.Label>
                            <Form.Control type="text" defaultValue={max} 
                                onChange={handleChange} className="mb-3" />
                        </Form.Group>   
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Form.Group controlId="is_empty">
                            <Form.Label>Empty Value?</Form.Label><br />
                            <Form.Check inline label="Yes" name="empty_values" id="is_empty_yes" type="radio" value={1} defaultChecked={is_empty_yes}
                              onChange={handleChange} />
                            <Form.Check inline label="No" name="empty_values" id="is_empty_no" type="radio" value={0} defaultChecked={is_empty_no}
                              onChange={handleChange} 
                            />
                        </Form.Group>   
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Form.Group controlId="size">
                            <Form.Label>Size/Length</Form.Label>
                            <Form.Control type="number" defaultValue={size} 
                                onChange={handleChange} className="mb-3" />
                        </Form.Group>   
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Group controlId="data_prep_notes">
                            <Form.Label>Data Prep Notes</Form.Label>
                            <Form.Control as="textarea" defaultValue={dataPrepNotes} 
                                onChange={handleChange} className="mb-3" />
                        </Form.Group>   
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Group controlId="additional_comments">
                            <Form.Label>Additional Comments</Form.Label>
                            <Form.Control as="textarea" defaultValue={additionalComments} 
                                onChange={handleChange} className="mb-3" />
                        </Form.Group>   
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
              </div>
              <Modal.Footer>
                  <Button variant="secondary" onClick={() => {
                    cancelFunc();
                  }}>
                      Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                      Submit
                  </Button>
              </Modal.Footer>
          </Form>
      </Modal>
    )
  }

  render() {
    const dataDictionary = this.props.fields;
    const dataset = this.props.dataset;
    if (typeof(dataDictionary) === 'undefined') {
      const filter = {
          dataset_id: [this.props.datasets.singleDataset.id]
      }
      this.props.fetchDatasetDD(filter);
    }
    let optionHeader = '';
    let addDDBtn = '';

    let role = Cache.getItem('role');
    let access = checkPermissions(dataset, role);
    
    const hasPermission = access.hasPermission;
    const accessRole = access.role;

    if (hasPermission) {
      role = accessRole;
      if (role === config.ROLE_CURATOR || role === config.ROLE_EDITOR) {
        optionHeader = <th width="2%"></th>
        // addDDBtn = <Button className="float-right" id="btn-icon" variant="primary" onClick={() => {
        //                   this.props.toggleShowDataDictionaryForm(true, ACTION_ADD_DD_FIELD);
        //                   this.props.resetDataDictionaryField();
        //                 }}>
        //                   <span className="ada-page-link">
        //                     <FontAwesomeIcon icon={faPlus} /> Add Item
        //                   </span>
        //                 </Button>
        addDDBtn = <Dropdown className="float-right" drop="up">
                    <Dropdown.Toggle variant="default" id="btn-toggle-dropdown">
                      <span className="ada-page-link">
                        <FontAwesomeIcon icon={faPlus} /> Add Item
                      </span>
                    </Dropdown.Toggle>
                  
                    <Dropdown.Menu>
                      <Dropdown.Item href="#" onClick={(e) => {
                        e.preventDefault();
                        this.props.toggleShowDataDictionaryForm(true, ACTION_ADD_DD_FIELD);
                        this.props.resetDataDictionaryField();
                      }}> 
                        <FontAwesomeIcon icon={faFile} /> New Item
                      </Dropdown.Item>
                      <Dropdown.Item href="#" onClick={(e) => {
                        e.preventDefault();
                        var fileUpload = document.getElementById("dataDictionaryUpload");
                        fileUpload.click();
                      }}> 
                        <FontAwesomeIcon icon={faUpload} /> Upload Bulk 
                      </Dropdown.Item>
                      <input multiple type="file" id="dataDictionaryUpload" style={{"display": "none"}} onChange={(e) => {
                          this.props.uploadDataDictionary(e, dataset);
                        }} />
                    </Dropdown.Menu>
                  </Dropdown>
      }
    }
    
    if (typeof(dataDictionary) !== "undefined") {
      let ddList = '';
      if (dataDictionary !== null) {
        ddList = dataDictionary.length > 0 ? (
          dataDictionary.map((field, key) => {
            let emptyVal = "True";
            if (field.is_empty === false) {
              emptyVal = "False";
            }
            let fieldOptions = '';

            let role = Cache.getItem('role');
            let access = checkPermissions(dataset, role);

            const hasPermission = access.hasPermission;
            const accessRole = access.role;
               
            if (hasPermission) {
              role = accessRole;
              if (role === config.ROLE_CURATOR || role === config.ROLE_EDITOR) {
                fieldOptions = <td>
                                  <Dropdown drop={"left"}>
                                    <Dropdown.Toggle variant="outline" id="ada-button-options">
                                        <FontAwesomeIcon icon={faEllipsisV} />
                                    </Dropdown.Toggle>                    
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => {
                                            this.props.setDataDictionaryField(field.id);
                                            this.props.toggleShowDataDictionaryForm(true, ACTION_EDIT_DD_FIELD);
                                        }}>
                                          <FontAwesomeIcon icon={faPen} /> Edit Field
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={
                                            () => { 
                                              this.props.setDataDictionaryField(field.id);
                                                this.props.toggleShowPrompt(true, ACTION_DELETE_DD_FIELD); 
                                            }
                                        }>
                                          <FontAwesomeIcon icon={faTrash} />  Delete Field
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
              }
            }

            return (
              <tr key={key}>
                {fieldOptions}
                <td>{field.field}</td>
                <td>{field.description}</td>
                <td className="align-center">{field.type}</td>
                <td className="align-center">{field.unit}</td>
                <td className="align-center">{field.size}</td>
                <td>{field.example_values}</td>
                <td className="align-center">{field.min}</td>
                <td className="align-center">{field.max}</td>
                <td className="align-center">{emptyVal}</td>
                <td><DisplayMoment dataTime={field.updated_at} /></td>
                <td>{field.updated_by}</td>
                <td>{field.data_prep_notes}</td>
                <td>{field.additional_comments}</td>
              </tr>
            )
          })
        ) : (
          <tr><td colSpan={12}>Loading...</td></tr>
        )
      }
      else {
        ddList = <tr><td colSpan={12}>No fields yet.</td></tr>
      }

      return (
        <div>
          {this.dataDictionaryModal()}
          <Row className="mt-3 mb-3">
            <Col>
              <h5>Data Dictionary</h5>
            </Col>
            <Col>
              {addDDBtn}
            </Col>
          </Row>
          <hr />
          <Row className="mt-3 mb-3">
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputGroup>
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                    placeholder="Search Data Dictionary"
                    aria-describedby="basic-addon1"
                    id="search" 
                    autoComplete="off" 
                    className="float-left" 
                    onChange={(e) => {
                        var searchText = e.target.value;
                        if (this.delay) {
                          clearTimeout(this.delay);
                        }
                        this.delay = setTimeout(() => {
                          this.props.searchDataDictionary(searchText)
                        }, 500)
                    }} 
                    />
                </InputGroup>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="float-right">
                  <Pagination totalitems={this.props.datasets.ddTotalItems}  
                        limit={this.props.datasets.ddLimit} 
                        
                        callback={(page) => {
                            const filter = {
                              dataset_id: [this.props.dataset.id]
                            }
                            this.props.fetchDatasetDD(filter, page, null, true);
                        }} />
                </div>
              </Col>
          </Row>
          <Table id="ada-data-dictionary" responsive bordered="true">
            <thead>
              <tr>
                {optionHeader}
                <th width="10%">Field Name</th>
                <th width="10%">Description</th>
                <th width="10%" className="align-center">Data Type</th>
                <th width="7%" className="align-center">Units</th>
                <th width="4%" className="align-center">Size</th>
                <th width="8%">Example Values</th>
                <th width="5%" className="align-center">Min Value</th>
                <th width="5%" className="align-center">Max Value</th>
                <th width="7%" className="align-center">Empty Value?</th>
                <th width="7%">Date Added</th>
                <th width="7%">Added by</th>
                <th width="9%">Data Prep Notes</th>
                <th width="9%">Additional Comments</th>
              </tr>
            </thead>
            <tbody bordered="true">
              {ddList}
            </tbody>
          </Table>
        </div>
      )
    }
    else {
      return 'Loading...';
    }
  }
}

const mapStateToProps = (state) => ({
  general: state.general,
  datasets: state.datasets
})

const mapDispatchToProps = (dispatch) => {
  return {
    toggleShowDataDictionaryForm: (...args) => dispatch(toggleShowDataDictionaryForm(...args)),
    setFieldValueChange: (...args) => dispatch(setFieldValueChange(...args)),
    setFormValidated: (...args) => dispatch(setFormValidated(...args)),
    addDataDictionaryField: (...args) => dispatch(addDataDictionaryField(...args)),
    fetchDatasetDD: (...args) => dispatch(fetchDatasetDD(...args)),
    setDataDictionaryField: (...args) => dispatch(setDataDictionaryField(...args)),
    updateDataDictionaryField: () => dispatch(updateDataDictionaryField()),
    deleteDataDictionaryField: () => dispatch(deleteDataDictionaryField()),
    toggleShowPrompt: (...args) => dispatch(toggleShowPrompt(...args)),
    setIsEditing: (...args) => dispatch(setIsEditing(...args)),
    resetDataDictionaryField: () => dispatch(resetDataDictionaryField()),
    searchDataDictionary: (...args) => dispatch(searchDataDictionary(...args)),
    uploadDataDictionary: (...args) => dispatch(uploadDataDictionary(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataDictionary);