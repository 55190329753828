import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Container, Form, Button, Table } from 'react-bootstrap';
import { ProfilePhoto, getRoleName } from '../../common/Utilities';
import { getUserGroups, resetUserGroups, fetchGroups } from '../../store/actions/settings/groupsActions';
import { toggleShowPrompt, 
    setProfileFieldValueChange, 
    setProfilePhoto,
    updateProfile,  
    setFormValidated,
    setUserInfo } from '../../store/actions/generalActions';
import { fetchRoles } from '../../store/actions/settings/usersActions';
import { ConfirmDialog } from '../../common/Utilities';
import { Cache } from 'aws-amplify';

class Profile extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
        const isLoggedIn = Cache.getItem('LOGGED_IN');
        if (isLoggedIn) {
            this.props.resetUserGroups();
            this.props.fetchRoles();
            const userId = Cache.getItem('userId');
            this.props.fetchGroups({"owner_id": userId});
        }
        else {
            this.props.history.push('/login');
        }
        
    }
    promptModal = () => {
        let modalTitle = '';
        let modalBody = '';
        let actionButton = '';

        modalTitle = 'Leave Group';
        modalBody = 'Are you sure you want leave the group?';
        actionButton = () => this.props.deleteUser(this.props.users.singleUser.id);

        const onHideFunction = () => {
            this.props.toggleShowPrompt(false)
        }

        const onClickCancel = () => {
            this.props.toggleShowPrompt(false);
        }

        const onClickConfirm = () => {
            actionButton();
            this.props.toggleShowPrompt(false);
            this.props.toggleDialogDisplay(false);
        }

        return (
            <ConfirmDialog 
                show={this.props.general.showPrompt} 
                modalTitle={modalTitle} 
                modalBody={modalBody} 
                onHideFunction={onHideFunction}
                onClickCancel={onClickCancel}
                onClickConfirm={onClickConfirm}
            />
        ) 
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.setFormValidated(true);
        
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        else {
            this.props.setFormValidated(false);
            this.props.updateProfile();
        }
    }

    groupsLists = () => {
        const groups = this.props.groups.userGroups;
        const ownedGroups = this.props.groups.groups;
        let allGroups = [];
        let groupsList;
        if (ownedGroups !== null) {
            if (ownedGroups.length > 0) {
                ownedGroups.forEach((group) => {
                    allGroups.push(group);
                })
            }
        }

        if (groups !== null) {
            if (groups.length > 0) {
                groups.forEach((group) => {
                    allGroups.push(group);
                })
            }
        }

        groupsList = allGroups.length > 0 ? (
            allGroups.map((group, key) => {
                let groupName = '';
                let role = '';
                let orgName = '';
                if (typeof(group.group) !== "undefined") {
                    if (group.group !== null) {
                        groupName = group.group.name;
                        orgName = group.organization.name;
                        role = group.role_name;
                    }
                }
                else {
                    groupName = group.name;
                    if (typeof(group.organization) !== "undefined") {
                        const org = group.organization;
                        if (org) {
                            orgName = org.name;
                        }
                    }
                    
                    role = 'Owner';
                }
                
                return (
                    <tr key={key}>
                        <td width="35%">{groupName}</td>
                        <td width="30%" className="align-center">{orgName}</td>
                        <td width="20%" className="align-center">{role}</td>
                        {/* <td width="15%"><Link to="#" onClick={() => {
                        this.props.toggleShowPrompt(true); 
                        }}>Leave Group</Link></td> */}
                    </tr>
                )
            })
        ) : (
            <tr><td colSpan={4}>No groups available</td></tr>
        )

        return groupsList;
    }

    renderProfile = (userInfo) => {
        
        if (Object.keys(userInfo).length === 0) {
            return <p>Loading...</p>
        }

        let first_name = '';
        let middle_name = '';
        let last_name = '';
        let email = '';
        let photo = '';
        let displayName = '';
        let isLoading = false;
        let role = '';
        let org = '';

        first_name = userInfo.first_name;
        middle_name = userInfo.middle_name;
        last_name = userInfo.last_name;
        role = getRoleName(userInfo.role);
        role = role[0];
        if (typeof(userInfo.organization) !== "undefined") {
            if (userInfo.organization) {
                org = userInfo.organization.name
            }
        }
        
        displayName = first_name + " " + last_name;
        
        email = userInfo.email;
        photo = userInfo.photo_url_signed;
        
        if (this.props.general.profilePhoto !== null) {
            isLoading = true
        }

        let form;
        let updateBtn='';
            form = <React.Fragment>
                        
                        <Form.Group controlId="first_name">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control defaultValue={first_name} type="text" onChange={this.props.setProfileFieldValueChange}/>
                        </Form.Group>
                        <Form.Group controlId="middle_name">
                            <Form.Label>Middle Name</Form.Label>
                            <Form.Control defaultValue={middle_name} type="text" onChange={this.props.setProfileFieldValueChange}/>
                        </Form.Group>
                        <Form.Group controlId="last_name">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control defaultValue={last_name} type="text" onChange={this.props.setProfileFieldValueChange}/>
                        </Form.Group>
                        <Form.Group controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control readOnly defaultValue={email} type="email" />
                        </Form.Group>
                        <Form.Group controlId="organization">
                            <Form.Label>Organization</Form.Label>
                            <Form.Control readOnly defaultValue={org} type="text"/>
                        </Form.Group>
                        <Form.Group controlId="role">
                            <Form.Label>Role</Form.Label>
                            <Form.Control readOnly defaultValue={role} type="text" />
                        </Form.Group>
                    </React.Fragment>
            
            updateBtn = <Button variant="primary" type="submit" className="float-right">
                            Save Profile
                        </Button>
        
        return (
            <Container>
                <Row>
                    <Col xls={12} sm={12} md={12} lg={12} xl={12}>      
                        <h3 className="ada-main-header">My Profile</h3> 
                    </Col>
                </Row>
                <hr />
                <Form noValidate validated={this.props.general.validated} onSubmit={this.handleSubmit}>
                    <Row>
                        <Col xs={12} sm={12} md={3} lg={3} xl={3} align="center">
                            <div id="profile-upload-photo" onMouseEnter={() => {
                                document.getElementById('profile-upload-photo-btn').style.display = 'block';
                            }} onMouseLeave={() => {
                                document.getElementById('profile-upload-photo-btn').style.display = 'none';
                            }}>
                                <ProfilePhoto photo={photo} displayName={displayName} loading={isLoading} />
                                <div id="profile-upload-photo-btn" onClick={() => {
                                    var fileUpload = document.getElementById("profilePhotoInput");
                                    fileUpload.click();
                                    document.getElementById('profile-upload-photo-btn').style.display = 'none';
                                }}>
                                    Change Profile Photo
                                </div>
                                <input multiple type="file" id="profilePhotoInput" style={{"display": "none"}} 
                                    onChange={this.props.setProfilePhoto} />
                            </div>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                                    {/* <Form.File id="photo" className="mt-3">
                                        <Form.File 
                                            id="custom-file-translate-scss"
                                            label=""
                                            lang="en"
                                            onChange={this.props.setProfilePhoto} 
                                            onClick={() => {
                                                this.setState({
                                                    isEditing: false
                                                });
                                            }}
                                            accept="image/x-png,image/gif,image/jpeg"
                                            custom 
                                        />
                                    </Form.File> */}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                            {form}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                            {updateBtn} 
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col xls={12} sm={12} md={12} lg={12} xl={12} className="mt-5">      
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xls={12} sm={12} md={12} lg={12} xl={12} className="mt-5">      
                        <h3 className="ada-main-header">Groups</h3> 
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col xls={12} sm={12} md={12} lg={12} xl={12}>
                        <Table>
                            <thead>
                                <tr>
                                    <th className="align-center">Group Name</th>
                                    <th className="align-center">Organization</th>
                                    <th className="align-center">Role</th>
                                    {/* <th></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {this.groupsLists()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        )
    }

    render() {
        const userInfo = this.props.general.userInfo;
        this.props.getUserGroups(); //group/s the user belongs
        return (
            <div>
                {this.promptModal()}
                {this.renderProfile(userInfo)}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        general: state.general,
        groups: state.groups
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserGroups: (...args) => dispatch(getUserGroups(...args)),
        fetchGroups: (...args) => dispatch(fetchGroups(...args)), 
        toggleShowPrompt: (...args) => dispatch(toggleShowPrompt(...args)),
        setProfileFieldValueChange: (...args) => dispatch(setProfileFieldValueChange(...args)),
        setProfilePhoto: (...args) => dispatch(setProfilePhoto(...args)),
        updateProfile: (...args) => dispatch(updateProfile(...args)),
        setFormValidated: (...args) => dispatch(setFormValidated(...args)),
        resetUserGroups: () => dispatch(resetUserGroups()),
        setUserInfo: () => dispatch(setUserInfo()),
        fetchRoles: (...args) => dispatch(fetchRoles(...args))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
