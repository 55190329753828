import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import generalReducer from './settings/generalReducer';
import usersReducer from './settings/usersReducer';
import organizationsReducer from './settings/organizationsReducer';
import groupsReducer from './settings/groupsReducer';
import datasetsReducer from './main/datasetsReducer';
import datasetPermissionsReducer from './main/datasetPermissionsReducer';

const rootReducer = combineReducers({
    general: generalReducer, 
    auth: authReducer,
    users: usersReducer,
    organizations: organizationsReducer,
    groups: groupsReducer, 
    datasets: datasetsReducer,
    datasetPermissions: datasetPermissionsReducer
});

export default rootReducer