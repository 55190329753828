import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Cache } from 'aws-amplify';
import { Button, Form, Container, Row, Col, Modal } from 'react-bootstrap'; 
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTagInput from "@pathofdev/react-tag-input";
import { setFieldValueChange,
    setTags, 
    createDataset, 
    fetchDatasets, 
    resetDataset, 
    updateDataset, 
    trashDataset, 
    addLinks, 
    setLinkFieldChange, 
    deleteDataDictionaryField, 
    addLinkToDataset, 
    deleteDataset, 
    removeDatasetLinkItem, 
    trashDatasetAttachment, 
    incrementAttachmentForm, 
    clearNumAttachmentForm, 
    clearCreateDataset, 
    ACTION_ADD_DATASET,
    ACTION_EDIT_DATASET,
    ACTION_TRASH_DATASET, 
    ACTION_DELETE_DATASET, 
    ACTION_DELETE_DD_FIELD,
    ACTION_DELETE_DATASET_FILE} from '../../../store/actions/main/datasetsActions';
import { toggleDialogDisplay, 
    toggleShowPrompt, 
    toggleShowAlert, 
    setFormValidated, 
    setIsEditing,
    setInputValidator, 
    ERROR_ALERT } from '../../../store/actions/generalActions';
import { fetchQualities } from '../../../store/actions/main/qualityActions';
import { checkPermissions } from '../../../store/actions/main/datasetPermissionsActions';
import { ConfirmDialog } from '../../../common/Utilities';
import * as config from '../../../app/config';

import DatasetAttachment from './DatasetAttachment';

class DatasetForm extends Component {

  componentDidMount() {
    this.props.fetchQualities();
  }

  promptModal = () => {
    const action = this.props.general.actionType;
    let modalTitle = '';
    let modalBody = '';

    switch (action) {
        case ACTION_TRASH_DATASET:
            modalTitle = 'Trash Dataset';
            modalBody = 'Are you sure you want to trash this dataset?';
            break;
        case ACTION_ADD_DATASET:
            modalTitle = 'Add Dataset';
            modalBody = 'Details will be lost. Are you sure you want to discard changes?';
            break;
        case ACTION_DELETE_DATASET:
            modalTitle = 'Permanently Delete Dataset';
            modalBody = 'Are you sure you want to delete this dataset?';
            break;
        case ACTION_EDIT_DATASET: 
            modalTitle = 'Update Dataset';
            modalBody = 'Details will be lost. Are you sure you want to discard changes?';
            break;
        case ACTION_DELETE_DD_FIELD: 
            modalTitle = 'Delete Data Dictionary Field';
            modalBody = 'Are you sure you want to delete this data dictionary field?';
            break;
        case ACTION_DELETE_DATASET_FILE:
            const attachment = this.props.datasets.singleAttachment;
            modalTitle = 'Delete Dataset File';
            modalBody = <span>Are you sure you want to delete <i>{attachment.file_name}</i> ?</span>;
            break;
        default:
            break;
    }

    const onClickCancel = () => {
        this.props.toggleShowPrompt(false);
        this.props.setFormValidated(false);
        if (ACTION_ADD_DATASET === action || action === ACTION_EDIT_DATASET) {
            this.props.toggleDialogDisplay(true, action);
            if (action === ACTION_EDIT_DATASET) {
                this.props.resetDataset();
            }
        }
    }

    const onHideFunction = () => {
        onClickCancel();
    }

    const onClickConfirm = () => {
        switch (action) {
            case ACTION_ADD_DATASET: 
                this.props.toggleDialogDisplay(false);
                this.props.setIsEditing(false);
                this.props.clearNumAttachmentForm();
                this.props.clearCreateDataset();
                break;
            case ACTION_EDIT_DATASET:
                this.props.resetDataset();
                this.props.setIsEditing(false);
                break;
            case ACTION_TRASH_DATASET:
                this.props.trashDataset();
                this.props.history.push('/main/datacatalog');
                break;
            case ACTION_DELETE_DATASET:
                const urlParams = new URLSearchParams(this.props.location.search);
                const datasetId =  urlParams.get('id');
                if (datasetId) {
                    this.props.deleteDataset(datasetId);
                }
                this.props.history.push('/main/datacatalog/trash');
                
                break;
            case ACTION_DELETE_DD_FIELD:
                this.props.deleteDataDictionaryField();
                break;
            case ACTION_DELETE_DATASET_FILE:
                const attachment = this.props.datasets.singleAttachment;
                if (attachment) {
                    let isLink = false;
                    if (attachment.file_type === "DATASET_ATTACHMENT_LINK") {
                        isLink = true;
                    }
                    this.props.trashDatasetAttachment(attachment.id, isLink)
                }
                break;
            default:
                break;
        }
        this.props.toggleShowPrompt(false);
        this.props.setFormValidated(false);
    }

    return (
        <ConfirmDialog 
            show={this.props.general.showPrompt} 
            modalTitle={modalTitle} 
            modalBody={modalBody} 
            onHideFunction={onHideFunction}
            onClickCancel={onClickCancel}
            onClickConfirm={onClickConfirm}
        />
    ) 
  }

  getUploadLinksToAdd = () => {
    let uploadLinksList = '';
    if (typeof(this.props.datasets.singleDataset.uploadLinks) != "undefined") {
      const uploadLinks = this.props.datasets.singleDataset.uploadLinks;
      if (uploadLinks.length > 0) {
        uploadLinksList = uploadLinks.map((link, key) => {
            return (
              <Row>
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                      <a target="_blank" href={link.datasetUrl}>{link.datasetName}</a>
                  </Col>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Button variant="primary" id="btn-icon" className="float-right" onClick={() => {
                          this.props.removeDatasetLinkItem(key);
                      }}>
                          <FontAwesomeIcon icon={faTimes} />
                      </Button> 
                  </Col>
              </Row>
            ) 
          })
      }
    }
    return uploadLinksList;
  }

  handleSubmit = (e) => {
      e.preventDefault();
      this.props.setFormValidated(true);
      const action = this.props.general.actionType;
      const form = e.currentTarget;
      let tags = '';
      if (action === ACTION_ADD_DATASET) {
        tags = this.props.datasets.createDataset.tags;
      }
      else {
        tags = this.props.datasets.singleDataset.tags;  
      }

      if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
      }
      else {
          if (action=== ACTION_ADD_DATASET) {
            this.props.createDataset();
            this.props.history.push('/main/datacatalog');
          }
          else {
            this.props.updateDataset();
          }

          this.props.setFormValidated(false);
          this.props.toggleDialogDisplay(false);
      }
  }

  showInputValidator = () => {
      if (typeof(this.props.general.onInputValidator) !== "undefined") {
          const onInputValidator = this.props.general.onInputValidator.show;
          const onInputValidatorMsg = this.props.general.onInputValidator.message;
          const validType = this.props.general.onInputValidator.type;
          let type = '';
          if (validType === "error") {
              type = "text-danger";
          }
          else if (validType === "success"){
              type = "text-success";
          }

          if (onInputValidator) {
              return (
                  <span className={"small " + type}>{onInputValidatorMsg}</span>
              )
          }
          else {
              return  '';
          }
      }
  }

  getQualityOptions = () => {
    
    const qualities = this.props.datasets.qualities;
    const qualityList =  qualities.map((quality, key) => {
        return (
            <option key={key} title={quality.description} value={quality.id}>{quality.name}</option>
        )
    })

    return qualityList
  }

  attachmentForms = () => {
    let attachmentFormList = [];
    const numAttachmentForms = this.props.datasets.addAttachmentForms;
    numAttachmentForms.forEach((attachmentForm, key) => {
        attachmentFormList.push(<DatasetAttachment key={key} attachmentForm={attachmentForm} />);
    })

    return attachmentFormList;
  }

  addAttachment = () => {
    const action = this.props.general.actionType;
    if (action !== ACTION_ADD_DATASET) {
        return '';
    }
    return (
    <React.Fragment>
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <b>Attachments</b>
            </Col>
        </Row>
        <hr />
        {this.attachmentForms()}
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button className="float-right" id="btn-icon" variant="primary" onClick={() => {
                        this.props.incrementAttachmentForm();
                        document.getElementById('ada-create-dataset-modal').scroll(0, 1000)
                        this.props.setIsEditing(true);
                    }}>
                    <span className="ada-page-link">
                        <FontAwesomeIcon icon={faPlus} /> Add Attachment
                    </span>
                </Button>
            </Col>
        </Row>
    </React.Fragment>
    )
  }

  render() {
    let dataset = {};
    let modalTitle = '';
    let datasetName = '';
    let qualityId = '';
    let description = '';
    let tags = [];
    let links = [];
    const action = this.props.general.actionType;
    //Set dataset depending on action
    if (action === ACTION_ADD_DATASET) {
        modalTitle = "Create Dataset";
        if (typeof(this.props.datasets.createDataset) !== "undefined") {
            dataset = this.props.datasets.createDataset;
        }
    }
    else if (action === ACTION_EDIT_DATASET) {
        modalTitle = "Update Dataset";
        if (typeof(this.props.datasets.editDataset) !== "undefined") {
            dataset = this.props.datasets.editDataset;
        }
    }

    const handleChange = (e) => this.props.setFieldValueChange(e, action);

    if (Object.keys(dataset).length > 0) {
        tags = dataset.tags.split(",");
        if (tags[0] === '') {
            tags = []
        }

        if (typeof(dataset.links) !== "undefined") {
            links = dataset.links;
        }

        if (typeof(dataset.name) !== "undefined") {
            datasetName = dataset.name;
        }

        if (typeof(dataset.quality_id) !== "undefined") {
            qualityId = dataset.quality_id;
        }

        if (typeof(dataset.description) !== "undefined") {
            description = dataset.description;
        }
    }

    let modalSize = "lg";
    let mainColSize = 7;
    let datasetCol = 7;
    let qualitySelect = <Form.Group controlId="quality_id">
                            <Form.Label>Quality</Form.Label>
                            <Form.Control required defaultValue={qualityId} 
                                as="select" 
                                onChange={handleChange} >
                                {this.getQualityOptions()}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select a dataset quality.
                            </Form.Control.Feedback>
                        </Form.Group>   

    let uploadDictionary = <Row className="mt-3">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.File id="data_dictionary">
                                        <Form.File.Label>Import Data Dictionary</Form.File.Label>
                                        <Form.File.Input 
                                            accept=".csv" 
                                            onChange={(e) => {
                                            if (e.target.files) {
                                                const file = e.target.files[0];
                                                const fileType = file.name.split(".");
                                                if (fileType[1] !== "csv") {
                                                    const message = 'Invalid Data Dictionary file. Please upload in CSV file.';
                                                    this.props.toggleShowAlert(true, ERROR_ALERT, message);
                                                    document.getElementById("data_dictionary").value = "";
                                                }
                                                else {
                                                    handleChange(e)
                                                }
                                            }
                                        }} />
                                    </Form.File>
                                </Col>
                            </Row>
    
    if (action === ACTION_EDIT_DATASET) {
        uploadDictionary = '';

        modalSize = "md";
        mainColSize = 12;

        let role = Cache.getItem('role');
        let access = checkPermissions(dataset, role);
        let accessRole = access.role;

        role = accessRole;
        if (role !== config.ROLE_CURATOR) {
            qualitySelect = '';
            datasetCol = 12;
        }
    }

    const onCancelFunc = () => {
        this.props.toggleDialogDisplay(false);
        this.props.setFormValidated(false);
        if (this.props.general.isEditing) {
            this.props.toggleShowPrompt(true, action);
        }
    }

    let btnSubmitDisabled = false;
    if (this.props.datasets.isLoading) {
        btnSubmitDisabled = true;
    }
    
    return (
        <div>
            {this.promptModal()}
        <Modal show={this.props.general.showFormDialog} onHide={() => {
            onCancelFunc();
        }} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={this.props.general.validated} onSubmit={this.handleSubmit}>
                <div id="ada-create-dataset-modal">
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Dataset Name</Form.Label>
                                        <Form.Control required defaultValue={datasetName} type="text" placeholder="" autoComplete="off"
                                            onChange={handleChange} className="mb-3" />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter dataset name.
                                        </Form.Control.Feedback>
                                    </Form.Group>                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Group controlId="tags">
                                        <Form.Label>Tags</Form.Label>
                                        <ReactTagInput 
                                            tags={tags} 
                                            onChange={ (newTags) => this.props.setTags(newTags, action) }
                                        />
                                        {this.showInputValidator()}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Group controlId="description">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control required defaultValue={description} 
                                            as="textarea" 
                                            rows="4"
                                            onChange={(e) => {
                                                let descLength = e.target.value.length;
                                                if (descLength > 1500) {
                                                     document.getElementById('createDatasetBtn').disabled = true;
                                                     this.props.toggleShowAlert(true, 
                                                        ERROR_ALERT, 
                                                        "Description must be less than or equal to 1,500 characters.")
                                                }
                                                else {
                                                    document.getElementById('createDatasetBtn').disabled = false;
                                                    handleChange(e)
                                                }
                                            }} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter description
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    {qualitySelect}
                                    
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    {uploadDictionary}
                                </Col>
                            </Row>
                            {this.addAttachment()}
                        </Container>
                    </Modal.Body>
                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        onCancelFunc();
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" id="createDatasetBtn" type="submit" disabled={btnSubmitDisabled}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
        </div>
    )
  }
}
const mapStateToProps = (state) => ({
  general: state.general, 
  organizations: state.organizations,
  datasets: state.datasets
})

const mapDispatchToProps = (dispatch) => {
  return {
      toggleDialogDisplay: (...args) => dispatch(toggleDialogDisplay(...args)),
      setFieldValueChange: (...args) => dispatch(setFieldValueChange(...args)),
      setTags: (...args) => dispatch(setTags(...args)),
      addLinks: (...args) => dispatch(addLinks(...args)), 
      setFormValidated: (...args) => dispatch(setFormValidated(...args)),
      createDataset: () => dispatch(createDataset()),
      updateDataset: () => dispatch(updateDataset()),
      fetchDatasets: () => dispatch(fetchDatasets()),
      resetDataset: () => dispatch(resetDataset()),
      toggleShowPrompt: (...args) => dispatch(toggleShowPrompt(...args)),
      setIsEditing: (...args) => dispatch(setIsEditing(...args)),
      trashDataset: () => dispatch(trashDataset()),
      deleteDataset: (...args) => dispatch(deleteDataset(...args)), 
      setInputValidator: (...args) => dispatch(setInputValidator(...args)),
      deleteDataDictionaryField: () => dispatch(deleteDataDictionaryField()),
      setLinkFieldChange: (...args) => dispatch(setLinkFieldChange(...args)),
      addLinkToDataset: (...args) => dispatch(addLinkToDataset(...args)),
      removeDatasetLinkItem: (...args) => dispatch(removeDatasetLinkItem(...args)),
      toggleShowAlert: (...args) => dispatch(toggleShowAlert(...args)),
      fetchQualities: () => dispatch(fetchQualities()),
      trashDatasetAttachment: (...args) => dispatch(trashDatasetAttachment(...args)),
      incrementAttachmentForm: () => dispatch(incrementAttachmentForm()),
      clearNumAttachmentForm: () => dispatch(clearNumAttachmentForm()),
      clearCreateDataset: () => dispatch(clearCreateDataset())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatasetForm))
