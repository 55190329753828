import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Cache } from 'aws-amplify';
import { Row, Col, Button, Table, Form, Modal,  } from 'react-bootstrap';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toggleShowPrompt, setFormValidated } from '../../../store/actions/generalActions';
import * as config from '../../../app/config';
import { checkBoxDownloadChange, 
  addLinkToDataset, 
  editLinkToDataset, 
  updateDatasetFile, 
  setLinkFieldChange,
  setAttachment, 
  toggleAddExternalLinkForm,
  ACTION_ADD_DATASET_LINK,  
  ACTION_EDIT_DATASET_LINK, 
  ACTION_DELETE_DATASET_FILE } from '../../../store/actions/main/datasetsActions';
import { checkPermissions } from '../../../store/actions/main/datasetPermissionsActions';
import { DatasetSiteLink } from './DatasetLink';

class ExternalLinks extends Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.setFormValidated(true);
    
    const form = e.currentTarget;
    const action = this.props.datasets.actionType;

    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
    }
    else {
      if (action === ACTION_ADD_DATASET_LINK) {
        this.props.addLinkToDataset(this.props.dataset.id);
      }
      else if (action === ACTION_EDIT_DATASET_LINK) {
        this.props.editLinkToDataset(this.props.dataset.id);
      }
      this.props.toggleAddExternalLinkForm(false)
      this.props.setFormValidated(false);
    }

    
  }
  addLinkModal = () => {
    let fieldName = '';
    let fieldLink = '';
    let modalTitle = '';

    const action = this.props.datasets.actionType;

    if (action === ACTION_ADD_DATASET_LINK) {
      modalTitle = 'Add External Link';
    }
    else {
      modalTitle = 'Edit External Link';
      let datasetLink = this.props.datasets.datasetLink;
      
      fieldName = datasetLink.fileName;
      fieldLink = datasetLink.fileLink;
    }

    return (
      <Modal show={this.props.datasets.showAddLinkForm} onHide={() => {
        this.props.toggleAddExternalLinkForm(false)
      }}>
        <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={this.props.general.validated} onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Group controlId="fileName">
                      <Form.Label>Link name</Form.Label>
                      <Form.Control required defaultValue={fieldName} type="text" className="mb-3" 
                        onChange={this.props.setLinkFieldChange} />
                    <Form.Control.Feedback type="invalid">
                        Please enter file name.
                    </Form.Control.Feedback>
                  </Form.Group>   
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Group controlId="fileLink">
                      <Form.Label>URL</Form.Label>
                      <Form.Control required placeholder="https://www.sample.com" 
                        defaultValue={fieldLink} 
                        type="text" 
                        className="mb-3" 
                        onChange={this.props.setLinkFieldChange} />
                      <Form.Control.Feedback type="invalid">
                          Please enter valid URL.
                      </Form.Control.Feedback>
                  </Form.Group>   
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                  this.props.toggleAddExternalLinkForm(false)
                }}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Modal.Footer>
        </Form>
      </Modal>
    )
  }
  render() {
    const dataset = this.props.dataset;
    if (typeof(dataset.attachments) === "undefined") {
      return '';
    }

    let addDatasetLink = '';

    let role = Cache.getItem('role');
    let access = checkPermissions(dataset, role);
    
    const hasPermission = access.hasPermission;
    const accessRole = access.role;
    if (hasPermission) {
      role = accessRole
      if (role === config.ROLE_CURATOR || role === config.ROLE_EDITOR) {
        addDatasetLink = <Button variant="primary" id="btn-icon" className="float-right" onClick={() => {
                            this.props.toggleAddExternalLinkForm(true, ACTION_ADD_DATASET_LINK)
                          }}>
                            <React.Fragment>
                              <span className="ada-page-link">
                                <FontAwesomeIcon icon={faPlus} /> New Link
                              </span>
                            </React.Fragment>
                          </Button>
      }
    }

    const attachments = dataset.attachments;
  
    let datasetLinks = attachments.filter(attachment => {
      if (attachment.attachment_type === "DATASET_ATTACHMENT_LINK") {
        return attachment;
      }
    }).map((attachment, key) => {
      return (
        <DatasetSiteLink key={key} 
          dataset={dataset}
          attachment={attachment} 
          openEditLinkForm={() => {
            this.props.toggleAddExternalLinkForm(true, ACTION_EDIT_DATASET_LINK);
          }}
          setLinkFieldChange={(e, attachmentId) => {
            this.props.setLinkFieldChange(e, attachmentId)
          }} 
          editLinkToDataset={(datasetId) => {
            this.props.editLinkToDataset(datasetId);
          }} 
          removeLinkCallback={() => {
            this.props.setAttachment(attachment);
            this.props.toggleShowPrompt(true, ACTION_DELETE_DATASET_FILE);
          }}
          />
      )
    })

    if (datasetLinks.length === 0) {
      datasetLinks = <tr><td colSpan={2}>No available links.</td></tr>;
    }

    return (
      <React.Fragment>
        {this.addLinkModal()}
        <Row className="mt-3 mb-3">
          <Col xs={6} sm={6} md={6} lg={6} xl={6}><h5>External Links</h5></Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            {addDatasetLink}
          </Col>
        </Row>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Last Modified</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {datasetLinks}
          </tbody>
        </Table>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  general: state.general, 
  datasets: state.datasets
})

const mapDispatchToProps = (dispatch) => {
  return {
    checkBoxDownloadChange: (...args) => dispatch(checkBoxDownloadChange(...args)),
    setLinkFieldChange: (...args) => dispatch(setLinkFieldChange(...args)),
    addLinkToDataset: (...args) => dispatch(addLinkToDataset(...args)),
    editLinkToDataset: (...args) => dispatch(editLinkToDataset(...args)),
    updateDatasetFile: (...args) => dispatch(updateDatasetFile(...args)),
    toggleShowPrompt: (...args) => dispatch(toggleShowPrompt(...args)),
    setAttachment: (...args) => dispatch(setAttachment(...args)),
    toggleAddExternalLinkForm: (...args) => dispatch(toggleAddExternalLinkForm(...args)),
    setFormValidated: (...args) => dispatch(setFormValidated(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalLinks)
