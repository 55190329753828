import React, { Component } from 'react';
import { Table, Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getUserLogs } from '../../store/actions/generalActions';
import * as utils from '../../common/Utilities';
import { Pagination } from '../../common/Utilities';

class ViewLogs extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  renderLogs = (logs) => {
    if (logs === null) {
      return <p>Loading...</p>
    }

    const logsList = logs.length > 0 ? (
      logs.map((log, key) => {
        if (log) {
          let details = '';
          switch (log.action) {
            case utils.LOG_USER_LOGIN:
            case utils.LOG_USER_LOGOUT:
              if (typeof(log.data) !== "undefined") {
                details = <React.Fragment>
                          <span className="small">IP Address: <span className="text-muted">{log.data.x_ip_address}</span></span><br />
                        </React.Fragment>
              }
              break;
            case utils.LOGS_USER_TRASH:
            case utils.LOGS_USER_DELETE:
              if (typeof (log.data.data) !== "undefined") {
                let org = '';
                if (log.data.data.organization !== null) {
                  org = log.data.data.organization.name;
                }
                details = <React.Fragment>
                          <span className="small">User: <span className="text-muted">{log.data.data.first_name + " " + log.data.data.last_name}</span></span><br />
                          <span className="small">Email: <span className="text-muted">{log.data.data.email}</span></span><br />
                          <span className="small">Organization: <span className="text-muted">{org}</span></span><br />
                          <span className="small">Role: <span className="text-muted">{log.data.data.role_name}</span></span><br />
                        </React.Fragment>
              }
              break;
            case utils.LOGS_USER_CREATE:
            case utils.LOGS_USER_UPDATE:
            case utils.LOGS_USER_CONFIRM:
              if (typeof (log.data) !== "undefined") {
                let organization;
                if (typeof(log.data.organization) !== "undefined") {
                  organization = log.data.organization.name;
                }
                details = <React.Fragment>
                          <span className="small">User: <span className="text-muted">{log.data.first_name + " " + log.data.last_name}</span></span><br />
                          <span className="small">Email: <span className="text-muted">{log.data.email}</span></span><br />
                          <span className="small">Organization: <span className="text-muted">{organization}</span></span><br />
                          <span className="small">Role: <span className="text-muted">{log.data.role_name}</span></span><br />
                        </React.Fragment>
              }
              break;
            case utils.LOGS_USER_UPDATE_PHOTO:
              if (typeof (log.user) !== "undefined") {
                details = <React.Fragment>
                          <span className="small">User: <span className="text-muted">{log.user.first_name + " " + log.user.last_name}</span></span><br />
                          <span className="small">Email: <span className="text-muted">{log.user.email}</span></span><br />
                          <span className="small">Role: <span className="text-muted">{utils.getRoleName(log.user.role)}</span></span><br />
                        </React.Fragment>
              }
              break;
            case utils.LOGS_USER_UPDATE_BYSUB:
            case utils.LOGS_USER_UPDATE_BY_SUB:
              if (typeof (log.user) !== "undefined") {
                details = <React.Fragment>
                          <span className="small">First Name: <span className="text-muted">{log.user.first_name}</span></span><br />
                          <span className="small">Middle Name: <span className="text-muted">{log.user.middle_name}</span></span><br />
                          <span className="small">Last Name: <span className="text-muted">{log.user.last_name}</span></span><br />
                          <span className="small">Email: <span className="text-muted">{log.user.email}</span></span><br />
                        </React.Fragment>
              }
              break;
            case utils.LOGS_ORG_CREATE:
            case utils.LOGS_ORG_UPDATE:
            case utils.LOGS_ORG_DELETE:
            case utils.LOGS_ORG_TRASH:
            case utils.LOGS_ORG_RESTORE:
              if (typeof (log.data) !== "undefined") {
                let org = '';
                let cognito_idp = '';
                if (typeof (log.data.data) !== "undefined") {
                  org = log.data.data.name;
                  cognito_idp = log.data.data.cognito_idp;
                }
                else {
                  org = log.data.name;
                  cognito_idp = log.data.cognito_idp;
                }
                details = <React.Fragment>
                            <span className="small">Organization: <span className="text-muted">{org}</span></span><br />
                            <span className="small">Identity Provider: <span className="text-muted">{cognito_idp}</span></span><br />
                          </React.Fragment>
              }
              break;
            case utils.LOGS_GROUP_CREATE:
            case utils.LOGS_GROUP_UPDATE:
            case utils.LOGS_GROUP_DELETE:
            case utils.LOGS_GROUP_TRASH:
              if (typeof (log.data) !== "undefined") {
                let org = '';
                let groupName = '';
                if (typeof (log.data.organization) !== "undefined") {
                  if (log.data.organization) {
                    org = log.data.organization.name;
                  }
                }
                else {
                  org = log.organization.name;
                }

                if (typeof (log.data.data) !== "undefined")  {
                  groupName = log.data.data.name;
                }
                else {
                  groupName = log.data.name;
                }

                details = <React.Fragment>
                            <span className="small">Group: <span className="text-muted">{groupName}</span></span><br />
                            <span className="small">Organization: <span className="text-muted">{org}</span></span><br />
                          </React.Fragment>
              }
              break;
            case utils.LOGS_GROUP_MEMBER_CREATE:
            case utils.LOGS_GROUP_MEMBER_ASSIGN:
            case utils.LOGS_GROUP_MEMBER_REMOVE:
            case utils.LOGS_GROUP_MEMBER_UPDATE:
            case utils.LOGS_GROUP_MEMBER_LEAVE:
              let name = '';
              let roleName = '';
              let groupName = '';
              let orgName = '';
              if (typeof (log.data) !== "undefined") {
                if (typeof (log.data.data) !== "undefined") {
                  const data = log.data.data;
                  if (data.user) {
                    name = data.user.first_name + " " + data.user.last_name;
                  }
                  
                  roleName = data.role_name;

                  if (data.group) {
                    groupName = data.group.name;
                  }
                  
                  if (data.organization) {
                    orgName = data.organization.name;
                  }
                  
                }
                else {
                  name = log.data.user.first_name + " " + log.data.user.last_name;
                  roleName = log.data.role_name;
                  groupName = log.data.group.name;
                  orgName = log.data.organization.name;
                }
              }
              details = <React.Fragment>
                            <span className="small">Member: <span className="text-muted">{name}</span></span><br />
                            <span className="small">Role: <span className="text-muted">{roleName}</span></span><br />
                            <span className="small">Group: <span className="text-muted">{groupName}</span></span><br />
                            <span className="small">Organization: <span className="text-muted">{orgName}</span></span><br />
                          </React.Fragment>
              break;
          }

          return (
            <tr key={key}>
              <td width="20%">{log.created_at}</td>
              <td width="45%" className="align-center">{utils.getLogActionName(log.action)}</td>
              <td width="35%">{details}</td>
              {/* <td>{}</td>
              <td>{email}</td>
              <td>{role}</td>
              <td>{orgName}</td>
              <td>{group}</td> */}
            </tr>
          )
        }
        else {
          return '';
        }
      })
    ) : (
      <tr><td>No user logs.</td></tr>
    )
    
    const user = this.props.users.singleUser;

    let organization = '';
    if (typeof(user.organization) !== "undefined") {
      const org = user.organization;
      if (org) {
        organization = org.name;
      }
    }
    return (
      <div>
        <Container fluid>
          <Row className="mb-5">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h3>{user.first_name + " " + user.last_name}</h3>
              <span className="text-muted small">{user.email}</span><br />
              <span className="text-muted small">{organization}</span>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="float-right">
                <Pagination totalitems={this.props.general.userLogsTotalItems}  
                  limit={this.props.general.userLogsLimit}
                  callback={(page) => {
                      this.props.getUserLogs(user.id, page, 10, true);
                  }} />
              </div>
            </Col>
          </Row>
          <Table>
            <thead>
              <tr>
                <th>Transaction Date/Time</th>
                <th className="align-center">Action</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {logsList}
            </tbody>
          </Table>
        </Container>
      </div>
    )
  }

  render() {
    console.log(this.props);
    const logs = this.props.general.userLogs;
    return(
      <div>
        {this.renderLogs(logs)}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  general: state.general, 
  users: state.users
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUserLogs: (...args) => dispatch(getUserLogs(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewLogs)
