import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTokens, getIdToken } from '../../store/helpers/tokens' ;
import { Auth, Cache  } from 'aws-amplify';
import { WEB_APP_URL } from '../../app/config';
import { confirmUser, setUserInfo } from '../../store/actions/generalActions';
import { transactionLog, ACTION_LOG_LOGIN } from '../../common/LogsUtils';
import { Loader } from '../../common/Utilities';

class Authenticator extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        Cache.setItem('LOGIN_RETRY',0); 
        Cache.setItem('LOGGED_IN', false);
        if(!this.getCode() && !this.props.getIdToken()){
            this.props.history.push('/login');
        }
    }

    getInvitationId(){
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('invitation_id');
    }
    getCode(){
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('code');
    }
    
    
    isLogout(){

        var IS_LOGOUT = Cache.getItem('IS_LOGOUT');
        if(IS_LOGOUT){
            Cache.removeItem('IS_LOGOUT');
            this.props.history.push('/login?message=You have been logged out.');
            return true;
        }
        return false;
    }
    
    isConfirmUser(){

        var invitation_id    = Cache.getItem('INVITATION_ID');
        var for_confirmation = Cache.getItem('FOR_CONFIRMATION');

        if(for_confirmation && invitation_id){
            this.props.confirmUser(invitation_id,()=>{
                Cache.removeItem('INVITATION_ID');
                Cache.removeItem('FOR_CONFIRMATION');
                this.props.setUserInfo();
                const idToken = Cache.getItem('idToken');
                setTimeout({
                    if (idToken) {
                        this.props.history.push('/main/datacatalog');
                    }
                }, 3000)
                
            });
            return true;
        }
        return false;
    }

    isAuthenticated(){
        var invitation_id = this.getInvitationId();

        if(this.isLogout()){
            return '';
        }
        if(invitation_id){
            window.location = WEB_APP_URL+'#/users/confirm?invitation_id='+invitation_id;
            return '';
        }
        var cache_retry = Cache.getItem('LOGIN_RETRY');
        if(cache_retry == null){
            cache_retry = 0;
        }
        else{
            cache_retry = cache_retry+1;
        }
        Auth.currentSession().then((item)=>{
            if(item.isValid()){
                Cache.removeItem('LOGIN_RETRY'); 
                this.props.setTokens(item);        
                
                this.props.setUserInfo(() => { 
                    //To avoid multiple login logging
                    const loggedIn = Cache.getItem('LOGGED_IN');
                    if (!loggedIn) {
                        transactionLog(ACTION_LOG_LOGIN);
                    }
                    Cache.setItem('LOGGED_IN', true);
                    this.props.history.push('/main/datacatalog')                  
                },()=>{
                    if(!this.isConfirmUser()){    
                        alert("You are not allowed to access this system.");
                        this.props.history.push('/login');                
                    }
                    
                });
            }
            else{            
                this.props.history.push('/login?error=Session expired.');
            }

        }).catch((reason)=>{            
            if(cache_retry > 30) {
                Cache.removeItem('LOGIN_RETRY'); 
                this.props.history.push('/login?error='+reason);
            }
            else{
                Cache.setItem('LOGIN_RETRY',cache_retry); 
                setTimeout(()=> { this.isAuthenticated() },1000);
            }
        });
    }

    render() {
        this.isAuthenticated()
        return (
            <Loader />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        general: state.general
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setTokens: (...args) => setTokens(...args),
        setUserInfo: (...args) => dispatch(setUserInfo(...args)),
        getIdToken: (...args) => getIdToken(...args),
        confirmUser: (...args) => dispatch(confirmUser(...args))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Authenticator)