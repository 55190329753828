const initState = {
    users: [],
    singleUser: {},
    searchByText: '', 
    searchByOrg: [],
    searchByRole: [],
    error: null,
    isUserExists: false, 
    isPaginating: false, 
    roles: [],
    filters: {},
    usersCurrentPage: null, 
    usersTotalItems: null, 
    usersLimit: null, 
    sortValues: {
        role: "",
        org_id: "", 
        first_name: "asc",
    }
}

const usersReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_USERS_BEGIN':
            return {
                ...state, 
                users: null,
                error: null
            }
        case 'FETCH_USERS_SUCCESS':
            return {
                ...state, 
                users: action.users
            }
        case 'FETCH_USERS_ERROR':
            return {
                ...state, 
                users: null,
                error: action.error
            }
        case 'SET_IS_PAGINATING':
            return {
                ...state, 
                isPaginating: action.isPaginating,
            }
        case 'GET_USER':
            return {
                ...state, 
                singleUser: Object.assign(state.singleUser, action.user)
            }
        case 'SELECT_USER':
            if (Object.keys(action.user).length > 0) {
                return {
                    ...state, 
                    singleUser: Object.assign(state.singleUser, action.user)
                }
            }
            else {
                return {
                    ...state, 
                    singleUser: {}
                }
            }
        case 'DELETE_USER':
            return {
                ...state, 
                users: action.users
            }
        case 'TRASH_USER':
            return {
                ...state, 
                users: action.users
            }
        case 'SEARCH_USER':
            return {
                ...state, 
                searchByText: action.keyword
            }
        case 'SEARCH_USER_BY_ORG':
            return {
                ...state,
                searchByOrg: action.organization
            }
        case 'SEARCH_USER_BY_ROLE':
            return {
                ...state,
                searchByRole: action.role
            }
        case 'USERS_SET_FIELD_ON_CHANGE': 
            return {
                ...state,
                singleUser: Object.assign(state.singleUser, action.value)
            }
        case 'TOGGLE_SHOW_PROMPT':
            return {
                ...state, 
                showPrompt: action.show,
                actionType: action.actionType
            }
        case 'SET_IS_USER_EXISTS':
            return {
                ...state, 
                isUserExists: action.exists
            }
        case 'SET_AVAILABLE_ROLES':
            return {
                ...state, 
                roles: action.availableRoles
            }    
        case 'USERS_SET_SORT_VALUES':
            return {
                ...state, 
                sortValues: action.sortValues
            }
        case 'USERS_SET_PAGINATION_DETAILS':
            return {
                ...state, 
                usersCurrentPage: action.page, 
                usersTotalItems: action.items,
                usersLimit: action.limit
            }
        case 'CLEAR_USER_SEARCH':
            return {
                ...state,
                searchByText: '',
                searchByOrg: [],
                searchByRole: [], 
            }
        default:
            return state;
    }
}

export default usersReducer

