import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Cache } from 'aws-amplify';
import { Link, withRouter } from 'react-router-dom';
import { Container, Table, Spinner, Row, Col, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import "@pathofdev/react-tag-input/build/index.css";
import { setFieldValueChange, 
    setTags, 
    createDataset, 
    fetchDatasets,
    getTotalTrashedDatasets,  
    getTotalActiveDatasets, 
    setDataset, 
    setupFilter, 
    resetDataset } from '../../store/actions/main/datasetsActions';
import { fetchQualities } from '../../store/actions/main/qualityActions';
import {setFormValidated } from '../../store/actions/generalActions';
import { showManageAccessForm, resetAllPermissions, checkPermissions } from '../../store/actions/main/datasetPermissionsActions';
import { fetchRoles } from '../../store/actions/settings/usersActions';
import { Pagination, DisplayMoment, DisplayAvatar } from '../../common/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faShieldAlt, faTag, faSitemap, faLock, faClock } from '@fortawesome/free-solid-svg-icons';
import * as config from '../../app/config';

import ManageAccessForm from './Datasets/ManageAccessForm';
import DatasetFilter from './Datasets/DatasetFilter';
import DatasetSort from './Datasets/DatasetSort';

class DataCatalog extends Component {

    constructor(props) {
        super(props);
        this.orgId = 0;
        this.groupId = 0;
    }
    componentDidMount() {
        const isLoggedIn = Cache.getItem('LOGGED_IN');
        if (isLoggedIn) {
            window.scrollTo(0, 0);
            let filter = {};
            if (typeof(this.props.datasets.datasetFilter.tag) !== "undefined") {
                filter = {
                    tags: this.props.datasets.datasetFilter.tag
                }
            }

            const urlParams = new URLSearchParams(this.props.location.search);
            this.orgId =  urlParams.get('org');
            this.groupId =  urlParams.get('group');

            if (this.orgId) {
                filter = {
                    org_id: [this.orgId]
                }
            }

            if (this.groupId) {
                filter =  {
                    group_id: [this.groupId]
                }
            }
            
            this.props.fetchDatasets(filter, null, {created_at: "desc"});
            this.props.getTotalActiveDatasets();
            this.props.getTotalTrashedDatasets();
            this.props.fetchQualities();
        }
        else {
            this.props.history.push('/login');
        }   
    }

    componentWillUnmount() {
        this.props.setupFilter(null, null, true) //clear filters
    }

    showTags = (tags, id) => {
        let tagsBadges;
        if (tags.length === 1 && tags[0] === "") {
            return '';
        }
        if (tags) {
            let comma = ", ";

            tagsBadges = tags.length > 0 && tags.map((tag, key) => {
                if (key === 3) {
                    return <Link to="#" key={key} className="small" onClick={(e) => {
                        e.preventDefault();
                        this.props.setupFilter(e, null, true) //clear filters
                        this.props.resetDataset();
                        this.props.resetAllPermissions();
                        this.props.history.push('/dataset?id=' + id);
                    }}>and more..</Link>
                }

                if ((tags.length - 1) === key) {
                    comma = "";
                }

                return <React.Fragment key={key}>
                            <Link to="#"className="small" onClick={(e) => {
                                e.preventDefault();
                                const filter = {
                                    tags: tag
                                }
                                window.scrollTo(0, 0);
                                this.props.fetchDatasets(filter, null, {created_at: "desc"})
                            }}>{tag}</Link>{comma}
                        </React.Fragment>
            })
        }

        return (
            <div className="mt-1">
                <span className="small text-primary">
                    <b><FontAwesomeIcon icon={faTag} />Tags:</b>
                </span>&nbsp;
                {tagsBadges}
            </div>
        )
    }

    renderDataCatalog = (datasets) => {
        if (datasets === null && !this.props.datasets.isPaginating) {
            return <p>Loading...</p>
        }

        let datasetsList;
        if (datasets !== null) {
            datasetsList = datasets.length > 0 ? (
                datasets.map((dataset, key) => {
                    let id = '';
                    let name = '';
                    let tags = '';
                    
                    let org = '';
                    let ownerName = '';
                    let ownerPhoto = '';
                    let visibility = '';
                    let quality = '';
                    let datasetOption = '';

                    if (dataset) {
                        id = dataset.id;
                        name = dataset.name;
                        tags = dataset.tags;
                        visibility = dataset.visibility_label;
                        quality = dataset.quality;
                        ownerName = '';

                        if (typeof(dataset.organization) !== "undefined") {
                            const organization = dataset.organization;
                            if (organization) {
                                org = organization.name;
                            }
                        }

                        if(typeof(dataset.owner) !== "undefined") {
                            ownerName = dataset.owner.first_name + " " + dataset.owner.last_name;
                            ownerPhoto = dataset.owner.photo_url_signed;
                        }

                        let role = Cache.getItem('role');
                        let orgId = Cache.getItem('orgId');
                        let hasPermission = false;
                        if (role === config.ROLE_PLATFORM_ADMIN || 
                                (role === config.ROLE_ORG_ADMIN && orgId === dataset.org_id)) {
                            hasPermission = true;
                            
                        }
                        else {
                            let access = checkPermissions(dataset, role);
                            hasPermission = access.hasPermission;
                            const accessRole = access.role;
                            role = accessRole; 
                        }
                        
                        if (hasPermission) {
                            switch (role) {
                                case config.ROLE_PLATFORM_ADMIN:
                                case config.ROLE_ORG_ADMIN:
                                case config.ROLE_CURATOR:
                                    datasetOption = <Dropdown drop={"left"}>
                                        <Dropdown.Toggle variant="outline" id="ada-button-options">
                                            <FontAwesomeIcon icon={faEllipsisH} />
                                        </Dropdown.Toggle>                    
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => {
                                                this.props.fetchRoles("group");
                                                let singleDataset = {}
                                                singleDataset = Object.assign(singleDataset, dataset);
                                                this.props.setDataset(singleDataset);
                                                this.props.showManageAccessForm(true, dataset.id);
                                                this.props.resetAllPermissions();
                                            }}>
                                                <FontAwesomeIcon icon={faShieldAlt} /> Manage Access
                                            </Dropdown.Item>
                                            {/* <Dropdown.Item onClick={
                                                () => { 
    
                                                }
                                            }>
                                                <FontAwesomeIcon icon={faTrash} /> Delete Dataset
                                            </Dropdown.Item> */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    break;
                                default:
                                    datasetOption = '';
                                    break;
                            }
                        }
                        
                    }
                    return (
                        <tr key={key}>
                            <td width="10%" className="align-center">
                                <OverlayTrigger
                                    key="top"
                                    placement="top"
                                    overlay={
                                    <Tooltip id="tooltip-top">
                                        {quality.description}
                                    </Tooltip>
                                    }
                                >
                                    <img src={quality.icon_url} id="ada-dataset-quality-icon" />
                                </OverlayTrigger>
                            </td>

                            <td width="80%" className="align-left">
                                <a id="ada-dataset-name" onClick={(e) => {
                                    e.preventDefault();
                                    this.props.setupFilter(e, null, true) //clear filters
                                    this.props.resetDataset();
                                    this.props.resetAllPermissions();
                                    this.props.history.push('/dataset?id=' + id);
                                }}><span>{name}</span></a>
                                <div className="small mt-1">
                                    <span className="mr-3">
                                        <DisplayAvatar photo={ownerPhoto} displayName={ownerName} size="sm" /> {ownerName}
                                    </span> |
                                    <span className="ml-3 mr-3">
                                        <FontAwesomeIcon icon={faSitemap} /> {org}
                                    </span> |
                                    <span className="ml-3 mr-3">
                                        <FontAwesomeIcon icon={faLock} /> {visibility}
                                    </span> |
                                    <span className="ml-3 mr-3">
                                        <FontAwesomeIcon icon={faClock} /> <DisplayMoment dataTime={dataset.created_at} />
                                    </span>
                                </div>
                                {this.showTags(tags, id)}
                            </td>
                            <td width="10%">
                                <span className="float-right">
                                    {datasetOption}
                                </span>
                            </td>
                        </tr>
                    )
                })
            ) : (
                <tr><td colSpan={7}>No datasets found.</td></tr>
            )
        }
        else {
            datasetsList = <tr><td colSpan={7} className="align-center"><Spinner animation="grow" variant="primary" /></td></tr>
        }
        return (
            <Container fluid>
                <h3>Data Catalog</h3>
                <DatasetFilter />
                <Row className="mt-3 mb-1">
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <div className="float-left">
                            <Link to="#" onClick={() => {
                                this.props.history.push('/main/datacatalog');
                            }}>All ({this.props.datasets.totalItems})</Link> |  &nbsp;

                            <Link to="#" onClick={() => {
                                this.props.history.push('/main/datacatalog/trash');
                            }}>Trash ({this.props.datasets.totalTrashDatasets})</Link>
                        </div> 
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                        <DatasetSort />
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                        <div className="float-right">
                            <Pagination totalitems={this.props.datasets.totalItems}  
                                limit={this.props.datasets.limit}
                                callback={(page) => {
                                    let filter = {}
                                    if (typeof(this.props.datasets.datasetFilter.org_id) !== "undefined") {
                                        filter = {
                                            org_id: [this.props.datasets.datasetFilter.org_id]
                                        }
                                    }

                                    if (typeof(this.props.datasets.datasetFilter.group_id) !== "undefined") {
                                        const groupId = this.props.datasets.datasetFilter.group_id;
                                        if (groupId > 0) {
                                            filter = {
                                                group_id: [this.props.datasets.datasetFilter.group_id]
                                            }
                                        }
                                    }

                                    if (typeof(this.props.datasets.datasetFilter.quality_id) !== "undefined") {
                                        Object.assign(filter, {
                                            quality_id: [this.props.datasets.datasetFilter.quality_id]
                                        })
                                    }
                                    this.props.fetchDatasets(filter, page)
                                }} 
                                currentPage={this.props.datasets.currentPage} />
                        </div>
                    </Col>
                </Row>
                <Table id="ada-data-catalog">
                    <tbody>
                        {datasetsList}
                    </tbody>
                </Table>
            </Container>
        )
    }

    render() {
        const datasets = this.props.datasets.datasets;
        return (
            <div>
                <ManageAccessForm />
                {this.renderDataCatalog(datasets)}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    general: state.general,
    datasets: state.datasets,
    organizations: state.organizations,
    groups: state.groups
})

const mapDispatchToProps = (dispatch) => {
    return {
        setFieldValueChange: (...args) => dispatch(setFieldValueChange(...args)),
        setTags: (...args) => dispatch(setTags(...args)),
        setFormValidated: (...args) => dispatch(setFormValidated(...args)),
        createDataset: () => dispatch(createDataset()),
        fetchDatasets: (...args) => dispatch(fetchDatasets(...args)),
        resetDataset: () => dispatch(resetDataset()),
        fetchQualities: () => dispatch(fetchQualities()),
        getTotalTrashedDatasets: () => dispatch(getTotalTrashedDatasets()),
        getTotalActiveDatasets: () => dispatch(getTotalActiveDatasets()),
        showManageAccessForm: (...args) => dispatch(showManageAccessForm(...args)),
        fetchRoles: (...args) => dispatch(fetchRoles(...args)),
        setDataset: (...args) => dispatch(setDataset(...args)),
        resetAllPermissions: () => dispatch(resetAllPermissions()),
        setupFilter: (...args) => dispatch(setupFilter(...args))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataCatalog))
