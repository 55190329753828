import axios from 'axios';
import { fetchDatasetDD } from './datasetsActions';
import * as apiDataDictionaryUtils from '../../../common/DataDictionaryAPIUtils';
import { getIdToken } from '../../helpers/tokens';
import { toggleShowAlert, 
  successAPIResDisplay,
  ERROR_ALERT } from '../generalActions';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SEARCH_DATA_DICTIONARY = 'SEARCH_DATA_DICTIONARY';

export const search = () => {
  return (dispatch, getState) => {
    let filters = {
      dataset_id: [getState().datasets.singleDataset.id]
    }
    
    if (getState().datasets.searchDataDictionary) {
        Object.assign(filters, {
            search: getState().datasets.searchDataDictionary
        })
    }
    const page = 1;
    dispatch(fetchDatasetDD(filters, page, null, true));
  }
}

export const searchDataDictionary = (keyword, clear = null) => {
  if (clear) {
      keyword = "";
  }
  return (dispatch) => {
      dispatch({type: SEARCH_DATA_DICTIONARY, keyword})
      dispatch(search());
  }
}

export const uploadDataDictionary = (e, dataset) => {
  console.log(e.target.files, dataset);
  return (dispatch) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      let formData = new FormData();
      formData.append('file', file);
      formData.append('dataset_id', dataset.id);
  
      axios.post(apiDataDictionaryUtils.CREATE_DATA_DICTIONARY_BULK_ENDPOINT, formData, {
        headers: {
          "id-token": getIdToken(),
          'Content-Type': 'multipart/form-data'
        }
      }).then(json => {
        const result = json.data;
        if (result) {
          const customMessage = "Data Dictionary has been successfully created.";
          dispatch(successAPIResDisplay(json, customMessage));
          const filter = {
            dataset_id: [dataset.id]
          }
          dispatch(fetchDatasetDD(filter, null, null, true)) //reload data dictionary table
        }
      }).catch(error => {
        console.log(error);
        let errorMessage = '';
        if (typeof(error.reponse) !== "undefined") {
          let response = error.response;
          if (typeof(response.data) !== "undefined") {
            errorMessage = error.response.data.message;
          }
          else {
            errorMessage = "An error occured while uploading data dictionary.";
          }
          dispatch(toggleShowAlert(true, ERROR_ALERT, response.message));
        }
     })
    }
  }
}