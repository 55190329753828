import axios from 'axios';
import * as apiUtils from './UsersAPIUtils';
import { getIdToken } from '../store/helpers/tokens';

export const ACTION_LOG_LOGIN = 'ACTION_LOG_LOGIN';
export const ACTION_LOG_LOGOUT = 'ACTION_LOG_LOGOUT';

export const transactionLog = (actionLog = null, itemId = null) => {
  if (actionLog !== null) {
      let endpoint = '';
      
      switch (actionLog) {
          case ACTION_LOG_LOGIN:
              endpoint = apiUtils.USER_LOG_LOGIN;
              break;
          case ACTION_LOG_LOGOUT:
              endpoint = apiUtils.USER_LOG_LOGOUT;
              break;
      }   

      if (endpoint !== ''){
          axios.post(endpoint, [], {
              headers: {
                  "id-token": getIdToken()
              },
          }).then(json => {
              console.log(json);
          }).catch(error => {
              console.log(error);
          })
      }
  }
}

