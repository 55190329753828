import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isWebUri } from 'valid-url';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import { setFieldValueChange,
  resetDataset, 
  setLinkFieldChange, 
  addLinkToDataset, 
  decrementAttachmentForm, 
  handleAttachmentLinkChange, 
  removeFileToUpload, 
  changeAttachmentType, 
  removeDatasetLinkItem } from '../../../store/actions/main/datasetsActions';
import { toggleShowAlert, ERROR_ALERT } from '../../../store/actions/generalActions';

class DatasetAttachment extends Component {

  render() {
    console.log(this.props);
    const attachmentId = this.props.attachmentForm.id;
    const attachmentType = this.props.attachmentForm.attachmentType;

    if (!this.props.datasets.isLoading) {
      const loader = document.getElementById('dataset-attachment-loader'+attachmentId);
      if (loader) {
        loader.style.display = "none";
      }
    }
    let uploadFile = '';
    const uploadDatasets = this.props.datasets.uploadDatasets;
    uploadDatasets.map(dataset => {
      if (dataset.attachmentId === attachmentId) {
        uploadFile = dataset.fileId[0].name;
      }
    })

    let linkFileName = '';
    let fileLink = '';
    if (attachmentType === "link") {
      if (typeof(this.props.attachmentForm.fileName) !== "undefined") {
        linkFileName = this.props.attachmentForm.fileName;
      }

      if (typeof(this.props.attachmentForm.fileLink) !== "undefined") {
        fileLink = this.props.attachmentForm.fileLink;
      }
    } 

    let fileInput = (
      <div id={"datasetUpload" + attachmentId}>
        <Form.Group controlId={"dataset" + attachmentId}>
            <Form.Label>File</Form.Label>
            <Form.File>
                <Form.File.Input onChange={(e) => {
                  handleChange(e)
                  document.getElementById('dataset-attachment-loader'+attachmentId).style.display = "block";
                  document.getElementById('dataset'+attachmentId).style.width = "90px";
                  
                }} />
            </Form.File> 
            <span id={"dataset-attachment-loader" + attachmentId} className="attachment-loader">
              <Spinner animation="border" size="sm" />
            </span>
        </Form.Group>  
        {uploadFile}
      </div>
    )

    let fileNameInput = (
      <div id={"addLink" + attachmentId}>
        <Form.Group controlId="fileName" >
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="" value={linkFileName} className="mb-3" onChange={(e) => {
                  this.props.handleAttachmentLinkChange(e, attachmentId)
                }} />
        </Form.Group>   
      </div>
    )

    let urlInput = (
      <div id={"addLinkURL" + attachmentId}>
          <Form.Group controlId="fileLink" >
              <Form.Label>URL</Form.Label>
              <Form.Control type="text" placeholder="" value={fileLink} autoComplete="off" className="mb-3" onChange={(e) => {
                  this.props.handleAttachmentLinkChange(e, attachmentId)
                }} 
                onBlur={(e) => {
                  if (e.target.value) {
                    if (!isWebUri(e.target.value)) {
                      const message = "Invalid URL";
                      this.props.toggleShowAlert(true, ERROR_ALERT, message);
                    }
                  }
                }}  />
          </Form.Group>   
      </div>
    )

    if (attachmentType === "file") {
      urlInput = '';
      fileNameInput = '';
    } 
    else {
      fileInput = '';      
    }

    const action = this.props.general.actionType;
    
    const handleChange = (e) => this.props.setFieldValueChange(e, action, attachmentId);

    return (
      <Container id={"attachment-form"+attachmentId} className="attachment-form">
        <Row>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <Form.Group>
                <Form.Label>Attachment Type</Form.Label>
                <Form.Control 
                    as="select" 
                    value={attachmentType}
                    id={"attachmentType" + attachmentId} 
                    onChange={() => {
                      const type = document.getElementById('attachmentType' + attachmentId).value;
                      this.props.changeAttachmentType(attachmentId, type);
                    }}>
                    <option value="file">File</option>
                    <option value="link">Link</option>
                </Form.Control>
            </Form.Group>  
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            {fileInput}
            {fileNameInput}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {urlInput}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Link to="#" className="text-danger" onClick={(e) => {
              e.preventDefault(); 
              this.props.decrementAttachmentForm(attachmentId);
              this.props.removeFileToUpload(attachmentId);
            }}>Remove</Link>
          </Col>
        </Row>
      </Container>
    )
    
  }
}

const mapStateToProps = (state) => ({
  general: state.general, 
  organizations: state.organizations,
  datasets: state.datasets
})

const mapDispatchToProps = (dispatch) => {
  return {
      setFieldValueChange: (...args) => dispatch(setFieldValueChange(...args)),
      resetDataset: () => dispatch(resetDataset()),
      setLinkFieldChange: (...args) => dispatch(setLinkFieldChange(...args)),
      addLinkToDataset: (...args) => dispatch(addLinkToDataset(...args)),
      removeDatasetLinkItem: (...args) => dispatch(removeDatasetLinkItem(...args)),
      decrementAttachmentForm: (...args) => dispatch(decrementAttachmentForm(...args)),
      removeFileToUpload: (...args) => dispatch(removeFileToUpload(...args)),
      changeAttachmentType: (...args) => dispatch(changeAttachmentType(...args)),
      handleAttachmentLinkChange: (...args) => dispatch(handleAttachmentLinkChange(...args)),
      toggleShowAlert: (...args) => dispatch(toggleShowAlert(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetAttachment)