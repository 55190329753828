import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSitemap, faLock, faTag, faUser, faClock } from '@fortawesome/free-solid-svg-icons';
import { searchDataset, search } from '../../../store/actions/main/datasetsActions';
import { DisplayMoment } from '../../../common/Utilities';

class DatasetCard extends Component {

  showTags = (tags, id) => {
    let tagsBadges;
    if (tags.length === 1 && tags[0] === "") {
        return '';
    }
    if (tags) {
        let comma = ", ";

        tagsBadges = tags.length > 0 && tags.map((tag, key) => {
            if (key === 3) {
                return <Link to="#" key={key} className="small" onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push('/dataset?id=' + id);
                }}>and more..</Link>
            }

            if ((tags.length - 1) === key) {
                comma = "";
            }

            return <React.Fragment key={key}>
                        <Link to="#" className="small" onClick={(e) => {
                          e.preventDefault(); 
                          e.stopPropagation();       
                          this.props.searchDataset(tag);
                          this.props.search(null, null, true);
                          this.props.history.push('/dataset/search')
                        }}>{tag}</Link>{comma}
                    </React.Fragment>
        })
    }

    return (
        <div className="mt-1">
            <span className="small text-primary">
                <b><FontAwesomeIcon icon={faTag} />Tags:</b>
            </span>&nbsp;
            {tagsBadges}
        </div>
    )
  }

  render() {
    return (
      <Card className="ada-dataset-search-result" onClick={(e) => {
        e.preventDefault();
        this.props.history.push('/dataset?id='+this.props.datasetId+'&q='+this.props.q);
      }}>
        <Card.Body>
          <Row>
            <Col xs={0.7} sm={0.7} md={0.7} lg={0.7} xl={0.7}>
              <div className="align-center">
                <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                    <Tooltip id="tooltip-top">
                        {this.props.qualityDesc}
                    </Tooltip>
                    }
                >
                  <img alt="quality-icon" src={this.props.quality} id="ada-dataset-quality-icon" />
                </OverlayTrigger>
              </div>
              
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <div>
                <b>{this.props.datasetName}</b>
              </div>
              <div className="small mt-1">
                <span className="mr-3">
                  <FontAwesomeIcon icon={faUser} /> {this.props.ownerName}
                </span> |
                <span className="ml-3 mr-3">
                    <FontAwesomeIcon icon={faSitemap} /> {this.props.orgName}
                </span> |
                <span className="ml-3 mr-3">
                    <FontAwesomeIcon icon={faLock} /> {this.props.visibility}
                </span> | 
                <span className="ml-3 mr-3">
                    <FontAwesomeIcon icon={faClock} /> <DisplayMoment dataTime={this.props.createdAt} />
                </span>
              </div>
              <div className="mt-1">
                {this.showTags(this.props.tags, this.props.datasetId)}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
     
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    search: (...args) => dispatch(search(...args)), 
    searchDataset: (...args) => dispatch(searchDataset(...args))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(DatasetCard))
