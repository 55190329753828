import React, { Component } from 'react';
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PERMISSION_TYPE_USER, PERMISSION_TYPE_GROUP } from '../../../store/actions/main/datasetPermissionsActions';
import { getRoleName } from '../../../common/Utilities';
class DisplayUserAccess extends Component {
  render() {
    const permission = this.props.permission;

    let accessText = '';
    let roleName = getRoleName(this.props.role);
    if (!roleName) {
      roleName = '';
    }
    switch (permission.type) {
      case PERMISSION_TYPE_USER:
        accessText = roleName + " Access";
        break;
      case PERMISSION_TYPE_GROUP:
        accessText = roleName + " Access from " + permission.group.name;
        break;
    }
    return (
      <div>
        <FontAwesomeIcon icon={faKey} /> {accessText}
      </div>
    )
  }
}

export default DisplayUserAccess
