import * as config from '../app/config';

const MODULE = config.API_GATEWAY_URL + 'datasets/attachments/';

export const LIST_ATTACHMENTS_ENDPOINT = MODULE + "list";
export const GET_ATTACHMENTS_ENDPOINT = MODULE + "get";
export const CREATE_ATTACHMENTS_ENDPOINT = MODULE + "create";
export const UPDATE_ATTACHMENTS_ENDPOINT = MODULE + "update";
export const TRASH_ATTACHMENTS_ENDPOINT = MODULE + "trash";
export const DOWNLOAD_ATTACHMENTS_ENDPOINT = MODULE + "download";
export const DOWNLOAD_BULK_ATTACHMENTS_ENDPOINT = MODULE + "download/bulk";