import React, { Component } from "react";
import { connect } from "react-redux";
import { Cache } from 'aws-amplify';
import { faSearch, faEllipsisV, faPlus, faPen, faTrash, faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-dropdown-select";
import { withRouter } from "react-router-dom"; 
import Avatar from "react-avatar";
import { Table, 
    Button, 
    Container, 
    Row, 
    Col, 
    InputGroup, 
    FormControl, 
    Form, 
    Modal, 
    Dropdown, 
    Spinner, 
     } from 'react-bootstrap';
import { 
    createUser, 
    fetchUsers,
    getUser,
    selectUser, 
    searchUser,
    searchUserByOrg,
    searchUserByRole,
    deleteUser,
    trashUser,
    setFieldValueChange,
    updateUser,
    fetchRoles, 
    clearSearch, 
    ACTION_ADD_USER,
    ACTION_TRASH_USER,
    ACTION_UPDATE_USER } from '../../store/actions/settings/usersActions';
import { fetchOrganizations, getOrganization } from '../../store/actions/settings/organizationsActions';
import { toggleShowPrompt, 
    toggleShowAlert, 
    toggleDialogDisplay, 
    setFormValidated, 
    setIsEditing, 
    getUserLogs, 
    validateEmail,
    ERROR_ALERT } from '../../store/actions/generalActions';
import { ConfirmDialog, Pagination, SortButton, DisplayMoment } from '../../common/Utilities';
import * as config from '../../app/config';

class UsersManagement extends Component {

    componentDidMount() {
        this.props.fetchUsers();
        this.props.fetchOrganizations(null, null, 99, null, false);
        this.props.fetchRoles();
        this.props.clearSearch();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        
        const user  = this.props.users.singleUser;
        let errorMessage = '';
        let email = null;
    
        if (typeof(user.email) !== 'undefined') {
            email = user.email;
        }
        
        if (!validateEmail(email)) {
            errorMessage = 'Invalid email address.';
            this.props.toggleShowAlert(true, ERROR_ALERT, errorMessage);
        }
        else {
            this.props.setFormValidated(true);
        
            const form = e.currentTarget;
    
            if (form.checkValidity() === false) {
                e.preventDefault();
                e.stopPropagation();
            }
            else {
                const action = this.props.general.actionType;

                if (action=== ACTION_ADD_USER) {
                    this.props.createUser();
                }
                else if (action === ACTION_UPDATE_USER) {
                    this.props.updateUser();
                }
    
                this.props.setFormValidated(false);
                this.props.toggleDialogDisplay(false);
            }
        }
    }

    userModal = () => {
        const user = this.props.users.singleUser;
        const action = this.props.general.actionType;
        const orgs = this.props.organizations.organizations;
        const roles = this.props.users.roles;
        let modalTitle = '';
        let orgsList = '';
        let rolesList = '';
        let last_name = '';
        let first_name = '';
        let middle_name = '';
        let email = '';
        let role = '';
        let organization = '';

        if (orgs !== null) {
            let option = '';
            orgsList = orgs.map((org, key) => {
                return <option key={key} value={org.id}>{org.name}</option>
            })
        }

        if (roles != null) {
            rolesList = roles.map((role, key) => {
                return <option key={key} value={role.role}>{role.name}</option>
            })
        }

        if (action === ACTION_ADD_USER) {
            modalTitle = 'Add New User';
        }
        else if (action === ACTION_UPDATE_USER) {
            modalTitle = 'Update User';
        }

        if (action === ACTION_UPDATE_USER) {
            last_name = user.last_name;
            first_name = user.first_name;
            middle_name = user.middle_name;
            email = user.email;
            role = user.role;
            if (typeof user.organization !== "undefined") {
                const org = user.organization;
                if (org) {
                    organization = org.id;
                }
            }
        }

        return (
            <Modal show={this.props.general.showFormDialog} onHide={() => {
                this.props.toggleDialogDisplay(false);
            }} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={this.props.general.validated} onSubmit={this.handleSubmit}>
                    <Modal.Body>
                            <Form.Group controlId="first_name">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control required type="text" defaultValue={first_name} onChange={this.props.setFieldValueChange}/>
                                <Form.Control.Feedback type="invalid">
                                    Please enter first name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="middle_name">
                                <Form.Label>Middle Name</Form.Label>
                                <Form.Control type="text" defaultValue={middle_name} onChange={this.props.setFieldValueChange}/>
                            </Form.Group>
                            <Form.Group controlId="last_name">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control required type="text" defaultValue={last_name} onChange={this.props.setFieldValueChange}/>
                                <Form.Control.Feedback type="invalid">
                                    Please enter last name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control required type="text" 
                                    defaultValue={email} 
                                    onChange={this.props.setFieldValueChange}/>
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="role">
                                <Form.Label>Role</Form.Label>
                                <Form.Control required as="select" defaultValue={role} onChange={this.props.setFieldValueChange}>
                                    <option value="">--SELECT ROLE--</option>
                                    {rolesList}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please select a role.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="org_id">
                                <Form.Label>Organization</Form.Label>
                                <Form.Control required as="select" defaultValue={organization} onChange={this.props.setFieldValueChange}>
                                    <option value="">--SELECT ORGANIZATION--</option>
                                    {orgsList}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please select an organization.
                                </Form.Control.Feedback>
                            </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.props.toggleDialogDisplay(false);
                            this.props.setFormValidated(false);
                            if (action === ACTION_UPDATE_USER && this.props.general.isEditing) {
                                this.props.toggleShowPrompt(true, ACTION_UPDATE_USER);
                            }
                        }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }

    showAddUserButton = () => {
        if (this.props.general.userInfo.role === "ROLE_PLATFORM_ADMIN" ||
        this.props.general.userInfo.role === "ROLE_ORG_ADMIN") {
            return (
                <Button className="float-right mb-3" onClick={() => {
                    this.props.selectUser({});
                    this.props.toggleDialogDisplay(true, ACTION_ADD_USER);
                    this.props.fetchOrganizations(null, null, 99, null, false);
                }}> 
                    <FontAwesomeIcon icon={faPlus} /> Add User
                </Button>
            ) 
        }
    }

    promptModal = () => {
        const action = this.props.general.actionType;
        let modalTitle = '';
        let modalBody = '';
        let actionButton = '';

        if (action === ACTION_UPDATE_USER) {
            modalTitle = 'Update User';
            modalBody = 'Your changes have not been saved. Are you sure you want to discard your changes?';
            actionButton = () => this.props.updateUser(this.props.users.singleUser);
        }
        else if (action === ACTION_TRASH_USER){
            modalTitle = 'Delete User';
            modalBody = 'Are you sure you want to delete this user?';
            actionButton = () => this.props.trashUser(this.props.users.singleUser.id);
        }

        const onHideFunction = () => {
            this.props.toggleShowPrompt(false);
            this.props.setIsEditing(false);
        }

        const onClickCancel = () => {
            this.props.toggleShowPrompt(false);
            if (action === ACTION_UPDATE_USER) {
                this.props.toggleDialogDisplay(true, ACTION_UPDATE_USER);
            }
        }

        const onClickConfirm = () => {
            if (action === ACTION_UPDATE_USER) {
                this.props.toggleShowPrompt(false);
                this.props.setIsEditing(false);
            }
            else {
                actionButton();
                this.props.toggleShowPrompt(false);
                this.props.toggleDialogDisplay(false);
            }
        }

        return (
            <ConfirmDialog 
                show={this.props.general.showPrompt} 
                modalTitle={modalTitle} 
                modalBody={modalBody} 
                onHideFunction={onHideFunction}
                onClickCancel={onClickCancel}
                onClickConfirm={onClickConfirm}
            />
        ) 
    }

    getOrgName = (orgId) => {
        const orgs = this.props.organizations.organizations;
        let orgName = '';
        orgs && orgs.filter(data => {
            if (data.id === orgId) {
                return data
            }
        }).map(org => {
            orgName = org.name;
        })

        return orgName;
    }

    renderUsers = (users) => {
        if (users === null && !this.props.users.isPaginating) {
            return <p>Loading...</p>
        }
        const organizations = this.props.organizations.organizations;
        const roles = this.props.users.roles;
        const error = this.props.users.error;
        const searchText = this.props.users.searchByText;
        
        let noUserResult = 'No users so far';
        if (searchText) {
            noUserResult = 'No results found';
        }

        let userLists;
        let avatar;

        if (error === null) {
            if (users !== null) {
                userLists = users.length ? (
                    users.map((user, key) => {
                        let userOrg = '';
                        if (typeof(user.organization) !== "undefined") {
                            const org = user.organization;
                            if(org) {
                                userOrg = org.name;
                            }
                        }
                        if (user.photo_url === null) {
                            avatar = <Avatar name={user.first_name + " " + user.last_name} size={config.AVATAR_SIZE} round={config.AVATAR_RADUIS} />
                        }
                        else {
                            avatar = <Avatar src={user.photo_url_signed} size={config.AVATAR_SIZE} round={config.AVATAR_RADUIS} />
                        }

                        return  (
                            <tr key={key}>
                                <td width="6%">
                                    {avatar}
                                </td>
                                <td width="22%">{user.first_name} {user.last_name}<br /><span className="text-muted small">{user.email}</span></td>
                                <td width="19%" className="align-center">{user.role_name}</td>
                                <td width="20%" className="align-center">{userOrg}</td>
                                <td width="17%" className="align-center">
                                    <DisplayMoment dataTime={user.created_at} />
                                </td>
                                <td width="6%">
                                    <Dropdown drop={"left"}>
                                        <Dropdown.Toggle variant="outline" id="ada-button-options">
                                            <FontAwesomeIcon icon={faEllipsisV} />
                                        </Dropdown.Toggle>                    
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => {
                                                this.props.getUser(user.id);
                                                this.props.toggleDialogDisplay(true, ACTION_UPDATE_USER);
                                            }}>
                                                <FontAwesomeIcon icon={faPen} /> Edit User
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={
                                                () => { 
                                                    this.props.getUser(user.id);
                                                    this.props.toggleShowPrompt(true, ACTION_TRASH_USER); 
                                                }
                                            }>
                                                <FontAwesomeIcon icon={faTrash} /> Remove User
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={
                                                () => { 
                                                    this.props.getUser(user.id);
                                                    this.props.getUserLogs(user.id);
                                                    this.props.history.push('/settings/logs');
                                                }
                                            }>
                                                <FontAwesomeIcon icon={faClipboardList} /> View Logs
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        )
                    })
                ) : (
                    <tr>
                        <td colSpan={6}><i>{noUserResult}</i></td>
                    </tr>
                )
            }
            else {
                userLists =<tr><td colSpan={6} className="align-center"><Spinner animation="grow" variant="primary" /></td></tr>
            }
        }
        else {
            userLists =<tr><td colSpan={6}><p className="text-danger">{error}</p></td></tr>
        }

        return (
            <div>
                {this.userModal()}
                {this.promptModal()}
                <Container fluid>
                    <h2>Users</h2>
                    <Row className="mt-5">
                        <Col md={4} sm={12}>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                placeholder="Search User"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                id="search" 
                                autoComplete="off" 
                                onChange = {(e) => {
                                    var searchText = e.target.value;
                                    if (this.delay) {
                                    clearTimeout(this.delay);
                                    }
                                    this.delay = setTimeout(() => {
                                    if (searchText) {
                                        this.props.searchUser(searchText);
                                    }
                                    else {
                                        this.props.searchUser(null, true); //clear search
                                    }
                                    }, 500)
                                    if (document.getElementById("pagination-dropdown")) {
                                        document.getElementById("pagination-dropdown").value = 1;
                                    }
                                    
                                }}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} sm={12}>
                            <Select 
                                id="ada-management-filter-orgs"
                                labelField="name"
                                valueField="role"
                                placeholder="Roles"
                                className="ada-management-filter-select-dropdown"     
                                multi={false} 
                                color="#f0781e"                 
                                keepSelectedInList={false}      
                                options={roles} 
                                clearable={true}
                                onChange={(values) => { 
                                    this.props.searchUserByRole(values);
                                    if (document.getElementById("pagination-dropdown")) {
                                        document.getElementById("pagination-dropdown").value = 1;
                                    }
                                }} />
                        </Col>
                        <Col md={4} sm={12}>
                            <Select 
                                id="ada-management-filter-orgs"
                                labelField="name"
                                valueField="id"
                                placeholder="Organization"
                                className="ada-management-filter-select-dropdown"     
                                multi={false} 
                                color="#f0781e"                 
                                keepSelectedInList={false}      
                                options={organizations}
                                clearable={true}
                                onChange={(values) => { 
                                    this.props.searchUserByOrg(values);
                                    if (document.getElementById("pagination-dropdown")) {
                                        document.getElementById("pagination-dropdown").value = 1;
                                    }
                                }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.showAddUserButton()}
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-1 float-right">
                        <Col md={6}>
                            <Pagination totalitems={this.props.users.usersTotalItems}  
                                limit={this.props.users.usersLimit}
                                callback={(page) => {
                                    let filters = {}
                                    
                                    if (this.props.users.searchByText) {
                                        Object.assign(filters, {
                                            name: this.props.users.searchByText
                                        })
                                    }
                                    
                                    if (this.props.users.searchByOrg.length > 0) {
                                        Object.assign(filters, {
                                            org_id: [this.props.users.searchByOrg]
                                        })
                                    }
                                    
                                    if (this.props.users.searchByRole.length > 0) {
                                        Object.assign(filters, {
                                            role: [this.props.users.searchByRole]
                                        })
                                    }
                                    this.props.fetchUsers(filters, page)
                                }} />
                        </Col>
                    </Row>
                    <div>
                        <Table hover>
                            <thead className="align-center">
                                <tr>
                                    <th width="6%"></th>
                                    <th width="22%">Name 
                                        <SortButton callback={(sortValue) => {
                                            this.props.fetchUsers(null, null, null, {"first_name":sortValue});
                                        }} />
                                    </th>
                                    <th width="19%">Role 
                                        <SortButton callback={(sortValue) => {
                                            this.props.fetchUsers(null, null, null, {"role":sortValue});
                                        }} />
                                    </th>
                                    <th width="20%">Organization
                                        <SortButton callback={(sortValue) => {
                                            this.props.fetchUsers(null, null, null, {"org_id":sortValue});
                                        }} />
                                    </th>
                                    <th width="17%">Date Created</th>
                                    <th width="6%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {userLists}
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </div>
        )
    }

    render() {
        console.log(this.props);
        const users = this.props.users.users;
        return(
            <div>
                {this.renderUsers(users)}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    general: state.general, 
    users:  state.users,
    organizations: state.organizations,
})

const mapDispatchToProps = (dispatch) => {
    return {
        createUser: (user) => dispatch(createUser(user)),
        fetchUsers: (...args) => dispatch(fetchUsers(...args)),
        getUser: (...args) => dispatch(getUser(...args)),
        selectUser: (...args) => dispatch(selectUser(...args)),
        searchUser: (...args) => dispatch(searchUser(...args)),
        searchUserByOrg: (...args) => dispatch(searchUserByOrg(...args)),
        searchUserByRole: (...args) => dispatch(searchUserByRole(...args)),
        deleteUser: (...args) => dispatch(deleteUser(...args)),
        trashUser: (...args) => dispatch(trashUser(...args)),
        toggleDialogDisplay: (...args) => dispatch(toggleDialogDisplay(...args)),
        setFieldValueChange: (...args) => dispatch(setFieldValueChange(...args)),
        toggleShowPrompt: (...args) => dispatch(toggleShowPrompt(...args)),
        updateUser: (...args) => dispatch(updateUser(...args)),
        fetchOrganizations: (...args) => dispatch(fetchOrganizations(...args)),
        getOrganization: (...args) => dispatch(getOrganization(...args)),
        setFormValidated: (...args) => dispatch(setFormValidated(...args)),
        fetchRoles: (...args) => dispatch(fetchRoles(...args)),
        setIsEditing: (...args) => dispatch(setIsEditing(...args)),
        getUserLogs: (...args) => dispatch(getUserLogs(...args)),
        toggleShowAlert: (...args) => dispatch(toggleShowAlert(...args)),
        clearSearch: () => dispatch(clearSearch())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersManagement))
