const initState = {
  datasets: [],
  isPaginating: false, 
  createDataset: {
    tags: '',
    uploadLinks: [],
    quality_id: 1
  }, 
  singleDataset: {
    tags: '',
    uploadLinks: [],
    quality_id: 1
  }, 
  editDataset: {
    tags: '',
    uploadLinks: [],
    quality_id: 1
  }, 
  datasetLink: {},  
  dataDictionaryField: {

  }, 
  sortValues: {
    created_at: "desc"
  },
  uploadDatasets: [], 
  singleAttachment: null, 
  bulkFileDownload: [],
  qualities: [],
  isLoading: false,
  datasetLogs: [], 
  totalTrashDatasets: 0,
  totalActiveDatasets: 0,

  currentPage: 1, 
  totalItems: 0,
  limit: 10,
  
  ddTotalItems: 0,
  ddLimit: 10, 
  
  logsTotalItems: 0,
  logsLimit: 10,
  
  searchDatasets: "", 
  searchDataDictionary: "",
  ddActionType: null, 
  showDataDictionaryForm: false, 
  showAddLinkForm: false,
  
  searchResults: [],
  searchCurrentPage: 1, 
  searchTotalItems: 0, 
  searchLimit: 10, 

  datasetFilter: {},
  recentlyViewed: [],
  actionType: null, 
  addAttachmentForms: [{
    id: 1, 
    attachmentType: "file"
  }]
}

const datasetsReducer = (state = initState, action) => {
  switch (action.type) {
      case 'FETCH_DATASETS_BEGIN':
          return {
            ...state,
            datasets: null,
            isPaginating: action.isPaginating,
          }
        
      case 'FETCH_DATASETS_SUCCESS':
          return {
            ...state,
            datasets: action.datasets
          }
        
      case 'SET_DATASET':
        return {
          ...state,
          singleDataset: Object.assign(state.singleDataset, action.dataset),
          editDataset: Object.assign(state.editDataset, action.dataset)
        }
      case 'RESET_CREATE_DATASET':
        return {
          ...state,
          createDataset: action.dataset, 
        }
      case 'RESET_DATASET':
        return {
          ...state,
          singleDataset: action.dataset, 
          editDataset: action.dataset, 
          datasetLink: {}
        }
      case 'SEARCH_DATASETS':
        return {
          ...state,
          searchDatasets:  action.keyword
        }
      case 'DATASET_SET_FIELD_ON_CHANGE': 
        return {
            ...state,
            createDataset: Object.assign(state.createDataset, action.value)
        }
      case 'ADD_DATASET_TO_UPLOAD':
        return {
          ...state,
          uploadDatasets: [...state.uploadDatasets, action.value]
        }
      case 'NEW_SET_OF_DATASET_TO_UPLOAD':
        return {
          ...state,
          uploadDatasets:  action.datasets
        }
      case 'RESET_DATASET_TO_UPLOAD':
        return {
          ...state,
          uploadDatasets: []
        }
      case 'DATASET_EDIT_SET_FIELD_ON_CHANGE':
        return {
          ...state,
          editDataset: Object.assign(state.editDataset, action.value)
        }
        
      case 'DATASETS_SET_SORT_VALUES':
        return {
            ...state, 
            sortValues: action.sortValues
        }
      case 'TOGGLE_DATA_DICTIONARY_FORM':
        let ddActionType = '';
        if (typeof action.actionType === 'undefined') {
            ddActionType = null;
        }
        else {
            ddActionType = action.actionType;
        }
        return {
            ...state, 
            showDataDictionaryForm: action.show, 
            ddActionType: ddActionType
        }
      case 'SET_DATASET_DATA_DICTIONARY':
        return {
          ...state,
          singleDataset: Object.assign(state.singleDataset, {dataDictionary: action.dataDictionary}),
        }
        
      case 'DATADICTIONARY_SET_FIELD_ON_CHANGE':
        return {
            ...state,
            dataDictionaryField: Object.assign(state.dataDictionaryField, action.value),
        }
        
      case 'SET_DATA_DICTIONARY_FIELD':
        return {
            ...state,
            dataDictionaryField: action.dataDictionaryField
        }
        
      case 'RESET_DATA_DICTIONARY_FIELD':
        return {
            ...state,
            dataDictionaryField: {}
        }
        
      case 'DATASET_LINK_FIELD_ON_CHANGE':
        return {
            ...state,
            datasetLink: Object.assign(state.datasetLink, action.value)
        }
        
      case 'ADD_LINK_TO_DATASET':
        return {
            ...state,
            createDataset: Object.assign(state.createDataset, {uploadLinks: [...state.createDataset.uploadLinks, action.link]})
        }
      // case 'ADD_LINK_TO_DATASET':
      //   return {
      //       ...state,
      //       uploadLinks: [...state.uploadLinks, action.link]
      //   }
      case 'REMOVE_DATASET_LINK_ITEM':
        const uploadLinks = state.singleDataset.uploadLinks;
        const newUploadLinks = uploadLinks.filter( (link, key) => {
          if (key !== action.item) {
            return link;
          }
        });
        return {
          ...state, 
          singleDataset: Object.assign(state.singleDataset, {uploadLinks: newUploadLinks})
        }
        
      case 'SET_BULK_DOWNLOAD_FILES':
        if (action.actionItem === "add") {
          return {
            ...state, 
            bulkFileDownload: [...state.bulkFileDownload, action.attachmentId]
          }
        }
        else {
          const bulkFiles = state.bulkFileDownload;
          const newBulkFileDownloads = bulkFiles.filter( (attachmentId, key) => {
            if (attachmentId !== action.attachmentId) {
              return attachmentId;
            }
          });
          return {
            ...state,
            bulkFileDownload: newBulkFileDownloads
          }
        }
        
      case 'SET_IS_LOADING':
        return {
          ...state,
          isLoading: action.loading
        }
        
      case 'SET_DATASET_QUALITIES':
        return {
          ...state,
          qualities: action.qualities
        }
        
      case 'DATASET_PAGINATION_DETAILS':
        return {
            ...state, 
            currentPage : action.page, 
            totalItems: action.items,
            limit: action.limit
        }
        
      case 'DATA_DICTIONARY_PAGINATION_DETAILS':
        return {
            ...state, 
            ddTotalItems: action.items,
            ddLimit: action.limit
        }
      case 'SEARCH_DATASET_PAGINATION_DETAILS':
        return {
            ...state, 
            searchCurrentPage : action.page, 
            searchTotalItems: action.items,
            searchLimit: action.limit
        }
      case 'SET_TOTAL_TRASHED_DATASETS':
        return {
          ...state,
          totalTrashDatasets: action.totalTrash
        }
        
      case 'SET_TOTAL_ACTIVE_DATASETS':
        return {
          ...state,
          totalActiveDatasets: action.totalActive
        }
        
      case 'SET_SELECTED_ATTACHMENT':
        return {
          ...state,
          singleAttachment: action.attachment 
        }
        
      case 'SET_DATASET_LOGS':
        return {
          ...state,
          datasetLogs: action.logs
        }
        
      case 'SET_DATASET_LOGS_PAGINATION':
        return {
          ...state,
          logsTotalItems: action.logsTotalItems,
          logsLimit: action.logsLimit
        }
      case 'SEARCH_DATA_DICTIONARY':
        return {
          ...state,
          searchDataDictionary: action.keyword
        }
      case 'TOGGLE_ADD_EXTERNAL_LINK':
        return {
          ...state,
          showAddLinkForm: action.show,
          actionType: action.actionType
        }
      case 'SET_SEARCH_RESULTS':
        return {
          ...state,
          searchResults: action.results
        }
      case 'SET_DATASET_FILTER':
        return {
          ...state,
          datasetFilter: Object.assign(state.datasetFilter, action.filter)
        }
      case 'CLEAR_DATASET_FILTER':
        return {
          ...state,
          datasetFilter: action.filter
        }
      case 'SET_RECENTLY_VIEWED':
        return {
          ...state,
          recentlyViewed: action.recentlyViewed
        }
      case 'INCREMENT_ATTACHMENT_FORM':
        let lastNum = state.addAttachmentForms.slice(-1)[0];
        if (isNaN(lastNum)) {
          lastNum = 1;
        }
        return  {
          ...state, 
          addAttachmentForms: [...state.addAttachmentForms, action.newForm] 
        }
      case 'DECREMENT_ATTACHMENT_FORM':
        return  {
          ...state, 
          addAttachmentForms: action.newNumAttachForms
        }
      case 'CLEAR_ATTACHMENT_FORM':
        return  {
          ...state, 
          addAttachmentForms: [{
            id: 1, 
            attachmentType: "file"
          }]
        }
      case 'CLEAR_SEARCH_RESULTS':
        return {
          ...state, 
          searchResults: []
        }
      default:
        return state;
        
  }
}

export default datasetsReducer

