import * as config from '../app/config';

const MODULE = config.API_GATEWAY_URL + 'organizations/';

export const LIST_ORGS_ENDPOINT = MODULE + "list";
export const LIST_ORGS_IDP_ENDPOINT = MODULE + "idp/list";
export const GET_ORG_ENDPOINT = MODULE + "get";
export const CREATE_ORG_ENDPOINT = MODULE + "create";
export const UPDATE_ORG_ENDPOINT = MODULE + "update";
export const DELETE_ORG_ENDPOINT = MODULE + "delete";
export const TRASH_ORG_ENDPOINT = MODULE + "trash";