import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom"; 
import { fetchOrganizations, 
    searchOrganization,  
    getOrganization, 
    deleteOrganization,
    toggleShowMembers,
    setFieldValueChange, 
    createOrganization, 
    updateOrganization, 
    ACTION_ADD_ORGANIZATION,
    ACTION_UPDATE_ORGANIZATION, 
    ACTION_DELETE_ORGANIZATION } from '../../store/actions/settings/organizationsActions';
import { toggleDialogDisplay, 
    toggleShowPrompt, 
    toggleShowAlert, 
    setFormValidated, 
    fetchIdentityProviders, 
    setIsEditing } from '../../store/actions/generalActions';
import { Table, 
    Button, 
    Container, 
    Row, 
    Col, 
    InputGroup, 
    FormControl, 
    Form, 
    Modal, 
    Spinner, 
    Dropdown } from 'react-bootstrap';

import { faSearch, faEllipsisV, faPlus, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmDialog, Pagination, SortButton, DisplayMoment } from '../../common/Utilities';
import * as config from '../../app/config';

class OrganizationsManagement extends Component {

    constructor(props){
        super(props);
        this.delay =  null;
    }
    
    componentDidMount() {
        const page = 1;
        const limit = 10;
        this.props.fetchOrganizations(null, page, limit);
        this.props.fetchIdentityProviders();
    }

    membersModal = () => {
        return (
            <Modal show={this.props.organizations.showMembers} onHide={() => {
                this.props.toggleShowMembers(false);
                this.props.setIsEditing(false);
            }} >
                <Modal.Header closeButton>
                    <Modal.Title>Members</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <th>Name</th>
                            <th>Role</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Member One</td>
                                <td>Curator</td>
                            </tr>
                            <tr>
                                <td>Member One</td>
                                <td>Curator</td>
                            </tr>
                            <tr>
                                <td>Member One</td>
                                <td>Curator</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        this.props.toggleShowMembers(false)
                    }}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => {
                        this.props.toggleShowMembers(false)
                    }}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.setFormValidated(true);
        
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        else {
            const action = this.props.general.actionType;
            
            if (action=== ACTION_ADD_ORGANIZATION) {
                this.props.createOrganization();
            }
            else if (action === ACTION_UPDATE_ORGANIZATION) {
                this.props.updateOrganization();
            }
  
            this.props.setFormValidated(false);
            this.props.toggleDialogDisplay(false);
        }
    }

    organizationModal() {
        const action = this.props.general.actionType;
        let orgName = '';
        let idp = '';

        let modalTitle = ''
        if (action === ACTION_ADD_ORGANIZATION) {
            modalTitle = 'Add New Organization';
        }
        else if (action === ACTION_UPDATE_ORGANIZATION) {
            const organization = this.props.organizations.singleOrganization;
            orgName = organization.name;   
            idp = organization.cognito_idp;
            modalTitle = 'Update Organization';
        }

        const idps = this.props.general.identityProviders;
        const idpList = idps.length > 0 ? (
            idps.map((idp, key) => {
                return (
                    <option key={key} value={idp.ProviderName}>{idp.ProviderName}</option>
                )
            })
        ) : (
            ''
        )
        return (
            <Modal show={this.props.general.showFormDialog} onHide={() => {
                this.props.setIsEditing(false);
                this.props.toggleDialogDisplay(false);
                this.props.setFormValidated(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={this.props.general.validated} onSubmit={this.handleSubmit}>
                    <Modal.Body>
                            <Form.Group controlId="name">
                                <Form.Label>Organization Name</Form.Label>
                                <Form.Control type="text" required defaultValue={orgName} onChange={this.props.setFieldValueChange} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter organization name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="cognito_idp">
                                <Form.Label>Identity Provider</Form.Label>
                                <Form.Control required as="select" defaultValue={idp} onChange={this.props.setFieldValueChange} >
                                    <option value="">--SELECT--</option>
                                    {idpList}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please enter identity provider.
                                </Form.Control.Feedback>
                            </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.props.setFormValidated(false);
                            this.props.toggleDialogDisplay(false);
                            if (action === ACTION_UPDATE_ORGANIZATION && this.props.general.isEditing) {
                                this.props.toggleShowPrompt(true, ACTION_UPDATE_ORGANIZATION);
                            }
                        }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }

    showAddButton = () => {
        if (this.props.general.userInfo.role === "ROLE_PLATFORM_ADMIN") {
            return (
                <Button className="float-right mb-3" onClick={() => {
                    this.props.toggleDialogDisplay(true, ACTION_ADD_ORGANIZATION)
                }}> 
                    <FontAwesomeIcon icon={faPlus} /> Add Organization
                </Button>
            )
        }
    }

    promptModal = () => {
        const action = this.props.general.actionType;
        let modalTitle = '';
        let modalBody = '';
        let actionButton = '';

        if (action === ACTION_DELETE_ORGANIZATION) {
            modalTitle = 'Delete Organization';
            modalBody = 'Are you sure you want to delete this organization?';
            actionButton = () => this.props.deleteOrganization(this.props.organizations.singleOrganization.id);
        }
        else if (action === ACTION_UPDATE_ORGANIZATION) {
            modalTitle = 'Update Organization';
            modalBody = 'Your changes have not been saved. Are you sure you want to discard your changes?';
        }
        const onHideFunction = () => {
            this.props.toggleShowPrompt(false)
        }

        const onClickCancel = () => {
            this.props.toggleShowPrompt(false);
            if (action === ACTION_UPDATE_ORGANIZATION) {
                this.props.toggleDialogDisplay(true, ACTION_UPDATE_ORGANIZATION);
            }
        }

        const onClickConfirm = () => {
            if (action === ACTION_UPDATE_ORGANIZATION) {
                this.props.toggleShowPrompt(false);
                this.props.setIsEditing(false);
            }
            else {
                actionButton();
                this.props.toggleShowPrompt(false);
                this.props.toggleDialogDisplay(false);
            }
        }
        return (
            <ConfirmDialog 
                show={this.props.general.showPrompt} 
                modalTitle={modalTitle} 
                modalBody={modalBody} 
                onHideFunction={onHideFunction}
                onClickCancel={onClickCancel}
                onClickConfirm={onClickConfirm}
            />
        )
    }

    renderOrganization(organizations, error) {
        if(!organizations && !this.props.organizations.isPaginating){
            return <p>Loading...</p>;
        }

        const searchText = this.props.organizations.search;

        let noResultText = 'No organizations so far';
        if (searchText) {
            noResultText = 'No results found';
        }

        let orgLists;
        if (error === null) {
            if (organizations !== null) {
                orgLists = organizations.length ? (
                    organizations.map((org, key) => {
                        let orgOptions;
                        if (this.props.general.userInfo.role === config.ROLE_PLATFORM_ADMIN) {
                            orgOptions = <React.Fragment>
                                            <Dropdown.Item onClick={() => {
                                                        this.props.getOrganization(org.id);
                                                        this.props.toggleDialogDisplay(true, ACTION_UPDATE_ORGANIZATION);
                                                    }}>
                                                        <FontAwesomeIcon icon={faPen} /> Edit Organization
                                                    </Dropdown.Item>
                                                    {/* <Dropdown.Item onClick={
                                                        ()=> {
                                                            this.props.toggleShowMembers(true);
                                                        }
                                                    }>
                                                        Manage Members
                                                    </Dropdown.Item> */}
                                                    <Dropdown.Item onClick={
                                                        () => { 
                                                            this.props.getOrganization(org.id);
                                                            this.props.toggleShowPrompt(true, ACTION_DELETE_ORGANIZATION); 
                                                        }
                                                    }>
                                                        <FontAwesomeIcon icon={faTrash} /> Remove Organization
                                                    </Dropdown.Item>
                                        </React.Fragment>
                        }
                        else if (this.props.general.userInfo.role === config.ROLE_ORG_ADMIN) {
                            orgOptions = <React.Fragment>
                                            <Dropdown.Item onClick={() => {
                                                this.props.getOrganization(org.id);
                                                this.props.toggleDialogDisplay(true, ACTION_UPDATE_ORGANIZATION);
                                            }}>
                                                Edit Organization
                                            </Dropdown.Item>
                                        </React.Fragment>
                        }
                    return  (
                        <tr key={key}>
                            <td>{org.name}</td>
                            <td className="align-center">{org.total_users}</td>
                            <td className="align-center">
                                <DisplayMoment dataTime={org.created_at} />
                            </td>
                            <td className="align-center">
                                <DisplayMoment dataTime={org.updated_at} />
                            </td>
                            <td width="3%">
                                <Dropdown drop={"left"}>
                                    <Dropdown.Toggle variant="outline" id="ada-button-options">
                                        <FontAwesomeIcon icon={faEllipsisV} />
                                    </Dropdown.Toggle>                    
                                    <Dropdown.Menu>
                                        {orgOptions}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    )
                })
                ) : (
                    <tr>
                        <td colSpan={5}><i>{noResultText}</i></td>
                    </tr>
                )
            }
            else {
                orgLists =<tr><td colSpan={5} className="align-center"><Spinner animation="grow" variant="primary" /></td></tr>
            }
        }
        else {
            orgLists =<tr><td colSpan={5}><p className="text-danger">{error}</p></td></tr>
        }
        return (
            <div>
                {this.organizationModal()}
                {this.membersModal()}
                {this.promptModal()}
                <Container fluid>
                    <h2>Organizations</h2>
                    <Row className="mt-5">
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                placeholder="Search Organization"
                                aria-label="Organization"
                                aria-describedby="basic-addon1"
                                id="search" 
                                autoComplete="off" 
                                onChange={(e) => {
                                    var searchText = e.target.value;
                                    if (this.delay) {
                                    clearTimeout(this.delay);
                                    }
                                    this.delay = setTimeout(() => {
                                    if (searchText) {
                                        this.props.searchOrganization(searchText);
                                    }
                                    else {
                                        this.props.searchOrganization(searchText, true); //clear search
                                    }}, 500)
                                    if (document.getElementById("pagination-dropdown")) {
                                        document.getElementById("pagination-dropdown").value = 1;
                                    }
                                }} 
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.showAddButton()}
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-1 float-right">
                        <Col md={6}>
                            <Pagination totalitems={this.props.organizations.orgsTotalItems}  
                                limit={this.props.organizations.orgsLimit}
                                callback={(page) => {
                                    let filters = {}
                                    
                                    if (this.props.organizations.search) {
                                        Object.assign(filters, {
                                            name: this.props.organizations.search
                                        })
                                    }
                                    const limit  = 10;
                                    this.props.fetchOrganizations(filters, page, limit)
                                }} />
                        </Col>
                    </Row>
                    <Table hover>
                        <thead align="center">
                            <tr>
                                <th>Name 
                                    <SortButton callback={(sortValue) => {
                                        this.props.fetchOrganizations(null, null, null, {"name":sortValue});
                                    }} />
                                </th>
                                <th>Members</th>
                                <th>Date Created</th>
                                <th>Date Modified</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {orgLists}
                        </tbody>
                    </Table>
                </Container>
            </div>
        )
    }
    

    render(){
        const organizations = this.props.organizations.organizations;
        const error = this.props.organizations.error;

        return (
            this.renderOrganization(organizations,error)
        )
    }
}

const mapStateToProps = (state) => ({
    general: state.general, 
    organizations: state.organizations
})

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrganizations: (...args) => dispatch(fetchOrganizations(...args)),
        createOrganization: () => dispatch(createOrganization()),
        updateOrganization: () => dispatch(updateOrganization()),
        getOrganization: (...args) => dispatch(getOrganization(...args)),
        searchOrganization: (...args) => dispatch(searchOrganization(...args)),
        deleteOrganization: (...args) => dispatch(deleteOrganization(...args)),
        toggleDialogDisplay: (...args) => dispatch(toggleDialogDisplay(...args)),
        toggleShowMembers: (...args) => dispatch(toggleShowMembers(...args)), 
        toggleShowPrompt: (...args) => dispatch(toggleShowPrompt(...args)),
        toggleShowAlert: (...args) => dispatch(toggleShowAlert(...args)),
        setFieldValueChange: (...args) => dispatch(setFieldValueChange(...args)),
        setFormValidated: (...args) => dispatch(setFormValidated(...args)),
        setIsEditing: (...args) => dispatch(setIsEditing(...args)),
        fetchIdentityProviders: () => dispatch(fetchIdentityProviders())
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationsManagement))
