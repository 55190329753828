import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTokens } from '../../store/helpers/tokens' ;
import { Auth  } from 'aws-amplify';

class TokenWatcher extends Component {

    componentDidMount() {
    }

    watchToken(){
        Auth.currentSession().then((item)=>{
            if(item.isValid()){
                this.props.setTokens(item);
            }
        });
        
        setTimeout(()=> { this.watchToken() },((10*60)*1000));
    }

    render() {
        this.watchToken()
        return (
            <div></div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setTokens: (...args) => setTokens(...args),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenWatcher)